<div class="domain-framework-listing-container" id="{{parentContainerID}}">
  <div class="header-section">
    <div class="heading lato-16-n-vw text-white">{{ title ? title : ''}}</div>
    <div class="active-inactive-buttons" *ngIf="this.isFirstPartyOn && this.isThirdPartyOn">
      <div class="tab inActive-tab lato-14-n-vw"
        [ngClass]="{'active': tabTitle== frameworkTypeTabEnum.firstParty}"
        (click)="onTabClick(frameworkTypeTabEnum.firstParty, true)">
        1st Party
      </div>
      <div class="tab active-tab lato-14-n-vw"
        [ngClass]="{'active': tabTitle === frameworkTypeTabEnum.thirdParty}"
        (click)="onTabClick(frameworkTypeTabEnum.thirdParty, false)">
        3rd Party
      </div>
    </div>
  </div>
  <div class="main-section">
    <div class="tabset">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" (activeIdChange)="this.clearSearch()"
        class="nav-tabs">
        <li [ngbNavItem]="TAB_ENUMS.RISK">
          <a ngbNavLink>Risk <span
              *ngIf="showFrameworkCounts">({{this.selectedCount[this.tabTitle][1]}})</span></a>
          <ng-template ngbNavContent>
            <div class="risk-framework-container">
              <div class="toggle-container"
                *ngFor="let riskFramework of riskFrameworks[this.tabTitle]; let ir = index">
                <div class="toggle-btn-container-wrapper">
                  <div class="btn-checkbox" *ngIf="showCheckbox"
                    [ngClass]="{'disable-checkbox': disableFrameworkChanges}">
                    <cygov-checkbox [id]="'risk'+ir+'checkbox'" [width]="1.9" [height]="1.9"
                      [useViewPortDimensions]="true" [isChecked]="riskFramework.status"
                      (changed)="changeToggleSelection($event, 'parent', riskFrameworks[this.tabTitle], ir)">
                    </cygov-checkbox>
                  </div>
                  <div class="toggle-btn-container" *ngIf="!showCheckbox">
                    <cygov-toggle-button [isChecked]="riskFramework.status"
                      [id]="'risk'+ir+'toggle'"
                      (changed)="changeToggleSelection($event, 'parent', riskFrameworks[this.tabTitle], ir)">
                    </cygov-toggle-button>
                  </div>
                  <div class="toggle-text text-ellipsis" [ngbTooltip]="riskFramework.name"
                    [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
                    (mouseout)="isFrameworkNameEllipsis = false"
                    [disableTooltip]="!isFrameworkNameEllipsis" tooltipClass="toogle-text-tooltip"
                    [ngClass]="{'blue-ellipsis' : riskFramework.status, 'normal-ellipsis': !riskFramework.status, 'disable-checkbox': disableFrameworkChanges}">
                    <span class="lato-16-n-vw value"
                      [ngStyle]="{'color': riskFramework.status ? toggleActiveTextColor : toggleInactiveTextColor}"
                      (click)="riskFramework?.children?.length ?  openFrameworkDetailList(riskFramework) : changeToggleSelection(!riskFramework.status, 'parent', riskFrameworks[this.tabTitle], ir)">{{
                      riskFramework.name }}</span>
                  </div>

                </div>

                <div *ngIf="riskFramework?.children?.length"
                  (click)="openFrameworkDetailList(riskFramework)" class="arrow-icon" cygovSvgIcon
                  [svgName]="'arrow-right-type3'"
                  [ngClass]="{'arrow-icon-blue' : riskFramework.status}"></div>
              </div>
            </div>

            <ng-container *ngTemplateOutlet="frameworkDetailArea;">
            </ng-container>

          </ng-template>
        </li>

        <li [ngbNavItem]="TAB_ENUMS.COMPLIANCE" *ngIf="this.isFirstParty">
          <a ngbNavLink>Compliance <span
              *ngIf="showFrameworkCounts && tabTitle === '1st Party'">({{this.selectedCount[this.tabTitle][2]}})</span></a>
          <ng-template ngbNavContent>
            <div class="risk-framework-container" *ngIf="!showDetailFrameworkModal">
              <div class="toggle-container"
                *ngFor="let compliance of complianceFrameworks[this.tabTitle]; let ir = index">
                <div class="toggle-btn-container-wrapper">
                  <div class="btn-checkbox" *ngIf="showCheckbox"
                    [ngClass]="{'disable-checkbox': disableFrameworkChanges}">
                    <cygov-checkbox [id]="'compliance'+ir+'checkbox'" [width]="1.9" [height]="1.9"
                      [useViewPortDimensions]="true" [isChecked]="compliance.status"
                      (changed)="changeToggleSelection($event, 'parent', complianceFrameworks[this.tabTitle], ir)">
                    </cygov-checkbox>
                  </div>
                  <div class="toggle-btn-container" *ngIf="!showCheckbox">
                    <cygov-toggle-button [isChecked]="compliance.status"
                      [id]="'compliance'+ir+'toggle'"
                      (changed)="changeToggleSelection($event, 'parent', complianceFrameworks[this.tabTitle], ir)">
                    </cygov-toggle-button>
                  </div>
                  <div class="toggle-text text-ellipsis" [ngbTooltip]="compliance.name"
                    [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
                    (mouseout)="isFrameworkNameEllipsis = false"
                    [disableTooltip]="!isFrameworkNameEllipsis" tooltipClass="toogle-text-tooltip"
                    [ngClass]="{'blue-ellipsis' : compliance.status, 'normal-ellipsis': !compliance.status, 'disable-checkbox': disableFrameworkChanges}">
                    <span class="lato-16-n-vw value"
                      [ngStyle]="{'color': compliance.status ? toggleActiveTextColor : toggleInactiveTextColor}"
                      (click)="compliance?.children?.length ?  openFrameworkDetailList(compliance) : changeToggleSelection(!compliance.status, 'parent', complianceFrameworks[this.tabTitle], ir)">{{
                      compliance.name }}</span>
                  </div>

                </div>

                <div *ngIf="compliance?.children?.length"
                  (click)="openFrameworkDetailList(compliance)" class="arrow-icon" cygovSvgIcon
                  [svgName]="'arrow-right-type3'"
                  [ngClass]="{'arrow-icon-blue' : compliance.status}"></div>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="frameworkDetailArea;">
            </ng-container>
          </ng-template>
        </li>
        <li [ngbNavItem]="TAB_ENUMS.CUSTOM"
          *ngIf="customRiskFramework[this.tabTitle]?.length || customComplianceFramework[this.tabTitle]?.length">
          <a ngbNavLink>Custom <span
              *ngIf="showFrameworkCounts">({{this.selectedCount[this.tabTitle][3]}})</span></a>
          <ng-template ngbNavContent>
            <div class="custom-framework-wrapper">
              <div class="custom-frameworks-container">
                <div class="header-section">
                  <div class="heading lato-18-n-vw">Risk</div>
                </div>

                <div class="custom-framework-item">
                  <div class="toggle-btn-container-wrapper"
                    *ngFor="let custom of customRiskFramework[this.tabTitle]; let ir = index">
                    <div class="btn-checkbox" *ngIf="showCheckbox"
                      [ngClass]="{'disable-checkbox': disableFrameworkChanges}">
                      <cygov-checkbox [id]="'custom-risk-' + tabTitle + '-' +ir +'checkbox'"
                        [width]="1.9" [height]="1.9" [useViewPortDimensions]="true"
                        [isChecked]="custom.status"
                        (changed)="changeToggleSelection($event, 'parent', customRiskFramework[this.tabTitle], ir)">
                      </cygov-checkbox>
                    </div>
                    <div class="toggle-btn-container" *ngIf="!showCheckbox"
                      [ngClass]="{'disable-checkbox': disableFrameworkChanges}">
                      <cygov-toggle-button [isChecked]="custom.status"
                        [id]="'custom-risk-' + tabTitle + '-' +ir+'toggle'"
                        (changed)="changeToggleSelection($event, 'parent', customRiskFramework[this.tabTitle], ir)">
                      </cygov-toggle-button>
                    </div>
                    <div class="toggle-text text-ellipsis" [ngbTooltip]="custom.name"
                      [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
                      (mouseout)="isFrameworkNameEllipsis = false"
                      [disableTooltip]="!isFrameworkNameEllipsis" tooltipClass="toogle-text-tooltip"
                      [ngClass]="{'blue-ellipsis' : custom.status, 'normal-ellipsis': !custom.status}">
                      <span class="lato-16-n-vw value"
                        [ngStyle]="{'color': custom.status ? toggleActiveTextColor : toggleInactiveTextColor}"
                        (click)="custom?.children?.length ?  openFrameworkDetailList(custom) : changeToggleSelection(!custom.status, 'parent', customRiskFramework[this.tabTitle], ir)">{{
                        custom.name }}</span>
                    </div>

                    <div *ngIf="custom?.children?.length" cygovSvgIcon class="arrow-icon"
                      [svgName]="'arrow-right-type3'"
                      [ngClass]="{'arrow-icon-blue' : custom.status}"
                      (click)="openFrameworkDetailList(custom)">
                    </div>
                  </div>

                </div>
              </div>
              <div class="custom-frameworks-container" *ngIf="this.isFirstParty">
                <div class="header-section">
                  <div class="heading lato-18-n-vw">Compliance</div>
                </div>

                <div class="custom-framework-item">
                  <div class="toggle-btn-container-wrapper"
                    *ngFor="let custom of customComplianceFramework[this.tabTitle]; let ir = index">
                    <div class="btn-checkbox" *ngIf="showCheckbox"
                      [ngClass]="{'disable-checkbox': disableFrameworkChanges}">
                      <cygov-checkbox
                        [id]="'custom-compliance-' + tabTitle + '-' +ir+ disableFrameworkChanges"
                        [width]="1.9" [height]="1.9" [useViewPortDimensions]="true"
                        [isChecked]="custom.status"
                        (changed)="changeToggleSelection($event, 'parent', customComplianceFramework[this.tabTitle], ir)">
                      </cygov-checkbox>
                    </div>
                    <div class="toggle-btn-container" *ngIf="!showCheckbox">
                      <cygov-toggle-button [isChecked]="custom.status"
                        [id]="'custom-compliance-' + tabTitle + '-' +ir+ disableFrameworkChanges"
                        (changed)="changeToggleSelection($event, 'parent', customComplianceFramework[this.tabTitle], ir)">
                      </cygov-toggle-button>
                    </div>
                    <div class="toggle-text text-ellipsis" [ngbTooltip]="custom.name"
                      [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
                      (mouseout)="isFrameworkNameEllipsis = false"
                      [disableTooltip]="!isFrameworkNameEllipsis" tooltipClass="toogle-text-tooltip"
                      [ngClass]="{'blue-ellipsis' : custom.status, 'normal-ellipsis': !custom.status}">
                      <span class="lato-16-n-vw value"
                        [ngStyle]="{'color': custom.status ? toggleActiveTextColor : toggleInactiveTextColor}"
                        (click)="custom?.children?.length ?  openFrameworkDetailList(custom) : changeToggleSelection(!custom.status, 'parent', customComplianceFramework[this.tabTitle], ir)">{{
                        custom.name }}</span>
                    </div>

                    <div *ngIf="custom?.children?.length" (click)="openFrameworkDetailList(custom)"
                      cygovSvgIcon class="arrow-icon" [svgName]="'arrow-right-type3'"
                      [ngClass]="{'arrow-icon-blue' : custom.status}"></div>
                  </div>

                </div>
              </div>

            </div>

            <ng-container *ngTemplateOutlet="frameworkDetailArea;">
            </ng-container>

          </ng-template>
        </li>

      </ul>
      <div class="search-container-wrapper">
        <div cygovSvgIcon [svgName]="'new-search-icon'" class="search-icon"></div>
        <input type="text" placeholder="Search" [(ngModel)]="searchText" class="search-text-box"
          (keyup)="prepareDataForSearch()">
      </div>
      <div class="select-deselect-container" *ngIf="isSelectAllVisible">
        <cygov-checkbox [width]="1.9" [height]="1.9" [useViewPortDimensions]="true"
          [isChecked]="selectAllStatus[this.tabTitle][this.active]"
          (changed)="changeSelectAllSelection($event)">
        </cygov-checkbox>
        <span class="lato-16-n-vw text-white select-text"
          (click)="changeSelectAllSelection(!selectAllStatus[this.tabTitle][this.active])">Select
          All</span>
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>

<ng-template #frameworkDetailArea>
  <ng-container *ngIf="showDetailFrameworkModal">
    <div class="frameworks-detail-list-container">
      <div class="detail-list-wrapper">
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
          (click)="closeFrameworkGroupmodal()">
        </div>
        <div class="header-section">
          <div class="heading lato-18-n-vw text-blue">{{ this.parentFramework.name}}</div>

        </div>
        <div class="toggle-container-wrapper">
          <div class="toggle-container" *ngFor="let framework of childFrameworks; let ir = index">
            <div class="toggle-btn-container-wrapper">
              <div class="btn-checkbox" *ngIf="showCheckbox">
                <cygov-checkbox [width]="1.9" [height]="1.9" [useViewPortDimensions]="true"
                  [isChecked]="framework.status" (changed)="changeToggleSelection($event, 'child', null,
                ir)">
                </cygov-checkbox>
              </div>
              <div class="toggle-btn-container" *ngIf="!showCheckbox">
                <cygov-toggle-button [isChecked]="framework.status" [id]="'childFramework'+ir"
                  (changed)="changeToggleSelection($event, 'child', null,
              ir)">
                </cygov-toggle-button>
              </div>
              <div class="toggle-text text-ellipsis">
                <span class="lato-16-n-vw value"
                  [ngStyle]="{'color': framework.status ? toggleActiveTextColor : toggleInactiveTextColor}"
                  (click)="changeToggleSelection(!framework.status, 'child', null, ir)">{{
                  framework.name }}</span>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  </ng-container>
</ng-template>