import { Injectable } from '@angular/core';
import { UtilsService } from 'app/shared/utils.service';
import { APIService, FrameworkSettings, GetDomainSettingsQuery, StandardType } from 'app/API.service';
import { FileService } from './file.service';
import { DomainEnum } from './enums/domain.enum';
import { FrameworkEnumTypes } from './enums/framework-enum-types.enum';
import { Router } from '@angular/router';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { domainToASCII } from 'url';

@Injectable({
  providedIn: 'root',
})
export class DomainFrameworkService {
  domainSetting = null; // basic settings related to domain
  average = null;
  isDomainSettingExists: boolean = false;
  // domain frameworks settings variables
  private _domainId: string;
  private _frameworksData: any;
  private _frameworkSettings: any;
  public restrictedFrameworks = [];
  currentDomain: GetDomainSettingsQuery;
  StandardEnum: any;
  RiskFrameworkEnum: any;
  VendorRiskFrameworkEnum: any;
  allFrameworksEnum: any;
  domainSettingsData: any = null;
  getSettingFromDomain: any = null;
  kmsStatus: any = true;
  createDomainSettingsInProgress: boolean = false;

  constructor(
    private api: APIService,
    private fileService: FileService,
    private router: Router,
    private customApi: CUSTOMAPIService
  ) {}

  // methods for domain frameworks settings
  public get domainId(): string {
    return this._domainId;
  }

  public get frameworksData(): any {
    return this._frameworksData;
  }

  public get frameworkSettings(): any {
    return this._frameworkSettings ? JSON.parse(this._frameworkSettings.settings) : null;
  }

  public set frameworkSettings(value) {
    this._frameworkSettings = value;
  }

  public static getDomain(): string {
    return window.location.origin;
  }

  public static getHostName(): string {
    return window.location.hostname;
  }

  public static createDomainInput(set): any {
    const domain = DomainFrameworkService.getDomain();
    const key = DomainFrameworkService.getHostName();
    const input = {
      domain,
      key,
      average: 0,
      settings: JSON.stringify(set),
      allowedRoutes: [
        'clients',
        'first-party',
        'third-party',
        'vendor',
        'board',
        'board-netskope',
        'binary-edge',
        'findings',
        'training-center',
        'admin',
        'generator',
      ],
    };
    return input;
  }

  async init(): Promise<void> {
    await this.fileService.init();
    this.StandardEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.STANDARD_ENUM);
    this.RiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.RISK_FRAMEWORK_ENUM);
    this.VendorRiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(
      FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM
    );
    this.allFrameworksEnum = { ...this.StandardEnum, ...this.RiskFrameworkEnum, ...this.VendorRiskFrameworkEnum };
    if (!this._frameworksData) {
      await this.initFrameworksData();
    }

    // getting domain from current url and set it in domainSetting variable of entity service

    // load domain frameworks settings
    this.populateRestrictedFrameworks();
  }

  async initFrameworksData(): Promise<void> {
    if (!this._frameworksData) {
      const fileLink = await this.fileService.downloadFileFromS3('public/GLOBAL_OBJECTS/frameworks.json');
      const fileData = await this.fileService.getFile(fileLink);
      if (fileData) {
        this._frameworksData = JSON.parse(fileData);
      } else {
        console.error('initFrameworksData failed');
      }
    }
  }

  getFrameworkName(frameworkKey: string): string {
    // if framework name is not found in the array, return framework key string manipulations
    return this.allFrameworksEnum[frameworkKey]
      ? this.allFrameworksEnum[frameworkKey]
      : frameworkKey?.replace(/_/g, ' ');
  }

  getFrameworkKey(frameworkName: string): string {
    return UtilsService.getEnumKey(this.allFrameworksEnum, frameworkName);
  }

  isFrameworkKey(key: string): boolean {
    return !!this.allFrameworksEnum[key];
  }

  async prepareDomainFrameworksData(): Promise<void> {
    try {
      if (!this._frameworksData) {
        await this.initFrameworksData();
      }
      const updatedData = await this.checkFrameworksUpdate(this.getSettingFromDomain);
      if (updatedData) {
        this.domainSettingsData = JSON.parse(updatedData.settings);
        this.kmsStatus = updatedData?.kmsStatus;
      }
    } catch (e) {
      console.log('error in prepareDomainFrameworksData ==> ', e.toString());
    }
  }

  // Create domain FrameworkSettings
  createFrameworkSettings(id: string, settings: any): Promise<FrameworkSettings> {
    if (!id || !settings) {
      return Promise.reject('createFrameworkSettings Error - id and settings are required');
    }
    try {
      return this.customApi.CreateFrameworkSettings({ id, settings: JSON.stringify(settings) });
    } catch (error) {
      console.log('error in createFrameworkSettings ==> ', error);
    }
  }
  updateFrameworkSettings(id: string, settings: any): Promise<any> {
    if (!id || !settings) {
      return Promise.reject('updateFrameworkSettings Error - id and settings are required');
    }
    try {
      return this.customApi.UpdateFrameworkSettings({ id, settings: JSON.stringify(settings) });
    } catch (error) {
      console.log('error in updateFrameworkSettings ==> ', error);
    }
  }
  updateDomainSettings(id: string, settings: any, kmsStatus = null): Promise<any> {
    if (!id || !settings) {
      return Promise.reject('updateFrameworkSettings Error - id and settings are required');
    }
    try {
      return this.customApi.UpdateDomainSettings(
        { id, settings: JSON.stringify(settings), ...(kmsStatus !== null && { kmsStatus }) },
        { domain: { eq: DomainFrameworkService.getDomain() } }
      );
    } catch (error) {
      console.log('error in updateFrameworkSettings ==> ', error);
    }
  }

  async checkFrameworksUpdate(existFrameworkSettingsRaw: any): Promise<any> {
    let updatedFrameworkSettings = null;
    let frameworkNameChanged = false;
    const existFrameworkSettings = existFrameworkSettingsRaw?.settings
      ? JSON.parse(existFrameworkSettingsRaw.settings)
      : null;
    const newFrameworkSettings = this.initFrameworkSettings();

    if (existFrameworkSettings) {
      Object.keys(newFrameworkSettings).forEach(fwType => {
        // check if only framework name changed
        const newFrameworkSettingsMap = UtilsService.mapBy(newFrameworkSettings[fwType], 'key');
        existFrameworkSettings[fwType].forEach(existEl => {
          if (newFrameworkSettingsMap[existEl.key] && existEl?.name !== newFrameworkSettingsMap[existEl.key]?.name) {
            existEl.name = newFrameworkSettingsMap[existEl.key].name;
            frameworkNameChanged = true;
          }
        });
        //  check if new frameworks were added
        const newSettingsFlat = newFrameworkSettings[fwType].map(a => a.key);
        const existSettingsFlat = existFrameworkSettings[fwType].map(b => b.key);
        const addDiffrence = newSettingsFlat.filter(x => !existSettingsFlat.includes(x));
        const removeDifference = existSettingsFlat.filter(x => !newSettingsFlat.includes(x));
        if (addDiffrence && addDiffrence.length) {
          const differenceObjArr = addDiffrence.map(fwKey =>
            newFrameworkSettings[fwType].find(item => item.key?.toLowerCase() === fwKey.toLowerCase())
          );
          updatedFrameworkSettings = updatedFrameworkSettings || existFrameworkSettings;
          updatedFrameworkSettings[fwType] = updatedFrameworkSettings[fwType].concat(differenceObjArr);
        }
        if (removeDifference && removeDifference.length) {
          updatedFrameworkSettings = updatedFrameworkSettings || existFrameworkSettings;
          updatedFrameworkSettings[fwType] = existFrameworkSettings[fwType].filter(
            fwKey => !removeDifference.find(item => fwKey.key?.toLowerCase() === item.toLowerCase())
          );
          // updatedFrameworkSettings = updatedFrameworkSettings || existFrameworkSettings;
          // updatedFrameworkSettings.filter(element=>)
          // updatedFrameworkSettings[fwType] = updatedFrameworkSettings[fwType].concat(differenceObjArr);
        }
      });
    } else {
      updatedFrameworkSettings = newFrameworkSettings;
    }

    if (!updatedFrameworkSettings && frameworkNameChanged) {
      updatedFrameworkSettings = existFrameworkSettings;
    }

    return updatedFrameworkSettings
      ? await this.updateDomainSettings(this.domainId, updatedFrameworkSettings)
      : existFrameworkSettingsRaw;
  }

  checkCustomRiskFramework(key: string): boolean {
    return Object.keys(this.frameworksData).some(domain => {
      if (domain === 'all') {
        return false;
      }
      const domainObject = this.frameworksData[domain];
      if (domainObject.RISK_FRAMEWORKS) {
        return domainObject.RISK_FRAMEWORKS.some(el => el.key === key);
      }
    });
    return false;
  }

  checkCustomComplianceFramework(key: string): boolean {
    return Object.keys(this.frameworksData).some(domain => {
      if (domain === 'all') {
        return false;
      }
      const domainObject = this.frameworksData[domain];
      if (domainObject.COMPLIANCE_FRAMEWORKS) {
        return domainObject.COMPLIANCE_FRAMEWORKS.some(el => el.key === key);
      }
      return false;
    });
  }
  checkDomainFramework(name: string, arrayKey): boolean {
    let status = false;
    const domain = DomainFrameworkService.getDomain().replace(/^https?:\/\//, '');

    const matchedKey = Object.keys(this.frameworksData).filter(key => key.replace(/^https?:\/\//, '').includes(domain));

    const matchedFrameworks = [];
    for (const key of matchedKey) {
      if (arrayKey in this.frameworksData[key]) {
        matchedFrameworks.push(...this.frameworksData[key][`${arrayKey}`]);
      }
    }

    if (matchedFrameworks?.length) {
      matchedFrameworks.forEach(item => {
        if (item && item.key?.toLowerCase() === name.toLowerCase()) {
          status = true;
        }
      });
    }
    const allDomainFrameworks = this.frameworksData.all[`${arrayKey}`];

    const itm = allDomainFrameworks.find(item => item.key?.toLowerCase() === name.toLowerCase());
    if (itm) {
      status = itm.status;
    }
    return status;
  }
  checkCustomVendorFramework(key: string): boolean {
    return Object.keys(this.frameworksData).some(domain => {
      if (domain === 'all') {
        return false;
      }
      const domainObject = this.frameworksData[domain];
      if (domainObject.VENDOR_FRAMEWORKS) {
        return domainObject.VENDOR_FRAMEWORKS.some(el => el.key === key);
      }
    });
    return false;
  }
  checkRiskFrameworkGroup(key: string): any {
    for (const domain of Object.keys(this.frameworksData)) {
      const domainObject = this.frameworksData[domain];
      if (domainObject.RISK_FRAMEWORKS) {
        const foundElement = domainObject.RISK_FRAMEWORKS.find(element => element.key === key);
        if (foundElement) {
          return foundElement.frameworkGroup;
        }
      }
    }
    return null;
  }
  checkComplianceFrameworkGroup(key: string): any {
    for (const domain of Object.keys(this.frameworksData)) {
      const domainObject = this.frameworksData[domain];
      if (domainObject.COMPLIANCE_FRAMEWORKS) {
        const foundElement = domainObject.COMPLIANCE_FRAMEWORKS.find(element => element.key === key);
        if (foundElement) {
          return foundElement.frameworkGroup;
        }
      }
    }
    return null;
  }
  checkVendorFrameworkGroup(key: string): any {
    for (const domain of Object.keys(this.frameworksData)) {
      const domainObject = this.frameworksData[domain];
      if (domainObject.VENDOR_FRAMEWORKS) {
        const foundElement = domainObject.VENDOR_FRAMEWORKS.find(element => element.key === key);
        if (foundElement) {
          return foundElement.frameworkGroup;
        }
      }
    }
    return null;
  }

  initFrameworkSettings(): any {
    const riskFrameWorkList = [];
    Object.keys(this.RiskFrameworkEnum).forEach(element => {
      const isChecked = this.checkDomainFramework(element, 'RISK_FRAMEWORKS');
      if (isChecked) {
        riskFrameWorkList.push({
          key: element,
          name: this.RiskFrameworkEnum[element],
          type: StandardType.RISK_FRAMEWORK,
          checked: isChecked,
          custom: this.checkCustomRiskFramework(element),
          frameworkFamily: this.checkRiskFrameworkGroup(element),
        });
      }
    });

    // filtering compliance frameworks
    const complianceFrameworkList = [];
    Object.keys(this.StandardEnum).forEach(element => {
      const isChecked = this.checkDomainFramework(element, 'COMPLIANCE_FRAMEWORKS');
      if (isChecked) {
        complianceFrameworkList.push({
          key: element,
          name: this.StandardEnum[element],
          type: StandardType.RISK_FRAMEWORK,
          checked: isChecked,
          custom: this.checkCustomComplianceFramework(element),
          frameworkFamily: this.checkComplianceFrameworkGroup(element),
        });
      }
    });
    const vendorFrameworkList = [];
    Object.keys(this.VendorRiskFrameworkEnum).forEach(element => {
      const isChecked = this.checkDomainFramework(element, 'VENDOR_FRAMEWORKS');
      if (isChecked) {
        vendorFrameworkList.push({
          key: element,
          name: this.VendorRiskFrameworkEnum[element],
          type: StandardType.VENDOR_FRAMEWORK,
          checked: isChecked,
          custom: this.checkCustomVendorFramework(element),
          frameworkFamily: this.checkVendorFrameworkGroup(element),
        });
      }
    });

    const frameworkSettings = {
      RISK_FRAMEWORKS: riskFrameWorkList.map(riskFramework => ({
        key: riskFramework.key,
        name: riskFramework.name,
        status: riskFramework.checked,
        custom: riskFramework.custom,
        frameworkFamily: riskFramework.frameworkFamily,
      })),
      COMPLIANCE_FRAMEWORKS: complianceFrameworkList.map(standard => ({
        key: standard.key,
        name: standard.name,
        status: standard.checked,
        custom: standard.custom,
        frameworkFamily: standard.frameworkFamily,
      })),
      VENDOR_FRAMEWORKS: vendorFrameworkList.map(vendorRisk => ({
        key: vendorRisk.key,
        name: vendorRisk.name,
        status: vendorRisk.checked,
        custom: vendorRisk.custom,
        frameworkFamily: vendorRisk.frameworkFamily,
      })),
    };

    return frameworkSettings;
  }
  // getDomainSettings() {
  //   if (UtilsService.isNetskope) {
  //     this.domainSetting = {
  //       domain: DomainEnum.NETSKOPE,
  //       key: DomainEnum.menuKey,
  //       allowedRoutes: ['board-netskope', 'clients', 'upperdeck'],
  //     };
  //   }
  // }

  // Get domain allowed routes and domain average score

  async getDomainSetting(): Promise<void> {
    try {
      const domainName = DomainFrameworkService.getDomain();
      // if (this.domainSetting) {
      //   return this.domainSetting;
      // }
      const { items } = await this.customApi.DomainSettingByDomain(domainName);
      if (items && items.length) {
        this.isDomainSettingExists = true;
        if (UtilsService.isNetskope) {
          this.domainSetting = {
            domain: DomainEnum.NETSKOPE,
            key: DomainEnum.menuKey,
            allowedRoutes: ['board-netskope', 'clients', 'upperdeck'],
          };
        }

        this._domainId = items[0].id;
        this.average = items[0].average ? items[0].average : 0;
        this.kmsStatus = items[0].kmsStatus;
        this.domainSettingsData = JSON.parse(items[0].settings);
        this.getSettingFromDomain = items[0];
        await this.prepareDomainFrameworksData();
      } else {
        if (!this.isDomainSettingExists && !this.createDomainSettingsInProgress) {
          this.createDomainSettingsInProgress = true;
          await this.createDomainSettings();
          this.createDomainSettingsInProgress = false;
        } else {
          return new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 3000); // 5000 milliseconds = 5 seconds
          });
        }
      }
    } catch (error) {
      const message = error instanceof Error ? error.message : error;
      if (message === 'No current user') {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.location.reload();
      }
    }
  }

  // Create domain allowed routes and domain average score
  async createDomainSettings(): Promise<any> {
    try {
      const domainName = DomainFrameworkService.getDomain();
      const { items } = await this.customApi.DomainSettingByDomain(domainName);
      if (items && items.length) {
        return;
      }
      const set = this.initFrameworkSettings();
      const createdDomain = await this.customApi.CreateDomainSettings(DomainFrameworkService.createDomainInput(set));
      this.getSettingFromDomain = createdDomain;
      this.domainSettingsData = JSON.parse(createdDomain?.settings);
      this._domainId = createdDomain.id;
      await this.prepareDomainFrameworksData();

      return createdDomain;
    } catch (error) {
      console.log('catch', error);
    }
  }

  /**
   * to get the current domain.
   * @returns nothing but updates the value of the variable.
   */
  // async getDomainData(): Promise<GetDomainSettingsQuery> {
  //   try {
  //     const result = await this.getDomainSetting();
  //     return result ? result : null;
  //   } catch (e) {
  //     console.log('Cannot get the domain name: ', e);
  //     Promise.reject();
  //   }
  // }

  /**
   * function to check if the feature is allowed or not.
   * @returns the boolean flag true or false.
   */
  // async isFeatureAllowed(name: string): Promise<boolean> {
  //   // first check if the current domain is available.
  //   if (!this.currentDomain) {
  //     try {
  //       this.currentDomain = await this.getDomain();
  //     } catch (e) {
  //       console.log('Cannot fetch the domain: ', e);
  //       Promise.reject();
  //     }
  //   }

  //   // checking if the feature is present in allowed features or not.
  //   if (this.currentDomain && this.currentDomain.allowedFeatures) {
  //     const parsedFeatures = JSON.parse(this.currentDomain.allowedFeatures);
  //     return !!parsedFeatures[name];
  //   }

  //   return false;
  // }

  populateRestrictedFrameworks(): void {
    this.restrictedFrameworks = [];

    if (
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('cygovdev.com') ||
      window.location.origin.includes('test.cygovrmp.com')
    ) {
      return;
    }

    switch (true) {
      case !UtilsService.isBnBCyberSite && !UtilsService.isMidMarket:
        // Remove Beecher frameworks
        this.restrictedFrameworks.push(
          this.RiskFrameworkEnum?.PARAGON_BASIC?.toLowerCase(),
          this.RiskFrameworkEnum?.PARAGON_FULL?.toLowerCase(),
          this.RiskFrameworkEnum?.BEAZLEY?.toLowerCase(),
          this.RiskFrameworkEnum?.BEAZLEY_OT?.toLowerCase(),
          this.RiskFrameworkEnum?.B_APP_RSWOT?.toLowerCase(),
          this.RiskFrameworkEnum?.PANEL_APPLICATION?.toLowerCase(),
          this.RiskFrameworkEnum?.MID_MARKET_APPLICATION?.toLowerCase()
        );
        break;
      case !UtilsService.isBnB:
        // Remove BnB framework
        this.restrictedFrameworks.push(this.RiskFrameworkEnum?.NIST_CSF_BB?.toLowerCase());
        break;
      case !UtilsService.isOklahomaUniversity:
        // Remove NIST OU if not Oklahoma Domain
        this.restrictedFrameworks.push(this.RiskFrameworkEnum?.NIST_OU?.toLowerCase());
        break;
      case !UtilsService.isITC:
        // Remove ITC Custom if not ITC domain
        this.restrictedFrameworks.push(this.RiskFrameworkEnum?.ITC_CUSTOM?.toLowerCase());
        break;
      case !UtilsService.isCyberArk:
        // Remove ITC Custom if not ITC domain
        this.restrictedFrameworks.push(this.RiskFrameworkEnum?.CYBERARK_CSA?.toLowerCase());
        this.restrictedFrameworks.push(this.RiskFrameworkEnum?.CYBERARK_NIST_CSF_CRITICAL?.toLowerCase());
        break;
    }
  }
}
