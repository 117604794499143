import { UtilsService } from 'app/shared/utils.service';
import { IPageInfo } from 'ngx-virtual-scroller';
import { EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { TableHeader } from 'models/table-header.model';
import { GetEntityQuery, GetRoleQuery, GetUserQuery, GetQuestionnaireCompletionQuery } from 'app/API.service';
import { EntityService } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'app/auth/auth.service';
import { UserService } from 'app/shared/user.service';
import { UsersSettingsService } from 'app/users-settings/users-settings.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { ArrowsEnum } from 'app/shared/enums/shared.enum';

export interface IUserData {
  access: string;
  entities: any;
  isSelected: boolean;
  isLowerRank: boolean; // if isLowerRank is true means current user is authorized to edit/delete the user.
  user: GetUserQuery;
}

@Component({
  selector: 'cygov-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnChanges {
  @Input() userList: any[] = [];
  @Output() userSelected = new EventEmitter<(GetQuestionnaireCompletionQuery & { assignments?: Array<any> })[]>();
  @Output() next = new EventEmitter();
  @Output() onSort = new EventEmitter();
  @Output() onDelete = new EventEmitter<any[]>();
  @Input() isLoading = false;
  @Input() updatable = true;
  @Input() rolesList: any[] = []; // temporarily changing the type to any, will revert it to get role query
  @Input() childEntities: GetEntityQuery[];
  @Input() vendorHashEntities: any[];
  @Input() rootEntity: GetEntityQuery;
  // updatedUsersList
  isEditMode: boolean;
  showUserDetail = false;
  selectedUser: string = null;
  currentUser: any = null;
  currentUserId: string;
  sortData;
  isDataLoaded: boolean = false;
  isViewOnly: boolean = false;
  vendorRole: GetRoleQuery;
  currentUserRole: GetRoleQuery;
  rolesInEntity: GetRoleQuery[];
  isGlobalUserSetting: boolean;
  isBnB: boolean = UtilsService.isBnB;
  arrowsEnum = ArrowsEnum;
  scrollbatchSize = 6;
  startIndex: number = 0;
  endIndex: number = this.scrollbatchSize;
  userListToShow: any[] = [];

  constructor(
    private entityService: EntityService,
    private toastr: ToastrService,
    private authService: AuthService,
    private logger: NGXLogger,
    private usersSettingsService: UsersSettingsService,
    private userService: UserService
  ) {}

  async ngOnInit(): Promise<any> {
    this.isGlobalUserSetting = this.usersSettingsService.isGlobalUserSettings();
    this.currentUser = await this.userService.getCurrentUser();
    this.currentUserRole = this.userService.getCurrentRole();
    this.isViewOnly = this.currentUser.isViewOnly;
    this.isDataLoaded = true;
    this.currentUserId = this.currentUser.id;
    // Filter user list basis on there roles.
    this.userList = this.userService.filterUserList(this.userList);
    if (this.userList) {
      // Get All Roles in a current Entity
      this.rolesInEntity = this.isGlobalUserSetting ? [] : this.usersSettingsService.getRolesInCurrentEntity();
      // Set the roles to users based on their role id.
      // get value of isLowerRank by user role comparison with current user role.
      this.addingRankToUsers();
    }
    this.usersSettingsService.getIsEditMode().subscribe(res => {
      this.isEditMode = res;
      if (!res) {
        this.userList.forEach(user => (user.isSelected = false));
      }
    });
    this.usersSettingsService.getIsAllSelected().subscribe(res => {
      this.onSelectAll(res);
    });
    this.vendorRole = this.rolesList.find(role => role.name === AppLevelRoleEnum.VENDOR);
    this.rolesList = this.isBnB
      ? this.userService.getSortedRolesForBNB(this.rolesList)
      : this.userService.getSortedRoles(this.rolesList);

    this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.addingRankToUsers();
    if (changes?.userList?.firstChange) {
      this.sortUsers();
    } else if (!changes?.userList?.firstChange) {
      this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
      console.log('this.userList', this.userList?.length);
    }
  }

  sortUsers(): void {
    this.userList =
      this.userList?.sort((a, b) => {
        const first = a?.user?.name.toLowerCase().trim();
        const second = b?.user?.name.toLowerCase().trim();
        return first.localeCompare(second); // Directly compare strings
      }) || [];
  }

  addingRankToUsers(): void {
    this.isGlobalUserSetting = this.usersSettingsService.isGlobalUserSettings();
    try {
      if (!UtilsService.isDefined(this.rolesInEntity)) {
        this.rolesInEntity = this.isGlobalUserSetting ? [] : this.usersSettingsService.getRolesInCurrentEntity();
      }

      this.userList = this.userList.map(user => {
        user.role = this.isGlobalUserSetting
          ? this.rolesList?.find(role => role.id === user.user.roleId)
          : this.rolesInEntity?.find(role => role.id === user.user.roleId);
        user.user.role = user.role;
        return {
          ...user,
          isSelected: false,
          isLowerRank:
            user.user.id === this.currentUserId
              ? false
              : this.userService.hasPermissionHierarchy(AppLevelRoleEnum.MSSP) ||
                this.userService.hasPermissionHierarchy(AppLevelRoleEnumBnB.USER_ADMINISTRATOR) ||
                this.usersSettingsService.isCurrentRoleManageGivenRole(this.currentUserRole, user.role),
        };
      });
      this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
    } catch (e) {
      console.log('Error while parsing UserList: ', e);
    }
  }

  onDeleted(deletedUsers: any[]) {
    this.onDelete.emit(deletedUsers);
  }

  toggleList(event: IUserData & { isSelected?: boolean }): void {
    if (this.currentUserId !== event.user.id && !this.isEditMode) {
      event.isSelected = !event.isSelected;
      this.userSelected.emit(this.userList.filter(item => item.isSelected));
    }
    // UtilsService.stopBubbling(event);
  }

  onSortChanged(sortData: TableHeader): void {
    this.sortData = sortData;
    this.onSort.emit(sortData);
  }

  fetchMore(event: IPageInfo): void {
    if (this.updatable && !this.isLoading && event.endIndex === this.userList.length - 1) {
      // // Need to work with Pagination Task
      this.next.emit();
    }
  }

  async deleteSelectedUsers(): Promise<void> {
    try {
      const usersToDelete = this.userList.filter((user: any) => user.isSelected);
      if (!usersToDelete.length) {
        this.toastr.info('No user Selected');
        return;
      }

      this.toastr.info('It can take a while...', 'Deleting Users');
      const promises = [];
      usersToDelete.forEach((user: any) => {
        promises.push(this.entityService.deleteEntity(user.id));
      });
      await Promise.all(promises);
      this.userList = this.userList.filter((user: any) => !user.isSelected);
      this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
      this.toastr.success('Selected user-actions deleted successfully');
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('Failed to delete user-actions');
    }
  }

  get selectedUsers(): any[] {
    return this.userList.filter(user => user.isSelected);
  }

  onSelectAll($event: boolean) {
    this.userList = this.userList.map(user => ({ ...user, isSelected: user.isLowerRank ? $event : user.isSelected }));
    this.userSelected.emit(this.userList.filter(item => item.isSelected && item.isLowerRank));
    this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
  }

  get allUserSelected() {
    return this.userList.every(user => user.isSelected);
  }

  onUpdateItem(event: any): void {
    console.log(event);
    // console.clear();
  }
  /**
   *  update the value of isSelect in the updateUserList of users setting service
   * @param user is type of user
   */
  updateSelected(user) {
    this.usersSettingsService.updateIsSelected(user);
  }

  isVendorUser(user: any): boolean {
    return user && user.roleId && this.vendorRole && user.roleId === this.vendorRole.id;
  }

  moveScroll(event: any): void {
    if (event === this.arrowsEnum.DOWN && this.endIndex < this.userList.length) {
      this.endIndex = this.endIndex + this.scrollbatchSize;
      this.startIndex = this.startIndex + this.scrollbatchSize;
      this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
    } else if (event === this.arrowsEnum.UP && this.startIndex > 0) {
      this.endIndex = this.endIndex - this.scrollbatchSize;
      this.startIndex = this.startIndex > this.scrollbatchSize ? this.startIndex - this.scrollbatchSize : 0;
      this.userListToShow = this.userList.slice(this.startIndex, this.endIndex);
    }
  }
}
