import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityTypeEnum, FileTypeEnum, GetEntityQuery, StandardType } from 'app/API.service';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import { EntityService } from 'app/shared/entity.service';
import { ToastrService } from 'ngx-toastr';
import { NGXLogger } from 'ngx-logger';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { AdditionalRequirements } from 'app/shared/enums/common.enum';
import { ThirdPartyService } from '../third-party.service';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { VendorNotifyTypeEnum } from 'app/shared/enums/email-notifications.enum';

@Component({
  selector: 'cygov-default-settings-new-modal',
  templateUrl: './default-settings-new-modal.component.html',
  styleUrls: ['./default-settings-new-modal.component.scss'],
})
export class DefaultSettingsNewModalComponent implements OnInit {
  @Output() closeDefaultModal = new EventEmitter<boolean>();

  activeTabId = 1;
  NotifyMessages: any = [];

  sliderScore: number = 30;
  gradient = ['#F77057', '#C71B40']; // slider background gradient
  riskGradient = ['#6A11C3', '#340E60'];

  selectedOpt = '';

  entity: GetEntityQuery = null;

  assessmentData: any = {
    frameworkName: null,
    frameworkKey: null,
    level: '',
    startDate: null,
    endDate: null,
    frequencyDays: 14,
    frequencyName: null,
    artifactOption: 'Suggested',
    commentOption: 'suggested',
    enbdDomains: [],
  };
  additionalData: any = {
    requirements: [],
    certifications: [],
    isThirdParty: false,
    isFourthParty: false,
    domains: [],
    customRequirements: [],
    customCertifications: [],
  };
  customRequirements: any = [];
  customCertifications: any = [];
  customDefaultTypes = {
    SCANS: [],
    DOCUMENTS: [],
    OTHERS: [],
    CERTIFICATIONS: [],
  };
  loading = true;
  VendorRiskFrameworkEnum: any;
  isHowden = UtilsService.isHowden;
  isENBD: boolean = UtilsService.isENBD;

  constructor(
    public activeModal: NgbActiveModal,
    private fileService: FileService,
    private route: ActivatedRoute,
    private entityService: EntityService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private clientLambdaService: ClientLambdaService,
    private thirdPartyService: ThirdPartyService
  ) {
    this.VendorRiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(
      FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM
    );
  }

  async ngOnInit() {
    // getting the entity of the current root entity.
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    this.entity = await this.entityService.getEntity(entityId);
    // updating the data of the additional requirements. and risk framework (assessment)
    if (this.entity) {
      this.additionalData = {
        requirements: this.entity.defaultSetting.artifacts,
        certifications: this.entity.defaultSetting.standards,
        isThirdParty: this.entity.defaultSetting.isThirdParty,
        isFourthParty: this.entity.defaultSetting.isFourthParty,
        domains: this.entity?.defaultSetting?.domains?.length ? JSON.parse(this.entity.defaultSetting.domains) : [],
        customRequirements: this.entity.defaultSetting?.customRequirements
          ? this.entity.defaultSetting?.customRequirements
          : [],
        customCertifications: this.entity.defaultSetting?.customCertifications
          ? this.entity.defaultSetting?.customCertifications
          : [],
      };
      const parsedOtherData = this.entity?.defaultSetting?.otherData
        ? JSON.parse(this.entity?.defaultSetting?.otherData)
        : null;
      const selectedRiskName = this.entity?.defaultSetting?.selectedRisk
        ? this.entity?.defaultSetting.selectedRisk
        : this.getDefaultFrameworkName();
      this.assessmentData = {
        frameworkName: selectedRiskName,
        frameworkKey: UtilsService.getEnumKey(this.VendorRiskFrameworkEnum, selectedRiskName),
        level: parsedOtherData?.level ? parsedOtherData.level : 'Full',
        startDate: parsedOtherData?.startDate ? parsedOtherData.startDate : null,
        endDate: parsedOtherData?.endDate ? parsedOtherData.endDate : null,
        frequencyDays: parsedOtherData?.frequencyDays ? parsedOtherData.frequencyDays : 14,
        artifactOption: parsedOtherData?.artifactOption ? parsedOtherData.artifactOption : 'Suggested',
        frequencyName: this.entity?.defaultSetting?.frequency ? this.entity.defaultSetting.frequency : null,
      };
      this.entity.defaultSetting.fourthPartyWeightage = this.entity?.defaultSetting?.fourthPartyWeightage
        ? this.entity?.defaultSetting?.fourthPartyWeightage
        : 50;
      this.sliderScore = this.entity.defaultSetting.fourthPartyWeightage;
    }

    this.initializeDefaultSettingsData();
    this.loading = false;
  }

  getDefaultFrameworkName(): string {
    let defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK;
    switch (true) {
      case UtilsService.isITC:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_ITC;
        break;
      case UtilsService.isHowden:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_FULL;
        break;
      case UtilsService.isCRB:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_CRB;
        break;
      case UtilsService.isSSMHealth:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_SSM_HEALTH;
        break;
      case UtilsService.isENBD:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD;
        break;
      case UtilsService.isAdnoc:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ;
        break;
      case UtilsService.isInformatica || UtilsService.isInformaticaUat:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1;
        break;
      case UtilsService.isMoelis:
        defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS;
        break;
      // case UtilsService.isDBU:
      //   defaultFrameworkName = this.VendorRiskFrameworkEnum.VENDOR_RISK_DBU_HECVAT_LITE;
      //   break;
    }
    return defaultFrameworkName;
  }

  assessmentDataChange(event: any): void {
    let obj = null;
    if (event && this.entity) {
      obj = {
        level: event?.level,
        startDate: event?.startDate,
        endDate: event?.endDate,
        frequencyDays: event?.frequencyDays,
        artifactOption: event?.artifactOption,
      };
      this.entity.defaultSetting.selectedRisk = event?.frameworkName;
      this.entity.defaultSetting.frequency = event?.frequencyName;
      this.entity.defaultSetting.otherData = JSON.stringify(obj);
      if (this.isENBD) {
        this.entity.defaultSetting.enbdDomain = event?.enbdDomains;
      }
    }
  }
  updateCertifications(event: any): void {
    if (this.entity && event?.data?.length && event.type === AdditionalRequirements.CERTIFICATIONS) {
      this.entity.defaultSetting.standards = event?.data;
    } else if (this.entity && event?.data?.length && event.type === AdditionalRequirements.REQUIREMENTS) {
      this.entity.defaultSetting.artifacts = event?.data;
    } else if (this.entity && event?.data?.length && event.type === AdditionalRequirements.CUSTOM_REQUIREMENTS) {
      this.customRequirements = event.data;
    } else if (this.entity && event?.data?.length && event.type === AdditionalRequirements.CUSTOM_CERTIFICATIONS) {
      this.customCertifications = event.data;
    } else if (this.entity && (event.type === 'isThirdParty' || event.type === 'isFourthParty')) {
      this.entity.defaultSetting[event.type] = event?.data;
    } else if (this.entity && event.type === 'domains') {
      const finalArr = [];
      event.data.forEach(data => {
        console.log(data);
        // console.clear();
        finalArr.push({ value: event.data });
      });
      this.entity.defaultSetting.domains = JSON.stringify(finalArr);
    }
  }

  async saveDefaultSetting(): Promise<void> {
    try {
      this.loading = true;
      // we need to check if there are any customFrameworks are added or not.
      if (this.customRequirements?.length) {
        await this.uploadFilesAndFrameworks(AdditionalRequirements.CUSTOM_REQUIREMENTS);
      }
      if (this.customCertifications?.length) {
        await this.uploadFilesAndFrameworks(AdditionalRequirements.CUSTOM_CERTIFICATIONS);
      }
      // remove extra spaces from the custom days.
      this.addRemoveExtrasCustomDay();
      this.entity.defaultSetting.surveyLevel = this.entity?.defaultSetting?.surveyLevel
        ?.toUpperCase()
        ?.replaceAll(' ', '_');
      this.entity.defaultSetting.notifications = JSON.stringify(this.NotifyMessages);
      this.entity = JSON.parse(
        JSON.stringify(this.entity, (k, v) =>
          k === '__typename' ||
          k === 'activeAssessment' ||
          k === 'activeAssessmentId' ||
          k === 'vendorDetails' ||
          k === 'updatedAt'
            ? undefined
            : v
        )
      );

      // * deleting framework related attributes due to these attributes being null
      // * otherwise request throws an error
      if (this.entity && !this.entity.frameworkId) {
        delete this.entity.frameworkId;
      }
      if (this.entity && !this.entity.frameworkName) {
        delete this.entity.frameworkName;
      }
      if (this.entity && !this.entity.frameworkType) {
        delete this.entity.frameworkType;
      }
      if (this.entity?.AI) {
        delete this.entity.AI;
      }

      // check Either the WeightAge is changed or not
      if (this.sliderScore !== this.entity.defaultSetting.fourthPartyWeightage) {
        // Weightage is changed
        this.entity.defaultSetting.fourthPartyWeightage = this.sliderScore;
        // rootEntityId and 4th party weightAge value ( 10 to 50)
        await this.thirdPartyService.applyWeightAgeCalculationsForAllVendors(this.entity.id, this.sliderScore);
      }
      if (this.isENBD) {
        this.assessmentData.enbdDomains = this.entity.defaultSetting?.enbdDomain;
      }

      await this.entityService.updateEntity(this.entity);
      this.toastr.success('Default setting saved successfully!');
      this.loading = false;
      this.closeDefaultModal.emit(true);
    } catch (e) {
      console.log('error', e);
      this.logger.error('saveDefaultSetting - Error: ', e);
      this.toastr.error('Failed to save default setting');
      this.loading = false;
      this.closeDefaultModal.emit(false);
    }
  }

  /**
   * helper function to iterate over the custom requirements/certifications to upload the templates.
   */
  async uploadTemplates(type: string): Promise<any> {
    try {
      const payLoad = {
        creationLevel: 'root_entity',
        rootEntityId: this.entity.id,
        frameworkList: [],
      };
      for (const req of this[type]) {
        if (req.templateURL && !req.templateURL.contentType) {
          req.templateURL = await this.fileService.uploadZippedFiles(
            req.templateURL,
            FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE
          );
        }
        const alreadyFound = this.entity?.defaultSetting[type]?.find(req1 => req1.name === req.name);
        const startingName =
          type === AdditionalRequirements.CUSTOM_REQUIREMENTS ? StandardType.ARTIFACT : EntityTypeEnum.VENDOR;
        const startingType =
          type === AdditionalRequirements.CUSTOM_REQUIREMENTS
            ? StandardType.ARTIFACT
            : StandardType.COMPLIANCE_FRAMEWORK;
        if (!alreadyFound) {
          payLoad.frameworkList.push({
            name: `${startingName}_${req?.name?.replaceAll(' ', '_')}`,
            key: req?.name?.replaceAll(' ', '_'),
            type: startingType,
            documentCategory: req?.documentCategory,
            documentType: req.documentType ? req?.documentType : null,
            templateType:
              type === AdditionalRequirements.CUSTOM_REQUIREMENTS
                ? AdditionalRequirements.REQUIREMENTS
                : AdditionalRequirements.CERTIFICATIONS,
            templateURL: req?.templateURL,
            vendorId: '',
            documentSubType: req.subType ? req?.subType : null,
          });
          if (!this.entity?.defaultSetting[type]) {
            this.entity.defaultSetting[type] = [];
          }
          this.entity?.defaultSetting[type].push({
            name: req?.name,
            isQualify: req?.isQualify,
            templateURL: req.templateURL ? req.templateURL : null,
            __typename: 'Qualification',
            documentCategory: req?.documentCategory,
            documentType: req?.documentType,
          });
        }
      }
      return payLoad;
    } catch (e) {
      console.log('Cannot upload the template of custom framework to the S3');
      return Promise.reject();
    }
  }

  /**
   *
   */
  async uploadFilesAndFrameworks(type: string): Promise<void> {
    try {
      // 1- First getting all the files. and uploading them to S3.
      const functionName = 'createFrameworkWithRanges';
      const payLoad = await this.uploadTemplates(type);
      // 2- after uploading the files, need to invoke the lambda for creating the custom framework.
      if (payLoad.frameworkList.length) {
        await this.clientLambdaService.invokeLambda(functionName, payLoad);
      } else {
        this.entity.defaultSetting[type] = this[type];
      }
    } catch (e) {
      console.log('Cannot upload the files/frameworks to S3');
      return Promise.reject();
    }
  }

  /**
   * initializing the wizard data change
   * @param
   */
  initializeDefaultSettingsData(): void {
    if (this.entity?.defaultSetting?.notifications) {
      // if entity data is present then add the data into the.
      const parsedNotifications = JSON.parse(this.entity?.defaultSetting?.notifications);
      this.NotifyMessages = parsedNotifications;
      this.addRemoveExtrasCustomDay(false);
    } else {
      this.NotifyMessages = [
        {
          id: 'notify1',
          key: 'message1',
          description: 'Notify internal project manager when a new user has been added',
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_NEW_VENDOR_USER,
        },
        {
          id: 'notify2',
          key: 'message2',
          description: `Remind ${this.isHowden ? 'client' : 'vendor'} to complete assessment prior to deadline`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.REMIND_VENDOR_TO_COMPLETE_ASSESSMENT,
          allowCustomDay: true, // Allow Custom days to reminder
          fixedDays: [
            { id: 'notify2#fixDay1', selected: true, value: 30 },
            { id: 'notify2#fixDay2', selected: true, value: 15 },
            { id: 'notify2#fixDay3', selected: true, value: 7 },
            { id: 'notify2#fixDay4', selected: true, value: 3 },
            { id: 'notify2#fixDay5', selected: true, value: 1 },
          ],
          customDays: [{ id: 'notify2#customDay1', selected: true, value: 0 }],
        },
        {
          id: 'notify3',
          key: 'message3',
          description: `Notify ${this.isHowden ? 'client' : 'vendor'} when an assessment is overdue after the deadline`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.REMIND_VENDOR_ASSESSMENT_OVERDUE,
          allowCustomDay: true,
          fixedDays: [
            { id: 'notify3#fixDay1', selected: true, value: 1 },
            { id: 'notify3#fixDay2', selected: true, value: 3 },
            { id: 'notify3#fixDay3', selected: false, value: 7 },
          ],
          customDays: [{ id: 'notify3#customDay1', selected: true, value: 0 }],
        },
        {
          id: 'notify7',
          key: 'message7',
          description: `Notify internal project manager when an automated reassessment of ${
            this.isHowden ? 'client' : 'vendor'
          } has begun`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_VENDOR_REASSESSMENT,
        },
        {
          id: 'notify4',
          key: 'message4',
          description: 'Notify internal project manager when a new intelligence finding was detected:',
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_VENDOR_NEW_FINDING,
          isFindings: true,
          findings: [
            { id: 'notify4#finding1', label: 'Any-Finding', selected: false },
            { id: 'notify4#finding2', label: 'Once a Day', selected: true },
          ],
        },
        {
          id: 'notify5',
          key: 'message5',
          description: 'Notify internal project manager when an assessment is completed and pending approval',
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_ASSESSMENT_COMPLETED,
        },
        {
          id: 'notify6',
          key: 'message6',
          description: `Notify internal project manager when a ${
            // eslint-disable-next-line quotes
            this.isHowden ? "client's" : "vendor's"
          } risk score drops below`,
          shouldNotify: true,
          notificationType: VendorNotifyTypeEnum.NOTIFY_MANAGER_VENDOR_RISK_DROPPED,
          riskSlider: true,
          riskScore: 3,
        },
      ];
    }
  }

  /**
   * addOrRemove Extra spaces
   */
  addRemoveExtrasCustomDay(isRemoved = true): void {
    if (isRemoved) {
      this.NotifyMessages.forEach(noti => {
        if (noti.shouldNotify && noti.allowCustomDay) {
          noti.customDays = noti.customDays.filter(n => n.selected && n.value);
        }
      });
    } else {
      this.NotifyMessages.forEach(noti => {
        if (noti.shouldNotify && noti.allowCustomDay) {
          noti.customDays.push({
            id: noti.id + '#' + noti.customDays.length,
            selected: true,
            value: 0,
          });
        }
      });
    }
  }

  addCustomNotifyDay(notification: any, index: number): void {
    if (this.NotifyMessages.find(notify => notify.id === notification.id)) {
      this.NotifyMessages[index].customDays.push({
        id: notification.id + '#' + notification.customDays.length,
        selected: true,
        value: 0,
      });
    }
  }
  removeCustomDay(notification: any, dayId: string): void {
    const index = this.NotifyMessages.findIndex(notify => notify.id === notification.id);
    if (index >= 0) {
      const dayIndex = this.NotifyMessages[index].customDays.findIndex(day => day.id === dayId);
      if (dayIndex >= 0) {
        this.NotifyMessages[index].customDays.splice(dayIndex, 1);
      }
    }
  }

  updateSelectedSurvey(): void {}
}
