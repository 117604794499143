import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  APIService,
  GetEntityQuery,
  GetRoleQuery,
  ListRolesQuery,
  ListUsersQuery,
  ModelUserConditionInput,
  UserByRoleIdQuery,
  EntityTypeEnum,
  GetUserQuery,
  MidMarketEnum,
  UpdateUserInput,
} from 'app/API.service';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'app/shared/utils.service';
import { EntityService } from 'app/shared/entity.service';
import { Roles } from './enums/users-setting.enum';
import { rolePermissions } from './enums/users-setting.enum';
import { AuthService } from 'app/auth/auth.service';
import { from, Subject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from './enums/appLevelRoles.enum';
import { environment } from 'environments/environment';
import { DomainFrameworkService } from './domain-framework.service';
import Lambda from 'aws-sdk/clients/lambda';
import { Auth } from 'aws-amplify';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { CUSTOMAPIService } from 'app/custom-api.service';

export interface GetRoleQueryExtended extends GetRoleQuery {
  childRole?: GetRoleQuery;
}
@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  isMidMarket: boolean = UtilsService.isMidMarket; // we need to use this flag when we get the domain on navigateByRole function
  entity: any;
  currentUser: GetUserQuery;
  currentUserRole: any;
  currentUserParentRole: any;
  authUser: any = null;
  hashRoles: any = {};
  tmpAllUsers: GetUserQuery[] = [];

  allRoles: GetRoleQuery[] = [];
  customRoles: GetRoleQuery[] = [];
  defaultRoles: GetRoleQuery[] = [];
  cachedDefaultRoles: GetRoleQuery[];
  KMSRoles: string[] = [AppLevelRoleEnum.ADMIN, AppLevelRoleEnum.MSSP, AppLevelRoleEnum.ENTITY_LEADER];
  roleIDToNameMapper = {};

  private subscriptionList: Subscription[];
  isCustomRole: any = {};
  isVendorPoc: boolean = false;
  toggleStateChanged$ = new Subject<any>();
  currentUserToken: string;

  constructor(
    private entityService: EntityService,
    private router: Router,
    private api: APIService,
    private authService: AuthService,
    private toastr: ToastrService,
    private logger: NGXLogger,
    private customApi: CUSTOMAPIService
  ) {
    from(Auth.currentSession()).subscribe((currentSession: any) => {
      if (!currentSession) {
        return;
      }
      this.currentUserToken = currentSession.getAccessToken().getJwtToken();
      this.loadSubscriptions();
    });

    this.customApi.webSocketConnectionListner$.subscribe(hubState => {
      if (hubState?.isConnectionClosed) {
        this.subscriptionList.forEach(listener => listener?.unsubscribe());
        this.subscriptionList = [];
        // this.loadSubscriptions();
      } else if (!hubState?.isConnectionClosed && !this.subscriptionList?.length) {
        this.loadSubscriptions();
      }
    });

    // if (this.authService.currentAuthUser) {
    // }
    this.init();
  }

  // load the by Default data Needed
  async init(): Promise<void> {
    await this.getDefaultRoles();
    this.initRoleNameByIdMapper();
  }

  /**
   *  Checks the role of the logged in user and set the routes and permissions.
   *  @retuns => navigates the user according to permission
   */
  async navigateByRole(): Promise<void> {
    let nextRoute: string[];
    let extras = null;
    try {
      // Getting the currently logged in user
      await this.getCurrentUser();
      // If user don't have role or don't have Entity Ids,  then go back to login page
      if (!this.currentUser || !this.currentUser.roleId) {
        this.logger.error('User has no permissions');
        await this.router.navigate(['/']);
        return Promise.reject();
      }
      // Handling Mid Market User scenario
      // As on creating the mid market user we add onBoardingStatus in the user
      // so for now as we dont have the midmarket domain we are navigating it on the basis
      // of onBoardingStatus
      if (this.currentUser?.onBoardingStatus) {
        await this.navigateMidMarketUser();
        return;
      }
      const isEntityAssigned: boolean = this.isCurrentUserAssignedEntities();
      if (!isEntityAssigned && this.currentUserRole.name !== Roles.ADMIN && this.currentUserRole.name !== Roles.MSSP) {
        this.toastr.info('No sub-entity is assigned to this user');
        await this.logOut();
        await this.router.navigate(['/']);
        return Promise.resolve();
      }
      // Getting the entity for the users other than admins and MSSP
      if (
        this.currentUserRole?.name.toLowerCase() !== Roles.ADMIN.toLowerCase() &&
        this.currentUserRole?.name.toLowerCase() !== Roles.MSSP.toLowerCase() &&
        this.currentUserRole?.name.toLowerCase() !== AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase()
      ) {
        // According to new Requirement/functionality we have different Entities assigned  w.r.t different domain
        // If we pick first entity of assigned may be the selected entity is not assigned in current domain.
        // so dynamically check the assigned entities instead of getting entity at 0 index
        const assignedEntities = await this.getCurrentUserAssignedEntities();
        if (assignedEntities && assignedEntities.length) {
          this.entity = assignedEntities[0]; // get first entity in assigned entities in current domain
        } else {
          this.toastr.info('No Entity is assigned to this user in current domain');
          if (this.currentUserRole?.name?.toLowerCase() === Roles.PARTICPANT.toLowerCase()) {
            this.toastr.success('Logged out');

            return this.logOut()
              .then(() => {
                this.toastr.success('Logged out');
                setTimeout(() => {
                  this.router.navigate(['/']);
                }, 2000);
                return Promise.resolve();
              })
              .catch(err => {
                this.logger.error(err);
                this.toastr.error('Log out failed');
              });
          } else {
            await this.router.navigate(['/']);
            return Promise.resolve();
          }
        }
      }
      // Changing the routes depends on the screen permissions allowed to user.
      const screenPermissions = JSON.parse(this.currentUserRole.screenPermissions);
      const entityIds =
        this.currentUser.entityIds && this.currentUser.entityIds.length
          ? this.currentUser.entityIds
          : this.currentUserRole.entityIds;
      // First check for the specific user netSkope and CRB
      if (
        this.isNetSkopeLeader() &&
        this.currentUserRole &&
        this.currentUserRole.name &&
        this.currentUserRole.name.toLowerCase() !== AppLevelRoleEnum.ENTITY_LEADER &&
        !this.currentUserRole.isRootEntityAccess
      ) {
        nextRoute = [`board-netskope/${entityIds[0]}/executive-summary`];
      } else if (this.isCRB() && UtilsService.isDefined(this.entity) && !this.isVendor()) {
        nextRoute = [`first-party/${this.entity.parentId}/collection`, this.entity.id];
      } else if (this.isVendor() || (this.isParticipant() && this.entity.entityType === EntityTypeEnum.VENDOR)) {
        nextRoute = [
          `first-party/${this.entity.parentId}/${
            this.isVendor() ? screenPermissions[0] : screenPermissions['first-party'][0]
          }/${this.entity.id}`,
        ];
        extras = {
          queryParams: { userId: this.currentUser.id },
        };
      } else {
        // Handling the cases for built in and custom users.
        // for handling ADMIN and MSSP
        if (screenPermissions['basic-routes'] && screenPermissions['basic-routes'].includes('clients')) {
          nextRoute = ['clients'];
        } else if (screenPermissions['first-party'] && screenPermissions['first-party'].length) {
          // for handling Entity leader, subEntity Leader, participant and custom Role
          nextRoute = this.isParticipant()
            ? [`first-party/${this.entity.parentId}/${screenPermissions['first-party'][0]}`, this.entity.id]
            : [
                `first-party/${this.entity.entityType === 'CHILD_ENTITY' ? this.entity.parentId : this.entity.id}/${
                  screenPermissions['first-party'][0]
                }`,
              ];
        } else if (screenPermissions['third-party'] && screenPermissions['third-party'].length) {
          nextRoute = [`third-party/${this.entity.parentId}/${screenPermissions['third-party'][0]}`];
        }
      }
      await this.router.navigate(nextRoute, extras ? extras : '');
    } catch (err) {
      console.log('Error in getting the current User:', err);
      await this.router.navigate(['']);
      return Promise.reject(err);
    }
  }

  /**
   * Function to navigate Mid Market User to either OnBoarding Screen or Collection
   * @param collection - If need to move to collection (If user is not login for the first time)
   */
  async navigateMidMarketUser(collection: boolean = false): Promise<void> {
    const { onBoardingStatus, entityId } = this.currentUser;
    // Wizard is completed by Both Admin and Client. Directly go to Collection screen of the first sub entity
    if (onBoardingStatus === MidMarketEnum.COMPLETED || collection) {
      await this.router.navigate([`first-party/${entityId}/collection`]);
      return;
    }
    await this.router.navigate(['/onboarding']);
  }

  /**
   * Function to update user onBoarding status for next time logins
   * @param status - Current Status
   */
  async updateMidMarketUser(status: MidMarketEnum): Promise<void> {
    try {
      const params = {
        id: this.currentUser.id,
        onBoardingStatus: status,
      };
      await this.customApi.UpdateUser(params);
    } catch (e) {
      console.log('Err :: updateMidMarketUser-', e);
    }
  }

  /**
   * Function to get Wizard when a Mid Market client loigns to system
   * @returns - Current Wizard Object with client form
   */
  async getMidMarketClientWizard(): Promise<any> {
    try {
      if (!this.currentUser) {
        this.currentUser = await this.authService.getCurrentUser();
      }
      const wizard = await this.customApi.GetEntityWizard(this.currentUser?.entityId);
      return wizard;
    } catch (e) {
      console.log('Err :: getMidMarketClientWizard-', e);
    }
  }

  /**
   *  sets the value of the current user.
   */
  set currentUserValue(value: GetUserQuery | null) {
    this.currentUser = value;
  }
  /**
   *
   * @returns the currently LoggedIn user
   */
  getCurrentUserSync(): GetUserQuery {
    return this.currentUser ? this.currentUser : null;
  }

  isManagerialRole(): boolean {
    return this.KMSRoles.includes(this.currentUserRole?.name.toLowerCase());
  }

  isCurrentUserAssignedEntities(): boolean {
    const validRoles = [AppLevelRoleEnum.ENTITY_LEADER.toLowerCase(), AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()];
    // User should be Entity Leader , Sub Entity Leader or Custom role
    if (
      this.currentUser &&
      this.currentUserRole &&
      (validRoles.includes(this.currentUserRole.name) || this.currentUserRole.defaultOrEntityId !== 'default')
    ) {
      return (
        (this.currentUser &&
          this.currentUser.entityIds &&
          this.currentUser.entityIds.length &&
          this.currentUserRole.defaultOrEntityId === 'default') ||
        (this.currentUser && this.currentUserRole.entityIds && this.currentUserRole.entityIds.length)
      );
    }
    return true;
  }
  /**
   * returns the currently logged in user.
   * @returns currentUser
   */
  async getCurrentUser(): Promise<GetUserQuery> {
    try {
      if (!this.currentUser) {
        this.currentUser = await this.authService.getCurrentUser();
        this.currentUserRole = this.authService.userRole;

        // Checking for the parentId in the role.
        // If parentId exists in role then it will have dependency on root role.
        if (this.currentUserRole && this.currentUserRole.parentId) {
          // Getting the parent Role.
          const parentRole = await this.authService.getParentRole(this.currentUserRole.parentId);

          // If parent role is present then update the current role with this role.
          if (parentRole && !this.currentUserRole.childRole) {
            parentRole.childRole = this.currentUserRole;
            this.currentUserRole = parentRole;

            // parsing both the parent and child role screen permissions
            this.currentUserRole.screenPermissions = JSON.parse(this.currentUserRole.screenPermissions);
            this.currentUserRole.childRole.screenPermissions = JSON.parse(
              this.currentUserRole.childRole.screenPermissions
            );

            // stringifying the screen Permissions.
            this.currentUserRole.screenPermissions = JSON.stringify({
              ...this.currentUserRole.screenPermissions,
              ...this.currentUserRole.childRole.screenPermissions,
            });
          }
        }
      }
      return this.currentUser;
    } catch (error) {
      console.log('error in auth user', error);
      Promise.reject();
    }
  }

  /**
   * returns the currently logged in user role.
   * @returns currentRole
   */
  getCurrentRole(): GetRoleQueryExtended {
    return this.currentUserRole;
  }

  /**
   * Function for getting the entity
   * @param entityId ID of the current entity.
   */
  async getCurrentUserEntity(entityId: any): Promise<any> {
    return await this.entityService.getEntity(entityId);
  }

  /**
   * Helper function for the netskope leader.
   */
  isNetSkopeLeader(): boolean {
    return this.entity && this.entity.entityType === EntityTypeEnum.ROOT_ENTITY && UtilsService.isNetskope;
  }

  /**
   * Helper function for the CRB leader.
   */
  isCRB(): boolean {
    return UtilsService.isCRB ? true : false;
  }

  /**
   * Helper function to check vendor.
   */
  isVendor(): boolean {
    return this.currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.VENDOR.toLowerCase();
  }

  /**
   * to check if the current user is entity leader or sub-entity leader
   * check for both Built-In and Custom Roles either user has few entities access or
   * all child entities access
   */
  isNotFromHigherRoles(): boolean {
    return (
      this.currentUserRole.name.toLowerCase() !== Roles.ADMIN.toLowerCase() &&
      this.currentUserRole.name.toLowerCase() !== Roles.MSSP.toLowerCase() &&
      this.currentUserRole.name.toLowerCase() !== Roles.ENTITYLEADER.toLowerCase()
    );
  }

  isFromLowerRoles(selectedUserRole: string): boolean {
    let userRole = this.currentUserRole.name;
    userRole = userRole.replace(/-/g, '');
    const selectctedUser = selectedUserRole?.replace(/-/g, '');
    return this.hasPermisssion(userRole, selectctedUser, JSON.stringify(rolePermissions));
  }
  hasPermisssion(userRole: Roles, selectedUserRole, rolePermission): boolean {
    const userPermission = JSON.parse(rolePermission);
    // Check if the userRole exists and if selectedUserRole is in canAnswerRoles
    return userPermission[userRole]?.includes(selectedUserRole);
  }
  /**
   * to check if user is participant or not.
   */
  isParticipant(): boolean {
    return this.currentUserRole.name.toLowerCase() === Roles.PARTICPANT;
  }

  /**
   *
   * @returns user will be logged out to login
   */
  async logOut(): Promise<void> {
    try {
      this.currentUser = null;
      this.currentUserRole = null;
      await this.authService.logOut();
      return;
    } catch (e) {
      this.logger.error('logOut - Error: ', e);
      if (e !== 'No current user') {
        return Promise.reject(e);
      }
      this.router.navigate(['/login']);
    }
  }

  /**
   * @returns true or false on the basis of currently logged in user authentication
   */
  async isAuthenticated(): Promise<boolean> {
    return !!(await this.getCurrentUser());
  }

  /**
   * set the updated roles list
   */
  public set roles(roles: GetRoleQuery[]) {
    this.allRoles = roles;
  }

  /**
   * set the updated custom roles list
   */
  public set custom_Roles(roles: GetRoleQuery[]) {
    this.customRoles = roles;
  }

  /**
   * set the updated default roles list
   */
  public set default_Roles(roles: GetRoleQuery[]) {
    this.defaultRoles = roles;
  }

  /**
   * get the updated custom roles list
   */
  public getCustom_Roles(): GetRoleQuery[] {
    return this.customRoles;
  }

  /**
   * get the updated default roles list
   */
  public getDefault_Roles(): GetRoleQuery[] {
    return this.defaultRoles;
  }

  /**
   * logout User
   */

  logoutUser() {
    this.logOut()
      .then(() => {
        this.toastr.success('Logged out');
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.logger.error(err);
        this.toastr.error('Log out failed');
      });
  }
  /**
   * Listener for updating the user list.
   */
  onUpdateUserListener(): any {
    return this.customApi.OnUpdateUserListener(this.currentUserToken).subscribe((res: any) => {
      (async () => {
        let updatedUser: GetUserQuery = res.value.data.onUpdateUser;
        if (!(updatedUser && updatedUser.roleId && updatedUser.entityIds && updatedUser.entityIds.length)) {
          updatedUser = await this.getCurrentUser();
        }
        if (updatedUser && this.currentUser && updatedUser.id === this.currentUser.id) {
          // current logged In user is updated
          // if role is updated logout the current user to re-login with updated permission
          if (
            this.currentUser &&
            updatedUser &&
            (this.currentUser?.roleId !== updatedUser?.roleId ||
              this.currentUser?.isViewOnly !== updatedUser?.isViewOnly)
          ) {
            this.toastr.success('Your role/permission is updated. Kindly login again');
            this.logoutUser();
          }
        } else if (!updatedUser?.entityIds?.length) {
          this.toastr.success('Please Login Again Because No SubEntity Exist');
          this.logoutUser();
        }
      })().catch(error => {
        console.error('Error in async callback:', error);
      });
    });
  }

  onUpdateRoleListener(): any {
    return this.customApi.OnUpdateRoleListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const updatedRole: GetRoleQuery = res.value.data.onUpdateRole;
        if (updatedRole.defaultOrEntityId !== 'default' && updatedRole.id === this.currentUserRole.id) {
          const newEntityIds = updatedRole.entityIds && updatedRole.entityIds.length ? updatedRole.entityIds : [];
          const oldEntityIds =
            this.currentUserRole.entityIds && this.currentUserRole.entityIds.length
              ? this.currentUserRole.entityIds
              : [];
          // Groups that removed users = Old EntityIds difference New EntityIds
          const groupsRemovedUsers = oldEntityIds.filter(item => !newEntityIds.includes(item));
          // Groups that added users = New EntityIds difference Old EntityIds
          const groupsAddedUsers = newEntityIds.filter(item => !oldEntityIds.includes(item));
          // if there is any group found in which users are updated
          if (groupsRemovedUsers.length || groupsAddedUsers.length) {
            this.toastr.success('Your role Permissions are updated. Kindly login again');
            this.logOut()
              .then(() => {
                this.toastr.success('Logged out');
                this.router.navigate(['/login']);
              })
              .catch(err => {
                this.logger.error(err);
                this.toastr.error('Log out failed');
              });
          }
        }
      },
    });
  }
  /**
   *
   * @param minRole currentRole of the user
   * @param entityIds list of entityIds
   * @param subEntityId subEntityId
   * @returns true or false if user has permission or not.
   */
  hasPermissionHierarchy(
    minRole: AppLevelRoleEnum | AppLevelRoleEnumBnB,
    entityIds?: string[],
    subEntityId: string = ''
  ): boolean {
    try {
      const role = this.getCurrentRole();
      const selectedRole = minRole?.toLowerCase();
      if (!selectedRole || !role || (role && !role.name)) {
        return false;
      }
      const userRole = role.name.toLowerCase();
      switch (selectedRole) {
        case AppLevelRoleEnum.ADMIN:
          return userRole === AppLevelRoleEnum.ADMIN;
        case AppLevelRoleEnum.MSSP:
          return userRole === AppLevelRoleEnum.ADMIN || userRole === AppLevelRoleEnum.MSSP;
        case AppLevelRoleEnumBnB.USER_ADMINISTRATOR:
          return (
            userRole === AppLevelRoleEnum.ADMIN ||
            userRole === AppLevelRoleEnum.MSSP ||
            userRole === AppLevelRoleEnumBnB.USER_ADMINISTRATOR
          );
        case AppLevelRoleEnum.ENTITY_LEADER:
          return (
            userRole === AppLevelRoleEnum.ADMIN ||
            userRole === AppLevelRoleEnum.MSSP ||
            (userRole === AppLevelRoleEnum.ENTITY_LEADER &&
              this.currentUser &&
              this.currentUser.entityIds &&
              Array.isArray(this.currentUser.entityIds) &&
              entityIds &&
              Array.isArray(entityIds) &&
              entityIds.every(i => this.currentUser.entityIds.includes(i)))
          );
        case AppLevelRoleEnum.SUBENTITY_LEADER:
          return (
            userRole === AppLevelRoleEnum.ADMIN ||
            userRole === AppLevelRoleEnum.MSSP ||
            userRole === AppLevelRoleEnum.ENTITY_LEADER ||
            (userRole === AppLevelRoleEnum.SUBENTITY_LEADER &&
              this.currentUser &&
              this.currentUser.entityIds &&
              Array.isArray(this.currentUser.entityIds) &&
              entityIds &&
              Array.isArray(entityIds) &&
              entityIds.every(i => this.currentUser.entityIds.includes(i)))
          );
        case AppLevelRoleEnum.PARTICIPANT:
          return (
            userRole === AppLevelRoleEnum.ADMIN ||
            userRole === AppLevelRoleEnum.MSSP ||
            userRole === AppLevelRoleEnum.ENTITY_LEADER ||
            userRole === AppLevelRoleEnum.SUBENTITY_LEADER ||
            (userRole === AppLevelRoleEnum.PARTICIPANT &&
              this.currentUser.entityIds &&
              Array.isArray(this.currentUser.entityIds) &&
              subEntityId &&
              this.currentUser.entityIds.includes(subEntityId))
          );
        case AppLevelRoleEnum.VENDOR:
          return (
            userRole === AppLevelRoleEnum.ADMIN ||
            userRole === AppLevelRoleEnum.MSSP ||
            userRole === AppLevelRoleEnum.ENTITY_LEADER ||
            userRole === AppLevelRoleEnum.SUBENTITY_LEADER ||
            (userRole === AppLevelRoleEnum.VENDOR &&
              this.currentUser.entityIds &&
              Array.isArray(this.currentUser.entityIds) &&
              subEntityId &&
              this.currentUser.entityIds.includes(subEntityId))
          );
      }
    } catch (err) {
      console.log('hasPermissionHierarchy Error ==>', err);
      return false;
    }
  }

  /**
   * method will get all the roles including the default as well as the custom roles based on the entity
   * @param defaultOrEntityId will be the entity ID of current Entity / default string
   * @param toFetchDefault is the flag to fetch default roles or not.
   * @returns list of roles assigned to the specific entity
   */
  async getRolesByDefaultOrEntityId(
    defaultOrEntityId: string,
    toFetchDefault: boolean = false
  ): Promise<GetRoleQuery[]> {
    try {
      if (defaultOrEntityId) {
        // Note : if defaultOrEntityId is default than get Roles from the cache instead of API call.
        let updatedRoles = [];
        if (defaultOrEntityId === UsersSettingConstant.default) {
          updatedRoles = this.entityService.allRoles?.length
            ? this.entityService.allRoles
            : this.defaultRoles?.length
            ? this.defaultRoles
            : await this.getDefaultRoles();
        } else {
          const result = await this.customApi.RolesByDefaultOrEntityId(defaultOrEntityId);
          updatedRoles = result && result.items && result.items.length ? result.items : [];
        }

        // check if the roles are default or custom.
        if (toFetchDefault && updatedRoles && updatedRoles.length) {
          this.defaultRoles = this.filterRoles(updatedRoles);
          this.defaultRoles = UtilsService.isBnB
            ? this.getSortedRolesForBNB(this.defaultRoles)
            : this.getSortedRoles(this.defaultRoles);
          updatedRoles = this.defaultRoles;
        } else if (updatedRoles && updatedRoles.length) {
          // roles are custom roles.
          this.customRoles = updatedRoles;
        }

        // removing the user administrator role as it is not required in first party.
        if (UtilsService.isBnB) {
          this.defaultRoles = this.defaultRoles.filter(
            role => role?.name?.toLowerCase() !== AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase()
          );
        }

        // merging both the roles for allRoles.
        this.allRoles = [...this.defaultRoles, ...this.customRoles];
        this.allRoles.map(role => (this.hashRoles[role.id] = role));
        return updatedRoles;
      }
    } catch (e) {
      console.log('Error ==>>', e);
      return [];
    }
  }
  /**
   * funtion to filter the roles based on bnb.
   * @returns list of roles for updated roles list.
   */
  filterRoles(roles: GetRoleQuery[]): GetRoleQuery[] {
    let newRoles = [];
    if (roles && roles.length) {
      // If BNB then remove default roles not included in bnb
      if (UtilsService.isBnB) {
        newRoles = roles.filter(role => {
          return (
            role?.name?.toLowerCase() !== AppLevelRoleEnum.ENTITY_LEADER &&
            role?.name?.toLowerCase() !== AppLevelRoleEnum.MSSP
          );
        });
      } else {
        newRoles = roles.filter(
          role =>
            role?.name?.toLowerCase() !== AppLevelRoleEnumBnB.ENTERPRISE &&
            role?.name?.toLowerCase() !== AppLevelRoleEnumBnB.DIVISION_LEADER &&
            role?.name?.toLowerCase() !== AppLevelRoleEnumBnB.REGION_LEADER &&
            role?.name?.toLowerCase() !== AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN
        );

        if (!UtilsService.isBnBCyberSite) {
          newRoles = newRoles.filter(role => role?.name?.toLowerCase() !== AppLevelRoleEnumBnB.USER_ADMINISTRATOR);
        }
      }
    }
    return newRoles;
  }

  returnHelperFunction(): any {
    return this.checkRoleValidation;
  }

  checkRoleValidation = (userObj: any, roleName: string): boolean => {
    if (this.hashRoles[userObj.roleId]) {
      const storedRoleName = this.hashRoles[userObj.roleId].name?.replace(/\s+/g, '_').toLowerCase();
      return storedRoleName === roleName.toLowerCase();
    } else {
      return false;
    }
  };

  /**
   * helper function which checks for the bnb and then fetch the default roles list from enum.
   * @returns the array containing the roles strings
   */
  getDefaultRoleArray(): string[] {
    return Object.values(UtilsService.isBnB ? AppLevelRoleEnumBnB : AppLevelRoleEnum).map(role => role);
  }

  /**
   *
   * @param rootEntityId ID of the root Entity.
   * @param childEntityIds will be the list of all the child entities of the root entity
   * @param currentUserRole role of currently logged in user.
   * @returns list of all the users based on the currently logged in user.
   */
  async getAllUsersForEntityWithRoleId(
    rootEntityId: string,
    childEntityIds: string[],
    currentUserRole: GetRoleQueryExtended,
    returnRolesObject: boolean = false,
    isCollectionEdit: boolean = false
  ): Promise<GetUserQuery[] | any> {
    try {
      const promises: any = [];
      let users = [];
      // 1. getting the list of roles (strings) based on the domain type.
      const rolesArray = this.getDefaultRoleArray();
      // 2. after getting the roles list we will find the index of the current role.
      // 		2.1- for simple flow, currentUserrole.name will be the role name.
      //    2.2- for bnb, currentUserRole.childRole.name will be the role name.
      if (rolesArray && rolesArray.length) {
        const currentRoleIndex = rolesArray.findIndex(role =>
          currentUserRole.childRole
            ? currentUserRole?.childRole?.name?.toLowerCase() === role
            : currentUserRole?.name?.toLowerCase() === role
        );

        const entityRoleEnum = UtilsService.isBnB
          ? AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase()
          : AppLevelRoleEnum.ENTITY_LEADER.toLowerCase();

        const rootEntityIndex = rolesArray.findIndex(role => entityRoleEnum === role?.toLowerCase());

        // 3. After finding the index, we will iterate over remaining items of array in rolesList.
        if (currentRoleIndex > -1 || isCollectionEdit) {
          const startIndex = this.isVendorPoc ? currentRoleIndex - 1 : currentRoleIndex;
          for (let i = startIndex; i < rolesArray.length; i++) {
            const roleId = this.getRoleId(rolesArray[i]);
            let filters;
            // if current index === to root entity index
            if (rootEntityIndex === i) {
              filters = {
                or: [
                  {
                    entityIds: {
                      contains: rootEntityId,
                    },
                  },
                  {
                    entityIds: {
                      attributeExists: false,
                    },
                  },
                ],
              };
              // if current index above root entity index. AKA roles: sub-entity leader, participant, vendor
            } else if (rootEntityIndex < i) {
              if (childEntityIds && childEntityIds.length) {
                if (childEntityIds.length < 30) {
                  const entityIdsFilters = childEntityIds.map(id => {
                    return {
                      entityIds: {
                        contains: id,
                      },
                    };
                  }) as any;
                  filters = {
                    or: entityIdsFilters,
                  };
                } else {
                  // we need to create batches of 30 entity ids.
                  const batchSize = 30;
                  const currentBatch = [];
                  for (let idx = 0; idx < childEntityIds.length; idx += batchSize) {
                    const batch = childEntityIds.slice(idx, idx + batchSize);
                    currentBatch.push(batch);
                  }
                  currentBatch.forEach(batch => {
                    const data = batch.map(id => {
                      return {
                        entityIds: {
                          contains: id,
                        },
                      };
                    });
                    filters = {
                      or: data,
                    };
                    promises.push(this.getUsersByRoleId(roleId, filters));
                  });
                  continue;
                }

                // TODO: handle nonAssignedUsers
                // const nonAssignedUsers = [AppLevelRoleEnum.PARTICIPANT, AppLevelRoleEnum.SUBENTITY_LEADER] as any;
                // if (nonAssignedUsers.includes(rolesArray[i])) {
                //   entityIdsFilters.push({
                //     entityIds: {
                //       attributeExists: false,
                //     },
                //   });
                //   entityIdsFilters.push({
                //     entityIds: {
                //       contains: '',
                //     },
                //   });
                //   if (UtilsService.isBnB) {
                //     entityIdsFilters.push({
                //       isGlobalParticipant: {
                //         eq: true,
                //       },
                //     });
                //   }
                // }
              } else {
                continue;
              }
            }
            // Make sure to get the lower roles users if current root entity has one or more than one sub-entity
            // if a root entity don't have any sub-entity than we dont have to get users with roles (Sub-entity , Participant etc)
            // const executeAPI = !(
            //   !UtilsService.isDefined(filters) &&
            //   rolesArray[i] !== AppLevelRoleEnum.ADMIN &&
            //   rolesArray[i] !== AppLevelRoleEnum.MSSP
            // );
            if (UtilsService.isDefined(roleId)) {
              promises.push(this.getUsersByRoleId(roleId, filters));
            }
          }
        }
      }

      if (currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase()) {
        const adminRoleId = this.getRoleId(AppLevelRoleEnum.ADMIN);
        if (UtilsService.isDefined(adminRoleId)) {
          promises.push(this.getUsersByRoleId(adminRoleId, {}));
        }
      }
      // 4. Awaiting for all the promises to be resolved and saving the users list.
      // const usersArrays = (await Promise.all(promises)).flat();
      const usersArrays = await Promise.all(promises);
      usersArrays.forEach(usersArray => (users = users.concat(usersArray)));
      // usersArrays.forEach(usersArray => (users = users.concat(usersArray)));
      this.tmpAllUsers = [...users];

      // 5. Filtering out the users list on the basis of entityIds. Only show those users which belongs to current entity.
      // users = UtilsService.isBnB
      //   ? this.filterEntityOrSubEntityLeadersUsersForBnb(users, rootEntityId, childEntityIds)
      //   : this.filterEntityOrSubEntityLeadersUsers(users, rootEntityId, childEntityIds);

      // 6. to check if the currently logged in user has custom role.
      this.isCustomRole = null;
      this.isCustomRole = this.customRoles.find(role => role.id === this.currentUserRole?.id);

      if (this.customRoles && this.customRoles?.length && this.isCustomRole) {
        // If currently logged in user' role has rootAccess then custom roles with not root Access will be shown.
        if (this.isCustomRole && this.isCustomRole?.isRootEntityAccess) {
          this.customRoles = this.updateCustomRolesList(true, this.isCustomRole);
        } else {
          this.customRoles = this.updateCustomRolesList(false, this.isCustomRole);
        }
        this.allRoles = this.customRoles;
      } else {
        // for subEntityLeader
        if (
          this.customRoles &&
          this.customRoles.length &&
          this.currentUserRole &&
          this.currentUserRole.name &&
          this.currentUserRole.name.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()
        ) {
          // update the custom roles.
          this.defaultCustomRolesUpdate();
        }
      }
      // getting assigned sub-entities and maintaing an array of their ids
      const assignedEntities = await this.getCurrentUserAssignedEntities();
      const assignedEntitiesId = [];
      assignedEntities.forEach(entity => {
        assignedEntitiesId.push(entity.id);
      });
      const customUsers = await this.usersWithCustomRoles(this.customRoles);
      let allUsers = [];
      if (customUsers && customUsers.length) {
        allUsers = [...users, ...customUsers];
      } else {
        allUsers = users;
      }
      this.tmpAllUsers = [...allUsers];
      // allUsers = UtilsService.isBnB
      //   ? this.filterEntityOrSubEntityLeadersUsersForBnb(allUsers, rootEntityId, childEntityIds)
      //   : this.filterEntityOrSubEntityLeadersUsers(allUsers, rootEntityId, childEntityIds);

      // if someone is logged in from a user that has custom role
      // it will filter only those users that have the same sub-entity ids
      // which are assigned to that custom role
      if (this.isCustomRole && !isCollectionEdit) {
        const filteredUsers = [];
        allUsers = allUsers?.filter(user => {
          let isUserFound = false;
          if (user?.entityIds?.length) {
            user.entityIds.forEach(elD => {
              if (assignedEntitiesId.includes(elD)) {
                isUserFound = true;
              }
            });
            if (isUserFound) {
              filteredUsers.push(user);
            }
          }
        });

        filteredUsers.push(this.currentUser);
        allUsers = filteredUsers;
      }

      if (returnRolesObject) {
        return { usersList: allUsers, roles: this.allRoles };
      }
      return allUsers;
    } catch (err) {
      console.log('Error ==>>', err);
      return [];
    }
  }

  /**
   * Get all users against given domain (http://localhost:4200 ,https://dev.cygovdev.com , bnb , etc...)
   * @returns list of Users
   */
  async getUsersByDomain(): Promise<GetUserQuery[] | any> {
    const cognitoUsers = await this.getUsersInGroup(DomainFrameworkService.getDomain());
    // Check either current user role
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    try {
      const filter: ModelUserConditionInput = {
        domain: {
          eq: DomainFrameworkService.getDomain(),
        },
      };
      // if Current user role is not admin discard the all admins from the list
      if (this.currentUserRole.name.toLowerCase() !== AppLevelRoleEnum.ADMIN.toLowerCase()) {
        const adminRole = this.allRoles.find(
          role => role?.name?.toLowerCase() === AppLevelRoleEnum?.ADMIN?.toLowerCase()
        );
        if (adminRole) {
          filter.roleId = { ne: adminRole?.id };
        }
      }
      // filtering on basis of domain so users can see other users of same domain only
      let nextToken;
      do {
        nextToken = result ? result.nextToken : null;
        result = await this.customApi.ListUsers(filter, environment.queryListLimit, nextToken);
        items = result && result.items ? items.concat(result.items as GetUserQuery[]) : items;
      } while (result.nextToken);

      if (cognitoUsers && cognitoUsers.length && items && items.length) {
        items = await this.getRemainingUsers(cognitoUsers, items);
      }
      return items;
    } catch (e) {
      console.log('Users cannot be fetched - Error: ===>>', e);
      return [];
    }
  }

  async getRemainingUsers(cognitoUsers: any[], users: GetUserQuery[]): Promise<GetUserQuery[]> {
    if (!(cognitoUsers && cognitoUsers.length)) {
      return users;
    }
    const tempList = [...users];
    try {
      for (const user of cognitoUsers) {
        const findUser = tempList.find(u => u.email === user.email);
        if (!findUser) {
          const userObj = await this.customApi.UserByEmail(user.email.toLowerCase());
          if (userObj && userObj.items && userObj.items.length) {
            tempList.push(userObj.items[0]);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return tempList;
  }

  // Not In use anymore.
  /**
   * Get All roles against given domain (http://localhost:4200 ,https://dev.cygovdev.com , bnb , etc...)
   * @returns return list of roles.
   */
  // async getRolesByDomain(): Promise<GetRoleQuery[] | any> {
  //   let result: ListRolesQuery;
  //   let items: GetRoleQuery[] = [];
  //   try {
  //     // filtering roles on basis of domain right now we are getting all roles not on basis of domain
  //     // as we don't have any domain filed in role later on we will change it.
  //     // const filter: ModelUserConditionInput = {
  //     //   domain: {
  //     //     eq: DomainFrameworkService.getDomain(),
  //     //   },
  //     // };
  //     let nextToken;
  //     do {
  //       nextToken = result ? result.nextToken : null;
  //       result = await this.customApi.ListRoles(null, environment.queryListLimit, nextToken);
  //       items = result && result.items ? items.concat(result.items as GetRoleQuery[]) : items;
  //     } while (result.nextToken);

  //     return items;
  //   } catch (e) {
  //     console.log('Users cannot be fetched - Error: ===>>', e);
  //     return [];
  //   }
  // }

  /**
   * @param entityIds is the list of subEntities against which entities will be get.
   * @returns the list of entities of the given entityIds.
   */
  async getEntitiesListbyEntityIds(entityIds: string[]): Promise<GetEntityQuery[]> {
    try {
      const promises = [];
      if (entityIds && entityIds.length) {
        entityIds.forEach(entityId => {
          promises.push(this.entityService.getEntity(entityId));
        });
        let result = await Promise.all(promises);
        result = result.filter(res => {
          return UtilsService.isDefined(res);
        });
        return [...result];
      }
    } catch (e) {
      console.log('Cannot fetch entityList for part ===> ', e);
      return [];
    }
    return [];
  }

  defaultCustomRolesUpdate(): void {
    this.customRoles = this.customRoles.filter(role => !role.isRootEntityAccess);
    this.allRoles = this.allRoles.filter(role => !role?.isRootEntityAccess);
  }

  /**
   * This method will filters all the users according to the entity.
   * @returns the list of updated users list.
   */
  filterEntityOrSubEntityLeadersUsers(
    allUsers: GetUserQuery[],
    rootEntity: string,
    childEntities: [string]
  ): GetUserQuery[] {
    // Filtering the entityLeader users
    const adminRole = this.allRoles.find(role => role?.name?.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase());
    const msspRole = this.allRoles.find(role => role?.name?.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase());
    const entityLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
    );
    const subEntityLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()
    );
    const participantRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase()
    );
    // const vendorRole = this.allRoles.find(role => role?.name?.toLowerCase() === AppLevelRoleEnum.VENDOR.toLowerCase());
    allUsers.find(user => {
      if (user && user.entityIds && user.entityIds.length) {
        // first check if the user us subEntity leader or not and has subEntity in childEntities.
        const selectedChildEntities = childEntities.filter(entity =>
          user.entityIds?.includes(entity) ? entity : null
        );
        // If selectedChildEntities array is 0 then it will be a entity leader.
        if (!selectedChildEntities.length) {
          // if no child Entity is assigned then it maybe rootEntity.
          // now checking for the rootEntity leader and entityId
          if (
            entityLeaderRole &&
            user?.roleId?.toLowerCase() === entityLeaderRole?.id?.toLowerCase() &&
            user?.entityIds[0] !== rootEntity
          ) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }

          // if no child Entity is assigned then it maybe subEntityLeader but not with current rootEntity's childs.
          if (
            (subEntityLeaderRole || participantRole) &&
            (user?.roleId?.toLowerCase() === subEntityLeaderRole?.id?.toLowerCase() ||
              user?.roleId?.toLowerCase() === participantRole?.id?.toLowerCase())
          ) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }

          // filter out current root entity vendors
          if (entityLeaderRole && user?.roleId.toLowerCase() === entityLeaderRole.id.toLowerCase()) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }
        }
      } else if (!user?.entityIds && !user?.entityId) {
        // If user has no entity id and entity ids then we need to check if it is a admin, mssp or custom.
        if (
          user?.roleId?.toLowerCase() !== adminRole?.id?.toLowerCase() &&
          user?.roleId?.toLowerCase() !== msspRole?.id?.toLowerCase()
        ) {
          // If user is neither admin or mssp then check if it is custom.
          const isFromCustom = this.customRoles.find(r => r.id === user.roleId);
          if (!isFromCustom || (isFromCustom && isFromCustom.defaultOrEntityId === 'default')) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }
        }
      }
    });
    return this.tmpAllUsers;
  }

  /**
   * This method will filters all the users according to the entity.
   * @returns the list of updated users list.
   */
  filterEntityOrSubEntityLeadersUsersForBnb(
    allUsers: GetUserQuery[],
    rootEntity: string,
    childEntities: [string]
  ): GetUserQuery[] {
    // Filtering the entityLeader users
    const adminRole = this.allRoles.find(role => role?.name?.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase());
    const enterpriseRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnumBnB.ENTERPRISE.toLowerCase()
    );
    const divisionLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnumBnB.DIVISION_LEADER.toLowerCase()
    );
    const regionLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnumBnB.REGION_LEADER.toLowerCase()
    );
    const businessEntityAdmin = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase()
    );
    const subEntityLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()
    );
    // const participantRole = this.allRoles.find(
    //   role => role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase()
    // );
    allUsers.find(user => {
      if (user && user.entityIds && user.entityIds.length) {
        // first check if the user us subEntity leader or not and has subEntity in childEntities.
        const selectedChildEntities = childEntities.filter(entity =>
          user.entityIds?.includes(entity) ? entity : null
        );
        // If selectedChildEntities array is 0 then it will be a entity leader.
        if (!selectedChildEntities.length) {
          // if no child Entity is assigned then it maybe rootEntity.
          // now checking for the rootEntity leader and entityId
          if (
            businessEntityAdmin &&
            user?.roleId?.toLowerCase() === businessEntityAdmin?.id?.toLowerCase() &&
            user?.entityIds[0] !== rootEntity
          ) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }

          // if no child Entity is assigned then it maybe subEntityLeader but not with current rootEntity's childs.
          if (subEntityLeaderRole && user?.roleId?.toLowerCase() === subEntityLeaderRole?.id?.toLowerCase()) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }
        }
      } else if (!user?.entityIds && !user?.entityId) {
        // If user has no entity id and entity ids then we need to check if it is a admin, mssp or custom.
        if (
          user?.roleId?.toLowerCase() !== adminRole?.id?.toLowerCase() &&
          user?.roleId?.toLowerCase() !== enterpriseRole?.id?.toLowerCase() &&
          user?.roleId?.toLowerCase() !== divisionLeaderRole?.id?.toLowerCase() &&
          user?.roleId?.toLowerCase() !== regionLeaderRole?.id?.toLowerCase()
        ) {
          // If user is neither admin or mssp then check if it is custom.
          const isFromCustom = this.customRoles.find(r => r.id === user.roleId);
          if (!isFromCustom || (isFromCustom && isFromCustom.defaultOrEntityId === 'default')) {
            const tmpIndex = this.tmpAllUsers.findIndex(u => u.id === user.id);
            this.tmpAllUsers.splice(tmpIndex, 1);
          }
        }
      }
    });
    return this.tmpAllUsers;
  }

  /**
   * Helper method to get the Id of the role.
   */
  getRoleId(roleName: string): string {
    return this.allRoles.find(role => role?.name?.toLowerCase() === roleName?.toLowerCase())?.id;
  }

  async getUsersByRoleId(roleId: string, filter: ModelUserConditionInput = {}): Promise<GetUserQuery[]> {
    let result: UserByRoleIdQuery;
    let items: GetUserQuery[] = [];
    try {
      // filtering on basis of domain so users can see other users of same domain only
      filter.domain = {
        eq: DomainFrameworkService.getDomain(),
      };
      let nextToken;
      do {
        nextToken = result ? result.nextToken : null;
        result = await this.customApi.UserByRoleId(roleId, null, filter, environment.queryListLimit, nextToken);
        items = result && result.items ? items.concat(result.items as GetUserQuery[]) : items;
      } while (result.nextToken);

      return items;
    } catch (e) {
      console.log('Users cannot be fetched - Error: ===>>', e);
      return [];
    }
  }

  /**
   * @param customRoles is list of GetRoleQuery
   * @returns list of GetUserQuery users
   */
  async usersWithCustomRoles(customRoles: GetRoleQuery[]): Promise<GetUserQuery[]> {
    try {
      if (customRoles && customRoles.length) {
        const usersArrays = await Promise.all(customRoles.map(role => this.customApi.UserByRoleId(role.id)));
        let users = [];
        usersArrays.forEach(usersArray => (users = users.concat(usersArray.items)));
        return users;
      }
      return [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  /* ( Note ) Needs to update : This Functional will fail and not give u all Users in Given Entity as according to our
              new architecture one user can has multiple entities assigned. we are using entityIds not EntityId
   *
   * @param entityId current entity Id for getting the list of users
   * @returns list of GetUserQuery users
   */
  async getUsersByEntityId(entityId: string): Promise<GetUserQuery[]> {
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.customApi.UserByEntityId(entityId, null, null, environment.queryListLimit, nextToken);
      items = items.concat(result.items);
    } while (result.nextToken);

    return items;
  }

  /**
   *
   * @param userList current users list
   * @returns return filtered user list basis on there role
   */

  filterUserList(userList = []) {
    const defaultRoles = this.getDefaultRoleArray();
    const currentUserRoleName = this.currentUserRole?.name;

    return userList.filter(item => {
      const roleName = item?.role?.name || item?.user?.role?.name;

      const defaultRoleIndex = defaultRoles.indexOf(roleName);
      const customRoleIndex = this.customRoles.findIndex(role => role.name === roleName);

      const finalIndex = customRoleIndex >= 0 ? customRoleIndex : defaultRoleIndex;

      return finalIndex >= defaultRoles.indexOf(currentUserRoleName);
    });
  }
  /**
   *
   * @returns the list of custom as well as built in roles based on custom users.
   */
  updateCustomRolesList(isRootAccess: boolean, customRole: GetRoleQuery): GetRoleQuery[] {
    // If true
    // Gets the custom roles not having the root access.
    console.log(`customRole => ${customRole}`);
    if (isRootAccess) {
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.PARTICIPANT));
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.SUBENTITY_LEADER));
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.ENTITY_LEADER));
    } else {
      this.customRoles = this.customRoles.filter(role => !role.isRootEntityAccess);
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.SUBENTITY_LEADER));
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.PARTICIPANT));
    }
    return this.customRoles;
  }

  /**
   * checks if the current user has the permision or not
   * for custom users only.
   */
  async checksPermission(screen: string): Promise<boolean> {
    if (!this.currentUser || !this.currentUserRole) {
      await this.getCurrentUser();
    }
    if (screen && this.currentUserRole && this.currentUserRole.screenPermissions) {
      return this.currentUserRole.screenPermissions.includes(screen);
    }
    return false;
  }

  /**
   * This function will merge bnb roles permissions with their parent roles
   * @param list - Current default roles list
   * @returns - updated default roles list
   */
  async updateDefaultRolesPermissions(rolesList: GetRoleQuery[]): Promise<GetRoleQuery[]> {
    rolesList.map(async role => {
      if (role.parentId) {
        try {
          const parent = await this.customApi.GetRole(role.parentId);
          role.screenPermissions = JSON.stringify({
            ...JSON.parse(role.screenPermissions),
            ...JSON.parse(parent.screenPermissions),
          });
        } catch (e) {
          console.log('updateDefaultRolesPermissions error: ', e);
        }
      }
    });
    this.defaultRoles = await Promise.all(rolesList);
    return this.defaultRoles;
  }

  /**
   * finds the role
   */
  getRequiredRole(id: string): GetRoleQuery {
    if (id) {
      const selectedRole = this.allRoles.find(r => r.id === id);
      if (selectedRole) {
        return selectedRole;
      }
    }
    return null;
  }

  /**
   * Sort Role By Their Priority
   */
  getSortedRoles(roles: any[]): any[] {
    roles = roles.map(role => {
      switch (role.name.toLowerCase()) {
        case AppLevelRoleEnum.ADMIN.toLowerCase():
          return { sortOrder: 1, ...role };
        case AppLevelRoleEnum.MSSP.toLowerCase():
          return { sortOrder: 2, ...role };
        case AppLevelRoleEnum.ENTITY_LEADER.toLowerCase():
          return { sortOrder: 3, ...role };
        case AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase():
          return { sortOrder: 4, ...role };
        case AppLevelRoleEnum.PARTICIPANT.toLowerCase():
          return { sortOrder: 5, ...role };
        default:
          return { sortOrder: 6, ...role };
      }
    });
    return roles.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  /**
   * Sort Role By Their Priority
   */
  getSortedRolesForBNB(roles: any[]): any[] {
    roles = roles.map(role => {
      switch (role.name.toLowerCase()) {
        case AppLevelRoleEnumBnB.ADMIN.toLowerCase():
          return { sortOrder: 0, ...role };
        case AppLevelRoleEnumBnB.ENTERPRISE.toLowerCase():
          return { sortOrder: 1, ...role };
        case AppLevelRoleEnumBnB.DIVISION_LEADER.toLowerCase():
          return { sortOrder: 2, ...role };
        case AppLevelRoleEnumBnB.REGION_LEADER.toLowerCase():
          return { sortOrder: 3, ...role };
        case AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase():
          return { sortOrder: 4, ...role };
        case AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase():
          return { sortOrder: 5, ...role };
        case AppLevelRoleEnumBnB.SUBENTITY_LEADER.toLowerCase():
          return { sortOrder: 6, ...role };
        case AppLevelRoleEnumBnB.PARTICIPANT.toLowerCase():
          return { sortOrder: 7, ...role };
        default:
          return { sortOrder: 8, ...role };
      }
    });
    return roles.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  /**
   * @param leaderType is type of AppLevelRoleEnumBnB
   * @returns boolean true if current user role match with given leaderType else false
   */
  isBnbLeader(leaderType: AppLevelRoleEnumBnB): boolean {
    if (this.currentUserRole && this.currentUserRole.childRole && this.currentUserRole.childRole.name) {
      return leaderType === this.currentUserRole.childRole.name;
    }
    return false;
  }

  async getUsersInGroup(groupName: string): Promise<any> {
    try {
      const payload = {
        groupName,
      };

      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: this.authService.getRegion(),
      });
      const result = await lambda
        .invoke({
          FunctionName: `getUsersByGroupName-${environment.name}`,
          Payload: JSON.stringify(payload),
        })
        .promise();
      const stringifiedArr = result.Payload as string;
      const data = JSON.parse(stringifiedArr);
      return data;
    } catch (e) {
      this.logger.error('Download Report - Error: ', e);
      return;
    }
  }

  /**
   * This function return the assigned entities to current user.
   *  we get entities based on user entityIds field in user or in role.
   * @returns list of Entities
   */
  async getCurrentUserAssignedEntities(): Promise<GetEntityQuery[]> {
    // get Current user and its Role
    try {
      const currentUser = await this.getCurrentUser();
      const currentUserRole = this.getCurrentRole();
      if (currentUser && currentUserRole && currentUserRole.defaultOrEntityId) {
        // We have two types of roles either Default or Custom
        const entityIds =
          currentUserRole.defaultOrEntityId === UsersSettingConstant.default
            ? currentUser.entityIds // if role is Default
            : currentUserRole.entityIds; // if role is custom
        return entityIds?.length
          ? this.entityService.getEntitiesByGivenIds(
              currentUser.entityIds ? currentUser.entityIds : currentUserRole?.entityIds
            )
          : [];
      }
    } catch (e) {
      console.log(e);
    }
    return [];
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }

  /**        Here We may have some repeated Functions but All Functions must be optimized and Simple to understand
   *         Once I optimize it we will remove above repeated functions and use optimized functions.
   *         I am doing this because I saw same functions with same functionality are written is different services
   *         which should be written once and we have some functions that call by user EntityIds instead of entityId as
   *         our architecture is updated our remaining functionality needs to be updated.
   */

  /** This Functions Get All Users Against Given EntityId or The Users Whom the given entityId is assigned
   * than filter these users based on given roleId if roleId is given.
   * @param entityId is type of string. entityId can be ( RootEntityId , ChildEntityId or VendorEntityId)
   * @param roleId is type string
   * @returns list of Users
   */
  async getAssignedUsersInEntity(entityId: string, roleId?: string): Promise<GetUserQuery[]> {
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    let nextToken;
    const filter: ModelUserConditionInput = {
      // As One User can has multiple Entities assigned. so we use entityIds not entityId of user
      entityIds: {
        contains: entityId,
      },
      domain: {
        eq: DomainFrameworkService.getDomain(),
      },
    };
    if (roleId) {
      filter.roleId = { eq: roleId };
    }
    try {
      do {
        nextToken = result ? result.nextToken : null;
        result = await this.customApi.ListUsers(filter, environment.queryListLimit, nextToken);
        items = items.concat(result.items);
      } while (result.nextToken);
    } catch (e) {
      console.log(e);
      return [];
    }

    return items;
  }
  async getUserByEmail(email: string): Promise<any> {
    return await this.customApi.UserByEmail(email);
  }

  //    *************************************  New Optimized Code related Users Management    *********************************

  /**
   *  Get All Default Roles....
   * This src/app/shared/entity.service.ts service has a copy of this function to avoid circular dependency.
   * Make sure to sync it with that function if any change occurs.
   * @returns roles list
   */
  async getDefaultRoles(): Promise<GetRoleQuery[]> {
    // if default roles are not cached in service than get from DB.
    if (!this.cachedDefaultRoles) {
      let result: ListRolesQuery;
      let items: GetRoleQuery[] = [];

      try {
        result = await this.customApi.RolesByDefaultOrEntityId(UsersSettingConstant.default);
        items = items.concat(result.items);
      } catch (e) {
        console.log(e);
        return [];
      }
      this.defaultRoles = items;
      this.cachedDefaultRoles = this.defaultRoles;
    }
    return this.cachedDefaultRoles;
  }

  /**
   * Function Initializes role Mapper
   */
  initRoleNameByIdMapper() {
    if (this.cachedDefaultRoles && this.cachedDefaultRoles.length > 0) {
      this.cachedDefaultRoles.forEach(role => {
        this.roleIDToNameMapper[role.id] = role.name;
      });
    }
  }

  /**
   *  Get All Users with Role ( MSSP & Entity Leaders)
   * @returns Manager Users for Add New Vendor
   */
  async getMangers(): Promise<GetUserQuery[]> {
    try {
      const roles = await this.getDefaultRoles();
      if (roles && roles.length) {
        const promises = [];
        roles.map(role => {
          if (
            role?.name === AppLevelRoleEnum.ADMIN.toLowerCase() ||
            role?.name === AppLevelRoleEnum.MSSP.toLowerCase() ||
            role?.name === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
          ) {
            promises.push(this.getUsersByRoleId(role.id));
          }
        });
        const usersData = await Promise.all(promises);
        let users = [];
        usersData.forEach(usersArray => (users = users.concat(usersArray)));
        return users;
      }
    } catch (e) {
      console.log('Error : ', e);
    }
    return [];
  }

  async getVendorUsers(): Promise<GetUserQuery[]> {
    try {
      const roles = await this.getDefaultRoles();
      if (roles && roles.length) {
        const promises = [];
        roles.map(role => {
          if (role?.name === AppLevelRoleEnum.VENDOR.toLowerCase()) {
            promises.push(this.getUsersByRoleId(role.id));
          }
        });
        const usersData = await Promise.all(promises);
        let users = [];
        usersData.forEach(usersArray => (users = users.concat(usersArray)));
        return users;
      }
    } catch (e) {
      console.log('Error : ', e);
    }
    return [];
  }

  /**
   * Updates the last login date for user.
   * Only in the case for Third Party Login and
   * when a user auto-logs in. Rest of the cases are
   * handle in AuthPostAuthentication Lambda
   */
  async updateUserLastLogin(): Promise<void> {
    const user = await this.getCurrentUser();
    if (user) {
      const updatedUser: UpdateUserInput = {
        id: user.id,
        lastLogin: Date.now(),
      };
      await this.customApi.UpdateUser(updatedUser);
    }
  }

  loadSubscriptions() {
    this.subscriptionList = [this.onUpdateUserListener(), this.onUpdateRoleListener()];
  }

  observeUserInteraction() {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        from(this.authService.checkTokensValidity()).subscribe(isAuthorized => {
          this.postObservationSteps(isAuthorized);
        });
      }
    });

    window.addEventListener('focus', () => {
      from(this.authService.checkTokensValidity()).subscribe(isAuthorized => {
        this.postObservationSteps(isAuthorized);
      });
    });
  }

  postObservationSteps(isAuthorized) {
    if (!isAuthorized && this.currentUser) {
      localStorage.clear();
      sessionStorage.clear();
      this.currentUser = null;
      this.router.navigate(['/login']);
    }
  }
}
