<div class="modal-body">
  <div class="modal-header">
    <div class="user-icon-title-container">
      <span class="lato-18-n-vw message">
        {{ 'REQUEST A NEW FRAMEWORK' }}
      </span>
    </div>
    <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="closeModal()">
    </div>
  </div>
  <div class='modal-content-body'>
    <div class="framework-request-container">
      <div class="form-container">
        <div class="form-group">
          <label for="fullName" class="lato-18-n-vw text-white">Full Name</label>
          <input type="text" id="fullName" class="input-field" [ngModel]="userName" readonly />
        </div>
        <div class="form-group">
          <label for="email" class="lato-18-n-vw text-white">Email</label>
          <input type="email" id="email" class="input-field" [ngModel]="userEmail" readonly />
        </div>

      </div>
      <hr class="divider">
      <div class="additional-framework-request-container">
        <div class="additional-framework-listing-container">
          <div class="header-section">
            <div class="heading lato-18-n-vw text-white">{{ 'Additional Frameworks Request'}}</div>

          </div>
          <div class="main-section">
            <div class="tabset">
              <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="TAB_ENUMS.RISK" *ngIf="riskFrameworks.length">
                  <a ngbNavLink>Risk</a>
                  <ng-template ngbNavContent>
                    <div class="risk-framework-wrapper">
                      <div class="risk-framework-container">
                        <div class="toggle-container"
                          *ngFor="let riskFramework of riskFrameworks; let ir = index"
                          [ngClass]="{'last-child': ir === riskFrameworks?.length - 1}">
                          <div class="toggle-btn-container-wrapper"
                            (click)="selectFramework(riskFramework, riskFrameworks)">
                            <div class="round-checkbox">
                              <input id="target + ir" name="target" type="checkbox"
                                [checked]="riskFramework.status" />
                              <label for="target"
                                class="lato-16-n-vw capitalize label target"></label>
                            </div>
                            <div class="toggle-text text-ellipsis" [ngbTooltip]="riskFramework.name"
                              [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
                              (mouseout)="isFrameworkNameEllipsis = false"
                              [disableTooltip]="!isFrameworkNameEllipsis"
                              tooltipClass="toogle-text-tooltip"
                              [ngClass]="{'blue-ellipsis' : riskFramework.status, 'normal-ellipsis': !riskFramework.status}">
                              <span class="value lato-16-n-vw"
                                [ngClass]="{'toggle-active-text' : riskFramework.status}"
                                (click)="selectFramework(riskFramework, riskFrameworks)">{{
                                riskFramework.name }}</span>
                            </div>

                          </div>


                        </div>
                        <hr class="divider framework-divider"
                          *ngIf="additionalGuideMessage[active].enabled">
                        <ng-container *ngIf="additionalGuideMessage[active].enabled">
                          <ng-container
                            *ngTemplateOutlet="additionalFrameworkInfoContent"></ng-container>
                        </ng-container>
                      </div>

                    </div>


                  </ng-template>
                </li>

                <li [ngbNavItem]="TAB_ENUMS.COMPLIANCE" *ngIf="complianceFrameworks.length">
                  <a ngbNavLink>Compliance </a>
                  <ng-template ngbNavContent>
                    <div class="risk-framework-wrapper">
                      <div class="risk-framework-container">
                        <div class="toggle-container"
                          *ngFor="let compliance of complianceFrameworks; let ir = index"
                          [ngClass]="{'last-child': ir === complianceFrameworks?.length - 1}">
                          <div class="toggle-btn-container-wrapper"
                            (click)="selectFramework(compliance,complianceFrameworks )">
                            <div class="round-checkbox">
                              <input id="target + ir" name="target" type="checkbox"
                                [checked]="compliance.status" />
                              <label for="target"
                                class="lato-16-n-vw capitalize label target"></label>
                            </div>
                            <div class="toggle-text text-ellipsis" [ngbTooltip]="compliance.name"
                              [placement]="'top'" (mouseenter)="isEllipsisActive($event.target)"
                              (mouseout)="isFrameworkNameEllipsis = false"
                              [disableTooltip]="!isFrameworkNameEllipsis"
                              tooltipClass="toogle-text-tooltip"
                              [ngClass]="{'blue-ellipsis' : compliance.status, 'normal-ellipsis': !compliance.status}">
                              <span class="value lato-16-n-vw"
                                [ngClass]="{'toggle-active-text' : compliance.status}">{{
                                compliance.name }}</span>
                            </div>

                          </div>


                        </div>
                        <hr class="divider framework-divider">

                        <ng-container *ngIf="additionalGuideMessage[active].enabled">
                          <ng-container
                            *ngTemplateOutlet="additionalFrameworkInfoContent"></ng-container>
                        </ng-container>
                      </div>

                    </div>

                  </ng-template>
                </li>
                <li [ngbNavItem]="TAB_ENUMS.CUSTOM">
                  <a ngbNavLink>Custom </a>
                  <ng-template ngbNavContent>
                    <div class="custom-framework-request-container">
                      <div class="select-deselect-container">
                        <cygov-checkbox [width]="1.9" [height]="1.9" [useViewPortDimensions]="true"
                          [isChecked]="additionalGuideMessage[active].enabled"
                          (changed)="additionalGuideMessage[active].enabled = $event">
                        </cygov-checkbox>
                        <span class="lato-16-n-vw text-white">Request New Framework</span>
                      </div>

                      <ng-container *ngTemplateOutlet="additionalFrameworkInfoContent">
                      </ng-container>
                    </div>


                  </ng-template>
                </li>

              </ul>
              <div class="search-container-wrapper" *ngIf="active !== TAB_ENUMS.CUSTOM">
                <div cygovSvgIcon [svgName]="'new-search-icon'" class="search-icon"></div>
                <input type="text" placeholder="Search" class="search-text-box">
              </div>

              <div [ngbNavOutlet]="nav"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="divider-footer"></div>
  <div class="modal-content-footer">
    <div class="d-flex">
      <!-- <button class="send-btn lato-16-n-vw" [disabled]="!selectedFramework">SEND</button> -->
      <button class="send-btn lato-14-n-vw"
        [disabled]="active===3 ? additionalGuideMessage[active].message==='' : !additionalGuideMessage[active].enabled"
        (click)="openNewFrameworkRequestAcknowledgementModal(newFrameworkRequestModalHeader,newFrameworkRequestModalContent, newFrameworkRequestModalFooter); sendData()">SEND</button>
      <cygov-button class=" button-margin" [buttonText]="'CANCEL'" [buttonColor]="'#030715'"
        [buttonBorder]="'#C5C9D1'" [buttonWidth]="'5.16vw'" [textColor]="'#C5C9D1'"
        (click)="closeModal()" [textSize]="'14'">
      </cygov-button>
    </div>
  </div>
</div>

<ng-template #additionalFrameworkInfoContent>
  <div class="additional-framework-info-container"
    [ngClass]="{'disabled-mode' : !additionalGuideMessage[active].enabled } ">
    <div class="label">Please provide any additional guides or information:
    </div>
    <div class="textarea-wrapper">

      <textarea class="textarea-input lato-16-n-vw"
        [(ngModel)]="additionalGuideMessage[this.active].message"></textarea>
    </div>
    <div class="mandatory-indicator" *ngIf="active === TAB_ENUMS.CUSTOM">
      <span class="asterisk lato-14-l-vw">*</span><span class="lato-14-n-vw text-white">
        Mandatory</span>
    </div>
  </div>
</ng-template>


<ng-template #newFrameworkRequestModalHeader>
  <!-- Empty for filters -->
</ng-template>

<ng-template #newFrameworkRequestModalContent>
  <div
    class='d-flex justify-content-center align-items-center flex-column confirm-filter-popup-content'>
    <div class="tick-icon-container">
      <div class="tick-icon" cygovSvgIcon [svgName]="'complete-popup'"></div>
    </div>
    <span class='lato-22-n-vw text-white'>Your request has been received.
    </span>
    <span class='lato-22-n-vw text-white'>A Centraleyes team member will
      contact you shortly.</span>
  </div>
</ng-template>

<ng-template #newFrameworkRequestModalFooter>
  <div class='external-scan-request-acknowledgement-footer d-flex justify-content-center'>
    <cygov-button class='lato-14-n-vw' [buttonText]="'OK'" [buttonWidth]="'6.6vw'" [textSize]="'14'"
      [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'" (click)="closeModalTemplate()"
      [buttonHeight]="'2.963vh'"></cygov-button>

  </div>
</ng-template>