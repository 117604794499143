import { environment } from './../../environments/environment';
import {
  CreateReportInput,
  CreateReportMutation,
  EntityTypeEnum,
  GetAssessmentStandardFrameworkQuery,
  GetUserQuery,
  ModelReportFilterInput,
  ReportByEntityIdQuery,
  StandardType,
} from './../API.service';
import { BoardWizardStepsEnum } from './../shared/enums/board-wizard-steps.enum';
import {
  ReportDates,
  Widgets,
  Assets,
  Events,
  ExternalAudit,
  Projects,
  ProjectItem,
  StepMeta,
  ThreatAlertObject,
  ThreatAlerts,
  S3ReportObject,
} from 'models/board-wizard.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BoardOverview } from 'models/board-overview.modal';
import { PostureChartModel, PostureDetailData } from 'models/posture.model';
import { DetailItemValue, DetailItem } from 'models/attack-detail.model';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from '../auth/auth.service';
import Lambda from 'aws-sdk/clients/lambda';
import { Auth } from 'aws-amplify';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FileTypeEnum, GetReportQuery } from 'app/API.service';
import { APIService } from 'app/API.service';
import { ReportTypeEnum } from 'app/API.service';
import { BreadCrumb } from 'models/bread-crumb.model';
import { FileService } from 'app/shared/file.service';
import * as uuid from 'uuid';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetChartData } from 'models/budget-chart.model';
import {
  CostRemediateImpactEnum,
  ImpactTextEnum,
  PostureChartDataEnum,
  PostureFrameworkGroupEnum,
  ProbabilityTextEnum,
  ReportDownloadOptionsEnum,
} from 'app/shared/enums/board.enum';
import { EntityService } from 'app/shared/entity.service';
import FrameworkNode from 'models/framework-node.model';
import { UtilsService } from 'app/shared/utils.service';
import { FrameworkTreeService } from 'app/shared/framework-tree.service';
import { NodeTypeEnum } from 'app/shared/enums/node-type.enum';
import JSZip from 'jszip';
import { UserService } from 'app/shared/user.service';
import { CUSTOMAPIService } from 'app/custom-api.service';

@Injectable({
  providedIn: 'root',
})
export class BoardService {
  private boardData: BoardOverview;
  private postureDetailData: PostureDetailData;
  private wizardDraftReport: CreateReportInput;

  // ======= New Feature for Board Module =========
  stepsDataObservable: BehaviorSubject<StepMeta[]>;
  showNextBtn = new BehaviorSubject<boolean>(false);
  showPrevBtn = new BehaviorSubject<boolean>(false);
  currentEntityId = new BehaviorSubject<string>('');
  allReportsLoaded = new BehaviorSubject<any | GetReportQuery[]>(null);
  prevBtnClicked = new Subject<boolean>();
  nextBtnClicked = new Subject<boolean>();
  boardChange = new Subject<string>();
  currentStepObservable = new Subject<StepMeta>();
  currentStepNumberObservable = new Subject<number>();
  downloadStepData = new Subject<any>();
  loadTemplate = new Subject<any>();
  // intentionally commented, need to test behaviorSubject when merge code.
  // currentOverviewLoaded: Subject<any> = new Subject<any>();
  currentOverviewLoaded: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  archiveReport: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private stepsInformation: StepMeta[];
  private progressNumber = 0;
  private progress = new BehaviorSubject<number>(this.progressNumber);
  private currentStep: StepMeta;
  private breadcrumbs: BreadCrumb[] = [];
  // In future will remove this hardcoded entity id;
  private entityId = '';
  private vendorsDetail: any = {};
  private postureDetail: any = {};
  private threatAlertsList: ThreatAlertObject = null;
  // contain all information of report which is save in s3
  private currentReportObject: S3ReportObject;
  private cacheReportObject: S3ReportObject;
  private latestReportObject: S3ReportObject;
  private previousReportObjects: S3ReportObject[] = [];
  reportObjectList: any[] = [];
  entityReports: GetReportQuery[] = [];
  draftToShow: GetReportQuery;
  reportToShow: GetReportQuery;
  wizardInitRequired = false;
  draftReportLoaded = true;
  draftSaved = true;
  currentOverviewData: StepMeta[] = [];
  previousCurrentReports: StepMeta[][] = [];
  categoryCISA = 'CISA';
  doesReportExists: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // flag to set report existence status
  tableHeightHeader = 0.075; // the height of the board table header in VH
  tableHeightRow = 0.062; // the height of a single row in board table in VH
  archiveReportId: string; // archive report Id to show on overview screen, this Id is kept null by default
  fromWizardImport: boolean = false;
  importQuarter: any;
  currentUser: GetUserQuery;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private authService: AuthService,
    private api: APIService,
    private fileService: FileService,
    private entityService: EntityService,
    private frameworkTreeService: FrameworkTreeService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private customApi: CUSTOMAPIService
  ) {}

  async initWizard(): Promise<void> {
    this.currentUser = await this.userService.getCurrentUser();
    if (this.wizardInitRequired) {
      this.wizardInitRequired = false;

      this.wizardDraftReport = null;
      this.entityReports.forEach(report => {
        if (report.isDraft) {
          // Setting most recent created Draft in wizardDraftReport
          if (
            !this.wizardDraftReport ||
            !this.wizardDraftReport.createdAt ||
            this.wizardDraftReport.createdAt < report.createdAt
          ) {
            delete report.__typename;
            this.wizardDraftReport = report;
            this.draftReportLoaded = true;
          }
        }
      });
      if (!this.wizardDraftReport) {
        this.initReport();
        this.showNextBtn.next(false);
        this.showPrevBtn.next(false);
      }

      await this.initWizardSteps();
      this.progressNumber = 0;
      this.setProgress(this.progressNumber);
    } else {
      this.showNextBtn.next(false);
      this.showPrevBtn.next(false);
    }
  }

  // Function to Flush service data before init
  flushService(): void {
    this.wizardDraftReport = null;
    this.wizardInitRequired = false;
    this.draftReportLoaded = false;
    this.draftSaved = false;
    this.reportToShow = null;
    this.draftToShow = null;
    this.currentOverviewData = null;
    this.previousCurrentReports = [];
    this.allReportsLoaded.next(null);
    this.importQuarter = null;
  }

  async init(): Promise<void> {
    this.flushService();
    if (this.entityId) {
      const entityReport = await this.listReportsByEntityId(this.entityId);
      this.entityReports = JSON.parse(
        JSON.stringify(entityReport, (k, v) => (k === 'updatedAt' || k === '__typename' ? undefined : v))
      );

      this.allReportsLoaded.next(this.entityReports);
      this.entityReports.forEach(report => {
        if (report.isDraft) {
          // Setting most recent created Draft in wizardDraftReport
          if (
            !this.wizardDraftReport ||
            !this.wizardDraftReport.createdAt ||
            this.wizardDraftReport.createdAt < report.createdAt
          ) {
            this.wizardDraftReport = report;
          }
        } else {
          // Setting most recent created Report in reportToShow
          if (!this.reportToShow || this.reportToShow.createdAt < report.createdAt) {
            this.reportToShow = report;
          }
        }
      });
    }
    this.wizardInitRequired = true;
    await this.initWizard();
    if (this.reportToShow) {
      this.doesReportExists.next(true);
      await this.loadCurrentOverviewData(true);
    } else {
      this.doesReportExists.next(false);
      this.currentOverviewLoaded.next(false);
      const url = this.router.url;
      if (!url.includes('wizard')) {
        this.router.navigate(this.generateUrlWithBaseUrl('wizard'));
      }
    }
  }

  getEntityReports(): GetReportQuery[] {
    return this.entityReports;
  }

  async listReportsByEntityId(entityId: string, filter?: ModelReportFilterInput): Promise<GetReportQuery[]> {
    try {
      let reportList: GetReportQuery[] = [];
      let nextToken = null;
      do {
        const reportResponse: ReportByEntityIdQuery = await this.customApi.ReportByEntityId(
          entityId,
          null,
          filter,
          environment.queryListLimit,
          nextToken
        );
        reportList = reportList.concat(reportResponse.items as any);
        nextToken = reportResponse.nextToken;
      } while (nextToken);
      return reportList;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  initReport(): void {
    this.wizardDraftReport = {
      id: uuid.v4(),
      entityId: this.entityId,
      startDate: null,
      endDate: null,
      type: null,
      isDraft: false,
      name: '',
      managerName: this.currentUser.name,
      previousReports: null,
    };
  }
  async initWizardSteps(): Promise<void> {
    const reportDatesContent: ReportDates = {
      name: '',
      startDate: null,
      endDate: null,
      reportType: ReportTypeEnum.QUARTERLY,
      prevReportIds: [],
      firstPrevQuarter: '',
      secondPrevQuarter: '',
      selectedQuarter: null,
    };

    const widgetsContent: Widgets = {
      widgets: null,
    };

    const assetsContent: Assets = {
      assetItems: null,
    };

    const eventsContent: Events = {
      eventPID: null,
      attackFunnel: null,
      proactiveMeasures: null,
    };
    const externalAuditContent: ExternalAudit = {
      externalAuditItems: null,
    };
    const budgetContent: Projects = {
      totalBudget: 0,
      projectItems: null,
    };
    const futureProjectsContent: Projects = {
      totalBudget: null,
      projectItems: null,
    };

    // Set Current active Step and Number
    this.currentStepObservable.subscribe(step => {
      this.currentStep = step;
      const stepNumber = this.stepsInformation.findIndex(stepInfo => stepInfo.title === step.title);
      this.currentStepNumberObservable.next(stepNumber);
    });

    const initStepInformation = (): void => {
      this.stepsInformation = [
        // Step 1
        {
          isComplete: false,
          isActive: true,
          title: BoardWizardStepsEnum.NEW_REPORT_DATA,
          description: 'Select the start date and end date for new report',
          icon: 'calendar-icon2',
          children: [],
          htmlId: 'steps-report-date',
          content: reportDatesContent,
        },
        // Step 2
        {
          isComplete: false,
          isActive: false,
          title: BoardWizardStepsEnum.SELECT_WIDGETS,
          description: 'Review the widgets that you would like to include in the executive report',
          icon: 'click-hand-icon',
          children: [],
          htmlId: 'steps-widgets',
          content: widgetsContent,
        },
        // Step 3
        {
          isComplete: false,
          isActive: false,
          title: BoardWizardStepsEnum.ADD_DATA,
          description: 'Please fill in the following sections',
          icon: 'listing-icon',
          htmlId: 'steps-data',
          content: null,
          children: [
            // Step 3.1
            {
              isComplete: false,
              isActive: false,
              title: BoardWizardStepsEnum.ASSET,
              description: '',
              icon: '',
              children: [],
              content: assetsContent,
            },
            // Step 3.2
            {
              isComplete: false,
              isActive: false,
              title: BoardWizardStepsEnum.EVENTS,
              description: '',
              icon: '',
              children: [],
              content: eventsContent,
            },
            // Step 3.3
            {
              isComplete: false,
              isActive: false,
              title: BoardWizardStepsEnum.EXTERNAL_AUDIT,
              description: '',
              icon: '',
              children: [],
              content: externalAuditContent,
            },
            // Step 3.4
            {
              isComplete: false,
              isActive: false,
              title: BoardWizardStepsEnum.BUDGET,
              description: '',
              icon: '',
              children: [],
              content: budgetContent,
            },
            // Step 3.5
            {
              isComplete: false,
              isActive: false,
              title: BoardWizardStepsEnum.FUTURE_PROJECTS_REGISTRY,
              description: '',
              icon: '',
              children: [],
              content: futureProjectsContent,
            },
          ],
        },
        // Step 4
        {
          isComplete: false,
          isActive: false,
          title: BoardWizardStepsEnum.PUBLISH,
          description: 'Finalize your board',
          icon: 'publish-icon',
          children: [],
          htmlId: 'steps-publish',
          content: null,
        },
      ];
      this.calculateStepProgressNcurrentStep(this.stepsInformation);
    };
    if (this.draftReportLoaded) {
      try {
        const stepDataText = await this.retrieveReportS3ZipFile(this.wizardDraftReport.id, true);
        this.stepsInformation = JSON.parse(stepDataText).body;

        const content = this.getStepContentByStepName(BoardWizardStepsEnum.NEW_REPORT_DATA) as ReportDates;
        content.startDate = new Date(content.startDate);
        content.endDate = new Date(content.endDate);
        this.setStepContentByStepName(BoardWizardStepsEnum.NEW_REPORT_DATA, content);
      } catch (err) {
        initStepInformation();
      } finally {
        this.stepsDataObservable = new BehaviorSubject<StepMeta[]>(this.stepsInformation);
        this.calculateStepProgressNcurrentStep(this.stepsInformation);
      }
    } else {
      initStepInformation();
      this.stepsDataObservable = new BehaviorSubject<StepMeta[]>(this.stepsInformation);
    }
  }

  // Load archive report and cache it
  loadArchiveReport(id: string) {
    const reportObject = this.reportObjectList.find(report =>
      id ? id === report.id : this.reportToShow.id === report.id
    );
    if (reportObject) {
      reportObject.body = typeof reportObject.body === 'string' ? JSON.parse(reportObject.body) : reportObject.body;
      this.cacheArchive(reportObject);
    }
  }
  async loadAllReports(reportList: any[]): Promise<void> {
    const missingReportIds = reportList.filter(report => !this.reportObjectList.find(rol => rol.id === report.id));
    const reportData = missingReportIds.map(async report => {
      this.reportObjectList.push(
        JSON.parse(await this.retrieveReportS3ZipFile(report.id ? report.id : this.reportToShow.id, true))
      );
    });
    await Promise.all(reportData);
  }

  async loadCurrentOverviewData(isLatest: boolean = false): Promise<void> {
    try {
      let isDataLoaded = false;
      this.previousReportObjects = [];
      this.previousCurrentReports = [];

      const requiredId = this.archiveReportId ? this.archiveReportId : this.reportToShow.id;
      let reportObject = this.reportObjectList.find(rol => rol.id === requiredId);
      // if we dont have report fetched only then fetch it.
      if (!reportObject) {
        reportObject = JSON.parse(await this.retrieveReportS3ZipFile(requiredId, true));
      }

      if (reportObject) {
        reportObject.body = typeof reportObject.body === 'string' ? JSON.parse(reportObject.body) : reportObject.body;
        // save all reports as cached list
        if (
          this.reportObjectList &&
          this.reportObjectList.length &&
          !this.reportObjectList.find(a => a.id === reportObject.id)
        ) {
          this.reportObjectList.push(reportObject);
        }

        this.addCurrentReportObject = reportObject;
        if (isLatest) {
          this.addLatestReportObject = reportObject;
        }
        this.currentOverviewData = reportObject.body;
        this.addVendorsDetail = reportObject.vendorsDetail ? reportObject.vendorsDetail : {};
        this.addPostureDetail = reportObject.postureDetail ? reportObject.postureDetail : {};
        this.addThreatAlerts = reportObject.threatAlerts ? reportObject.threatAlerts : {};
        isDataLoaded = true;
      }
      // previousReports array can have null, so remove it by filtering
      let reportPrevReportIds = [];
      if (UtilsService.isDefined(this.archiveReportId)) {
        this.entityReports.forEach(report => {
          if (report.id === this.archiveReportId) {
            reportPrevReportIds.push(...report.previousReports);
          }
        });
      } else {
        reportPrevReportIds = this.reportToShow.previousReports.filter(pr => pr);
      }

      if (this.reportToShow && this.reportToShow.previousReports && reportPrevReportIds.length) {
        const promises = [];
        let previousReports = this.reportObjectList.filter(rol => reportPrevReportIds.includes(rol.id));
        if (!previousReports.length) {
          reportPrevReportIds.map(reportId => {
            if (UtilsService.isDefined(reportId)) {
              promises.push(this.retrieveReportS3ZipFile(reportId, true));
            }
          });
        } else if (previousReports.length !== reportPrevReportIds.length) {
          const missingReportIds = reportPrevReportIds.filter(id => !previousReports.find(c => c.id === id));
          missingReportIds.map(reportId => {
            if (UtilsService.isDefined(reportId)) {
              promises.push(this.retrieveReportS3ZipFile(reportId, true));
            }
          });
        }
        previousReports = await Promise.all(promises);
        // clear old previous reports and fetch all new ones.
        this.addPreviousReportObjects = [];

        if (previousReports && previousReports.length) {
          previousReports.forEach(report => {
            if (report) {
              // if two previous reports fetch then dont add more
              if (this.previousReportObjects.length < 2) {
                // store prev reports full object
                this.previousReportObjects.push(JSON.parse(report));
              }
              // store prev reports body array 'stepMeta'
              this.previousCurrentReports.push(JSON.parse(report).body);
            }
          });
        }
      }
      this.currentOverviewLoaded.next(isDataLoaded);
    } catch (e) {
      console.log('currentOverviewLoaded Error', e);
      this.currentOverviewLoaded.next(false);
    }
  }

  async retrieveReportS3File(id: string): Promise<any> {
    try {
      const s3FileUrl = await this.fileService.getFileUrlFromBucket(
        `${FileTypeEnum.BOARD_REPORTS}/${this.entityId}/${id}`,
        { contentType: 'text/plain', level: 'public' }
      );
      if (s3FileUrl) {
        const file = await this.fileService.getFile(s3FileUrl);
        return file ? file : null;
      } else {
        return null;
      }
    } catch (e) {
      console.log('Error : ', e.message);
      return null;
    }
  }

  async retrieveReportS3ZipFile(id: string, cached = false): Promise<any> {
    try {
      // here cached means download file directly, so aws auto cache if fetch continuously
      let s3File = await this.fileService.getFileUrlFromBucket(
        `${FileTypeEnum.BOARD_REPORTS}/${this.entityId}/${id}.zip`,
        { contentType: 'text/plain', level: 'public', download: cached }
      );

      if (!s3File) {
        return null;
      }
      // if cached false it will return url
      if (typeof s3File === 'string') {
        s3File = await this.fileService.getFile(s3File, { responseType: 'blob' });
      } else {
        // if cached true then it will return object
        s3File = s3File.Body;
      }
      const zip = new JSZip();
      const originalFile = await zip.loadAsync(s3File);
      const fileName = Object.keys(originalFile.files)[0];
      const blob = await originalFile.files[fileName].async('blob');
      return await blob.text();
    } catch (err) {
      console.log('S3 Error: ', err);
      return null;
    }
  }

  getCurrentOverviewData(): StepMeta[] {
    return this.currentOverviewData;
  }

  getPreviousReports(): StepMeta[][] {
    return this.previousCurrentReports;
  }

  async downloadBoardReport(URLS_ARRAY) {
    try {
      const session = Object.keys(localStorage).reduce(
        (obj, item) => ({ ...obj, [item]: localStorage.getItem(item) }),
        {}
      );
      const payload = {
        type: 'board',
        body: {
          urls: URLS_ARRAY,
          session,
          domain: window.location.origin,
          userId: this.authService.currentAuthUser.username,
        },
      };

      const credentials = await Auth.currentCredentials();

      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: this.authService.getRegion(),
      });
      return await lambda
        .invoke({
          FunctionName: `generateReport-${this.authService.getCurrentEnvironment()} `,
          Payload: JSON.stringify(payload),
        })
        .promise();
    } catch (e) {
      this.logger.error('Download Report - Error: ', e);
      return;
    }
  }

  // ======= New Features for Board Module =========

  getStepsInformation(): StepMeta[] {
    return this.stepsInformation;
  }

  // Set progress status in progress Number and in Observable progress variables
  setProgress(num: number): void {
    this.progressNumber = num;
    this.progress.next(num);
  }

  // Get Observable Progress
  getProgress(): Observable<number> {
    return this.progress.asObservable();
  }

  // Get Progress Number
  getProgressNumber(): number {
    return this.progressNumber;
  }

  // Get Observable current step
  getCurrentStepObservable(): Observable<StepMeta> {
    return this.currentStepObservable;
  }

  // Get current step
  getCurrentStep(): StepMeta {
    return this.currentStep;
  }

  // Get current Step number by title attribute
  getCurrentStepNumber(): number {
    return this.stepsInformation.findIndex(step => step.title === this.currentStep.title);
  }

  // Get current Step Content To be used inside Component
  getCurrentStepContent(): ReportDates | Widgets | Assets | Events | ExternalAudit | Projects {
    let currentStep = this.getCurrentStep();
    if (currentStep.children && currentStep.children.length > 0) {
      currentStep = currentStep.children.find(item => item.isActive);
    }
    return currentStep.content;
  }

  getCurrentStepTitle(): string {
    const step = this.getCurrentStep();
    return step.children && step.children.length ? step.children.find(item => item.isActive).title : step.title;
  }

  initializeOverviewLoadedSubscriber() {
    this.currentOverviewLoaded.next(null);
  }

  /**
   * @param {string} id // on the basis of this id data will be loaded on the overview screen
   * */
  async setArchiveReport(id: string) {
    this.archiveReportId = id;
    const isLatest = !id;
    await this.loadCurrentOverviewData(isLatest);
    this.archiveReport.next(!isLatest);
  }

  getArchiveReportId(): string {
    return this.archiveReportId;
  }

  getFromWizardStepsOverview(): boolean {
    return this.fromWizardImport;
  }

  setFromWizardImportOverview(boolVal: boolean): void {
    this.fromWizardImport = boolVal;
  }

  // Archive cache setter
  cacheArchive(report) {
    this.cacheReportObject = report;
    this.archiveReport.next(true);
  }

  // Currently cached archive getter
  getCacheArchive() {
    return this.cacheReportObject;
  }

  setCurrentStepContent(content: ReportDates | Widgets | Assets | Events | ExternalAudit | Projects): void {
    this.currentStep.content = content;
  }

  setStepContentByStepName(
    stepName: BoardWizardStepsEnum,
    content: ReportDates | Widgets | Assets | Events | ExternalAudit | Projects
  ): void {
    const step = this.getStepByStepName(stepName);
    if (step) {
      step.content = content;
    }
  }

  getStepByStepName(stepName: BoardWizardStepsEnum, stepsInformation: StepMeta[] = this.stepsInformation): StepMeta {
    let step = null;
    stepsInformation.forEach(item => {
      if (item.children && !!item.children.length) {
        const tempStep = this.getStepByStepName(stepName, item.children);
        if (tempStep) {
          step = tempStep;
        }
      }
      if (item.title === stepName) {
        step = item;
      }
    });
    return step;
  }

  getStepContentByStepName(
    stepName: BoardWizardStepsEnum,
    stepsInformation: StepMeta[] = this.stepsInformation
  ): ReportDates | Widgets | Assets | Events | ExternalAudit | Projects {
    const step = this.getStepByStepName(stepName, stepsInformation);
    if (step && step.content) {
      return { ...step.content };
    } else {
      return null;
    }
  }

  setImportQuarter(val: any): void {
    this.importQuarter = val;
  }

  getImportQuarter(): any {
    return this.importQuarter;
  }

  // Set Visibility for Next button
  setNextBtnVisibility(visibility: boolean): void {
    this.showNextBtn.next(visibility);
  }

  // Set Visibility for Previous button
  setPrevBtnVisibility(visibility: boolean): void {
    this.showPrevBtn.next(visibility);
  }

  // Triggers that Next button is clicked (One purpose is to save component state)
  setNextBtnClicked(): void {
    this.nextBtnClicked.next(true);
  }
  // Triggers that Previous button is clicked
  setPrevBtnClicked(): void {
    this.prevBtnClicked.next(true);
  }
  // Board Table change occurred
  setBoardChange(action): void {
    this.boardChange.next(action);
  }

  // Switch Steps on completing current
  switchBtwSteps(stepsArray: StepMeta[], stepNumber: number, move: 'forward' | 'backward'): void {
    const progressValues = [30, 30, [5, 5, 5, 5, 5], 15];
    const currentStepIndex = stepNumber ? stepNumber : stepsArray.findIndex(data => data.isActive);
    switch (move) {
      case 'forward': {
        this.setNextBtnClicked();
        const childrenSteps = stepsArray[currentStepIndex].children;
        const childStepIndex = childrenSteps.findIndex(data => data.isActive);
        // if current child index found
        if (childStepIndex !== -1) {
          // current main element of array is not completed
          if (!stepsArray[currentStepIndex].isComplete) {
            // Set New progress value only if current child step is not complete
            if (!childrenSteps[childStepIndex].isComplete) {
              this.setProgress(this.progressNumber + +progressValues[currentStepIndex][childStepIndex]);
            }
          }
          childrenSteps[childStepIndex].isComplete = true;
          childrenSteps[childStepIndex].isActive = false;
          if (childrenSteps[childStepIndex + 1]) {
            // check if next element present only then make below changes
            childrenSteps[childStepIndex + 1].isActive = true;
          } else {
            // if Last child was active then inactive it and activate next step
            stepsArray[currentStepIndex].isActive = false;
            stepsArray[currentStepIndex].isComplete = true;
            stepsArray[currentStepIndex + 1].isActive = true;
            // Set current Active Step
            this.currentStepObservable.next(stepsArray[currentStepIndex + 1]);
            // If next step has children then turn isActive of first child to true
            if (stepsArray[currentStepIndex + 1].children.length > 0) {
              stepsArray[currentStepIndex + 1].children[0].isActive = true;
            }
          }
        } else {
          // current element should not be the last element or next element should be completed
          if (currentStepIndex === stepsArray.length - 1 || !stepsArray[currentStepIndex + 1].isComplete) {
            // Set New progress value if current element isn't completed
            if (!stepsArray[currentStepIndex].isComplete) {
              this.setProgress(this.progressNumber + +progressValues[currentStepIndex]);
            }
          }
          stepsArray[currentStepIndex].isActive = false;
          stepsArray[currentStepIndex].isComplete = true;
          if (stepsArray[currentStepIndex + 1]) {
            stepsArray[currentStepIndex + 1].isActive = true;
            // Set current Active Step
            this.currentStepObservable.next(stepsArray[currentStepIndex + 1]);
            // If next step has children then turn isActive of first child to true
            if (stepsArray[currentStepIndex + 1].children.length > 0) {
              stepsArray[currentStepIndex + 1].children[0].isActive = true;
            }
          }
        }
        break;
      }
      case 'backward': {
        if (currentStepIndex <= 1) {
          // Hide previous and next buttons When previous Button is clicked on First or Zero Step
          this.setNextBtnVisibility(false);
          this.setPrevBtnVisibility(false);
        }
        if (currentStepIndex) {
          const childrenSteps = stepsArray[currentStepIndex].children;
          const childStepIndex = childrenSteps.findIndex(data => data.isActive);
          // if child with isComplete false found
          if (childStepIndex > 0 && childStepIndex < childrenSteps.length) {
            childrenSteps[childStepIndex].isActive = false;
            // check if next element present only then make below changes
            if (childrenSteps[childStepIndex - 1]) {
              childrenSteps[childStepIndex - 1].isActive = true;
            }
          } else {
            stepsArray[currentStepIndex].isActive = false;
            stepsArray[currentStepIndex - 1].isActive = true;
            // Set current Active Step
            this.currentStepObservable.next(stepsArray[currentStepIndex - 1]);
            // If next step has children then turn isActive of first child to true
            const totalChildren = stepsArray[currentStepIndex - 1].children.length;
            if (totalChildren > 0) {
              stepsArray[currentStepIndex - 1].children[totalChildren - 1].isActive = true;
            }
          }
          break;
        } else {
          // Trigget This subscription when we are at step zero
          this.setPrevBtnClicked();
        }
      }
    }

    this.stepsDataObservable.next(stepsArray);
  }

  // Calculate Step Progress and current Step for Board Wizard by stepInformation
  calculateStepProgressNcurrentStep(stepsInformation: StepMeta[]): void {
    const progressValues = [30, 30, [5, 5, 5, 5, 5], 15];
    let currentProgress = 0;
    let currentStepNum = 0;
    stepsInformation.forEach((step, i) => {
      // step has children
      if (step.children && step.children.length > 0) {
        step.children.forEach((subStep, j) => {
          if (subStep.isComplete) {
            currentProgress += +progressValues[i][j];
          }
        });
      } else if (step.isComplete) {
        currentProgress += +progressValues[i];
      }
      if (step.isActive) {
        currentStepNum = stepsInformation.indexOf(step);
      }
    });
    this.progressNumber = currentProgress;
    this.progress.next(currentProgress);
    this.currentStepNumberObservable.next(currentProgress);
    this.currentStepObservable.next(stepsInformation[currentStepNum]);

    // If currentStep is not first step then show next/prev btns
    if (currentStepNum) {
      this.setNextBtnVisibility(true);
      this.setPrevBtnVisibility(true);
    }
  }

  async getReportByEntityId(entityId: string): Promise<GetReportQuery[]> {
    let results = [];
    let nextToken = null;
    do {
      const result = await this.customApi.ReportByEntityId(entityId, null, null, 10000, nextToken);
      results = results.concat(result.items);
      nextToken = result.nextToken;
    } while (nextToken);
    return results;
  }

  setReportData(reportData: ReportDates, isDraft = false): void {
    this.wizardDraftReport.startDate = reportData.startDate.getTime();
    this.wizardDraftReport.endDate = reportData.endDate.getTime();
    this.wizardDraftReport.type = reportData.reportType;
    this.wizardDraftReport.name = reportData.name;
    this.wizardDraftReport.managerName = this.currentUser.name;
    this.wizardDraftReport.previousReports = reportData.prevReportIds;
    this.wizardDraftReport.isDraft = isDraft;
    this.wizardDraftReport.createdAt = Date.now();
  }

  async saveReportInDb(isDraft = false): Promise<CreateReportMutation> {
    try {
      const reportData: ReportDates = this.getStepContentByStepName(
        BoardWizardStepsEnum.NEW_REPORT_DATA
      ) as ReportDates;

      this.setReportData(reportData, isDraft);
      if (this.draftReportLoaded || this.draftSaved) {
        await this.customApi.UpdateReport(
          { id: this.wizardDraftReport.id, ...this.wizardDraftReport },
          { entityId: { eq: this.wizardDraftReport?.entityId } }
        );
      } else {
        await this.customApi.CreateReport(this.wizardDraftReport);
      }
    } catch (e) {
      this.logger.error('createReport - Error: ', e);
      return Promise.reject(e);
    }
  }
  async saveReportInS3(entity, executeLambda: boolean = false): Promise<void> {
    const content = this.stepsInformation[0].content as ReportDates;
    const quarter = content.selectedQuarter.label && entity ? entity.name + '-' + content.selectedQuarter.label : null;
    const fileInfo = {
      id: this.wizardDraftReport.id,
      entityId: this.entityId,
      entityDetail: {
        score: entity.scores,
      },
      body: this.getStepsInformation() as any,
      fileType: FileTypeEnum.BOARD_REPORTS,
      quarter,
      vendorsDetail: this.getVendorsDetail,
      postureDetail: this.getPostureDetail,
      threatAlerts: this.threatAlertsList,
    };
    const newFileInfo = { ...fileInfo };
    const zip = new JSZip();
    zip.file(`${this.wizardDraftReport.id}.txt`, JSON.stringify(fileInfo));
    const zipFile = await zip.generateAsync({ type: 'blob' });
    newFileInfo.body = zipFile;
    await this.fileService.uploadToS3(newFileInfo);
    // Execute generate Report event for PDF
    if (executeLambda) {
      // This condition will make sure the lambda should be executed only for Save Report not for Draft Report.
      await this.generateReportEvent(fileInfo, ReportDownloadOptionsEnum.PDF);
      // Execute generate Report event for PPTX
      await this.generateReportEvent(fileInfo, ReportDownloadOptionsEnum.PPTX);
    }
  }
  async saveReport(): Promise<void> {
    const entity = await this.entityService.getEntity(this.entityId, false);

    // Cook reports threat alerts
    await this.populateThreatAlerts(entity);
    // Cook Posture Information for current and previous reports
    await this.setPostureDataFromFrameworks(entity);
    await Promise.all([this.saveReportInDb(), this.saveReportInS3(entity, true)]);
  }

  async saveDraftReport(): Promise<void> {
    this.setNextBtnClicked();
    const entity = await this.entityService.getEntity(this.entityId, false);
    await Promise.all([this.saveReportInDb(true), this.saveReportInS3(entity)]);
    this.draftSaved = true;
  }

  // ====== Getter Setter ========
  // Breadcrumb methods
  set newBreadCrumbs(obj: BreadCrumb[]) {
    this.breadcrumbs = obj;
  }

  get currentBreadCrumbs(): BreadCrumb[] {
    return this.breadcrumbs;
  }

  get boardBaseUrl(): string {
    return `/board/${this.entityId}`;
  }

  set addVendorsDetail(obj: any) {
    this.vendorsDetail = obj;
  }
  get getVendorsDetail(): any {
    return this.vendorsDetail;
  }

  set addPostureDetail(obj: any) {
    this.postureDetail = obj;
  }
  get getPostureDetail(): any {
    return this.postureDetail;
  }

  set addCurrentReportObject(obj: any) {
    this.currentReportObject = obj;
  }
  get getCurrentReportObject(): S3ReportObject {
    return this.currentReportObject;
  }

  // latest report setter
  set addLatestReportObject(obj: any) {
    this.latestReportObject = obj;
  }

  // latest report getter
  get getLatestReportObject(): S3ReportObject {
    return this.latestReportObject;
  }

  set addPreviousReportObjects(value: any[]) {
    this.previousReportObjects = value;
  }
  get getPreviousReportObjects(): any {
    return this.previousReportObjects; // return array of obj
  }

  set addThreatAlerts(obj: ThreatAlertObject) {
    this.threatAlertsList = obj;
  }

  // =============================

  getThreatAlerts(): ThreatAlertObject {
    return this.threatAlertsList;
  }

  setCurrentEntityId(id: string): void {
    this.entityId = id;
    this.currentEntityId.next(id);
  }

  getCurrentEntityId(): BehaviorSubject<string> {
    return this.currentEntityId;
  }

  getCurrentEntityIdString(): string {
    return this.entityId;
  }

  generateUrlWithBaseUrl(url: string): string[] {
    const splitUrl = url.split('/');
    const newUrlArray = this.boardBaseUrl.split('/').concat(splitUrl);
    return newUrlArray;
  }

  getQuarter(date): number {
    return Math.floor(new Date(date).getMonth() / 3) + 1;
  }

  generateBoardChartData(items: ProjectItem[]): BudgetChartData[] {
    // Create Chart Data
    // If data is empty it will return a singular chart data value stating 'NONE'

    const currentDate = new Date().getTime();
    let planned = 0;
    let completed = 0;
    let inProcess = 0;

    if (items && items.length) {
      items.forEach(project => {
        if (project.startDate > currentDate) {
          planned++;
        } else if (project.startDate < currentDate) {
          if (project.endDate < currentDate) {
            completed++;
          } else {
            inProcess++;
          }
        }
      });
      return [
        { text: 'Completed', value: completed },
        { text: 'Planned', value: planned },
        { text: 'In Process', value: inProcess },
      ];
    } else {
      return [{ text: 'None', value: 100 }];
    }
  }
  // calculate gaps, remediation and their sum up risk matrix values
  generateRiskMatrixs(assets): any[] {
    const matrixWeight = [
      [13, 14, 15, 16],
      [9, 10, 11, 12],
      [3, 6, 7, 8],
      [1, 2, 4, 5],
    ];
    if (assets && !!assets.length) {
      // Gaps Matrix
      const boardImpacts = Object.values(ImpactTextEnum).reverse();
      const boardProbabilities = Object.values(ProbabilityTextEnum);
      // return 2D array [[], [], [], []]
      const gapMatrix = boardImpacts.map((impact, indx) => {
        return boardProbabilities.map((prob, ind) => {
          // filter assets according to impact and probabilities
          return assets.filter(asset => {
            const found =
              asset.impact.toLowerCase() === impact.toLowerCase() &&
              asset.probability.toLowerCase() === prob.toLowerCase();
            if (found) {
              // add new property gapWeight inside required asset. this property will be available in matrix component
              // due to pass by reference
              asset.gapWeight = matrixWeight[indx][ind];
              return true;
            } else {
              return false;
            }
          }).length;
        });
      });

      // Remediation matrix
      const boardcostImpact = Object.values(CostRemediateImpactEnum).reverse(); // extreme, high, medium, low
      // 1 year has 52 weeks, so first three elements should be within 52 weeks as remediation graph shows
      // 12 weeks = 3 months, 24 weeks = 6 months, 52 weeks = 12 month, 52+ = 12 months +
      const boardTimeMonths = [12, 24, 52, 52];
      // get Gap Matrix, return 2D array [[], [], [], []]
      const remediationMatrix = boardcostImpact.map((cost, i) => {
        return boardTimeMonths.map((time, indx) => {
          switch (indx) {
            case 0:
              // 0-3 months or 12 weeks
              return assets.filter(asset => {
                const found =
                  asset.costToRemediateImpact.toLowerCase() === cost.toLowerCase() && asset.timeToRemediate <= time;
                if (found) {
                  // add new property gapWeight inside required asset. this property will be available in matrix component
                  // due to pass by reference
                  asset.remediationWeight = matrixWeight[i][indx];
                  return true;
                } else {
                  return false;
                }
              }).length;
              break;
            case 1:
              // 3-6 months or 24 weeks
              return assets.filter(asset => {
                const found =
                  asset.costToRemediateImpact.toLowerCase() === cost.toLowerCase() &&
                  asset.timeToRemediate >= boardTimeMonths[indx - 1] &&
                  asset.timeToRemediate <= time;
                if (found) {
                  // add new property gapWeight inside required asset. this property will be available in matrix component
                  // due to pass by reference
                  asset.remediationWeight = matrixWeight[i][indx];
                  return true;
                } else {
                  return false;
                }
              }).length;
              break;
            case 2:
              // 6-12 months or 52 weeks
              return assets.filter(asset => {
                const found =
                  asset.costToRemediateImpact.toLowerCase() === cost.toLowerCase() &&
                  asset.timeToRemediate >= boardTimeMonths[indx - 1] &&
                  asset.timeToRemediate <= time;
                if (found) {
                  // add new property gapWeight inside required asset. this property will be available in matrix component
                  // due to pass by reference
                  asset.remediationWeight = matrixWeight[i][indx];
                  return true;
                } else {
                  return false;
                }
              }).length;
              break;
            case 3:
              // 12+ months or 52+ weeks
              return assets.filter(asset => {
                const found =
                  asset.costToRemediateImpact.toLowerCase() === cost.toLowerCase() && asset.timeToRemediate > time;
                if (found) {
                  // add new property gapWeight inside required asset. this property will be available in matrix component
                  // due to pass by reference
                  asset.remediationWeight = matrixWeight[i][indx];
                  return true;
                } else {
                  return false;
                }
              }).length;
              break;
          }
        });
      });
      // Risk Matrix, use provided formula for gaps and remediation matrix to create 4d matrix
      // check weight of gap and remediation of each asset and use this
      // Math.round( (gapWeight+remediationWeight)/2 )

      // create 2d array with 0 values
      const matrix2D = Array.from(Array(4), () => [0, 0, 0, 0]);
      assets.forEach(asset => {
        const formulaResult = Math.round((asset.gapWeight + asset.remediationWeight) / 2);
        matrixWeight.forEach((mw, i) => {
          mw.forEach((value, ind) => {
            if (formulaResult === value) {
              // if formulaResult of asset found inside matrixWeight set new property riskMatrixWeight for that asset
              asset.riskMatrixWeight = formulaResult;
              matrix2D[i][ind] += 1;
            }
          });
        });
      });
      const riskSumUpMatrix = matrix2D;

      return [gapMatrix, remediationMatrix, riskSumUpMatrix];
    }

    return [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
    ];
  }

  async getFrameworksByEntityId(entity = null): Promise<any[]> {
    if (!entity) {
      entity = this.getCurrentEntityIdString()
        ? await this.entityService.getEntity(this.getCurrentEntityIdString(), false)
        : null;
    }
    if (entity) {
      const riskFrameworks = await this.entityService.getFrameworksByEntityId(
        entity.id,
        StandardType.RISK_FRAMEWORK,
        entity.entityType === EntityTypeEnum.ROOT_ENTITY ? true : false
      );
      const nistNodes = await this.fetchNodesWithScores(riskFrameworks);
      return nistNodes;
    }
    return null;
  }

  async fetchNodesWithScores(riskFrameworks) {
    try {
      return (
        await Promise.all(
          riskFrameworks.map(async framework => {
            const scores = await this.frameworkTreeService.getAssessmentTreeFromS3(framework.assessmentId, {
              key: framework.key,
            } as GetAssessmentStandardFrameworkQuery);
            const groupScores = await this.frameworkTreeService.getGroupAssessmentTreeFromS3(framework.assessmentId, {
              key: framework.key,
            } as GetAssessmentStandardFrameworkQuery);
            let scoresArray = [];
            if (scores && scores.children) {
              scoresArray = scores.children;
            }
            if (groupScores && groupScores.children) {
              scoresArray = scoresArray.concat(groupScores.children);
            }
            return scoresArray;
          })
        )
      ).flat();
    } catch (e) {
      console.log('Error - fetchNodesWithScores ::', e);
      return [];
    }
  }

  async setPostureDataFromFrameworks(entity = null): Promise<void> {
    const postureData: PostureChartModel = {
      identify: 0,
      protect: 0,
      detect: 0,
      respond: 0,
      recover: 0,
      totalScore: entity && entity.scores ? entity.scores.total : 0,
    };
    const nistNodes: any[] = await this.getFrameworksByEntityId(entity);
    // Will remove later
    // const frameworkNodesBaseScores1: FrameworkNode[] = await UtilsService.buildFrameworksList(
    //   nistNodes
    // );
    let frameworkGroupsBaseScores: FrameworkNode[] = [];
    let frameworkNodesBaseScores: FrameworkNode[] = [];
    // filter framework nodes and remove framework group type
    if (nistNodes) {
      // Will remove later
      // frameworkGroupsBaseScores = nistNodes.filter(framework => {
      //   return (Object.values(PostureFrameworkGroupEnum) as string[]).includes(
      //     framework.name.toLowerCase()
      //   );
      // });
      // frameworkNodesBaseScores = frameworkNodesBaseScores.filter(framework => {
      //   return Object.keys(postureData).includes(framework.name.toLowerCase());
      // });
      frameworkGroupsBaseScores = nistNodes.filter(framework => {
        return (
          framework.entityType === NodeTypeEnum.GROUP &&
          (Object.values(PostureFrameworkGroupEnum) as string[]).includes(framework.name.toLowerCase())
        );
      });
      frameworkNodesBaseScores = nistNodes.filter(framework => {
        return (
          framework.entityType === NodeTypeEnum.FRAMEWORK_NODE &&
          Object.values(PostureChartDataEnum).includes(framework.name.toLowerCase())
        );
      });
      // If groups are not found due to old data in which group type never exist then do this
      if ((frameworkGroupsBaseScores && !frameworkGroupsBaseScores.length) || !frameworkGroupsBaseScores) {
        frameworkGroupsBaseScores = nistNodes.filter(framework => {
          return (
            // check if type is framework Node & values are technology, intelligence etc
            framework.entityType === NodeTypeEnum.FRAMEWORK_NODE &&
            (Object.values(PostureFrameworkGroupEnum) as string[]).includes(framework.name.toLowerCase())
          );
        });
      }

      // If nodes are not found due to old data in which identity, protect aren't in top level but
      // nested inside technology nodes then do this
      if ((frameworkNodesBaseScores && !frameworkNodesBaseScores.length) || !frameworkNodesBaseScores) {
        // find identity, protect frameworks in array, it may contain multiple identity or
        // protect etc elements as well
        frameworkNodesBaseScores = frameworkGroupsBaseScores
          .filter(framework => {
            return framework && framework.children && !!framework.children.length;
          })
          .flatMap(filteredFramework => {
            return filteredFramework.children.filter(child => {
              return (
                child.entityType === NodeTypeEnum.FRAMEWORK_NODE &&
                Object.values(PostureChartDataEnum).includes(child.name.toLowerCase())
              );
            });
          });
      }

      // if frameworkGroup is [] then buildFramework return undefined so set it as empty array []
      frameworkGroupsBaseScores = (await UtilsService.buildFrameworksList(frameworkGroupsBaseScores)) || [];
      // if frameworks contain multiple same name frameworks then it will merge them and take average
      frameworkNodesBaseScores = (await UtilsService.buildFrameworksList(frameworkNodesBaseScores)) || [];

      frameworkNodesBaseScores = frameworkNodesBaseScores || [];
      frameworkGroupsBaseScores = frameworkGroupsBaseScores || [];
      frameworkNodesBaseScores.forEach(framework => {
        postureData[framework.name.toLowerCase()] = framework.scores.total;
      });
    }
    const content = this.stepsInformation[0].content as ReportDates;
    const quarter = content.selectedQuarter && content.selectedQuarter.label ? content.selectedQuarter.label : '';
    this.addPostureDetail = {
      // Fetch data for different charts in posture
      chartData: {
        nistCsfData: {
          quarter,
          spiderChartData: frameworkNodesBaseScores.map((fnode, indx) => {
            return { axis: fnode.name.toLowerCase(), value: fnode.scores.total, order: indx };
          }),
        },
        protectionLevelData: frameworkGroupsBaseScores.map(fGroup => {
          return {
            title: fGroup.name.toLowerCase(),
            data: {
              label: quarter,
              data: { target: fGroup.scores.target, value: fGroup.scores.total },
            },
          };
        }),
        totalScoreData: {
          title: quarter,
          data: [
            {
              label: 'MainScore',
              data: {
                target: entity && entity.scores ? entity.scores.target : 0,
                value: entity && entity.scores ? entity.scores.total : 0,
              },
            },
          ].concat(
            frameworkNodesBaseScores.map(fNode => {
              return {
                label: fNode.name.toLowerCase(),
                data: { target: fNode.scores.target, value: fNode.scores.total },
              };
            })
          ),
        },
      },
      // fetch data for average of frameworkNodes in animation of posture
      frameworkNodesScores: postureData,
    };
  }

  createDetailItemObject(value: number | string, total: number, isPassed: boolean, isIncreased: boolean): any {
    return {
      value,
      total,
      isPassed,
      isIncreased,
    };
  }

  populateAttackProactive(
    attackArray: DetailItem[],
    proactiveArray: DetailItem[],
    content,
    previousContent = null
  ): void {
    const attackTable = content.eventPID;

    // populating attack types
    attackArray.forEach(attack => {
      const name = attack.label.charAt(0).toLowerCase() + attack.label.slice(1);
      let previousIncreased = true;
      if (previousContent) {
        previousIncreased = previousContent.eventPID[name] <= attackTable[name];
      }
      attack.values.unshift(
        this.createDetailItemObject(attackTable[name] + '%', 0, !previousIncreased, previousIncreased)
      );
    });

    // populating proactive measures
    const proactive = content.proactiveMeasures;
    const prevPro = previousContent ? previousContent.proactiveMeasures : null;
    let prevDecreased = true;
    proactiveArray.forEach(measure => {
      let valueObject: DetailItemValue;
      switch (measure.label) {
        case 'Pentest':
          valueObject = this.createDetailItemObject(
            proactive.pentestFindingsTotal === proactive.pentestFindingsTarget ? 'Pass' : 'Fail',
            0,
            true,
            true
          );
          break;
        case 'Phishing Test':
          if (prevPro) {
            prevDecreased = this.comparePreviousProactive(proactive, prevPro, 'phishingTests');
          }
          valueObject = this.createDetailItemObject(
            proactive.phishingTestsTotal,
            proactive.phishingTestsTotalTarget,
            prevDecreased,
            prevDecreased
          );
          break;
        case 'Vulnerabilities Patched':
          if (prevPro) {
            prevDecreased = this.comparePreviousProactive(proactive, prevPro, 'vulnerabilitiesPatched');
          }
          valueObject = this.createDetailItemObject(
            proactive.vulnerabilitiesPatchedTotal,
            proactive.vulnerabilitiesPatchedTotalTarget,
            prevDecreased,
            prevDecreased
          );
          break;
      }
      measure.values.unshift(valueObject);
    });
  }

  comparePreviousProactive(currentObj, prevObj, objName: string): boolean {
    const current = (currentObj[objName + 'Total'] / currentObj[objName + 'TotalTarget']) * 100;
    const prev = (prevObj[objName + 'Total'] / prevObj[objName + 'TotalTarget']) * 100;
    return prev <= current;
  }

  async subEntitiesExist(): Promise<boolean> {
    const frameworks = await this.getFrameworksByEntityId();
    return frameworks && !!frameworks.length;
  }

  async populateThreatAlerts(entity): Promise<void> {
    try {
      const content = this.stepsInformation[0].content as ReportDates;
      // getting current report date to show above alerts
      const date = content.endDate;
      const alertDate =
        date.getDate() +
        ' ' +
        date.toLocaleString('default', { month: 'short' }).toUpperCase() +
        ',' +
        date.getFullYear();

      // getting alerts for the selected entity
      const alertList = entity.industry ? await this.customApi.ListAlerts(entity.industry, FileTypeEnum.ALERTS) : [];
      const alertMap = {};

      // sort alerts by priority
      const sortedAlerts = UtilsService.sortByProp(alertList, 'priority');

      // format all alerts
      sortedAlerts.forEach(alert => {
        alertMap[alert.type] = alertMap[alert.type] || [];
        alertMap[alert.type].push({
          content: alert.content,
        });
      });

      // for now - cut only 3 items of each alert type
      const alerts = {};
      const alertsObject = {
        vulnerabilities: [],
        threats: [],
        regulations: [],
      };
      Object.keys(alertMap).forEach(type => {
        alerts[type] = alertMap[type].slice(0, 3);
        // store the alert type with some dummy data
        alerts[type].forEach((alert, i) => {
          if (type === 'regulations') {
            // removing "Read More" tag from Regulations Alert
            alert.content = alert.content.replace(/readmore|read more/g, '');
          }
          const description = UtilsService.capitalizeFirstLetter(alerts[type][i].content);
          alertsObject[type].push(
            this.createAlertObject(alertDate, this.categoryCISA, description, this.titleString(description))
          );
        });
      });
      this.addThreatAlerts = alertsObject;
    } catch (e) {
      this.logger.error('Alerts - Error: ', e);
      this.addThreatAlerts = null;
    }
  }

  titleString(desc: string): string {
    const titles = desc.split(/[.(:]| to | are /);
    return titles[0];
  }

  createAlertObject(date: string, category: string, description: string, title: string): ThreatAlerts {
    return {
      date,
      category,
      description,
      title,
    } as ThreatAlerts;
  }

  /**
   * Calculating the numbers of rows needed to fill table height
   * @param  {Number} tableHeight The table height value in VH
   */
  calculateNumberOfRows(tableHeight: number): number {
    const totalH = document.documentElement.clientHeight;
    const tableH = totalH * tableHeight;
    return Math.floor((tableH - totalH * this.tableHeightHeader) / (totalH * this.tableHeightRow));
  }

  /**
   * Add initial empty rows if no data is loaded in the table
   * This will fill the table till it's full height.
   * @param  {any}    dataObject the object containing the rows empty data
   * @param  {Number} tableHeightVH The table height value in VH
   */
  generateEmptyRows(dataObject: any, tableHeightVH: number): any[] {
    // calculating the total rows needed to fill the table height
    const totalRows = this.calculateNumberOfRows(tableHeightVH);
    // creating and storing the number of total empty rows.
    const tableArray = JSON.parse(JSON.stringify(new Array(totalRows).fill(dataObject)));
    return tableArray;
  }

  /**
   * A Subscriber to alert that the download template button is pressed
   */
  downloadStepDataCSV(stepData: any): void {
    this.downloadStepData.next(stepData);
  }

  /**
   * A subscriber to pass on the loaded template data to the current step
   * @param  {any} template the object/array containing the uploaded data
   */
  loadStepTemplate(template: any): void {
    this.loadTemplate.next(template);
  }

  // Set Report by id to latest report. and load all its dependencies for overview
  async setReportAsLatest(reportId: string) {
    if (reportId) {
      // if entityReports is empty
      if (this.entityReports || !this.entityReports.length) {
        this.entityReports = await this.listReportsByEntityId(this.entityId);
      }
      // Extra check if await call execute and still no reports found
      if (this.entityReports && !!this.entityReports.length) {
        this.reportToShow = this.entityReports.find(report => report.id === reportId);
        // load all dependencies of current overview against latest report
        await this.loadCurrentOverviewData(true);
      }
    } else {
      await this.init();
    }
  }

  // delete reports from dynamoDB
  deleteReportsFromDb(reportIds: string[]): void {
    if (reportIds && !!reportIds.length) {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      reportIds.forEach(async id => {
        // it also delete report file from S3 through trigger lambda boardReportFromS3Trigger
        await this.customApi.DeleteReport({ id }, { entityId: { eq: this.entityId } });
      });
      // Removed all reports which are deleted from above method.
      this.entityReports = this.entityReports.filter(er => !reportIds.includes(er.id));
      this.allReportsLoaded.next(this.entityReports);
    }
  }

  // Generate PDF & PPT reports in S3 for Report as an event
  async generateReportEvent(info, reportType = 'pdf'): Promise<void> {
    const credentials = await Auth.currentCredentials();
    // add region and credentials for access permission to execute lambda
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: environment.region,
    });
    // passing session information from localStorage to lambda func
    const session = Object.keys(localStorage).reduce(
      (obj, item) => ({ ...obj, [item]: localStorage.getItem(item) }),
      {}
    );
    const urls = [
      `/board/${info.entityId}/overview`,
      `/board/${info.entityId}/risk-score`,
      `/board/${info.entityId}/compliance`,
      `/board/${info.entityId}/threat-level`,
      `/board/${info.entityId}/monitoring`,
      `/board/${info.entityId}/operational`,
    ];

    const payload = {
      type: 'new_board',
      body: {
        entityId: info.entityId,
        reportId: info.id,
        session,
        urls,
        quarter: info.quarter,
        domain: window.location.origin,
        type: reportType,
      },
    };

    // Information which will be send as parameters like event.json in lambda
    const params = {
      FunctionName: `generateReport-${environment.name}`,
      Payload: JSON.stringify({ ...payload }),
      InvocationType: 'Event', // InvocationType -> event means it will run as background Job like EventBridge
    };
    // Invoking lambda
    try {
      await lambda.invoke(params).promise();
      console.log(payload);
    } catch (e) {
      console.log(' Error : ', e);
    }
  }

  // Download new board design report
  async downloadNewBoardReport(reportId, type): Promise<string | null> {
    type = type?.toLowerCase();
    if (reportId && this.entityId && type) {
      const filePath = `${FileTypeEnum.BOARD_REPORTS}/${this.entityId}/${type}/${reportId}.${type}`;
      return await this.fileService.downloadFileFromS3(filePath);
    } else {
      return null;
    }
  }

  async downloadFailedNewBoardReport(selectedReport: any, reportType: string) {
    const reportName = selectedReport.reportName.replace('Report ', '');
    const entity = await this.entityService.getEntity(this.entityId);
    const quarter = `${entity.name}-${reportName}`;
    const info = {
      entityId: this.entityId,
      id: selectedReport.id,
      quarter,
      type: 'QUARTERLY',
    };

    await this.generateReportEvent(info, reportType);
  }
}
