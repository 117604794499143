<div class="users" *ngIf="isDataLoaded">
  <div class="user-list-header">
    <cygov-checkbox class="user-list-checkbox" [isChecked]="allUserSelected" cygovUserPermission
      (changed)="onSelectAll($event)">
    </cygov-checkbox>
    <cygov-user-list-header (onDeleted)="onDeleted($event)" (onUnselectAll)="onSelectAll(false)"
      [selectedUsers]="selectedUsers" [userList]="userList" (onSortChanged)="onSortChanged($event)"
      [isEditMode]="isEditMode">
    </cygov-user-list-header>
  </div>
  <!-- <div class="horizontal-separator"></div> -->
  <div class="users-wrapper" [ngStyle]="{'width': userListToShow.length > 9 ? '102%': '101.3%'}">
    <!-- <virtual-scroller #scroll [items]="userList" (vsEnd)="fetchMore($event)" [bufferAmount]="10"
      [scrollbarWidth]="10"> -->
    <div *ngIf="userList?.length > 0" [ngClass]="startIndex > 0 ? 'up-arrow' : 'up-arrow-disabled'"
      (click)="moveScroll(arrowsEnum.UP)" cygovSvgIcon [svgName]="'arrow-up-type1'">
    </div>
    <div *ngFor="let viewPortItem of userListToShow" class="td-users d-flex">
      <div class="user-item-margin"
        *ngIf="(showUserDetail && viewPortItem.id == selectedUser) || !showUserDetail">
        <div class="user-item-container"
          [ngClass]="{ 'selected': viewPortItem.isSelected ,'user-item-container-edit':isEditMode }"
          (click)="viewPortItem?.isLowerRank && toggleList(viewPortItem)">
          <div *ngIf="(currentUserId !== viewPortItem.user.id);then checkbox else spaces">
          </div>
          <ng-template #checkbox>
            <cygov-checkbox class="user-list-checkbox" [id]="'user_'+ viewPortItem.id"
              [isChecked]="viewPortItem.isSelected" cygovUserPermission
              [disable]="isEditMode && viewPortItem.isSelected"
              (changed)="viewPortItem?.isLowerRank && viewPortItem.isSelected = $event; updateSelected(viewPortItem)">
            </cygov-checkbox>
          </ng-template>
          <ng-template #spaces>
            <div [ngClass]="{'extra-space': !isViewOnly }"></div>
          </ng-template>
          <cygov-user-item (onUpdateItem)="onUpdateItem($event)" class="user-item"
            [item]="viewPortItem" [isEditable]="viewPortItem?.isLowerRank"
            [isVendorUser]="isVendorUser(viewPortItem?.user)" [rolesList]="rolesList"
            [childEntities]="childEntities" [vendorEntities]="vendorHashEntities"
            [rootEntity]="rootEntity" (userUpdated)="viewPortItem.isSelected = true;"
            [currentUser]="currentUser">
          </cygov-user-item>
        </div>
        <div class="container-fluid userDetailTabSet"
          *ngIf="(isLoading || showUserDetail) && viewPortItem.id === selectedUser">
        </div>
      </div>
    </div>
    <div *ngIf="userList.length === 0" class="td-users d-flex-c no-record">
      No Record Found
    </div>
    <div *ngIf="userList?.length > 0"
      [ngClass]="endIndex >= userList?.length ? 'down-arrow-disabled' : 'down-arrow'"
      (click)="moveScroll(arrowsEnum.DOWN)" cygovSvgIcon [svgName]="'arrow-down-type1'">
    </div>
    <div *cygovLoader="isLoading"></div>
    <!-- </virtual-scroller> -->
  </div>
</div>