import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { ToastrService } from 'ngx-toastr';
import { GetEntityQuery, EntityTypeEnum, ScoresInput, GetRoleQuery, APIService, User } from 'app/API.service';
import { EntityService } from 'app/shared/entity.service';
import { UsersSettingsService } from 'app/users-settings/users-settings.service';
import { UserPermissionEnum } from 'app/shared/enums/users-setting.enum';
import { FormBuilder } from '@angular/forms';
import { UtilsService } from 'app/shared/utils.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { UserService } from 'app/shared/user.service';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';

export interface TableBodyItem {
  value: string;
  class: string;
  name: string;
}

@Component({
  selector: 'cygov-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
})
export class UserItemComponent implements OnInit, OnChanges {
  @Input() item: any;
  @Input() isEditable: boolean = true;
  @Input() isVendorUser: boolean;
  @Input() vendorEntities: any[] = [];
  @Input() rolesList: GetRoleQuery[] = [];
  @Input() childEntities: GetEntityQuery[];
  @Input() rootEntity: GetEntityQuery;
  @Input() currentUser: User;
  @Output() userUpdated = new EventEmitter(); // emit when any user item is changed
  isEditMode: boolean;
  expanded = false;
  collectionHeight = 20;
  toShowAccess: boolean = false;
  isUpdatingUser = false;
  entityTypeEnum = EntityTypeEnum;

  showColumnTooltip: boolean = false;
  permission: UserPermissionEnum = UserPermissionEnum.EDIT;
  roleOptions: any[] = [];
  permissionOptions: string[] = [...Object.values(UserPermissionEnum)] || null;
  userForm: any; // Reactive form for each item
  selectedChildEntities: GetEntityQuery[] | string[] = [];
  currentUserRole: GetRoleQuery;
  isParticipant = false;
  tmpSelectedEntities: string[] | string = null;
  viewOnlyNotAllowed: boolean = false;
  tmpRoleList: GetRoleQuery[] = [];
  subEntityNames: any[] = [];
  isGlobalUserSetting: boolean;
  globalRootEntity: any;
  globalChildEntities: any[] = [];
  isBnB: boolean = UtilsService.isBnB;
  accessColName: string;
  isEntityAdmin: boolean;
  rootEntities: any[];
  devOrRegName: any;
  firstTime: boolean = true;
  lastUserActivity = null;
  isParticipantUser: boolean = false;
  constructor(
    private entityService: EntityService,
    private toastr: ToastrService,
    private usersSettingsService: UsersSettingsService,
    private formBuilderService: FormBuilder,
    private userService: UserService,
    private api: APIService
  ) {}

  ngOnChanges(changes): void {
    if (changes && changes.rolesList) {
      this.roleOptions = this.rolesList.map(role => role.name);
    }
  }

  async ngOnInit(): Promise<void> {
    try {
      this.isGlobalUserSetting = this.usersSettingsService.isGlobalUserSettings();
      this.currentUserRole = this.userService.getCurrentRole();
      this.isParticipantUser = this.item?.user?.role?.name?.toLowerCase() === AppLevelRoleEnum.PARTICIPANT;
      this.roleOptions = this.rolesList.length ? this.rolesList.map(role => role.name) : [];
      this.usersSettingsService.getIsEditMode().subscribe(res => {
        this.isEditMode = res;
        if (this.userForm) {
          this.isSubEntityLeader(this.item?.user?.role?.id);
          this.getAcessColumNameValue(this.item?.user?.role?.name);
        }
      });
      this.permission = this.item.user.isViewOnly ? UserPermissionEnum.VIEW_ONLY : UserPermissionEnum.EDIT;
      if (
        this.item?.role?.name?.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN ||
        this.item?.role?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
      ) {
        this.tmpSelectedEntities = this.item?.user?.entityIds?.length ? this.item.user.entityIds[0] : null;
      } else {
        this.tmpSelectedEntities = this.item?.user?.entityIds?.length ? this.item.user.entityIds : null;
      }
      this.tmpRoleList = [...this.rolesList];
      const removeVendorRole = this.rolesList.findIndex(role => role.name === AppLevelRoleEnum.VENDOR);
      if (removeVendorRole > -1) {
        this.tmpRoleList.splice(removeVendorRole, 1);
      }
      this.tmpRoleList = this.filterLowerRoles(this.tmpRoleList);
      if (this.isGlobalUserSetting) {
        this.tmpRoleList = this.removeLowerRoles();
        if (
          this.item?.role?.name?.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN ||
          this.item?.role?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
        ) {
          this.rootEntities = this.usersSettingsService.getGlobalRootEntities();
          this.isEntityAdmin = true;
        }
      }
      // filtering roles for edit mode on basis of ranking

      // it will be used later
      // this.selectedChildEntities = this.item.user.entityIds ? this.item.user.entityIds : null;
      this.initForm();
      // if name is changed, select checkbox
      this.userForm.valueChanges.subscribe(() => {
        this.userUpdated.emit();
      });

      if (this.isGlobalUserSetting) {
        let entityId;
        let entityType;
        const roleName = this.item?.user?.role?.name?.toLowerCase();
        if (
          roleName === AppLevelRoleEnum.ENTITY_LEADER ||
          roleName === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN ||
          roleName === AppLevelRoleEnum.SUBENTITY_LEADER ||
          roleName === AppLevelRoleEnum.PARTICIPANT
        ) {
          entityId = this.item.user.entityIds && this.item.user.entityIds.length ? this.item.user.entityIds[0] : null;
          entityType =
            roleName === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() ||
            roleName === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase()
              ? EntityTypeEnum.ROOT_ENTITY
              : EntityTypeEnum.CHILD_ENTITY;
        } else if (this.item.user.role.defaultOrEntityId !== 'default') {
          entityId =
            this.item.user.role.entityIds && this.item.user.role.entityIds.length
              ? this.item.user.role.entityIds[0]
              : null;
          entityType = this.item.user.role.isRootEntityAccess
            ? EntityTypeEnum.ROOT_ENTITY
            : EntityTypeEnum.CHILD_ENTITY;
        }
        if (entityId) {
          const globalEntity = await this.usersSettingsService.getRootAndChildEntities(entityId, entityType);
          if (globalEntity) {
            this.globalChildEntities = globalEntity.childEntities ? globalEntity.childEntities : [];
            this.childEntities = this.globalChildEntities;
            this.globalRootEntity = globalEntity.rootEntity ? globalEntity.rootEntity : null;
          }
        }
      }
      this.setChildEntities();

      this.isSubEntityLeader(this.userForm.value.role ? this.userForm.value.role.id : null);
      this.setViewOnlyPermission(this.userForm.value.role ? this.userForm.value.role.id : null);
      this.getAccessColumnValue();
      this.setLastLogin();
    } catch (e) {
      console.log(e);
    }
  }
  removeLowerRoles(): any {
    return this.rolesList.filter(
      role =>
        role.name !== AppLevelRoleEnum.VENDOR &&
        role.name !== AppLevelRoleEnum.SUBENTITY_LEADER &&
        role.name !== AppLevelRoleEnumBnB.PARTICIPANT &&
        (role.isRootEntityAccess || role.defaultOrEntityId === UsersSettingConstant.default)
    );
  }

  /**
   * initializing the form.
   */
  initForm(): void {
    this.userForm = this.formBuilderService.group({
      id: [this.item.user.id],
      name: [this.item.user.name ? this.item.user.name : null],
      role: [this.item.user.role ? this.item.user.role : null],
      phone: [this.item.user.phone ? this.item.user.phone : null],
      permission: [this.item.user.isViewOnly ? UserPermissionEnum.VIEW_ONLY : UserPermissionEnum.EDIT],
      entityIds: [this.item.user.entityIds ? this.item.user.entityIds : null],
      entityLayerIds: [this.item.user.entityLayerIds ? this.item.user.entityLayerIds : null],
    });
  }

  onupdate(): void {
    if (!this.checkValidation()) {
      return;
    }
    if (this.changesFound()) {
      this.usersSettingsService.addUserToUpdateList(this.userForm.value, this.item.isSelected);
    } else {
      this.usersSettingsService.removeUserToUpdateList(this.userForm.value);
    }
  }

  setChildEntities(): void {
    if (this.item && this.item.user && this.childEntities && this.childEntities.length) {
      this.selectedChildEntities = this.childEntities.filter(entity =>
        this.userForm?.value?.entityIds?.includes(entity.id) ? entity : null
      );
      this.selectedChildEntities = this.selectedChildEntities.map(entity => entity.name);
    }
  }

  calculateScore(user: GetEntityQuery): ScoresInput {
    return user.scores;
  }

  getImpactText(impact: number): string {
    return ThirdPartyService.getImpactText(impact);
  }

  // this function is not in use
  updateUser(): void {
    this.isUpdatingUser = true;
    this.toastr.info(`  Updating User: ${this.item.user.name}`);
    try {
      // const userToUpdate = JSON.parse(
      //   JSON.stringify(this.item.user, (k, v) =>
      //     k === '__typename' || k === 'logoUrl' || k === 'isSelected' || k === 'activeAssessment'
      //       ? undefined
      //       : v
      //   )
      // );
      // this.user = await this.entityService.updateEntity(userToUpdate);
      this.isUpdatingUser = false;
      this.toastr.success(`${this.item.user.name} Updated successfully`);
    } catch (e) {
      this.isUpdatingUser = false;
      this.toastr.error(`Failed to update ${this.item.user.name}`);
    }
  }

  toggleExpand(event: Event) {
    if (
      this.item &&
      (!this.item.entities || this.item.entities.length === 0) &&
      (!this.subEntityNames || !(this.subEntityNames.length > 1))
    ) {
      return;
    }
    UtilsService.stopBubbling(event);
    this.expanded = !this.expanded;
  }

  hidePhoneNumber(phone: string) {
    const reverse = s => s.split('').reverse().join('');
    const replaceRange = (s, start, end, substitute) => s.substring(0, start) + substitute + s.substring(end);
    const reversedPhone = reverse(phone);
    const reversedPhoneCensored = replaceRange(reversedPhone, 2, 8, 'XXXXXXX');
    return reverse(reversedPhoneCensored);
  }
  selectValueChanged(event: any): void {
    this.userForm.value.role = event;
  }

  checkIfEllipsis(element: any): void {
    this.showColumnTooltip = element.scrollWidth > element.clientWidth;
  }

  /**
   * Check either item value is changed or not
   * @returns
   */
  changesFound(): boolean {
    if (
      this.item &&
      this.userForm &&
      (this.item?.user?.name !== this.userForm.value?.name ||
        this.item?.user?.role !== this.userForm.value?.role ||
        this.item?.user?.permission !== this.userForm.value?.permission ||
        (JSON.stringify(this.item?.user?.entityIds) !== JSON.stringify(this.userForm?.value?.entityIds) &&
          this.toShowAccess))
    ) {
      this.setChildEntities();
      return true;
    }
    return false;
  }

  /**
   * check the Form validations
   * @returns
   */
  checkValidation(): boolean {
    if (!UtilsService.isDefined(this.userForm.value.name)) {
      this.toastr.error('Name can not be empty');
      return false;
    }
    return true;
  }

  /**
   * check the form subEntity Leader
   * @returns
   */
  isSubEntityLeader(roleId: string | null): void {
    const prevItemRole = this.item.user?.role?.name.toLowerCase();
    if (roleId) {
      this.toShowAccess = this.rolesList.some(
        role =>
          (role.name.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase() ||
            role.name.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase() ||
            role.name.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase()) &&
          role.id === roleId
      );
      this.isParticipant = this.rolesList.some(
        role => role.name.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase() && role.id === roleId
      );
      // show Access Dropdown only in case of GlobalUserSetting for entityLeader
      if (this.isGlobalUserSetting) {
        this.toShowAccess = this.rolesList.some(
          role => role.name.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() && role.id === roleId
        );
      }

      this.isParticipantUser = this.isParticipant;

      // If new role is subEntityLeader and previous is entityLeader then empty the EntityIds Arr.
      if (this.toShowAccess && prevItemRole === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()) {
        this.userForm.value.entityIds = [];
        // this.tmpSelectedEntities = [];
        if (this.isGlobalUserSetting && !this.firstTime) {
          this.isEntityAdmin = false;
        }
      } else if (!this.toShowAccess && prevItemRole === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()) {
        // If entityLeader and previous user is subEntityLeader then empty the EntityIds Arr.
        this.userForm.value.entityIds = null;
      } else if (
        (this.toShowAccess && prevItemRole === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()) ||
        prevItemRole === AppLevelRoleEnum.PARTICIPANT.toLowerCase()
      ) {
        this.userForm.value.entityIds = this.item?.user?.entityIds;
        if (this.isGlobalUserSetting && !this.firstTime) {
          this.isEntityAdmin = true;
        }
      }
    }
  }

  /**
   * checks if the new passed role is true or not with the current user item.
   * @param role
   */
  isPreviousRole(role: AppLevelRoleEnum): boolean {
    return this.item?.user?.role?.name.toLowerCase() === role.toLowerCase();
  }

  updatePermission(val, edit = false): void {
    this.permission = val;
    this.userForm.value.permission = val;
    if (edit) {
      this.userUpdated.emit();
    }
  }

  updateSubEntities(val): void {
    if (!val.selected && !val.value) {
      this.tmpSelectedEntities = val;
      this.userForm.value.entityIds = val;
      this.userUpdated.emit();
      this.setChildEntities();
      // The commented code was written to fix the issue in case of a subentity leader
      // selecting multiple subentities. In that case, it was not showing.
      // However, I tested that scenario as well, and it's working fine with the comment removed
      // if (!this.isEntityAdmin) {
      //   this.getSubEntityNames(val);
      // }
    }
  }

  /**
   * Function to update Entity Ids for prticipant on user selection
   * @param event - Currently Selected entities
   */
  updateSubEntitiesParticipant(event: any): void {
    const entities = event.map(entity => entity.id);
    this.tmpSelectedEntities = entities;
    this.userForm.value.entityIds = entities;
    this.userUpdated.emit();
    this.setChildEntities();
    this.getSubEntityNames(entities);
  }

  /**
   * if role of user is updated to ADMIN or PARTICIPANT then hide view only option from permission list
   * @param roleId is type of string
   */
  setViewOnlyPermission(roleId: string | null): void {
    console.clear();
    /* Commenting the code below because we need to display the original permissions granted to users during creation.
   However, this code is converting permissions for "admins and participants" from "view only" to "edit". */
    // if (roleId) {
    //   this.viewOnlyNotAllowed = this.rolesList.some(
    //     role =>
    //       (role.name.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase() ||
    //         role.name.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase()) &&
    //       role.id === roleId
    //   );
    //   if (this.viewOnlyNotAllowed) {
    //     this.updatePermission(UserPermissionEnum.EDIT);
    //   }
    // }
  }

  /**
   * chnage the accessColName value according to the role.
   * @param role user role name in string
   * @returns
   */
  getAcessColumNameValue(role: string = '') {
    const roleName = role;
    if (
      roleName.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.ENTERPRISE.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase()
    ) {
      this.accessColName = 'All';
    } else if (
      roleName.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase()
    ) {
      // This line is only written for Global setting demo later on we will change it.
      let entity;
      if (this.isGlobalUserSetting) {
        entity = this.globalRootEntity ? this.globalRootEntity : '-';
      } else {
        entity = this.rootEntity ? this.rootEntity : '-';
      }
      this.accessColName = entity && entity.name ? entity.name : '-';
    } else {
      this.accessColName = '';
    }
  }

  async getAccessColumnValue(): Promise<void> {
    const roleName =
      this.item && this.item.user && this.item.user.role && this.item.user.role.name ? this.item.user.role.name : '';
    if (
      roleName.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.ENTERPRISE ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.USER_ADMINISTRATOR
    ) {
      this.accessColName = 'All';
    } else if (
      roleName.toLowerCase() === AppLevelRoleEnumBnB.DIVISION_LEADER ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.REGION_LEADER
    ) {
      if (this.item?.user?.entityLayerIds && this.item?.user?.entityLayerIds.length) {
        this.accessColName = await this.entityService.getDivRegName(this.item?.user?.entityLayerIds[0]);
      }
    } else if (
      roleName.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() ||
      roleName.toLowerCase() === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN.toLowerCase() ||
      (this.item && this.item.user && this.item.user.role && this.item.user.role.isRootEntityAccess)
    ) {
      // This line is only written for Global setting demo later on we will change it.
      let entity;
      if (this.isGlobalUserSetting) {
        entity = this.globalRootEntity ? this.globalRootEntity : '-';
      } else {
        entity = this.rootEntity ? this.rootEntity : '-';
      }
      this.accessColName = entity && entity.name ? entity.name : '-';
    } else if (roleName.toLowerCase() === AppLevelRoleEnum.VENDOR.toLowerCase()) {
      // ( Note ) : No need to call API for each item to show the data.
      //            it increase the number of calls to get same entity which already got.
      if (this.item && this.item.user && this.item.user.entityIds && this.item.user.entityIds.length) {
        // According to new requirement a vendor user can has multiple vendor entities assigned
        this.subEntityNames = []; // Contains assigned vendor entities names
        this.item.user.entityIds.map(id => {
          if (this.vendorEntities[id]) {
            this.subEntityNames.push(this.vendorEntities[id]?.name);
          }
        });
      }
    } else if (roleName.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()) {
      this.getSubEntityNames(this.item.user.entityIds, true);
    } else if (roleName.toLowerCase() === AppLevelRoleEnum.PARTICIPANT.toLowerCase()) {
      // If BNB then get the subEntities list from the user entity Ids.
      if (UtilsService.isBnB || UtilsService.isBnBCyberSite) {
        const subEntityList = await this.userService.getEntitiesListbyEntityIds(this.item.user.entityIds);
        if (subEntityList && subEntityList.length) {
          this.subEntityNames = subEntityList.map(subEnt => subEnt.name);
        }
      } else {
        this.getSubEntityNames(this.item.user.entityIds, true);
      }
    } else if (
      this.item &&
      this.item.user &&
      this.item.user.role &&
      this.item.user.role.entityIds &&
      this.item.user.role.entityIds.length &&
      this.item.user.role.defaultOrEntityAccess !== 'default'
    ) {
      this.getSubEntityNames(this.item.user.role.entityIds);
    }
  }

  getSubEntityNames(givenEntities: any[] = [], showOnlyAssigned = false): void {
    givenEntities = givenEntities ? givenEntities : [];
    const entities = [];
    let childEntities = this.isGlobalUserSetting && this.isBnB ? this.globalChildEntities : this.childEntities;
    if (!childEntities) {
      return;
    }
    const grantAccess =
      this.currentUserRole &&
      this.currentUserRole?.name.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase();

    if (showOnlyAssigned && grantAccess) {
      childEntities = childEntities.filter(entity => this.currentUser.entityIds.includes(entity.id));
      this.childEntities = childEntities;
    }
    givenEntities.map(entityId => {
      const childEntity = childEntities.find(e => e.id === entityId);
      if (childEntity) {
        entities.push(childEntity.name);
      }
    });
    this.subEntityNames = entities;
  }

  compairRole(role: any, selectedRole: any) {
    return role && selectedRole && role.id === selectedRole.id;
  }

  async selectRole(event: any): Promise<void> {
    this.userForm.value.role = event;
    const roleName = this.rolesList.filter(role => role.id === this.userForm?.value?.role)[0]?.name;
    this.firstTime = false;
    // if role is changed, select checkbox
    this.isSubEntityLeader(this.userForm.value.role);
    this.userUpdated.emit();
    this.setViewOnlyPermission(this.userForm.value.role);
    this.getAcessColumNameValue(roleName);
    if (this.isBnB) {
      await this.bnbRoleChange(event);
    }
  }
  /**
   * filter those roles which are lower than the current user's role.
   * @param tmpRoleList is type of GetRoleQuery List.
   * @returns roles list
   */
  filterLowerRoles(tmpRoleList: GetRoleQuery[]): GetRoleQuery[] {
    return tmpRoleList.filter(
      role =>
        this.userService.hasPermissionHierarchy(AppLevelRoleEnum.MSSP) ||
        this.userService.hasPermissionHierarchy(AppLevelRoleEnumBnB.USER_ADMINISTRATOR) ||
        this.usersSettingsService.isCurrentRoleManageGivenRole(this.currentUserRole, role)
    );
  }

  async bnbRoleChange(roleId: string): Promise<void> {
    const selectedRole = this.rolesList.find(role => role.id === roleId);
    if (selectedRole) {
      // const selectedRole = this.rolesList[roleIndex];
      switch (selectedRole.name.toLowerCase()) {
        case AppLevelRoleEnumBnB.ENTERPRISE:
          this.userForm.value.entityIds = [];
          this.userForm.value.entityLayerIds = [];
          break;
        case AppLevelRoleEnumBnB.DIVISION_LEADER: {
          const divisionId = await this.entityService.getDivisionRegionId(true, this.rootEntity.id);
          this.userForm.value.entityIds = [];
          this.userForm.value.entityLayerIds = [divisionId];
          break;
        }
        case AppLevelRoleEnumBnB.REGION_LEADER: {
          const regionId = await this.entityService.getDivisionRegionId(true, this.rootEntity.id);
          this.userForm.value.entityIds = [];
          this.userForm.value.entityLayerIds = [regionId];
          break;
        }
        case AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN:
          // this.userForm.value.entityIds = this.tmpSelectedEntities;
          this.rootEntities = this.usersSettingsService.getGlobalRootEntities();
          this.userForm.value.entityLayerIds = [];
          if (this.isGlobalUserSetting) {
            this.isEntityAdmin = true;
          }
          break;
        default:
          this.userForm.value.entityLayerIds = [];
      }
    }
  }

  userRoleForEnterprise(item: any): string {
    if (item && item.user && item.user.role) {
      return this.isBnB ? item?.user?.role?.secondaryName : item?.user?.role?.name;
    } else {
      return 'Select Role';
    }
  }

  setLastLogin(): void {
    const timeStamps = [];
    if (this.item?.user?.lastLogin) {
      timeStamps.push(this.item.user.lastLogin);
    }
    if (this.item?.user?.modifiedAt) {
      timeStamps.push(this.item.user.modifiedAt);
    }
    if (this.item?.user?.updatedAt) {
      const dateTime = new Date(this.item.user.updatedAt);
      timeStamps.push(dateTime.getTime());
    }
    if (timeStamps.length) {
      timeStamps.sort();
      this.lastUserActivity = timeStamps[timeStamps.length - 1];
    }
  }
}
