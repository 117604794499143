import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { UtilsService } from 'app/shared/utils.service';
import {
  CreateUserInput,
  EntityTypeEnum,
  FileTypeEnum,
  GetDataStateQuery,
  GetEntityQuery,
  GetQuestionnaireCompletionQuery,
  GetRoleQuery,
  GetUserQuery,
  RoleEnum,
} from 'app/API.service';
import { EntityService, GetEntityQueryExtended } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddUserSettingsModalComponent } from '../shared/add-user-settings-modal/add-user-settings-modal.component';
import { QuestionnaireService } from '../questionnaire/questionnaire.service';
import { QuestionnaireProgressEnum } from '../shared/enums/questionnaire-progress.enum';
import { AuthService } from '../auth/auth.service';
import { TableHeader } from '../../models/table-header.model';
import { UsersSettingsService } from './users-settings.service';
import { DeleteUserModalComponent } from '../shared/user-actions/delete-user-modal/delete-user-modal.component';
import pluralize from 'pluralize';
import { DeleteModalComponent } from 'app/shared/delete-modal/delete-modal.component';
import { Observable, Subscription, from } from 'rxjs';
import { GetRoleQueryExtended, UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum, AppLevelRoleEnumBnB } from 'app/shared/enums/appLevelRoles.enum';
import { UsersSettingConstant, UsersSettingScreens } from './users-settings.constant';
import { ThirdPartyService } from 'app/third-party/third-party.service';
import { CommonEnum } from 'app/shared/enums/common.enum';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { KMS_STATUS, UPPERDECK_FACELIFT } from 'app/shared/enums/shared.enum';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';
import { OnUpdateEntityByRootId } from '../../graphql/custom-subscriptions/subscription';
import { API, Auth } from 'aws-amplify';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { ModalTemplateComponent } from 'app/shared/modal-template/modal-template.component';
import { FileService } from 'app/shared/file.service';
import { Lambda } from 'aws-sdk';
import { environment } from 'environments/environment';
import { NewFrameworkRequestModalComponent } from './new-framework-request-modal/new-framework-request-modal.component';
import { DomainFrameworksListingsComponent } from 'app/shared/domain-frameworks-listings/domain-frameworks-listings.component';

interface UserItem {
  user: GetUserQuery;
  access: string;
}

interface AIData {
  credits?: number | null;
  date?: number | null;
  isActive?: boolean | null;
  usedCredits?: number | null;
  userName?: string | null;
}

@Component({
  selector: 'cygov-users-settings',
  templateUrl: './users-settings.component.html',
  styleUrls: ['./users-settings.component.scss'],
})
export class UsersSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  // template references
  @ViewChild('entityKMSUserPermissionTemp') entityKMSUserPermissionTemp: TemplateRef<any>;
  @ViewChild('firstPartyTemp') firstPartyTemp: TemplateRef<any>;
  @ViewChild('thirdPartyTemp') thirdPartyTemp: TemplateRef<any>;
  upperTabsContentOptions: any[] = [];

  @ViewChild('usersTemp') usersTemp: TemplateRef<any>;
  @ViewChild('rolePermissionTemp') rolePermissionTemp: TemplateRef<any>;
  @ViewChild(DomainFrameworksListingsComponent) domainFrameworksListingsComponent: DomainFrameworksListingsComponent;
  lowerTabsContentOptions: any[] = [];

  tabIdentifer: Record<any, any> = {
    upperTab: 1,
    lowerTab: 1,
  };

  isCardView = true;
  loading = true;
  innerLoading = false;
  entityId: string;
  entityList: any = [];
  vendorsList: any = [];
  entityTypeEnum = EntityTypeEnum;
  entities: Map<string, GetEntityQuery> = new Map();
  users: any = {};
  currentUser: GetUserQuery = null;
  selectedUsers: (GetQuestionnaireCompletionQuery & { assignments?: Array<any> } & { entities?: Array<any> })[] = [];
  progressObject: any = {};
  subscriptions: Subscription[] = [];
  allRoles: GetRoleQuery[] = [];
  customRoles: GetRoleQuery[] = [];
  defaultRoles: GetRoleQuery[] = [];
  selectedRole: GetRoleQuery;
  permissions: string = '';
  currentUserRole: GetRoleQueryExtended;
  showColumnTooltip: boolean = false;

  sortOptions = { name: 'na', invitationDate: 'na', lastLogin: 'na', email: 'na' };
  previousSort = '';

  ENTITY_LEADER_ROLE = 'Entity Leader';
  SUBENTITY_LEADER_ROLE = 'Sub Entity Leader';

  status: string = 'all';
  role: string = 'all';
  statusFilter: string = 'all';
  roleFilter: any = { id: 'all', name: 'all' };
  statusFilterOptions = [
    'all',
    ...Object.keys(QuestionnaireProgressEnum).map(key => {
      this.progressObject[key] = QuestionnaireProgressEnum[key];
      return QuestionnaireProgressEnum[key];
    }),
  ];
  roleFilterOptions = null;
  filteredUserList = [];
  searchText = '';
  isEditMode: boolean = false;
  showRolePermission: boolean = false;
  isDefaultRole: boolean = false;

  isInviteSent: boolean = false;
  ngbModalDeleteUserOptions: NgbModalOptions = {
    size: 'sm',
    windowClass: 'delete-user-settings-modal delete-collection-framework-modal',
    backdropClass: 'delete-user-settings-custom-role-backdrop-modal delete-collection-framework-backdrop-modal',
    backdrop: 'static',
  };
  ngbModalRiskConfirmOptions: NgbModalOptions = {
    size: 'sm',
    windowClass: 'delete-user-settings-modal delete-collection-framework-modal risk-ai-confirmation-modal',
    backdropClass: 'delete-user-settings-custom-role-backdrop-modal delete-collection-framework-backdrop-modal',
    backdrop: 'static',
  };
  ngbModalOptions: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont user-settings-popup-modal',
    backdropClass: 'delete-user-settings-backdrop-modal',
    backdrop: 'static',
  };
  ngbModalOptionsConfirmation: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont confirmation-filter-popup-window credit-package-popup-window',
    backdropClass: 'modal-template-cont-backdrop risk-popup-backdrop-modal credit-package-modal-template-backdrop',
    backdrop: 'static',
  };
  ngbModalOptionsAttackSurface: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont confirmation-filter-popup-window attack-surface-modal-window',
    backdropClass: 'modal-template-cont-backdrop risk-popup-backdrop-modal attack-surface-modal-template-backdrop',
    backdrop: 'static',
  };
  ngbModalOptionsExternalScanRequest: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont confirmation-filter-popup-window external-scan-request-modal-window',
    backdropClass: 'modal-template-cont-backdrop risk-popup-backdrop-modal external-scan-request-modal-backdrop',
    backdrop: 'static',
  };
  ngbModalOptionsExternalScanRequestScanAcknowledgement: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass:
      'modal-template-cont confirmation-filter-popup-window external-scan-request-scan-acknowledgement-modal-window',
    backdropClass:
      'modal-template-cont-backdrop risk-popup-backdrop-modal external-scan-request-scan-acknowledgement-modal-backdrop',
    backdrop: 'static',
    centered: true,
  };
  ngbModalOptionsNewFrameworkRequest: NgbModalOptions = {
    size: 'sm',
    windowClass: 'modal-template-cont  new-framework-request-modal-window',
    backdropClass: 'modal-template-cont-backdrop new-framework-request-modal-backdrop',
    backdrop: 'static',
  };

  modalRef: any;
  roleSelected = false;
  tmpRole: any;
  childEntities: GetEntityQuery[] = [];
  vendorEntities: any[] = [];
  vendorHash: any[] = [];
  tmpSelectedUsers;
  entity: GetEntityQuery;
  UsersSettingConstant = UsersSettingConstant;
  UsersSettingScreens = UsersSettingScreens;
  currentTab: string = UsersSettingScreens.users;
  isUsersScreen: boolean = true;
  isBnb: boolean = UtilsService.isBnB;
  isBeecher: boolean = UtilsService.isBnBCyberSite;
  isGlobalUserSetting: boolean = false;
  entitySelectionMode: boolean = true;
  rootEntities: GetEntityQueryExtended[] = [];
  activeTabId: number = 1;
  isCurrentUserAdmin: boolean = false;
  commonEnum = CommonEnum;
  kmsToggle: boolean = false;
  rotationInProgress: boolean = false;
  activationInProgress: boolean = false;
  isManagerialRole: boolean = false;
  entityIdInUrl: boolean = false;
  kmsEnum = KMS_STATUS;
  user: GetUserQuery;
  timers = [];
  singleEntityLenghtCheck = false;
  isMidMarket: boolean = UtilsService.isMidMarket;
  nextOffset: number = 10;
  assessments: any = [];
  allUsers: any = [];
  assignedUsers: any = [];
  closeModalInstance;
  aiData: AIData;
  isAITabAccess: boolean;
  isAIUpdateInProgress: boolean = false;
  activeScan: boolean = false;
  domainValue: string = '';
  updatedDomainValue: string = '';
  intervalId: any;
  entityDomainValue: string = '';
  isEdit: boolean = false;
  isLogoUploaded: boolean = true;
  isAttackSurfaceTabAccess: boolean = false;
  rootEntityId: string;
  riskFrameworks: any[] = [];
  standardList: any[] = [];
  domainSetting = null;
  logoUrl: string = null;
  isNotFromRotation: boolean = false;

  constructor(
    private questionnaireService: QuestionnaireService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private entityService: EntityService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private logger: NGXLogger,
    private userSettingService: UsersSettingsService,
    private userService: UserService,
    private thirdPartyService: ThirdPartyService,
    private clientLambdaService: ClientLambdaService,
    private customAPI: CUSTOMAPIService,
    private fileService: FileService,
    private domainFrameworkService: DomainFrameworkService
  ) {}

  async ngOnInit(): Promise<any> {
    try {
      if (!this.isBnb && !this.isBeecher && !this.isMidMarket) {
        if (!this.entityId) {
          // if entityId exist in params, it means not global user setting, so dont need to select entity
          this.entitySelectionMode = !UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
        }

        this.domainSetting = this.domainFrameworkService.getSettingFromDomain;
        if (!this.domainSetting) {
          await this.domainFrameworkService.getDomainSetting();
          this.domainSetting = this.domainFrameworkService.getSettingFromDomain;
        }
        // this.riskFrameworks = settings.RISK_FRAMEWORKS;
        // this.standardList = settings.COMPLIANCE_FRAMEWORKS;

        this.isGlobalUserSetting = this.userSettingService.isGlobalUserSettings();
        if (this.entitySelectionMode && !this.entity) {
          this.rootEntities = await this.getExtendEntityList();
          if (this.rootEntities.length === 1) {
            this.singleEntityLenghtCheck = true;
            await this.selectEntityForSettings(this.rootEntities[0]);
          } else {
            this.singleEntityLenghtCheck = false;
          }
          this.loading = false;
          // don't process further because need to show entities for selection
          return;
        } else if (!this.entitySelectionMode) {
          this.isGlobalUserSetting = false;
        }
      } else {
        this.isGlobalUserSetting = this.userSettingService.isGlobalUserSettings();
      }
      this.currentUser = await this.userService.getCurrentUser();
      this.currentUserRole = this.userService.getCurrentRole();
      this.subscriptions.push(
        this.userSettingService.currEntRoles.subscribe(roles => {
          if (roles && !!roles.length && !this.isGlobalUserSetting) {
            this.setPrivileges(roles);
          } else if (roles && !!roles.length) {
            this.allRoles = this.userSettingService.currEntRolesList;
            this.customRoles = this.allRoles.filter(role => UtilsService.isDefined(role.entityId));
          }
        })
      );

      if (this.isGlobalUserSetting && (this.isBnb || this.isBeecher || this.isMidMarket)) {
        // Get All Root Entities (Domain) and Cache them in Users Setting Service
        const rootEntities = await this.entityService.listEntitysByEntityType(EntityTypeEnum.ROOT_ENTITY);
        this.userSettingService.setGlobalEntities(rootEntities);
        const result = await this.userSettingService.getGlobalRoles(rootEntities);
        this.allRoles = [];
        result.map(roleList => {
          this.allRoles = this.allRoles.concat(roleList);
        });
        if (this.currentUserRole.name !== AppLevelRoleEnum.ADMIN) {
          this.allRoles = this.allRoles.filter(role => {
            return role.name !== AppLevelRoleEnum.ADMIN;
          });
        }

        // removing the vendor role.
        const vendorRoleIndex = this.allRoles.findIndex(role => role?.name === AppLevelRoleEnum.VENDOR);
        if (vendorRoleIndex > -1) {
          this.allRoles.splice(vendorRoleIndex, 1);
        }
        this.userSettingService.setRoles(this.allRoles);
        this.roleFilter = { id: 'all', name: 'all' };
      } else {
        // this part will work only when we need to access User-Settings from First Party
        this.subscriptions.push(
          this.userSettingService.currEntRoles.subscribe(roles => {
            if (roles && roles.length) {
              this.setPrivileges(roles);
            }
          })
        );
        if (!this.entityId) {
          this.entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
          if (!this.authService.isNotFromHigherRoles() && this.entityId) {
            this.entity = await this.entityService.getEntity(this.entityId);
          }
        }

        // const parentEntity = await this.entityService.getEntity(this.entity.id, true);
        if (!this.authService.isNotFromHigherRoles()) {
          const frameworkSettings = this.entity?.frameworkSettings && JSON.parse(this.entity?.frameworkSettings);
          const dominFrameworkSettings = this.domainSetting?.settings ? JSON.parse(this.domainSetting?.settings) : {};
          this.riskFrameworks = this.checkIfNewFrameworkAdded(
            dominFrameworkSettings?.RISK_FRAMEWORKS,
            frameworkSettings?.RISK_FRAMEWORKS || dominFrameworkSettings.RISK_FRAMEWORKS
          );
          this.standardList = this.checkIfNewFrameworkAdded(
            dominFrameworkSettings?.COMPLIANCE_FRAMEWORKS,
            frameworkSettings?.COMPLIANCE_FRAMEWORKS || dominFrameworkSettings.COMPLIANCE_FRAMEWORKS
          );
        }

        await this.getChildEntities();
        this.userSettingService.setCurrEntity(this.entityId);
        const entityCustomRoles = await this.userService.getRolesByDefaultOrEntityId(this.entityId, false);
        const defaultRoles = await this.userService.getRolesByDefaultOrEntityId(UsersSettingConstant.default, true);
        await this.setPrivileges();
        this.entities = await this.getEntitiesMap();
        this.userSettingService.setRolesInCurrentEntity([...entityCustomRoles, ...defaultRoles]);
      }

      this.users = {};
      this.statusFilterOptions.forEach(element => (this.users[element] = []));
      await this.fetchAllUsers();
      // if BNB then we need to update the default roles with their parent role permissions.
      if (this.isBnb) {
        this.defaultRoles = await this.userService.updateDefaultRolesPermissions(this.defaultRoles);
      }
      // Filtering users before showing on frontend so that no duplicate exist
      this.filterUniqueEmailUsers();
      this.filteredUserList = [...this.users[this.status]];
      this.getVendorUsersList();
      // get Entity Roles and set these roles in User setting Service so that all components
      // of user setting module can use it.

      if (this.userSettingService.getIsEditMode()) {
        this.userSettingService.setIsEditMode(false);
      }
      this.isManagerialRole = this.userService.isManagerialRole();
      if (this.isManagerialRole) {
        await this.setLatestEntityStatus();
        this.isAITabAccess = true;
      }
      this.entityIdInUrl = !!UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      this.loading = false;
      if (UtilsService.isDefined(this.entityId)) {
        const entityId = this.entityId;
        const currentSession = await Auth.currentSession();
        this.subscriptions.push(
          (
            API.graphql({
              query: OnUpdateEntityByRootId,
              variables: {
                id: entityId,
              },
              authMode: 'AWS_LAMBDA',
              authToken: currentSession.getAccessToken().getJwtToken(),
            }) as unknown as Observable<any>
          ).subscribe({
            next: res => {
              const updatedEntity = res?.value?.data?.onUpdateEntityByRootId;
              if (updatedEntity) {
                this.onEncryptionResponse(updatedEntity);
              }
            },
            error: err => {
              console.log('OnUpdateEntityByRootId subscriptions: ', err);
              // if appsync closes connection try getting the latest entity state
              if (UtilsService.isDefined(this.entityId)) {
                this.timers.push(
                  setInterval(() => {
                    void (async () => {
                      const latestEntity = await this.entityService.getEntity(this.entityId, false);
                      this.onEncryptionResponse(latestEntity);
                    })();
                  }, 10000)
                );
              }
            },
          })
        );
      }
      this.activationInProgress = this.entity?.KMS?.status === KMS_STATUS.IN_PROGRESS.toUpperCase();
      this.rotationInProgress = this.entity?.KMS?.rotations?.status === KMS_STATUS.IN_PROGRESS.toUpperCase();
      const roleMapper = this.userService.roleIDToNameMapper;
      if (roleMapper[this.currentUser.roleId] === AppLevelRoleEnum.ADMIN.toLowerCase()) {
        this.isCurrentUserAdmin = true;
      }

      if (
        this.assessments?.length > 10 &&
        !(this.isGlobalUserSetting && (this.isBnb || this.isBeecher || this.isMidMarket))
      ) {
        this.subscriptions.push(from(this.backgroundJobAsync()).subscribe(() => {}));
      }
      if (this.entity) {
        this.activeScan = this.entity.domainScanned ? this.entity.domainScanned : false;
        const domainArr = this.entity.domainScanTimes ? JSON.parse(this.entity.domainScanTimes) : [];
        this.entityDomainValue = domainArr?.length ? domainArr[0].name : '';
        this.domainValue = this.entityDomainValue;
        this.isEdit = this.activeScan && this.entityDomainValue ? true : false;
        this.logoUrl = (await this.entityService.setLogoUrl(this.entity)).logoUrl;
      }

      if (window.history?.state?.isFromRiskAI) {
        this.tabIdentifer = { upperTab: 2, lowerTab: 1 };
      }
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('UsersSettingsComponent - Error: ', e);
    }
  }

  checkIfNewFrameworkAdded(domainFrameworks = [], entityFrameworks = []) {
    domainFrameworks.forEach(item => {
      // Check if the item from array1 is not present in array2
      const isInArray2 = entityFrameworks.some(obj => obj.name === item.name);
      // Add the object with status true to array2 if it's not already present
      if (!isInArray2 && item.status === true) {
        entityFrameworks.push({ ...item, status: false });
      }
    });

    entityFrameworks = entityFrameworks.filter(item =>
      domainFrameworks.some(obj => obj.name === item.name && obj.status === true)
    );

    return entityFrameworks;
  }

  async updateFrameworkSetting() {
    const formatedObject = {
      id: this.entity.id,
      frameworkSettings: JSON.stringify(this.domainFrameworksListingsComponent.formatFrameworkArrays()),
    };
    this.isNotFromRotation = true;
    await this.entityService.updateEntity(formatedObject);

    this.toastr.success('Framework Settings updated successfully');
  }

  checkPermissionForExternalDomainScan() {
    if (
      this.currentUserRole.name.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase() ||
      this.currentUserRole.name.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase()
    ) {
      return true;
    }
    return false;
  }

  async imgHandler(eventFile: Event): Promise<void> {
    try {
      const value = await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.LOGO);
      if (!value) {
        return;
      }
      const s3Input = await this.fileService.convertToS3Input(eventFile, this.entity.id);
      this.logoUrl = await this.fileService.getUploadedImg(eventFile);
      const updatedLogo = await this.fileService.uploadToS3(s3Input);
      await this.customAPI.UpdateEntity({
        id: this.entity.id,
        logo: updatedLogo,
      });
      this.entity.logo = updatedLogo;
    } catch (error) {
      this.logger.error('Image Handler  Error =', error);
      this.toastr.error(error);
      eventFile = null;
    }
  }

  /**
   * Function to filter Unique users only as there is an issue of duplicate SSO users
   */
  filterUniqueEmailUsers() {
    Object.keys(this.users).forEach(catogory => {
      this.users[catogory] = this.users[catogory].filter((userObj, index, self) => {
        return index === self.findIndex(u => u.user?.email === userObj.user?.email);
      });
    });
  }

  ngAfterViewInit(): void {
    // Initialiation Upper tabs data
    this.upperTabsContentOptions = [
      { label: this.commonEnum.GENERAL, content: this.entityKMSUserPermissionTemp },
      {
        label: this.commonEnum.FIRSTPARTY,
        content: this.firstPartyTemp,
        disabled: this.authService.isNotFromHigherRoles(),
      },
      { label: this.commonEnum.THIRDPARTY, content: this.thirdPartyTemp, disabled: true },
    ];

    // Initialization Lower Tabs Data
    this.lowerTabsContentOptions = [
      { label: this.statusFilter + ' Users', content: this.usersTemp, relativePos: true },
      { label: 'Role Permissions', content: this.rolePermissionTemp },
    ];
  }

  changeTabActiveId(data): void {
    const splitData = data.split('#');
    if (splitData[1] === '1') {
      this.isNotFromRotation = false;
    }
    this.tabIdentifer[splitData[0]] = splitData[1];

    const currentContentOptionIndex = +splitData[1] - 1;
    // check if tab is All Users then enable relative position for container for "Add New User" btn styling
    this[`${splitData[0]}sContentOptions`][0].relativePos = UtilsService.isDefined(
      this[`${splitData[0]}sContentOptions`][currentContentOptionIndex].relativePos
    );
  }

  /**
   * updated the vendor entity with its user.
   */
  getVendorUsersList(): void {
    if (this.vendorEntities && this.vendorEntities.length && this.filteredUserList) {
      this.vendorEntities.forEach(entity => {
        entity.userCounter = 0;
        this.filteredUserList.forEach(filteredUser => {
          if (filteredUser.user.entityIds && filteredUser.user.entityIds[0] === entity.id) {
            entity.userCounter += 1;
          }
        });
      });
      if (this.vendorEntities) {
        this.vendorEntities.map(vendor => (this.vendorHash[vendor.id] = vendor));
      }
    }
  }

  /**
   * gets the list of child entities including the entities as well.
   */
  async getChildEntities(): Promise<void> {
    const entities = await this.entityService.listEntitysByParentId(this.entityId);
    this.childEntities = entities.filter(entity => entity?.entityType === 'CHILD_ENTITY');
    this.vendorEntities = entities.filter(entity => entity?.entityType === 'VENDOR');
  }

  /**
   * only Admin , Mssp and Entity Leader (default role) can access the role section
   * @returns boolean value
   */
  isCurrentUserAccessRoleSection(): boolean {
    return (
      this.currentUserRole &&
      this.currentUserRole.name &&
      (this.currentUserRole.name.toLowerCase() === AppLevelRoleEnum.ADMIN.toLowerCase() ||
        this.currentUserRole.name.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase() ||
        this.currentUserRole.name.toLowerCase() === AppLevelRoleEnumBnB.USER_ADMINISTRATOR.toLowerCase() ||
        this.currentUserRole.name.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase())
    );
  }

  async setPrivileges(roles?) {
    const rolePrivileges = await this.userSettingService.filterRolesByUser(roles);
    if (rolePrivileges) {
      this.allRoles = [...rolePrivileges?.privileges];
      this.entityService.roles = this.allRoles;
      this.userService.roles = this.allRoles;
      this.roleFilterOptions = [{ id: 'all', name: 'all' }, ...this.allRoles];
      this.roleFilter = { id: 'all', name: 'all' };
      this.customRoles = rolePrivileges.privileges.filter(role => UtilsService.isDefined(role.entityId));
      this.entityService.customRoles = this.customRoles;
      this.userService.customRoles = this.customRoles;
      this.selectedRole = null;
      this.roleSelected = false;
      this.defaultRoles = this.userService.defaultRoles;
      // removing the vendor role.
      const vendorRoleIndex = this.defaultRoles.findIndex(role => role?.name === AppLevelRoleEnum.VENDOR);
      if (vendorRoleIndex > -1) {
        this.defaultRoles.splice(vendorRoleIndex, 1);
      }
    }
  }
  checkIfEllipsis(element: any): void {
    this.showColumnTooltip = element.scrollWidth > element.clientWidth;
  }
  selectRole(event, index, roleType: string = UsersSettingConstant.custom): void {
    this.roleSelected = true;
    if (roleType.toLowerCase() === UsersSettingConstant.default) {
      this.selectedRole = this.defaultRoles[index];
      this.isDefaultRole = true;
    } else {
      this.selectedRole = this.customRoles[index];
      this.isDefaultRole = false;
    }
    this.showRolePermission = true;
    // this.permissions = this.customRoles[index].screenPermissions;
  }

  async deleteCustomRole(role: any): Promise<void> {
    // check either this
    const roleDeleteable = await this.userSettingService.isRoleDeleteAble(role.id);
    if (!roleDeleteable) {
      this.toastr.error('You Can not delete ' + role.name + ' some users are there against this role');
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent, this.ngbModalDeleteUserOptions);

    modalRef.componentInstance.titleText = '';
    modalRef.componentInstance.show2ndConfirmText = 'All your data will be lost';
    modalRef.componentInstance.removeLabelText = 'Custom Role';
    modalRef.componentInstance.deleteEntityModal = true;
    modalRef.componentInstance.svgName = 'trash-icon-3';

    modalRef.componentInstance.deleteFramework.subscribe((deleteConfirm: boolean) => {
      if (deleteConfirm) {
        this.userSettingService.deleteRoles(role.id);
        let ind = this.customRoles.findIndex(customRole => customRole.id === role.id);
        let res = ind > -1 ? this.customRoles.splice(ind, 1) : null;
        ind = this.allRoles.findIndex(r => r.id === role.id);
        res = ind > -1 ? this.allRoles.splice(ind, 1) : null;
        ind = this.roleFilterOptions.findIndex(r => r?.name === role?.name);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        res = ind > -1 ? this.roleFilterOptions.splice(ind, 1) : null;
        this.showRolePermission = false;
      }
    });
  }

  showScrenPermission(): void {
    this.showRolePermission = true;
  }

  async getEntitiesMap(): Promise<Map<string, GetEntityQuery>> {
    const entitiesMap: Map<string, GetEntityQuery> = new Map();
    const entityList = await this.entityService.listChildEntitysByParentId(this.entityId);
    this.vendorsList = await this.thirdPartyService.getExtendVendorsListByParentId(this.entityId);
    this.entityList = entityList;
    entityList.forEach(entity => (entitiesMap[entity.id] = entity));
    return entitiesMap;
  }

  async getAllUsers(): Promise<UserItem[]> {
    const entityNVendorIds: string[] = Object.keys(this.entities);
    entityNVendorIds.push(...this.vendorsList.map(v => v.id?.toString()));
    const data = await this.userService.getAllUsersForEntityWithRoleId(
      this.entityId,
      entityNVendorIds,
      this.currentUserRole,
      true
    );
    const { usersList, roles } = data;
    this.allRoles = roles;

    this.roleFilterOptions = ['all', ...this.allRoles];

    return usersList.map(item => this.addAccessLevel(item));
  }

  async getGlobalAllUsers(): Promise<UserItem[]> {
    const usersList = await this.userService.getUsersByDomain();
    this.allRoles = this.userService.getSortedRolesForBNB(this.allRoles);
    this.customRoles = this.allRoles.filter(role => role.defaultOrEntityId !== UsersSettingConstant.default);
    this.defaultRoles = this.allRoles.filter(role => role.defaultOrEntityId === UsersSettingConstant.default);
    // this.roleFilterOptions = ['all', ...this.allRoles];
    // this.roleFilter = 'all';
    this.roleFilterOptions = [{ id: 'all', name: 'all' }, ...this.allRoles];
    this.roleFilter = { id: 'all', name: 'all' };
    if (usersList && !!usersList.length) {
      const finalList = usersList
        .map(item => {
          if (item) {
            return this.addAccessLevel(item);
          }
        })
        .filter(user => {
          const index = this.allRoles.findIndex(rol => rol?.id === user?.user?.roleId);
          if (index !== -1) {
            return user;
          }
        });
      return finalList;
    } else {
      return [];
    }
  }

  /**
   *
   * @returns the list of custom as well as built in roles based on custom users.
   */
  updateCustomRolesList(isRootAccess: boolean, customRole: GetRoleQuery): void {
    // If true
    // Gets the custom roles not having the root access.
    if (isRootAccess) {
      this.customRoles = this.customRoles.filter(role => !role.isRootEntityAccess);
      this.customRoles.push(customRole);
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.PARTICIPANT));
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.SUBENTITY_LEADER));
    } else {
      this.customRoles = [];
      this.customRoles.push(customRole);
      this.customRoles.push(this.defaultRoles.find(role => role.name === AppLevelRoleEnum.PARTICIPANT));
    }
    this.allRoles = this.customRoles;
    this.roleFilterOptions = [{ id: 'all', name: 'all' }, ...this.allRoles];
  }

  async getAssignedUsers(): Promise<any> {
    if (
      this.currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase() ||
      this.currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
    ) {
      this.assessments = (await this.customAPI.AssessmentsByChildId(this.entityId, null, null, 10000, null)).items;
    } else {
      this.assessments = (await this.customAPI.AssessmentsByRootId(this.entityId, null, null, 10000, null)).items;
    }

    return await this.updateUserAssignments(this.assessments.slice(0, this.nextOffset));
  }

  /**
   */
  async getAssignedUsersForGlobal(users: any): Promise<any> {
    const usersMap = {};
    let completions = [];
    try {
      completions = await Promise.all(
        users.map(async user =>
          (
            await this.customAPI.CompletionByUser(user?.user?.id)
          ).items.map(item => ({
            ...item,
          }))
        )
      );
    } catch (e) {
      console.log('Cannot Fetch the assigned users ===> ', e);
      Promise.reject();
    }

    const completionData = completions.flat(1).filter((d: any) => UtilsService.isDefined(d.user));
    return {
      data: Object.values(
        completionData.reduce((obj, { user, ...completion }: any) => {
          if (user) {
            usersMap[user.id] = true;
            return {
              ...obj,
              [user.id]: {
                user: this.getUser(user),
                access: [AppLevelRoleEnum.ADMIN, AppLevelRoleEnum.MSSP].some(item => item === user.role)
                  ? 'All'
                  : this.entities[completion.subEntityId]?.name,
                entities: [
                  ...(obj && obj[user.id] && obj[user.id].entities ? obj[user.id].entities : []),
                  {
                    ...completion,
                    assignments: JSON.parse(completion.assignmentMap),
                    access: [AppLevelRoleEnum.ADMIN, AppLevelRoleEnum.MSSP].some(item => item === user.role)
                      ? 'All'
                      : this.entities[completion.subEntityId]?.name,
                  },
                ],
              },
            };
          }
        }, {})
      ),
      map: usersMap,
    };
  }

  async fetchAllUsers(): Promise<void> {
    try {
      this.allUsers =
        this.isGlobalUserSetting && (this.isBnb || this.isBeecher || this.isMidMarket) && !this.entityId
          ? await this.getGlobalAllUsers()
          : await this.getAllUsers();
      this.assignedUsers =
        this.isGlobalUserSetting && (this.isBnb || this.isBeecher || this.isMidMarket)
          ? await this.getAssignedUsersForGlobal(this.allUsers)
          : await this.getAssignedUsers();
      // Assigned users are updated and admins are filtered out separately in the upper function.
      // still added ..can be used later.
      // if (
      //   this.currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.MSSP.toLowerCase() ||
      //   this.currentUserRole?.childRole?.name?.toLowerCase() === AppLevelRoleEnumBnB.ENTERPRISE.toLowerCase() ||
      //   this.currentUserRole?.childRole?.name?.toLowerCase() === AppLevelRoleEnumBnB.DIVISION_LEADER.toLowerCase() ||
      //   this.currentUserRole?.childRole?.name?.toLowerCase() === AppLevelRoleEnumBnB.REGION_LEADER.toLowerCase()
      // ) {
      //   const filteredAllUsers = allUsers.filter(item => {
      //     const tmpRole = this.allRoles.find(role => item?.user && role?.id === item?.user?.roleId);
      //     return tmpRole?.name?.toLowerCase() !== AppLevelRoleEnum.ADMIN.toLowerCase();
      //   });
      //   const filteredAssignedUsers = assignedUsers.data.filter(item => {
      //     const tmpRole = this.allRoles.find(role => item?.user && role?.id === item?.user?.roleId);
      //     return tmpRole?.name?.toLowerCase() !== AppLevelRoleEnum.ADMIN.toLowerCase();
      //   });
      //   // if (!this.isGlobalUserSetting) {

      //   [...filteredAllUsers.filter(item => !assignedUsers.map[item.user.id]), ...filteredAssignedUsers].map(
      //     this.mapUserProgress
      //   );

      //   // }
      //   // else {
      //   //     [...allUsers].map(this.mapUserProgress);
      //   //   }
      // } else {

      // if logged in user has a custom role
      // then show only those users having sub-entities those are assigned to that particular custom role
      // if isCustomRole = {}, it will still fall into if section. Thats why need to check if it is not empty or not
      this.updateUsers();
      // }
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('UsersSettingsComponent - Error: ', e);
    }
  }

  /**
   * Not In use anymore.
   * This function populates the filterOptions.
   */
  populateRoleFilter() {
    const index = this.roleFilterOptions.indexOf(RoleEnum.LEADER);
    const roleNames = [...this.roleFilterOptions];
    if (index > -1) {
      roleNames.splice(index, 1, ...[this.ENTITY_LEADER_ROLE, this.SUBENTITY_LEADER_ROLE]);
    }
    if (this.currentUserRole.name.toLowerCase() === RoleEnum.LEADER.toLowerCase()) {
      const leader = this.getUser(this.currentUser);
      this.roleFilterOptions = UtilsService.getRoleMapped(leader.role, roleNames);
    } else {
      this.roleFilterOptions = UtilsService.getRoleMapped(this.currentUserRole.name, roleNames);
    }
  }

  addAccessLevel(user): any {
    // temporary getting the role of the user then will be updated later after adding connection.
    // If condition is used for attaching the role of the currently logged in User for handling the case of E.L / S.E.L
    if (user.roleId === this.currentUser.roleId) {
      user.role = this.userService.getCurrentRole();
    } else {
      user.role = this.allRoles.find(role => role?.id.toLowerCase() === user?.roleId.toLowerCase());
    }
    return {
      user: this.getUser(user),
      access:
        [
          AppLevelRoleEnum.ADMIN,
          AppLevelRoleEnum.MSSP,
          AppLevelRoleEnum.ENTITY_LEADER,
          AppLevelRoleEnumBnB.ENTERPRISE,
          AppLevelRoleEnumBnB.DIVISION_LEADER,
          AppLevelRoleEnumBnB.REGION_LEADER,
          AppLevelRoleEnumBnB.USER_ADMINISTRATOR,
          AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN,
        ].some(role => role === user.role?.name) ||
        // case handling for custom role with root entity access.
        (user && user.role && user.role.entityIds && user.role.isRootEntityAccess) // If the custom role has the root entity access.
          ? 'All'
          : (user.entityIds && this.entities[user.entityIds[0]]) || // handling the case of the subEntity.
            (user && user.role && user.role.entityIds && !user.role.isRootEntityAccess) // handling the case of the subEntity custom.
          ? this.entities[user.entityIds ? user.entityIds[0] : user.role.entityIds[0]]?.name
          : { [AppLevelRoleEnum.ADMIN]: 'All', [AppLevelRoleEnum.MSSP]: 'All Clients' }[user?.role?.name],
    };
  }

  addUserToList(user: any) {
    this.mapUserProgress(this.addAccessLevel(user));
    this.filteredUserList = [...this.users[this.status]];
    this.filteredUserList = this.userService.filterUserList(this.filteredUserList);
  }

  mapUserProgress = (questionCompletionObj: any & { assignments?: Array<any> }): void => {
    const user = questionCompletionObj.user;
    // temporary adding the role will be removed after the connection.
    // const tmpRole = this.allRoles.find(role => role?.id === user.roleId);
    // user.role = this.userSettingService.isBuiltInRole(tmpRole?.name) ? user.role : this.tmpRole;
    this.users.all = [questionCompletionObj, ...this.users.all];

    if (!user.isCognitoUser) {
      this.users[QuestionnaireProgressEnum.pending] = [
        questionCompletionObj,
        ...this.users[QuestionnaireProgressEnum.pending],
      ];
    } else {
      if (!questionCompletionObj.assessment || !questionCompletionObj.completedQuestions) {
        this.users[QuestionnaireProgressEnum.enrolled] = [
          questionCompletionObj,
          ...this.users[QuestionnaireProgressEnum.enrolled],
        ];
      } else if (questionCompletionObj.completedQuestions < questionCompletionObj.totalQuestions) {
        this.users[QuestionnaireProgressEnum.inProcess] = [
          questionCompletionObj,
          ...this.users[QuestionnaireProgressEnum.inProcess],
        ];
      } else if (questionCompletionObj.completedQuestions === questionCompletionObj.totalQuestions) {
        this.users[QuestionnaireProgressEnum.completed] = [
          questionCompletionObj,
          ...this.users[QuestionnaireProgressEnum.completed],
        ];
      }
    }
  };

  getUser(user): GetUserQuery {
    return {
      ...user,
      role:
        user.role !== AppLevelRoleEnum.ENTITY_LEADER
          ? user.role
          : user.entityId === this.entityId
          ? this.ENTITY_LEADER_ROLE
          : this.SUBENTITY_LEADER_ROLE,
    };
  }

  handleEditMode(): void {
    this.isEditMode = !this.isEditMode;
    this.userSettingService.setIsEditMode(this.isEditMode);
    // const modalRef = this.modalService.open(AddUserSettingsModalComponent, {
    //   centered: true,
    //   size: 'lg',
    //   windowClass: 'add-user-modal add-participant-modal',
    // });
    // modalRef.componentInstance.isUpdating = true;
    // modalRef.componentInstance.user = this.selectedUsers[0].user;
    // modalRef.componentInstance.modalResult.subscribe((newUser: UpdateUserInput) => {
    //   this.updateUser(newUser).then(() => {
    //     modalRef.close();
    //   });
    // });
  }

  filterChanged(filterType: string, newFilter: string): void {
    if (!newFilter) {
      newFilter = 'all';
    }
    if (filterType === 'status') {
      this.statusFilter = newFilter;
      this.status = newFilter;
      this.filteredUserList = [...this.users[this.statusFilter]];
      this.filteredUserList =
        this.searchText.length < 2
          ? this.filteredUserList
          : [...this.filteredUserList].filter(({ user }) =>
              user.name.toLowerCase().includes(this.searchText.toLowerCase())
            );
      this.filteredUserList =
        this.role === 'all'
          ? this.filteredUserList
          : [...this.filteredUserList].filter(({ user }) => user.roleId === this.role);

      this.lowerTabsContentOptions[0].label = this.statusFilter + ' Users';
    }

    if (filterType === 'search') {
      this.filteredUserList =
        newFilter.length < 2
          ? [...this.users[this.status]]
          : [...this.users[this.status]].filter(
              ({ user }) =>
                user.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                user.email.toLowerCase().includes(this.searchText.toLowerCase())
            );
      this.filteredUserList =
        this.role === 'all'
          ? this.filteredUserList
          : [...this.filteredUserList].filter(({ user }) => user.roleId === this.role);
    }

    if (filterType === 'role') {
      this.role = newFilter;
      this.filteredUserList =
        this.role === 'all'
          ? [...this.users[this.status]]
          : [...this.users[this.status]].filter(({ user }) => user.roleId === this.role);
      this.filteredUserList =
        this.searchText.length < 2
          ? this.filteredUserList
          : [...this.filteredUserList].filter(({ user }) =>
              user.name.toLowerCase().includes(this.searchText.toLowerCase())
            );
      this.sorting(UsersSettingConstant.name, UsersSettingConstant.ascending);
    }
  }

  onUserSelected($event: (GetQuestionnaireCompletionQuery & { assignments?: Array<any> })[]): void {
    this.selectedUsers = $event;
  }

  sortData(tableHeader: TableHeader): void {
    const type: string = tableHeader.sortDirection;
    const col: string = tableHeader.value;

    // temporary adding the condition will be removed after connection is done
    if (tableHeader && tableHeader?.name?.toLowerCase() === 'role') {
      this.mapUserRole();
    }
    if (this.previousSort !== '') {
      this.sortOptions[this.previousSort] = 'na';
    }
    this.previousSort = col;
    this.sortOptions[col] = type;

    this.filteredUserList.sort(function (a, b) {
      if (col === 'name' || col === 'role' || col === 'email') {
        const nameA = a.user[col].toUpperCase();
        const nameB = b.user[col].toUpperCase();
        if (nameA < nameB) {
          if (type === 'asc') {
            return -1;
          } else {
            return 1;
          }
        }

        if (nameA > nameB) {
          if (type === 'asc') {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      } else {
        const valueA = a.user[col];
        const valueB = b.user[col];
        if (type === 'asc') {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }
    });
    // Adding filtered list
    this.filteredUserList = JSON.parse(JSON.stringify(this.filteredUserList));
  }

  /**
   * temporary method for sorting the list of users according to the roles.
   */
  mapUserRole(): void {
    // mapping the user roles from roles.
    this.filteredUserList.map(filteredUser => {
      filteredUser.user.role = this.userService.hashRoles[filteredUser.user.roleId].name;
    });
  }

  sorting(col: string, type: string): void {
    this.filteredUserList.sort(function (a, b) {
      if (col === UsersSettingConstant.name) {
        const nameA = a.user[col].toUpperCase();
        const nameB = b.user[col].toUpperCase();
        if (nameA < nameB) {
          if (type === UsersSettingConstant.ascending) {
            return -1;
          } else {
            return 1;
          }
        }

        if (nameA > nameB) {
          if (type === UsersSettingConstant.ascending) {
            return 1;
          } else {
            return -1;
          }
        }
        return 0;
      } else {
        const valueA = a.user[col];
        const valueB = b.user[col];
        if (type === UsersSettingConstant.ascending) {
          return valueA - valueB;
        } else {
          return valueB - valueA;
        }
      }
    });
  }

  getFilteredUsers() {
    return this.users[this.statusFilter];
  }

  /**
   * ******************** Actions Functionality *********************
   */

  /**
   * Open a modal to Add New User
   */
  addNewUser(): void {
    this.userSettingService.setIsAddNewUSerModalOpened(true);
    const modalRef = this.modalService.open(AddUserSettingsModalComponent, {
      size: 'lg',
      windowClass: 'add-user-modal custom-user-settings-add-user',
      backdropClass: 'custom-user-settings-add-user-backdrop',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.roles = this.allRoles;
    modalRef.componentInstance.currentEntityId = this.entityId;
    modalRef.componentInstance.modalResult.subscribe(async (newUser: CreateUserInput) => {
      try {
        modalRef.close();
        await this.addUser(newUser);
      } catch (e) {
        console.log(e);
      }
    });
  }
  /**
   *  add new User to database and update the Current Users list here
   * @param newUser is type of User Object
   */
  async addUser(newUser: CreateUserInput): Promise<void> {
    if (newUser) {
      try {
        this.tmpRole = newUser.role;
        newUser.role = RoleEnum.CUSTOM; // Temporary adding the CUSTOM enum to every user, will be removed later.
        this.toastr.info('adding user...');
        let user = await this.authService.addNewParticipant(newUser);
        const tempUser = user;
        user = { entityIds: newUser?.entityIds, ...tempUser };
        this.addUserToList(user);
        this.toastr.success('User added');
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }
  /**
   * this update the a Single user at time ( old Functionality not in use)
   * @param data
   */
  async updateUser(data: any): Promise<void> {
    if (data) {
      const user = {
        id: data?.id,
        name: data.name,
        phone: data.phone,
        entityId: data.entityId,
        role: data.role,
      };
      try {
        this.toastr.info('updating user...');
        // Updating user via API
        await this.customAPI.UpdateUser(user);
        // Updating user in the list on client side
        const index = this.users[this.statusFilter].findIndex(item => item.user?.id === user?.id);
        this.users[this.statusFilter][index] = { ...this.users[this.statusFilter][index], user };
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }
  /**
   * This function update all the selected Users whose data is changed all at a time
   */
  async saveUpdatedUsers(): Promise<void> {
    const users = this.userSettingService.getUpdatedUsersList();
    // To check how many users are selected
    if (users && users.length) {
      const selectedUsers = [];
      let isValid = true;
      users.filter(item => {
        const userItem = JSON.parse(JSON.stringify(item));
        if (userItem.isSelected) {
          userItem.roleId = typeof userItem.role === 'string' ? userItem.role : userItem.role?.id;
          userItem.role = 'CUSTOM';
          userItem.isViewOnly = userItem?.permission?.toLowerCase() !== 'edit';
          const tmpRole = this.allRoles.find(role => role?.id === userItem.roleId);
          if (tmpRole?.name === AppLevelRoleEnum.ENTITY_LEADER) {
            userItem.entityId = this.entityId;
            userItem.entityIds = [this.entityId];
          } else if (tmpRole.name === AppLevelRoleEnumBnB.BUSINESS_ENTITY_ADMIN) {
            userItem.entityId =
              userItem && userItem.entityIds && userItem.entityIds.length ? userItem.entityIds[0] : null;
          } else if (
            tmpRole &&
            (tmpRole.name === AppLevelRoleEnum.SUBENTITY_LEADER || tmpRole.name === AppLevelRoleEnum.PARTICIPANT)
          ) {
            userItem.entityId = this.entityId;
            if (!(userItem.entityIds && userItem.entityIds.length)) {
              this.toastr.error('Please select at least one sub entity for ' + tmpRole.name);
              isValid = false;
              return;
            }
          } else {
            userItem.entityId = null;
            userItem.entityIds = null;
          }
          delete userItem.isSelected;
          delete userItem.permission;
          selectedUsers.push(userItem);
        }
      });
      if (!isValid) {
        return;
      }
      const anyEmptyRoleName = selectedUsers.find(u => !u.name || (u.name && u.name.trim() === ''));
      if (selectedUsers && !!selectedUsers.length && anyEmptyRoleName) {
        this.toastr.error('Name cannot be empty');
      } else if (selectedUsers && selectedUsers.length) {
        try {
          this.toastr.info(`Updating ${pluralize('user', selectedUsers.length)}...`);
          let updatedUser;
          await Promise.all(
            selectedUsers.map(async user => {
              updatedUser = await this.customAPI.UpdateUser(user);
            })
          );
          if (updatedUser.statusCode === 401) {
            this.toastr.error('User is not Authorized to perform this action');
            return;
          }
          this.afterUsersUpdate(selectedUsers);
          this.isEditMode = false;
          this.userSettingService.setIsEditMode(false);
          this.toastr.success('Selected Users are updated');
        } catch (e) {
          const message = UtilsService.msgFromError(e);
          this.toastr.error(message);
        }
      } else {
        this.toastr.error('No user selected to update');
      }
    } else {
      this.toastr.error('No change found in any User data');
    }
  }

  /**
   * update the users in Current Users List
   * @param updatedUsers is type of users list . the users that are updated
   */
  afterUsersUpdate(updatedUsers: any[]): void {
    this.selectedUsers = [];
    updatedUsers.map(updatedUser => {
      const index = this.filteredUserList.findIndex(u => u?.user?.id === updatedUser?.id);
      if (index >= 0) {
        try {
          this.filteredUserList[index].user.name = updatedUser.name
            ? updatedUser.name
            : this.filteredUserList[index].user.name;
          // Finding the role on temporary basis will be removed after connection
          const isRoleUpdated = this.filteredUserList[index].user.roleId !== updatedUser.roleId;
          this.filteredUserList[index].user.role = updatedUser.roleId
            ? this.allRoles.find(role => role.id === updatedUser.roleId)
            : this.filteredUserList[index].user.role;
          this.filteredUserList[index].user.roleId = updatedUser.roleId
            ? this.allRoles.find(role => role?.id === updatedUser.roleId)?.id
            : this.filteredUserList[index].user.roleId;
          this.filteredUserList[index].user.phone = updatedUser.phone
            ? updatedUser.phone
            : this.filteredUserList[index].user.phone;
          // * set permission to updated permission
          // * due to ? usage it was assigning old value in some cases
          // * if isViewOnly was true befor and updated value was false
          // * it would fall in else case and previous true value was being assigned
          this.filteredUserList[index].user.isViewOnly = updatedUser.isViewOnly;
          this.filteredUserList[index].isSelected = false;
          this.userSettingService.updateIsSelected(this.filteredUserList[index]);

          if (!(this.role === 'all') && isRoleUpdated) {
            this.filteredUserList = this.filteredUserList.filter(u => u?.user?.id !== updatedUser?.id);
          }
        } catch (err) {
          this.toastr.error('Something went wrong!');
        }
      }
    });
    this.handleEditMode();
  }

  /**
   * This function will open the Modal and in modal ask for confirmation to delete the selected users
   *  this function is not in use after new delete pop-up
   */
  onClickDelete(): void {
    const modalRef = this.modalService.open(DeleteUserModalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'delete-modal',
      backdropClass: 'delete-backdrop-modal',
    });

    modalRef.componentInstance.modalResult.subscribe(async () => {
      try {
        await this.deleteUsers(this.selectedUsers);
        modalRef.close();
      } catch (e) {
        console.log(e);
      }
    });

    modalRef.componentInstance.users = this.selectedUsers;
  }
  /**
   * Delete Users new Pop current in use
   */
  deleteUserPopup(): void {
    if (!(this.selectedUsers && this.selectedUsers.length)) {
      this.toastr.error(' No user is selected');
      return;
    }
    const modalRef = this.modalService.open(DeleteModalComponent, this.ngbModalDeleteUserOptions);
    modalRef.componentInstance.titleText = '';
    modalRef.componentInstance.show2ndConfirmText = 'All your data will be lost';
    modalRef.componentInstance.removeLabelText =
      this.selectedUsers.length > 1 ? this.selectedUsers.length + ' Selected Users ' : this.selectedUsers[0].user.name;
    modalRef.componentInstance.deleteEntityModal = false;
    modalRef.componentInstance.removeFromText =
      this.selectedUsers.length > 1 ? ' and their data will be deleted' : ' and his data will be deleted';
    modalRef.componentInstance.deleteFramework.subscribe(async (deleteConfirm: boolean) => {
      if (deleteConfirm) {
        try {
          const eligibleToDelete = [];

          for (const eachUser of this.selectedUsers || []) {
            if (!eachUser?.entities) {
              eligibleToDelete.push(eachUser);
            } else {
              for (const entity of eachUser.entities || []) {
                const filter = {
                  managerId: {
                    eq: eachUser.user.id,
                  },
                };

                const assessmentStandards = await this.customAPI.StandardFrameworksByAssessmentId(
                  entity.assessmentId,
                  null,
                  filter
                );
                const archivedAssessments = assessmentStandards.items.every(assessment => assessment.archived);

                if (archivedAssessments) {
                  eligibleToDelete.push(eachUser);
                } else {
                  this.toastr.error(`${eachUser.user.name} cannot be deleted as it is the last manager`);
                }
              }
            }
          }

          this.selectedUsers = eligibleToDelete;
          this.tmpSelectedUsers = [...this.selectedUsers];
          this.checkForVendorUsers();
          this.tmpSelectedUsers = [...this.selectedUsers];
          // It will check if the deleting user is the last manager or not.
          // await this.checkFrameworkManagerUsers();
          if (this.selectedUsers && this.selectedUsers.length) {
            await this.deleteUsers(this.selectedUsers);
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
  }

  openRiskAiConfirmationModal($event) {
    const modalRef = this.modalService.open(DeleteModalComponent, this.ngbModalRiskConfirmOptions);
    modalRef.componentInstance.titleText = '';
    modalRef.componentInstance.isFromRiskAiConfonfirmation = true;
    modalRef.componentInstance.svgName = 'robot';
    modalRef.componentInstance.deleteFramework.subscribe(async (deleteConfirm: boolean) => {
      if (deleteConfirm) {
        this.isAIUpdateInProgress = true;
        this.aiData = {
          ...this.aiData,
          isActive: $event,
          userName: this.currentUser.name,
          date: Date.now(),
        };
        if (!this.aiData?.credits || this.aiData?.credits === 0) {
          this.aiData.credits = 100;
        }
        this.aiData.usedCredits = this.aiData?.usedCredits || 0;
        try {
          await this.customAPI.updateAICredits({
            id: this.entityId,
            AI: this.aiData,
            requestType: 'updateAICredits',
          });
          this.toastr.success('AI features Activated Successfully');
        } catch (error) {
          console.log('error', error);
          this.toastr.error('Unable to Activate  AI features');
        }
        this.isAIUpdateInProgress = false;
      }
    });
    modalRef.componentInstance.closeModal.subscribe(isClose => {
      this.aiData.isActive = isClose;
      this.userService.toggleStateChanged$.next(isClose);
    });
  }

  async deActivateRiskAI($event) {
    this.isAIUpdateInProgress = true;
    this.aiData = {
      ...this.aiData,
      isActive: $event,
      userName: this.currentUser.name,
      date: Date.now(),
    };
    try {
      await this.customAPI.updateAICredits({
        id: this.entityId,
        AI: this.aiData,
        requestType: 'updateAICredits',
      });
      this.toastr.success('AI features Deactivated Successfully');
      this.isAIUpdateInProgress = false;
    } catch (error) {
      console.log('error', error);
      this.toastr.error('Unable to Deactivate AI features');
      this.isAIUpdateInProgress = false;
    }
  }

  openCreditPackageModal(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>) {
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsConfirmation);
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
  }

  /**
   * will get all frameworks against deleting user
   */
  async checkFrameworkManagerUsers(): Promise<void> {
    for (const tmpUser of this.tmpSelectedUsers) {
      try {
        const frameworks = await this.customAPI.FrameworkManagersByManagerId(tmpUser.user?.id);
        if (frameworks.items && frameworks.items.length) {
          await this.splitLastFrameworkUser(frameworks.items, tmpUser);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  /**
   *
   * @param frameworks - All the frameworks user is the manager
   * @param deletingUser - Current deleting user
   */
  async splitLastFrameworkUser(frameworks: any, deletingUser: any): Promise<void> {
    // Because the framework should not have a user other than current user
    for (const framework of frameworks) {
      const filter = {
        managerId: {
          ne: deletingUser.user.id,
        },
        assessmentId: { eq: framework.assessmentId },
      };
      try {
        const result = await this.customAPI.FrameworkManagersByStandardFrameworkId(
          framework.standardFrameworkId,
          null,
          filter
        );
        if (result && result.items && !result.items.length) {
          this.toastr.error(`${deletingUser.user.name} cannot be deleted as it is the last manager`);
          // If user is the last manager then splice it from selected user.
          this.selectedUsers = this.selectedUsers.filter(selectedUser => {
            if (selectedUser.user && selectedUser.user.id && deletingUser.user && deletingUser.user.id) {
              return selectedUser.user.id !== deletingUser.user.id;
            }
          });
          // if a single framework found which has no other user, then terminate the process (User cannot be deleted)
          return;
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  isUserSelected(): boolean {
    return this.selectedUsers && !!this.selectedUsers.length;
  }
  /**
   * checks for the selected users are vendors
   * if vendors then they should be not the last users.
   */
  checkForVendorUsers(): void {
    if (this.tmpSelectedUsers && this.vendorEntities) {
      // iterate over the selected users to check if they are vendor users.
      this.tmpSelectedUsers.forEach(tmpSelectedUser => {
        // checking and getting the vendor entity
        const selectedVendorEntity =
          this.vendorHash &&
          tmpSelectedUser?.user &&
          tmpSelectedUser?.user?.entityIds &&
          tmpSelectedUser?.user?.entityIds.length
            ? this.vendorHash[tmpSelectedUser?.user?.entityIds[0]]
            : null;
        if (selectedVendorEntity && selectedVendorEntity.userCounter && selectedVendorEntity.userCounter > 1) {
          this.vendorHash[tmpSelectedUser?.user?.entityIds[0]].userCounter--;
        } else if (selectedVendorEntity && selectedVendorEntity.userCounter && selectedVendorEntity.userCounter === 1) {
          const tmpIndex = this.selectedUsers.findIndex(
            selectedUser => selectedUser.user.id === tmpSelectedUser?.user?.id
          );
          if (tmpIndex > -1) {
            this.selectedUsers.splice(tmpIndex, 1);
          }
          this.toastr.warning(
            `Cannot delete last user: ${tmpSelectedUser?.user?.name} of vendor ${selectedVendorEntity.name}`
          );
        }
      });
    }
  }

  /**
   * this function will delete the users from Database through Auth Service
   * @param users is type of users list
   */
  async deleteUsers(users: any[]) {
    try {
      this.toastr.info(`Deleting ${pluralize('user', users.length)}...`);
      await Promise.all(users.map(async ({ user: { id } }) => await this.authService.removeUser(id)));
      this.toastr.success(`${pluralize('User', users.length)} deleted!`);
      this.afterDeletedUsers(users);
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.toastr.error(message);
    }
  }

  deleteFunction(deletedUsers: any[]): void {
    for (const key in this.users) {
      if (this.users[key]) {
        this.users[key] = this.users[key].filter(
          ({ user }) => !deletedUsers.some(deletedUser => deletedUser.user.id === user.id)
        );
      }
    }
  }
  /**
   * This function modify the
   * @param deletedUsers is type of users list that are deleted
   */
  afterDeletedUsers(deletedUsers: any[]): void {
    // This function will remove the users from all statuses
    this.deleteFunction(deletedUsers);

    this.filteredUserList = this.filteredUserList.filter(
      user => !deletedUsers.some(deletedUser => deletedUser.user.id === user.user.id)
    );
    this.filteredUserList = this.userService.filterUserList(this.filteredUserList);
    this.selectedUsers = [];
  }

  /**
   * Invitation or Enrollment functionality
   */
  resendEnrollmentPopup(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>): void {
    // const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptions);
    // const compInstance = modalRef.componentInstance;
    // compInstance.customizeHeader = true;
    // compInstance.headerContentRef = header;
    // compInstance.contentRef = content;
    // compInstance.footerContentRef = footer;
    this.onClickInvite({ content, header, footer }, false);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onClickInvite(modalData: any = {}, isWelcomeEmail: boolean = false) {
    if (this.selectedUsers && this.selectedUsers.length) {
      try {
        this.toastr.info('Sending invite...');
        await this.inviteUsers(this.selectedUsers, isWelcomeEmail);
        if (this.isInviteSent) {
          this.userSettingService.setIsAllSelected(false);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      this.toastr.error('No user selected please select first');
    }
  }
  async inviteUsers(users: any[], isWelcomeEmail: boolean = false) {
    this.isInviteSent = true;
    try {
      await Promise.all(
        users.map(async ({ user: { id, email, name, phone } }) => {
          isWelcomeEmail = phone ? true : false; //* flag is true if a phone number already exists
          await this.authService.sendInviteEmail(id, email, name, isWelcomeEmail);
        })
      );
      this.toastr.success('Invitation sent successfully');
    } catch (e) {
      const message = UtilsService.msgFromError(e);
      this.isInviteSent = false;
      this.toastr.error(message);
    }
  }

  async getExtendEntityList(): Promise<GetEntityQueryExtended[]> {
    let entList = await this.entityService.listEntitysByEntityType(EntityTypeEnum.ROOT_ENTITY, null, null, true);
    entList = entList.sort((a, b) => a.name.localeCompare(b.name));
    return entList;
  }

  /**
   * on Reset phone
   */
  onResetPhone(): void {}

  async enrollmentConfirm(): Promise<void> {
    try {
      this.toastr.info('Sending Enrollment invite...');
      await this.inviteUsers(this.selectedUsers, false);
      this.modalRef.close();
    } catch (e) {
      console.log(e);
    }
  }

  displayRolePermission(): void {
    this.showRolePermission = true;
    this.roleSelected = false;
    this.isDefaultRole = false;
    this.selectedRole = null;
  }

  /**
   * helper method to switch the screens between users and role permissions
   */
  changePermissionHeaderToPermissions(screen: string): void {
    if (screen && screen.toLowerCase() === UsersSettingScreens.users) {
      this.currentTab = UsersSettingScreens.users;
      this.isUsersScreen = true;
    } else if (screen && screen.toLowerCase() === UsersSettingScreens.permissions) {
      this.currentTab = UsersSettingScreens.permissions;
      this.isUsersScreen = false;
    }
  }

  async selectEntityForSettings(entity: GetEntityQuery): Promise<void> {
    this.innerLoading = true;
    this.entity = entity;
    this.entityId = entity.id;
    // const parentEntity = await this.entityService.getEntity(this.entityId, true);
    // const frameworkSettings = JSON.parse(parentEntity.frameworkSettings);
    // this.riskFrameworks = frameworkSettings.RISK_FRAMEWORKS;
    // this.standardList = frameworkSettings.COMPLIANCE_FRAMEWORKS;

    this.entitySelectionMode = false;
    this.activeScan = this.entity.domainScanned ? this.entity.domainScanned : false;
    const domainArr = this.entity.domainScanTimes ? JSON.parse(this.entity.domainScanTimes) : [];
    this.entityDomainValue = domainArr?.length ? domainArr[0].name : '';
    this.ngOnDestroy();
    await this.ngOnInit();
    this.innerLoading = false;
  }

  /**
   * Function to toggle active/inactive button
   */
  async toggleActivation(confirm: boolean = false): Promise<void> {
    if (confirm) {
      await this.enableEncryption();
    }
  }

  async runRotation(): Promise<void> {
    try {
      if (await this.checkIfVendorsCreationInProgress()) {
        this.toastr.info(`Vendors creation for ${this.entity?.name} is in progress please try again in few minutes.`);
        return;
      }
      if (!this.entity.KMS) {
        return;
      }

      if (
        this.rotationInProgress ||
        this.entity?.KMS?.rotations?.count >= 3 ||
        this.entity?.KMS?.status !== this.kmsEnum.DONE.toUpperCase()
      ) {
        return;
      }
      this.rotationInProgress = true;

      if (!this.entity.KMS.rotations || (this.entity.KMS.rotations && this.entity.KMS.rotations.count < 3)) {
        this.toastr.info(`Rotation process started for ${this.entity?.name}...`);

        this.timers = [
          setTimeout(() => {
            this.toastr.info('This will take few moments, please wait... ');
          }, 2000),
          setInterval(() => {
            this.toastr.info('Rotation usually takes more time if entity is being used by any user. please wait... ');
          }, 16000),
        ];

        let response: any = await this.clientLambdaService.invokeLambda('rotationHandler', {
          action: 'rotate_keys',
          params: {
            rootId: this.entityId,
          },
        });

        if (!response) {
          this.toastr.error('Error during rotation.');
          this.timers.forEach(timer => clearInterval(timer));
          this.rotationInProgress = false;
          return;
        }

        response = JSON.parse(response.Payload);

        if (response.error) {
          this.toastr.error('Failed: error occurred during rotation process.');
          this.timers.forEach(timer => clearInterval(timer));
          this.rotationInProgress = false;
          return;
        }

        // not 'null' but 'false'
        if (response.response === false) {
          this.toastr.error('Failed: ' + response.msg);
          this.timers.forEach(timer => clearInterval(timer));
          this.rotationInProgress = false;
          return;
        }
      } else {
        this.toastr.error('You have reached annual limit of 3 rotations! ');
      }
    } catch (error) {
      console.log('runRotation err - ', error);
      this.toastr.error(error);
      this.rotationInProgress = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(listener => listener.unsubscribe());
    this.timers?.forEach(timer => clearInterval(timer));
    clearInterval(this.intervalId);
  }

  async enableEncryption(): Promise<void> {
    try {
      if (await this.checkIfVendorsCreationInProgress()) {
        this.toastr.info(`Vendors creation for ${this.entity?.name} is in progress please try again in few minutes.`);
        return;
      }
      if (!this.kmsToggle && !this.activationInProgress) {
        this.activationInProgress = true;
        this.toastr.info(`Encryption process started for ${this.entity?.name}...`);

        this.timers = [
          setTimeout(() => {
            this.toastr.info('This will take few moments, please wait... ');
          }, 2000),
          setInterval(() => {
            this.toastr.info('It usually takes more time if entity is being used by any user. please wait... ');
          }, 10000),
        ];

        this.user = await this.userService.getCurrentUser();

        if (!this.user || !this.user.id) {
          this.toastr.error('Failed to get current user.');
          this.timers.forEach(timer => clearInterval(timer));
          this.activationInProgress = false;
          return;
        }

        await this.clientLambdaService.invokeLambda('securityHandler', {
          action: 'encrypt_root_entity',
          params: {
            rootId: this.entityId,
            userId: this.user.id,
          },
        });
      }
    } catch (err) {
      this.toastr.error('Failed encryption.', err);
      this.activationInProgress = false;
    }
  }

  async setLatestEntityStatus(): Promise<void> {
    // set lasted KMS status value
    // set latest AI Credits status
    if (this.entityId) {
      this.entity = await this.entityService.getEntity(this.entityId, false);
      this.kmsToggle = this.entity?.KMS?.status === 'DONE';
      delete this.entity?.AI?.__typename;
      this.aiData = this.entity?.AI || { isActive: false };
    }
  }

  async onEncryptionResponse(updatedEntity: any): Promise<void> {
    if (updatedEntity?.entityType === EntityTypeEnum.ROOT_ENTITY) {
      // Make sure the updated entity KMS rotation doesn't exists, to make sure this works for encryption only
      // KMS status is Done Or Failed
      // This block works for showing success or failure of encryption to client
      // Process this block only for the user who has started the encryption process
      const domain = DomainFrameworkService.getDomain();
      if (
        updatedEntity?.domain === domain &&
        !this.isNotFromRotation &&
        !updatedEntity?.KMS?.rotations &&
        updatedEntity?.KMS?.userId === this.user?.id &&
        (updatedEntity?.KMS?.status === this.kmsEnum.DONE.toUpperCase() ||
          updatedEntity?.KMS?.status === this.kmsEnum.FAILED.toUpperCase())
      ) {
        this.timers.forEach(timer => clearInterval(timer));
        if (updatedEntity?.KMS?.status === this.kmsEnum.DONE.toUpperCase()) {
          this.toastr.success(`${this.entity?.name} is Successfully Encrypted!`);
          this.kmsToggle = true;
        } else {
          this.toastr.error('Failed: There was an error during encryption process.');
          this.kmsToggle = false;
        }
        this.entity.KMS = updatedEntity.KMS;
        this.activationInProgress = false;
        return;
      }

      // check if the updated entity KMS rotation exists with Done Or Failed status
      // This block works for showing success or failure of rotation to client
      if (
        updatedEntity?.domain === domain &&
        updatedEntity?.KMS?.rotations &&
        !this.isNotFromRotation &&
        (updatedEntity?.KMS?.rotations?.status === this.kmsEnum.DONE.toUpperCase() ||
          updatedEntity?.KMS?.rotations?.status === this.kmsEnum.FAILED.toUpperCase())
      ) {
        this.timers.forEach(timer => clearInterval(timer));
        this.entity = await this.entityService.getEntity(this.entityId, false);
        if (updatedEntity?.KMS?.rotations?.status === this.kmsEnum.DONE.toUpperCase()) {
          this.toastr.success('Rotation process completed Successfully');
        } else {
          this.toastr.error('Failed: error occurred during rotation process.');
        }
        this.rotationInProgress = false;
      }
    }
  }

  async fetchVendorsDataStates(entityId: string): Promise<GetDataStateQuery[]> {
    try {
      let token;
      const result: GetDataStateQuery[] = [];
      do {
        const { nextToken, items } = await this.customAPI.DataStatesByParentId(entityId);
        result.push(...items);
        token = nextToken;
      } while (token);

      return result;
    } catch (err) {
      this.toastr.error('Failed to fetch vendor details.', err);
    }
  }

  async checkIfVendorsCreationInProgress() {
    try {
      const vendors = await this.fetchVendorsDataStates(this.entityId);
      return vendors?.find(vendor => {
        return vendor?.message === 'Vendor in progress';
      });
    } catch (err) {
      this.toastr.error('Failed to check vendors creation state.', err);
    }
  }

  async updateUserAssignments(assessments) {
    try {
      const usersMap = {};
      const completions = await Promise.all(
        assessments.map(async assessment =>
          (
            await this.questionnaireService.getQuestionnaireCompletionByAssessmentId(assessment.id)
          ).map(item => ({
            ...item,
            subEntityId: assessment.childId,
          }))
        )
      );

      // This peace of code is used to filter the Users which are defined and has data
      const completionData = completions.flat(1).filter((d: any) => UtilsService.isDefined(d.user));
      return {
        data: Object.values(
          completionData.reduce((obj, { user, ...completion }: any) => {
            if (user) {
              usersMap[user.id] = true;
              return {
                ...(obj as any),
                [user.id]: {
                  user: this.getUser(user),
                  access: [AppLevelRoleEnum.ADMIN, AppLevelRoleEnum.MSSP].some(item => item === user.role)
                    ? 'All'
                    : this.entities[completion.subEntityId]?.name,
                  entities: [AppLevelRoleEnum.ADMIN, AppLevelRoleEnum.MSSP].some(item => item === user.role)
                    ? null
                    : [
                        ...(obj && obj[user.id] && obj[user.id].entities ? obj[user.id].entities : []),
                        {
                          ...completion,
                          assignments: JSON.parse(completion.assignmentMap),
                          access: [AppLevelRoleEnum.ADMIN, AppLevelRoleEnum.MSSP].some(item => item === user.role)
                            ? 'All'
                            : this.entities[completion.subEntityId]?.name,
                        },
                      ],
                },
              };
            }
          }, {})
        ),
        map: usersMap,
      };
    } catch (err) {}
  }

  async backgroundJobAsync(): Promise<void> {
    let startIndex = this.nextOffset;
    let assessments = this.assessments.slice(startIndex, (this.nextOffset = this.nextOffset + 10));
    while (assessments?.length) {
      await this.delay(5000);
      const updatedUserAssignments = await this.updateUserAssignments(assessments);
      const mergedData = [...this.assignedUsers.data, ...updatedUserAssignments?.data];
      const uniqueDataMap = new Map();
      mergedData.forEach(user => uniqueDataMap.set(user.id, user));
      this.assignedUsers.data = Array.from(uniqueDataMap.values());
      this.assignedUsers.map = { ...this.assignedUsers.map, ...updatedUserAssignments?.map };
      this.updateUsers();
      assessments = this.assessments.slice((startIndex = this.nextOffset), (this.nextOffset = this.nextOffset + 10));
    }
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  updateUsers() {
    if (this.userService.isCustomRole && Object.keys(this.userService.isCustomRole)?.length > 0) {
      [...this.allUsers.filter(item => !this.assignedUsers.map[item.user.id])].map(this.mapUserProgress);
    } else {
      [...this.allUsers.filter(item => !this.assignedUsers.map[item.user.id]), ...this.assignedUsers.data].map(
        this.mapUserProgress
      );
    }
  }

  closeModal() {
    this.closeModalInstance.close();
  }
  async activateAttackSurface(): Promise<void> {
    this.activeScan = !this.activeScan;
    await this.customAPI.UpdateEntity({
      id: this.entityId,
      domainScanned: this.activeScan,
      upperdeckSetting: this.activeScan
        ? UPPERDECK_FACELIFT.RESIDUAL_RISK_ATTACK_SURFACE
        : UPPERDECK_FACELIFT.RESIDUAL_RISK,
    });
  }

  async scanAllDomains(): Promise<void> {
    const isValidDomain = UtilsService.validateDomain(this.domainValue);
    const domainList = [this.domainValue];
    if (isValidDomain) {
      this.toastr.info('Scanning your domain in the background. Results will be available shortly!');
      // Start periodic toasters
      this.intervalId = setInterval(() => {
        this.toastr.info('Scanning your domain in the background. Results will be available shortly!');
      }, 20000); // Show toaster every 5 seconds
      const allFiles = await this.fileService.checkIfPathExistsS3(`EXTERNAL_SCANS/${this.entityId}`);
      if (allFiles && allFiles.length) {
        allFiles.forEach(file => {
          const domName = file.key.split('/')[2];
          const dom = domName.split('.zip')[0];
          const index = domainList.findIndex(d => d === dom);
          if (index >= 0) {
            domainList.splice(index, 1);
          } else {
            // only keep the file related to updated domain,al other files should be deleted
            this.fileService.deleteFromS3(file);
          }
        });
      }
      if (!domainList.length) {
        clearInterval(this.intervalId); // Stop periodic toasters
        this.toastr.success('Domain scan complete! Your results are now ready to view in the Upperdeck');
        return;
      }
      const json = {
        wizardId: this.entityId,
        isRootEntity: true,
        domainList,
      };
      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      const params = {
        FunctionName: `multiDomainBinaryScan-${environment.name}`,
        Payload: JSON.stringify({ ...json }),
      };
      try {
        await lambda.invoke(params).promise();
        clearInterval(this.intervalId); // Stop periodic toasters when done
        this.toastr.success('The domain scan has been completed! The results are now available in the Upperdeck.');
        this.entityDomainValue = this.domainValue;
        this.isEdit = true;
      } catch (error) {
        clearInterval(this.intervalId); // Stop periodic toasters on error
        this.toastr.error('The domain scan has failed, please try and re-run later.');
        console.log('Issue while scanning domains ' + JSON.stringify(error));
      }
    } else {
      this.toastr.error('Please add valid domain');
    }
  }

  openAttackSurfaceModal(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>) {
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsAttackSurface);
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
  }

  domainEdit(isEdit: boolean) {
    if (isEdit) {
      const isValid = UtilsService.validateDomain(this.updatedDomainValue);
      if (isValid) {
        this.domainValue = this.updatedDomainValue;
        this.entity.domainScanTimes = JSON.stringify([
          { name: this.domainValue, created: Date.now(), updated: Date.now() },
        ]);
      } else {
        this.toastr.error('Please Add Valid Domain');
        return;
      }
    } else {
      const domainArr = this.entity.domainScanTimes ? JSON.parse(this.entity.domainScanTimes) : [];
      this.domainValue = domainArr?.length ? domainArr[0].name : '';
    }
    this.updatedDomainValue = '';
    this.closeModal();
  }

  openExternalScanRequestModal(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>) {
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalOptionsExternalScanRequest);
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
  }
  openExternalScanRequestScanAcknowledgementModal(
    header: TemplateRef<any>,
    content: TemplateRef<any>,
    footer: TemplateRef<any>
  ) {
    this.closeModal();
    const modalRef = this.modalService.open(
      ModalTemplateComponent,
      this.ngbModalOptionsExternalScanRequestScanAcknowledgement
    );
    const compInstance = modalRef.componentInstance;
    this.closeModalInstance = modalRef;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
    const emailObject = {
      type: 'NOTIFY_CENTRALEYES_EXTERNAL_SCAN_REQUEST',
      userName: this.currentUser.name,
      userEmail: this.currentUser.email,
      entityName: this.entity.name,
      domain: DomainFrameworkService.getDomain(),
    };
    this.senEmailContent(emailObject);
  }
  async senEmailContent(emailContent) {
    await this.clientLambdaService.invokeLambda('sendNotification', emailContent);
  }

  openNewFrameworkRequestModal() {
    const modalRef = this.modalService.open(NewFrameworkRequestModalComponent, this.ngbModalOptionsNewFrameworkRequest);
    modalRef.componentInstance.userName = this.currentUser.name;
    modalRef.componentInstance.userEmail = this.currentUser.email;
    modalRef.componentInstance.riskFrameworks = this.riskFrameworks.filter(risk => risk.status === false);
    modalRef.componentInstance.complianceFrameworks = this.standardList.filter(
      compliance => compliance.status === false
    );
  }
}
