// eslint-disable-next-line no-shadow
export enum CogAuthorizedScopes {
  PHONE = 'phone',
  EMAIL = 'email',
  OPENID = 'openid',
  PROFILE = 'profile',
  COGNITOADMIN = 'aws.cognito.signin.user.admin',
}

// TODO: The plan is to convert the OKTA group to SAML and the AAD to OPENID
export enum DomainBaseEnum {
  OKTA = 'okta',
  AAD = 'activedirectory',
  SAML = 'saml',
  OPENID = 'openid',
}

export enum DomainBaseADIDPs {
  // dev urls
  'localhost' = 'ActiveDirectoryLocal',
  'dev.cygovdev.com' = 'ActiveDirectory',
  'bbins.cygovdev.com' = 'ActiveDirectory-bbins.cygovdev',
  // production urls
  'live.cermp.com' = 'ActiveDirectory-Live',
  'cyber-test.bbins.com' = 'ActiveDirectory',
  'cyber.bbins.com' = 'ActiveDirectory-cyber.bbins',
  'cwu.cegrc.com' = 'ActiveDirectory-cwu',
}

export enum DomainBaseOktaIDPs {
  // dev urls
  'localhost' = 'okta-local',
  'dev.cygovdev.com' = 'okta-dev',
  // production urls
  'live.cermp.com' = 'Okta-Live',
  'cl.cermp.com' = 'Okta-Celsius',
  'corvus.cegrc.com' = 'Okta-Corvus',
  'appsflyer.cermp.com' = 'Okta-appsflyer',
  'testuk.cermp.com' = 'Okta-testuk',
  'informatica-uat.cegrc.com' = 'Okta-informatica-uat',
  'informatica.cegrc.com' = 'Okta-informatica',
  'moelis.cegrc.com' = 'Okta-Moelis',
}

export enum DomainBaseSAML {
  // dev urls
  'localhost' = 'SAML-local',
  'dev.cygovdev.com' = 'okta-dev',
  // production urls
  'ca.cegrc.com' = 'SAML-CyberArk',
  'ca-test.cermp.com' = 'SAML-CyberArkTest',
  'ou.cermp.com' = 'SAML-Ping-OU',
  'usb.cermp.com' = 'SAML-Ping-USB',
  'live.cermp.com' = 'SAML-Ping-Live',
  'stockman.cegrc.com' = 'SAML-Ping-stockman',
  'nebraska.cegrc.com' = 'SAML-Ping-nebraska',
}
