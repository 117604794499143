import {
  BnbLayerEnum,
  CreateLogsInput,
  EntityWizard,
  ListEntityLayersQuery,
  ModelEntityLayersConditionInput,
} from './../API.service';
import { IndustryEnum, MidMarketEnum } from './enums/brownAndBrown.enum';
import {
  ListAssessmentsQuery,
  GetAssessmentQuery,
  ModelAssessmentFilterInput,
  StandardFilterInput,
  GetFrameworkScoresQuery,
  CreateAssignmentMutation,
  TimelineInput,
  CreateAssessmentStandardFrameworkInput,
  CreateAssessmentStandardFrameworkMutation,
  UpdateAssessmentStandardFrameworkInput,
  UpdateAssessmentStandardFrameworkMutation,
  FileTypeEnum,
  ModelUserConditionInput,
  GetArchivedUserQuery,
  ListArchivedUsersQuery,
  ModelArchivedUserFilterInput,
  GetAnswerQuery,
  GetAssignmentQuery,
  CreateEntityLayersInput,
  CreateEntityLayersMutation,
  GetEntityLayersQuery,
  S3FileInput,
  UpdateEntityWizardInput,
  UpdateEntityLayersInput,
  DeleteEntityWizardMutation,
  GetRoleQuery,
  UserByRoleIdQuery,
  CreateCustomTaskInput,
  CreateUserInput,
  UpdateUserInput,
} from 'app/API.service';
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/unbound-method */
import { Injectable, Injector, OnDestroy, EventEmitter } from '@angular/core';
import Lambda from 'aws-sdk/clients/lambda';
import { v4 as uuid } from 'uuid';
import { FrameworkTreeService } from './framework-tree.service';
import { UtilsService } from 'app/shared/utils.service';
import {
  GetEntityQuery,
  EntityTypeEnum,
  FrequencyCheckEnum,
  AccessLevelEnum,
  APIService,
  CreateEntityMutation,
  CreateEntityInput,
  UpdateEntityMutation,
  UpdateEntityInput,
  ModelEntityFilterInput,
  ModelSortDirection,
  ModelStringKeyConditionInput,
  GetUserQuery,
  ListUsersQuery,
  EntityParentQuery,
  RoleEnum,
  CreateAssessmentMutation,
  CreateAssessmentInput,
  StandardFrameworkInput,
  StandardType,
  CreateEntityWizardInput,
  GetEntityWizardQuery,
  DocumentType,
} from 'app/API.service';
import { ImpactTextEnum } from './enums/impact.enum';
import { ClientsSubMenuPriority, NistBBRiskTitleEnum } from './enums/brownAndBrown.enum';
import { FileService } from './file.service';
import { Subscription, BehaviorSubject, Observable, from } from 'rxjs';
import { Auth } from 'aws-amplify';
import { IKeyValue } from 'models/ikey-value';
import { environment } from 'environments/environment';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { ObjectsSort } from './helpers/generic-helpers';
import { ActivatedRoute, Router } from '@angular/router';
import { AppLevelRoleEnum } from './enums/appLevelRoles.enum';
import { ToastrService } from 'ngx-toastr';
import { Integration } from 'app/shared/enums/integration.enum';
import { DomainFrameworkService } from './domain-framework.service';
import * as Excel from 'exceljs';
import { LargeNumberShort } from 'app/shared/pipes/large-number-short.pipe';
import { saveAs } from 'file-saver';
import { FrameworkEnumTypes } from './enums/framework-enum-types.enum';
import { ClientLambdaService } from './client-lambda.service';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { UsersSettingConstant } from 'app/users-settings/users-settings.constant';
import { DomainBaseEnum } from './enums/cognito.enum';

export interface GetEntityQueryExtended extends GetEntityQuery {
  showProjectManager?: boolean;
  logoUrl?: string;
  alerts?: any;
  dummy?: any;
}

export interface FrameworkScore extends StandardFrameworkInput {
  not_added?: any;
  archived?: boolean;
  assessmentId?: string;
  updatedAt: string;
  key: string;
  rootId?: string;
  childId?: string;
  name: string;
  scores: any;
  assessmentTree?: any;
  __typename: 'StandardFramework';
}

export interface FrameworkScoreAndManagers {
  frameworks: FrameworkScore[];
  managers: string[];
}

export interface RequiredStandard {
  key: string;
  name: string;
  type: StandardType;
  checked: boolean;
  fileName?: string;
  filter?: StandardFilterInput;
  documentCategory?: DocumentType;
  documentType?: string;
  level?: string;
}

export interface QuestionSettings {
  isCollaborative?: boolean;
  isArtifactsMandatory?: any;
  isApprovalRequired?: any;
  isCommentsMandatory?: any;
  isIntegrationActive?: boolean;
  isSmartMappingActive?: boolean;
}

export interface RequiredStandardIndustry extends RequiredStandard {
  industry: string;
}

export interface ExtendedEntityLayer extends GetEntityLayersQuery {
  wizardData?: GetEntityWizardQuery;
  isDraft?: boolean;
}

export interface ModifiedEntityObj {
  isDelete: boolean;
  modifiedEntity: any;
}

@Injectable({
  providedIn: 'root',
})
export class EntityService implements OnDestroy {
  public static onDataChanged = new BehaviorSubject(false);
  public static onEntityAdded: Subject<any> = new Subject<any>();
  public static onModifyEntity: Subject<ModifiedEntityObj> = new Subject<ModifiedEntityObj>();
  public static onStandardListUpdate: Subject<any> = new Subject<any>();
  private static promCache: any = {};
  private static entityMap: IKeyValue<any> = {};
  user: GetUserQuery;
  userRole: any;
  authUser = null; // This is for cognito user
  private subscriptionList: Subscription[];
  updateEntityScoreSubscriber: Subject<any> = new Subject<any>();
  noArchivedFramework: Subject<any> = new Subject<boolean>();
  isDataLoaded: Subject<any> = new Subject<boolean>();
  noArchivedRisk: Subject<any> = new Subject<boolean>();
  updateEntitySubscriber: Subject<any> = new Subject<any>();
  updateEntityIntegrationSubscriber: Subject<any> = new Subject<any>();
  updateVendorSubscriber: Subject<any> = new Subject<any>();
  updateVendorDetailSubscriber: Subject<any> = new Subject<any>();
  updateIntelligenceSubscriber: Subject<any> = new Subject<any>();
  updateBreachesSubscriber: Subject<any> = new Subject<any>();
  updateWizardDomainSubscriber: Subject<boolean> = new Subject<boolean>();
  wizardDataUpdated: Subject<any> = new Subject<any>();
  private activeSubEntityObservable = new Subject();
  private archivedUsers: GetArchivedUserQuery[] = [];
  public activeSubEntitySubscriber = this.activeSubEntityObservable.asObservable();
  standardsNIndustries: any = null;
  industryFrameworkMap = {};
  allRoles: GetRoleQuery[] = [];
  customRoles: GetRoleQuery[] = [];
  defaultRoles: GetRoleQuery[] = [];
  isLoadingSub: Subject<boolean> = new Subject<boolean>();
  createdSubEntity: Subject<any> = new Subject<any>();
  smartTagsUpdated: Subject<any> = new Subject<any>();
  isUserLoggedIn: boolean = false;
  foundArchived: boolean = true;
  // bnb wizard template
  wizardCSVTemplate = null;
  nistMappedRC = {} as any;
  rskCount = {};
  frameworkRangeMap = [];
  // BnB variables
  private static _entityLayerIds = {};
  isCRB: boolean = UtilsService.isCRB;
  subEntitiesListByParent = {};
  private static _layerPriority = ClientsSubMenuPriority;
  StandardEnum: any;
  RiskFrameworkEnum: any;
  entityId: string = '';
  public selectedAll: boolean = true; // added to handle the archive tab enable/disable scenario
  currentUser: GetUserQuery;
  wizard: GetEntityWizardQuery;
  brokersEmail: any = [];
  private fetchEntityPromises: { [parentId: string]: Promise<GetEntityQuery[]> } = {};
  private thirdPartySmartTagsSubject = new BehaviorSubject<{ [key: string]: Set<string> }>({});
  thirdPartySmartTags$ = this.thirdPartySmartTagsSubject.asObservable();
  assignmentCreatedEvent = new EventEmitter<any>();
  currentUserToken: string;
  onCreateVendorDetailListner$ = new Subject<any>();
  entityArchivedFrameworks: any = {};

  constructor(
    private api: APIService,
    private logger: NGXLogger,
    private fileService: FileService,
    private router: Router,
    private route: ActivatedRoute,
    private frameworkTreeService: FrameworkTreeService,
    private toastr: ToastrService,
    private shortNumber: LargeNumberShort,
    private domainFrameworkService: DomainFrameworkService,
    private clientLambdaService: ClientLambdaService,
    private customApi: CUSTOMAPIService,
    private injector: Injector //* private injector to avoid circular dependancy
  ) {
    this.init();
    from(Auth.currentSession()).subscribe((currentSession: any) => {
      if (!currentSession) {
        return;
      }
      this.currentUserToken = currentSession.getAccessToken().getJwtToken();
      this.loadSubscriptions();
    });

    this.customApi.webSocketConnectionListner$.subscribe(hubState => {
      if (hubState?.isConnectionClosed) {
        this.subscriptionList.forEach(listener => listener?.unsubscribe());
        this.subscriptionList = [];
      } else if (!hubState?.isConnectionClosed && !this.subscriptionList?.length) {
        this.loadSubscriptions();
      }
    });
  }

  public set roles(roles: GetRoleQuery[]) {
    this.allRoles = roles;
  }

  public set custom_Roles(roles: GetRoleQuery[]) {
    this.customRoles = roles;
  }

  public set default_Roles(roles: GetRoleQuery[]) {
    this.defaultRoles = roles;
  }

  public getCustom_Roles(): GetRoleQuery[] {
    return this.customRoles;
  }

  public getArchivedUsers(): GetArchivedUserQuery[] {
    return this.archivedUsers;
  }

  public getDefault_Roles(): GetRoleQuery[] {
    return this.defaultRoles;
  }

  public static getAdminGroup(): string {
    return window.location.origin;
  }

  public static getParticipantGroup(): string {
    return `${window.location.origin}-participants`;
  }
  async init(): Promise<void> {
    await this.fileService.init();
    this.StandardEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.STANDARD_ENUM);
    this.RiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.RISK_FRAMEWORK_ENUM);
    if (!this.isUserLoggedIn) {
      this.isUserLoggedIn = await Auth.currentUserInfo();
    }

    if (this.isUserLoggedIn) {
      const currentUser = await this.getCurrentUser();
      if (currentUser) {
        // this.archivedUsers = await this.getAllArchivedUsers();
      }
      // Get frameworkRangeMap constant data from s3
      this.frameworkRangeMap = await this.fileService.getFrameworkRangeMap();
    }
  }
  public static initEntity(entityType: EntityTypeEnum, projectManager: string, parentId?: string): CreateEntityInput {
    if (!parentId && entityType !== EntityTypeEnum.ROOT_ENTITY) {
      throw new Error('parentId is required!');
    }
    const initiationDate = UtilsService.getDateInNgbDateStructFormat(Date.now());
    const initiationDateStamp = UtilsService.getDateFromNgbDateStruct(initiationDate).getTime();
    const now = initiationDateStamp;
    const generatedId = uuid();
    // 'admin' means admin access to the entity and the whole tree under
    const accessGroups = [this.getAdminGroup()];
    if (entityType === EntityTypeEnum.ROOT_ENTITY) {
      accessGroups.push(generatedId);
    }
    if (entityType === EntityTypeEnum.CHILD_ENTITY) {
      accessGroups.push(parentId);
      accessGroups.push(generatedId);
    }
    const participantGroup = [this.getParticipantGroup()];
    return {
      id: generatedId,
      name: '',
      entityType,
      scores: {
        target: 9.1,
        collection: 0,
        remediation: 0,
        notApplicable: false,
        total: 0,
        criticalGaps: 0,
        totalGaps: 0,
        totalQuestions: 0,
        completedQuestions: 0,
      },
      timeline: {
        initiationDate: now,
        collectionDate: null,
      },
      defaultSetting: {
        frequency: FrequencyCheckEnum.ANNUAL,
        surveyLevel: ImpactTextEnum.HIGH,
        accessLevel: AccessLevelEnum.PHYSICAL,
      },
      createdAt: new Date().getTime(),
      modifiedAt: new Date().getTime(),
      accessGroups,
      participantGroup,
      projectManager,
      activeAssessmentId: 'null',
      domain: DomainFrameworkService.getDomain(),
      parentId: entityType === EntityTypeEnum.CHILD_ENTITY ? parentId : undefined,
      rootEntityId: entityType === EntityTypeEnum.CHILD_ENTITY ? parentId : undefined,
      childEntityId: entityType === EntityTypeEnum.CHILD_ENTITY ? generatedId : undefined,
      defaultQuestionSettings: {
        isApprovalRequired: false,
        isArtifactsMandatory: false,
        isCollaborative: true,
        isCommentsMandatory: false,
      },
    };
  }

  // convert StandardEnum to RequiredStandard type
  initStandardList(): any[] {
    return [
      Object.entries(this.StandardEnum).map(([key, name]) => {
        return {
          key,
          name,
          type: StandardType.COMPLIANCE_FRAMEWORK,
          checked: false,
        };
      }),
    ].flat();
  }

  // convert Add industry in standardList with help of csv file
  public async initStandardListWithIndustry(sortColumn = null): Promise<RequiredStandardIndustry[]> {
    await this.fileService.init();
    this.StandardEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.STANDARD_ENUM);
    const standardList = this.initStandardList();
    // Fetch standards and industries csv file from bucket
    const filePath = `${FileTypeEnum.INDUSTRY_FRAMEWORKS}/standards_n_industries.csv`;
    const options = { contentType: 'text/csv', level: 'public' };
    const url = await this.fileService.getFileUrlFromBucket(filePath, options);
    const response = await fetch(url);
    this.standardsNIndustries = this.fileService.convertCSVDataToJSON(await response.text());

    let list: RequiredStandardIndustry[] = standardList.map(standard => {
      const foundedStandardObj = this.standardsNIndustries.find(sNi => {
        return (
          sNi.standards_name.replace(/[\s-]/g, '_') === standard.key &&
          (sNi.category || '').toLowerCase() === 'compliance' // check if category type should be compliance
        );
      });

      return {
        ...standard,
        industry: foundedStandardObj && foundedStandardObj.industry ? foundedStandardObj.industry : null,
      };
    });

    if (sortColumn) {
      // Sort by label which is provided in parameter
      list = ObjectsSort(list, sortColumn);
    }
    return list;
  }

  initIndustryFrameworkMap = () => {
    if (this.standardsNIndustries && this.standardsNIndustries.length) {
      this.industryFrameworkMap = this.standardsNIndustries.reduce((acc, standard) => {
        if (
          standard &&
          standard.category &&
          standard.category.toLowerCase() === 'compliance' &&
          standard.standards_name &&
          standard.industry
        ) {
          const regex = new RegExp(/[\s-]/g);
          const [key, industry] = [
            standard.standards_name.replace(regex, '_').toLowerCase(),
            standard.industry.replace(regex, '_').toLowerCase(),
          ];

          acc[industry] = acc[industry] && acc[industry].length ? acc[industry] : [];
          acc[industry].push(key);
        }
        return acc;
      }, {});
    }
    return this.industryFrameworkMap;
  };

  public async setLogoUrl(entity: GetEntityQuery): Promise<GetEntityQueryExtended> {
    let logoUrl = '';
    if (entity.logo) {
      logoUrl = (await this.fileService.downloadFromS3(entity.logo)) as string;
    }

    return {
      ...entity,
      logoUrl,
    };
  }

  public async createAssessmentStandard(
    entity: any,
    isArtifactsMandatory,
    isCommentsMandatory,
    frameworkReviewers: any[] = [],
    requiredStandard?: RequiredStandard[],
    selectedChapter?: string[]
  ) {
    const assessmentStandardFramework: any = await this.createAssessmentStandardFramework(
      entity.activeAssessmentId,
      entity.timeline,
      entity.projectManager,
      requiredStandard,
      selectedChapter,
      isArtifactsMandatory,
      isCommentsMandatory,
      {
        ...entity.defaultQuestionSettings,
        isCommentsMandatory: isCommentsMandatory === 'all' ? true : false,
        isArtifactsMandatory: isArtifactsMandatory === 'all' ? true : false,
      },
      frameworkReviewers
    );
    return assessmentStandardFramework;
  }
  public async createEntity(
    entity: CreateEntityInput,
    requiredStandard?: RequiredStandard[],
    newAddedUsers?: CreateUserInput[],
    rootEntityId?: string,
    selectedChapter?: string[],
    frameworkReviewers: any[] = [],
    needToCreateAssignments?: boolean,
    isFirstParty: boolean = false
  ): Promise<CreateEntityMutation> {
    try {
      await this.createAndAddUserGroup(entity);

      const isArtifactsMandatory: any = entity?.defaultQuestionSettings?.isArtifactsMandatory;
      const isCommentsMandatory: any = entity?.defaultQuestionSettings?.isCommentsMandatory;

      let createdEntity: CreateEntityMutation;
      let standardFrameworkList: [];
      if (entity.entityType === EntityTypeEnum.CHILD_ENTITY || entity.entityType === EntityTypeEnum.VENDOR) {
        const assessment: any = await this.createAssessment(
          entity.rootEntityId,
          entity.childEntityId,
          entity.timeline,
          entity.projectManager,
          frameworkReviewers
        );
        entity.activeAssessmentId = assessment.id;

        createdEntity = await this.customApi.CreateEntity(entity);

        standardFrameworkList = await this.createAssessmentStandard(
          entity,
          isArtifactsMandatory,
          isCommentsMandatory,
          frameworkReviewers,
          requiredStandard,
          selectedChapter
        );
      } else {
        standardFrameworkList = await this.createAssessmentStandard(
          entity,
          isArtifactsMandatory,
          isCommentsMandatory,
          frameworkReviewers,
          requiredStandard,
          selectedChapter
        );
        createdEntity = await this.customApi.CreateEntity(entity);
      }
      if (entity.entityType === EntityTypeEnum.CHILD_ENTITY || entity.entityType === EntityTypeEnum.VENDOR) {
        createdEntity.activeAssessment.standardFrameworkList.items = standardFrameworkList;
        delete this.fetchEntityPromises[entity.rootEntityId];
        // need to update the local cache and promise.
        if (!this.subEntitiesListByParent[entity.rootEntityId]?.length) {
          this.subEntitiesListByParent[entity.rootEntityId] = [createdEntity];
        } else {
          this.subEntitiesListByParent[entity.rootEntityId].push(createdEntity);
        }
      }
      await this.afterCreateEntity(createdEntity, frameworkReviewers, needToCreateAssignments, isFirstParty);
      // /////////////////////////////////////////
      // fetching roleId of sub-entity leader
      const roles: any = await this.customApi.ListRoles();
      let roleId: any;
      let rootEntity: any;
      if (rootEntityId) {
        rootEntity = await this.getEntity(rootEntityId);
      }

      if (this.isCRB && rootEntity?.name?.toLowerCase()?.includes('mpl')) {
        roleId = roles.items.filter((role: any) => role.name.toLowerCase() === AppLevelRoleEnum.PARTICIPANT);
      } else {
        roleId = roles.items.filter((role: any) => role.name.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER);
      }
      // Add user here, for normal vendor flow, it will be empty
      if (newAddedUsers) {
        const users = await this.createOrUpdateUsers(newAddedUsers, roleId[0].id, createdEntity.id, rootEntityId);
        // ////////////////////////////////////////
        // Create assignments for that user
        if (users) {
          users.forEach(async (user: any) => {
            const assignment = {
              userId: user.id,
              targetId: createdEntity.activeAssessment.id,
              assessmentId: createdEntity.activeAssessment.id,
              left: 890000,
              right: 890599,
            };
            await this.customApi.CreateAssignment(assignment);
          });
        }
      }
      return createdEntity;
    } catch (e) {
      this.logger.error('createEntity - Error: ', e);
      return Promise.reject(e);
    }
  }
  async createOrUpdateUsers(users, roleId, entityId, rootEntity): Promise<any> {
    const allUsers: Promise<any>[] = [];
    users.forEach((newUser: any) => {
      if (newUser.id) {
        allUsers.push(this.updateUser(newUser, roleId, entityId));
        // this.toastr.success(`User+ ${pluralize(users.length)} updated successfully!`);
      } else {
        allUsers.push(this.addUser(newUser, roleId, entityId, rootEntity));
        // this.toastr.success(`User+ ${pluralize(users.length)} added successfully!`);
      }
    });
    return await Promise.all(allUsers);
  }
  async addUser(newUser: CreateUserInput, roleId, entityId, rootEntity): Promise<CreateUserInput> {
    if (newUser) {
      const user: CreateUserInput = {
        name: newUser.name,
        email: newUser.email,
        entityIds: [entityId],
        roleId,
        isViewOnly: false,
        entityId: rootEntity,
      };
      try {
        const cuser = await this.addNewParticipant(user);
        const tempUser = JSON.parse(cuser);
        this.toastr.success('User added successfully!');
        return await tempUser;
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }
  async addNewParticipant(newUser): Promise<any> {
    this.logger.log('user = ', newUser);
    const inputUser = JSON.stringify(newUser);
    const domain = EntityService.getAdminGroup();
    return await this.customApi.AddCognitoUser({
      inputUser,
      domain,
    });
  }
  async updateUser(cuser: UpdateUserInput, roleId, entityId): Promise<UpdateUserInput> {
    if (cuser) {
      const user: UpdateUserInput = {
        id: cuser.id,
        name: cuser.name,
        email: cuser.email,
        entityIds: [entityId],
        roleId,
        isViewOnly: false,
      };
      try {
        this.toastr.info('updating user...');
        // Updating user via API
        const updatedUser: UpdateUserInput = (await this.customApi.UpdateUser(user)) as any;
        this.toastr.success('User updated successfully!');
        return updatedUser;
      } catch (e) {
        const message = UtilsService.msgFromError(e);
        this.toastr.error(message);
      }
    }
  }

  async updateUserUsingLambda(user) {
    try {
      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      user = {
        userRole: (await this.getCurrentUser())?.role,
        userDetail: user,
      };
      const CryptoJS = require('crypto-js');
      const encyptedData = CryptoJS.AES.encrypt(JSON.stringify(user), 'cygovSecretKey2023').toString();
      const encyptedUserData = JSON.stringify(encyptedData);
      const params = {
        FunctionName: `userAuthorizer-${environment.name}`,
        Payload: encyptedUserData,
      };
      const response = await lambda.invoke(params).promise();
      let resp;
      if (response && response.Payload) {
        resp = JSON.parse(JSON.stringify(response.Payload));
        if (JSON.parse(resp).statusCode === 200) {
          this.toastr.success('User Updated');
        }
      }
      return JSON.parse(resp) || response;
    } catch (e) {
      this.toastr.error('Error while User Updated');
      console.log('Error - userAuthMiddleware');
    }
  }

  public async createAssessment(
    rootId: string,
    childId: string,
    timeline: TimelineInput,
    projectManager: string,
    frameworkReviewers?: any
  ) {
    const assessmentId = UtilsService.isCRB ? `${uuid()}#CRB` : uuid();

    const managers = frameworkReviewers.length
      ? frameworkReviewers.flatMap(reviewer => reviewer.id)
      : projectManager
      ? [projectManager]
      : [];

    const createAssessmentInput: CreateAssessmentInput = {
      id: assessmentId,
      rootId,
      childId,
      timeline,
      scores: {
        target: 9.1,
        collection: 0,
        remediation: 0,
        notApplicable: false,
        total: 0,
        criticalGaps: 0,
        totalGaps: 0,
        totalQuestions: 0,
        completedQuestions: 0,
      },
      managers,
    };
    const createdAssessment: CreateAssessmentMutation = await this.customApi.CreateAssessment(createAssessmentInput);
    return createdAssessment;
  }

  public async createAssessmentStandardFramework(
    assessmentId: string,
    timeline: TimelineInput,
    projectManager: string,
    requiredStandard: RequiredStandard[],
    selectedChapter?: string[],
    isArtifactsMandatory: any = null,
    isCommentsMandatory: any = null,
    questionSettings?: any,
    frameworkReviewers?: any
  ): Promise<CreateAssessmentStandardFrameworkMutation[]> {
    if (!timeline.initiationDate || (timeline.initiationDate && timeline.initiationDate <= 0)) {
      // for any case the old UI is used for creating sub-entity or vendor,
      // which doesn't have a start calendar to select date.
      // make the initiation date the creation date of that framework.
      const initiationDate = UtilsService.getDateInNgbDateStructFormat(Date.now());
      const initiationDateStamp = UtilsService.getDateFromNgbDateStruct(initiationDate).getTime();
      timeline.initiationDate = initiationDateStamp;
    }

    let createdFramework;

    this.toastr.info('Adding Frameworks into Assessment... ');
    const assessmentStandardFrameworks: CreateAssessmentStandardFrameworkMutation[] = [];
    //* flag for Mid market and Beecher for Mandatory comments and artifacts
    const isMidMarketOrBeecher = UtilsService.isMidMarket || UtilsService.isBnBCyberSite;
    for (const standard of requiredStandard) {
      const framework: CreateAssessmentStandardFrameworkInput = {
        key: standard.key,
        type: standard.type,
        assessmentId,
        managerId: projectManager,
        timeline,
        selectedChapters: selectedChapter && selectedChapter.length ? selectedChapter : null,
        id: `${assessmentId}#${standard.key}`,
        suggestedArtifact: isMidMarketOrBeecher ? 'suggested' : isArtifactsMandatory, // passing hardcoded values for
        suggestedComments: isMidMarketOrBeecher ? 'suggested' : isCommentsMandatory, //  mid-market & beecher as there is no ui to change it
        defaultQuestionSettings: questionSettings,
      };
      if (!!standard.fileName && standard.fileName !== null) {
        framework.fileName = standard.fileName;
      }
      if (!!standard.filter && standard.filter !== null) {
        framework.filter = standard.filter;
      }

      createdFramework = await this.customApi.CreateAssessmentStandardFramework(framework);

      /*
        Added delay after each framework creation
        because Controls.zip was missing framework due to parallel creation
      */
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.toastr.info(`Added ${createdFramework.key.replace(/_/g, ' ')} successfully.`);
      assessmentStandardFrameworks.push(createdFramework);
    }

    // First time projectManager will be empty, because in user we need entityId, so first create entity
    // and then add projectmanager
    if (frameworkReviewers.length) {
      const frameworksManagerList = assessmentStandardFrameworks.flatMap(framework => {
        const frameworkRange = this.getFrameworkRange(framework.key);
        return frameworkReviewers.map(reviewer => ({
          assessmentId: framework.assessmentId,
          standardFrameworkId: framework.id,
          managerId: reviewer.id,
          left: frameworkRange.left,
          right: frameworkRange.right,
          id: `${framework.id}#${reviewer.id}`,
        }));
      });

      await Promise.all(frameworksManagerList.map(item => this.customApi.CreateFrameworkManager(item)));
    }

    return assessmentStandardFrameworks;
  }

  public async createAssessmentFramework(
    assessmentId: string,
    projectManagerId: string,
    frameworkDetails: any,
    questionSettings: QuestionSettings,
    timeline: TimelineInput,
    selectedManagers?: any,
    needToCreateAssignment?: boolean
  ): Promise<CreateAssessmentStandardFrameworkMutation> {
    try {
      const framework: CreateAssessmentStandardFrameworkInput = {
        key: frameworkDetails?.framework.key,
        type: frameworkDetails?.framework.type,
        assessmentId,
        managerId: projectManagerId,
        defaultQuestionSettings: {
          ...questionSettings,
          isCommentsMandatory: questionSettings?.isCommentsMandatory === 'all' ? true : false,
          isArtifactsMandatory: questionSettings?.isArtifactsMandatory === 'all' ? true : false,
        },
        timeline,
        id: `${assessmentId}#${frameworkDetails?.framework.key}`,
        documentCategory: frameworkDetails?.framework?.documentCategory
          ? frameworkDetails?.framework?.documentCategory
          : null,
        documentType: frameworkDetails?.framework?.documentType ? frameworkDetails?.framework?.documentType : null,
        level: frameworkDetails?.framework?.level ? frameworkDetails?.framework?.level : 'FULL',
        suggestedArtifact: frameworkDetails?.suggestedArtifact,
        suggestedComments: frameworkDetails?.suggestedComments,
        // reassessmentStatus: ReassessmentEnum.NOT_INITIATED,
        // Commenting this as this call is related to adding framework not reassessment
      };
      if (UtilsService.isDefined(frameworkDetails?.framework.fileName)) {
        framework.fileName = frameworkDetails?.framework.fileName;
      }
      if (UtilsService.isDefined(frameworkDetails?.framework?.filter)) {
        framework.filter = frameworkDetails?.framework.filter;
      }

      const createdFramework = await this.customApi.CreateAssessmentStandardFramework(framework);
      // wait for 5 seconds, so AssessmentStandardFrameworkTrigger completes and create frameworks in s3
      // then we run assignFramework func, which use assignmentTrigger and then calcAssessmentScore.
      // otherwise we get error in calcAssess fn, that file not exist in s3
      // need to update the local cache and promise.
      if (this.subEntitiesListByParent[createdFramework?.assessment?.rootId]) {
        const foundIndex = this.subEntitiesListByParent[createdFramework?.assessment?.rootId]?.findIndex(
          ent => ent.id === createdFramework?.assessment?.childId
        );
        if (foundIndex > -1) {
          this.subEntitiesListByParent[createdFramework?.assessment?.rootId][
            foundIndex
          ]?.activeAssessment?.standardFrameworkList?.items.push(createdFramework);
          delete this.fetchEntityPromises[createdFramework?.assessment?.rootId];
        }
      }
      if (needToCreateAssignment) {
        return new Promise((resolve, reject) =>
          setTimeout(() => {
            try {
              // this case will work for the first party
              if (this.router.url.includes('first-party')) {
                if (selectedManagers.length > 0) {
                  this.assignFrameworkToReviewers(frameworkDetails?.framework.key, assessmentId, selectedManagers);
                } else if (projectManagerId) {
                  this.assignFramework(frameworkDetails?.framework.key, assessmentId, projectManagerId);
                }
              }
              resolve(createdFramework);
            } catch (err) {
              console.log('createAssessmentFramework -> Error ==>', err);
              reject(null);
            }
          }, 5000)
        );
      } else {
        return createdFramework;
      }
    } catch (error) {
      console.log('error', error);
      return error;
    }
  }

  public async updateAssessmentFramework(
    assessmentId: string,
    projectManagerId: string,
    frameworkDetails: any,
    timeline: TimelineInput,
    not_added: boolean,
    questionSettings: QuestionSettings,
    frameworkReviewers: any
  ): Promise<UpdateAssessmentStandardFrameworkMutation> {
    const framework: UpdateAssessmentStandardFrameworkInput = {
      key: frameworkDetails.framework.key,
      type: frameworkDetails.framework.type,
      assessmentId,
      managerId: projectManagerId,
      timeline,
      archived: false,
      id: `${assessmentId}#${frameworkDetails.framework.key}`,
      not_added,
      defaultQuestionSettings: {
        ...questionSettings,
        isCommentsMandatory: questionSettings.isCommentsMandatory === 'all' ? true : false,
        isArtifactsMandatory: questionSettings.isArtifactsMandatory === 'all' ? true : false,
      },
      suggestedArtifact: frameworkDetails?.suggestedArtifact,
      suggestedComments: frameworkDetails?.suggestedComments,
    };
    if (UtilsService.isDefined(frameworkDetails.framework.fileName)) {
      framework.fileName = frameworkDetails.framework.fileName;
    }
    if (UtilsService.isDefined(frameworkDetails.framework.filter)) {
      framework.filter = frameworkDetails.framework.filter;
    }
    const updatedFramework = await this.customApi.UpdateAssessmentStandardFramework(framework);

    if (frameworkReviewers.length > 0) {
      await this.assignFrameworkToReviewers(frameworkDetails.framework.key, assessmentId, frameworkReviewers);
    }

    if (this.subEntitiesListByParent[updatedFramework?.assessment?.rootId]) {
      const foundIndex = this.subEntitiesListByParent[updatedFramework?.assessment?.rootId]?.findIndex(
        ent => ent.id === updatedFramework?.assessment?.childId
      );
      if (foundIndex > -1) {
        const fIndex = this.subEntitiesListByParent[updatedFramework?.assessment?.rootId][
          foundIndex
        ]?.activeAssessment?.standardFrameworkList?.items?.findIndex(fr => fr.id === updatedFramework.id);
        if (fIndex > -1) {
          this.subEntitiesListByParent[updatedFramework?.assessment?.rootId][
            foundIndex
          ].activeAssessment.standardFrameworkList.items[fIndex] = updatedFramework;
        }
        delete this.fetchEntityPromises[updatedFramework?.assessment?.rootId];
      }
    }

    return updatedFramework;
  }

  // public async getEntity(entityId: string, fromCache = true): Promise<GetEntityQuery> {
  //   try {
  //     if (!entityId) {
  //       this.logger.error('getEntity - Error: entityId is required');
  //       return Promise.reject('getEntity - Error: entityId is required');
  //     }
  //     if (UtilsService.isIdFake(entityId)) {
  //       entityId = entityId.substring(1);
  //     }

  //     // Data cache mechanism
  //     let entity: GetEntityQuery = null;
  //     if (fromCache && EntityService.entityMap[entityId]) {
  //       entity = EntityService.entityMap[entityId];
  //     }

  //     if (fromCache && entity) {
  //       return Promise.resolve(entity);
  //     }

  //     // Promises cache mechanism
  //     let queryProm: Promise<GetEntityQuery>;
  //     if (fromCache && EntityService.promCache && EntityService.promCache[entityId]) {
  //       // this.logger.log('EntityService.getEntity() returned from cache');
  //       queryProm = EntityService.promCache[entityId];
  //     } else {
  //       console.log('entity service 838');
  //       queryProm = this.customApi.GetEntity(entityId);
  //       EntityService.promCache[entityId] = queryProm;
  //       // this.logger.log('EntityService.getEntity() returned (not from cache)');
  //     }

  //     try {
  //       entity = await queryProm;

  //       // update cache map
  //       if (entity) {
  //         EntityService.entityMap[entity.id] = entity;
  //       }

  //       return entity;
  //     } catch (e) {
  //       this.logger.error('getEntity - Error: ', e);
  //       // return Promise.reject(e);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.log('Error in getEntity ===> ', error);
  //     return null;
  //   }
  // }

  public async getEntity(entityId: string, fromCache = true, entityObj?: any): Promise<GetEntityQuery> {
    try {
      if (!entityId) {
        this.logger.error('getEntity - Error: entityId is required');
        return Promise.reject('getEntity - Error: entityId is required');
      }
      if (UtilsService.isIdFake(entityId)) {
        entityId = entityId.substring(1);
      }

      // Data cache mechanism
      let entity = null;
      if (fromCache && EntityService.entityMap[entityId]) {
        entity = EntityService.entityMap[entityId];
      }

      if (fromCache && entity) {
        return Promise.resolve(entity);
      }

      // Promises cache mechanism
      let queryProm: Promise<GetEntityQuery>;
      if (fromCache && EntityService.promCache && EntityService.promCache[entityId]) {
        // this.logger.log('EntityService.getEntity() returned from cache');
        queryProm = EntityService.promCache[entityId];
      } else {
        queryProm = entityObj ? entityObj : await this.customApi.GetEntity(entityId);
        EntityService.promCache[entityId] = queryProm;
        // this.logger.log('EntityService.getEntity() returned (not from cache)');
      }

      try {
        entity = queryProm;

        // update cache map
        if (entity) {
          EntityService.entityMap[entity.id] = entity;
        }

        return entity;
      } catch (e) {
        this.logger.error('getEntity - Error: ', e);
        // return Promise.reject(e);
        return null;
      }
    } catch (error) {
      console.log('Error in getEntity ===> ', error);
      return null;
    }
  }

  updateCacheEntity(framework) {
    const entity = EntityService.entityMap[framework.childId];
    if (entity) {
      entity.activeAssessment.standardFrameworkList.items =
        entity?.activeAssessment?.standardFrameworkList?.items.filter(
          item => item.key !== framework.key || item.assessmentId !== framework.assessmentId
        );
      EntityService.entityMap[framework.rootId] = entity;
    }
  }

  public async updateEntity(entity: UpdateEntityInput): Promise<UpdateEntityMutation> {
    try {
      const updatedEntity: UpdateEntityMutation = await this.customApi.UpdateEntity(entity);
      const entityUpdatedWithLogo = await this.setLogoUrl(updatedEntity);
      return entityUpdatedWithLogo;
    } catch (e) {
      this.logger.error('updateEntity - Error: ', e);
      return Promise.reject(e);
    }
  }

  public async deleteEntity(entityId: string): Promise<void> {
    try {
      await this.customApi.DeleteEntity({ id: entityId });
    } catch (e) {
      this.logger.error('deleteEntity - Error: ', e);
      return Promise.reject(e);
    }
  }

  async listEntitys(filter: ModelEntityFilterInput, fromCache: boolean = true): Promise<GetEntityQuery[]> {
    if (!filter) {
      this.logger.error('listEntitys - Error: filter is required');
      return Promise.reject('listEntitys - Error: filter is required');
    }

    console.log(fromCache);
    // console.clear();

    let listEntitys: GetEntityQuery[] = null;
    // if (fromCache && EntityService.entityMap[JSON.stringify(filter)]) {
    //   listEntitys = EntityService.entityMap[JSON.stringify(filter)];
    // }

    // if (fromCache && listEntitys && listEntitys.length) {
    //   return Promise.resolve(listEntitys);
    // }

    try {
      let result = await this.customApi.ListEntities(filter, environment.queryListLimit);

      listEntitys = result.items as any;

      while (result.nextToken) {
        result = await this.customApi.ListEntities(filter, environment.queryListLimit, result.nextToken);
        listEntitys = listEntitys.concat(result.items as any);
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(entity => (EntityService.entityMap[entity.id] = entity));

      const promises = [];
      if (filter.entityType && filter.entityType.eq === EntityTypeEnum.CHILD_ENTITY) {
        listEntitys.forEach(({ activeAssessment }) => {
          activeAssessment &&
            activeAssessment.standardFrameworkList.items.forEach(({ key }) => {
              promises.push(this.frameworkTreeService.getFrameworkTreeFromS3(key));
            });
        });
      }
      await Promise.all(promises);
      return listEntitys;
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  static sortObjectsArray(objectsArray, order: string = 'ASC'): Promise<any> {
    try {
      if (order === 'ASC') {
        objectsArray = objectsArray.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
      } else {
        objectsArray = objectsArray.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
      }
      return objectsArray;
    } catch (e) {
      console.log('Error occur while parsing objects array', e);
    }
  }

  async listEntitysByEntityType(
    entityType?: EntityTypeEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    fetchLogo: boolean = false
  ): Promise<GetEntityQueryExtended[]> {
    const domain = DomainFrameworkService.getDomain();

    let listEntitys: GetEntityQuery[] = null;

    try {
      let result = await this.customApi.EntitiesByDomainAndEntityType(
        domain,
        { eq: entityType },
        sortDirection,
        filter,
        environment.queryListLimit
      );

      listEntitys = result.items as any;

      while (result.nextToken) {
        result = await this.customApi.EntitiesByDomainAndEntityType(
          domain,
          { eq: entityType },
          sortDirection,
          filter,
          environment.queryListLimit,
          result.nextToken
        );
        listEntitys = listEntitys.concat(result.items as any);
      }

      if (fetchLogo) {
        const promises = listEntitys.map(entity => this.setLogoUrl(entity));
        return Promise.all(promises);
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(entity => (EntityService.entityMap[entity.id] = entity));
      return listEntitys;
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  getThirdPartySmartTags(entityId: string): Set<string> | undefined {
    const tags = this.thirdPartySmartTagsSubject.getValue();
    return tags[entityId];
  }

  updateThirdPartySmartTags(entityId: string, tags: Set<string>) {
    const currentTags = this.thirdPartySmartTagsSubject.getValue();
    currentTags[entityId] = tags;
    this.thirdPartySmartTagsSubject.next(currentTags);
  }

  async listEntityByDomainAlongToken(
    sortDirection: ModelSortDirection = ModelSortDirection.ASC,
    filter?: ModelEntityFilterInput,
    fetchLogo: boolean = false,
    nextToken: string = null,
    searching: boolean = false,
    limit: number = 17
  ): Promise<[GetEntityQueryExtended[], string]> {
    const domain = DomainFrameworkService.getDomain();

    let listEntitys: GetEntityQuery[] = null;
    const entityType = filter?.entityType;
    try {
      if (searching) {
        // for searching set limit to default which is 1000
        limit = environment.queryListLimit;
        delete filter?.entityType;
      }

      let result;

      if ((nextToken && nextToken.toLowerCase() === 'start') || searching) {
        // first time this will run, second time when token exist run above  OR for searching
        result = await this.customApi.EntitiesByDomainAndEntityType(
          domain,
          entityType,
          sortDirection,
          searching ? filter : null,
          limit
        );
      } else if (nextToken) {
        result = await this.customApi.EntitiesByDomainAndEntityType(
          domain,
          entityType,
          sortDirection,
          searching ? filter : null,
          limit,
          nextToken
        );
      }

      listEntitys = result && result.items?.length ? result.items : [];
      // if listEntitys has data or listEntity is empty but we have nextToken,
      // which means in limit 17, root_entity type entity is not present because
      // we are using filter and filter applied after indexing. also check nextToken should not be empty
      if (listEntitys.length || (!listEntitys.length && result.nextToken)) {
        while (result.nextToken && (listEntitys.length < limit || searching)) {
          result = await this.customApi.EntitiesByDomainAndEntityType(
            domain,
            entityType,
            sortDirection,
            searching ? filter : null,
            limit,
            result.nextToken
          );
          listEntitys = listEntitys.concat(result.items);
        }
      }
      const newNextToken = result?.nextToken;
      if (fetchLogo) {
        const promises = listEntitys.map(entity => this.setLogoUrl(entity));
        const entities = await Promise.all(promises);
        return [entities, newNextToken];
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(entity => (EntityService.entityMap[entity.id] = entity));
      return [listEntitys, newNextToken];
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  async listEntitysByEntityId(
    entityId: string,
    isRoot: boolean,
    filter?: ModelEntityFilterInput
  ): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      const apiQuery = isRoot ? this.customApi.EntityRootEntityByType : this.customApi.EntityChildEntityByType;
      do {
        const entityResponse: EntityParentQuery = await apiQuery(
          entityId,
          null,
          null,
          filter,
          environment.queryListLimit,
          nextToken
        );
        entityList = entityList.concat(entityResponse.items as any);
        nextToken = entityResponse.nextToken;
      } while (nextToken);
      return entityList;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async listChildEntitysByParentId(
    parentId: string,
    type = EntityTypeEnum.CHILD_ENTITY,
    filter?: ModelEntityFilterInput
  ): Promise<GetEntityQuery[]> {
    try {
      // eslint-disable-next-line
      if (!this.fetchEntityPromises[parentId]) {
        this.fetchEntityPromises[parentId] = this.fetchEntities(parentId, type, filter);
      }
      return await this.fetchEntityPromises[parentId];
    } catch (e) {
      return Promise.reject(e);
    }
  }

  setFetchEntityPromiseArray(parentId, newArray) {
    this.fetchEntityPromises[parentId] = JSON.parse(JSON.stringify(newArray));
  }

  async fetchEntities(
    parentId: string,
    type: EntityTypeEnum,
    filter?: ModelEntityFilterInput
  ): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      const childFilter: ModelEntityFilterInput = {
        entityType: { eq: type },
      };
      filter = { ...filter, ...childFilter };
      if (!this.subEntitiesListByParent[parentId] || !this.subEntitiesListByParent[parentId].length) {
        do {
          const entityResponse: EntityParentQuery = await this.customApi.EntityParent(
            parentId,
            null,
            filter,
            environment.queryListLimit,
            nextToken
          );
          entityList = entityList.concat(entityResponse.items as any);
          nextToken = entityResponse.nextToken;
        } while (nextToken);
        this.subEntitiesListByParent[parentId] = entityList?.length ? entityList : [];
        if (entityList?.length) {
          entityList.forEach(ent => {
            if (!EntityService.promCache[ent.id]) {
              EntityService.promCache[ent.id] = ent;
            }
          });
        }
      }
      return await this.filterEntitiesBasedOnRole(
        [...this.subEntitiesListByParent[parentId]],
        AppLevelRoleEnum.SUBENTITY_LEADER
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async listEntitiesByParentId(parentId: string, filter?: ModelEntityFilterInput): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      const childFilter: ModelEntityFilterInput = {
        entityType: { eq: EntityTypeEnum.CHILD_ENTITY },
      };
      filter = { ...filter, ...childFilter };
      if (!this.subEntitiesListByParent[parentId] || !this.subEntitiesListByParent[parentId].length) {
        do {
          const entityResponse: EntityParentQuery = await this.customApi.EntitiesByParent(
            parentId,
            null,
            filter,
            environment.queryListLimit,
            nextToken
          );
          entityList = entityList.concat(entityResponse.items as any);
          nextToken = entityResponse.nextToken;
        } while (nextToken);
        this.subEntitiesListByParent[parentId] = entityList?.length ? entityList : [];
      }
      return await this.filterEntitiesBasedOnRole(
        [...this.subEntitiesListByParent[parentId]],
        AppLevelRoleEnum.SUBENTITY_LEADER
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }

  // * Functions filter and returns entity list based on role sent in paramaters
  async filterEntitiesBasedOnRole(entityList: GetEntityQuery[], roleForFilter: string): Promise<GetEntityQuery[]> {
    let subEntityLeaderRoleId;
    try {
      this.allRoles = this.defaultRoles?.length ? this.defaultRoles : await this.getDefaultRoles();
      //* get all role ID for specific role
      const subEntityLeaderRole = this.allRoles.find((role: any) => role.name.toLowerCase() === roleForFilter);
      subEntityLeaderRoleId = subEntityLeaderRole ? subEntityLeaderRole.id : null;
    } catch (e) {
      return Promise.reject(e);
    }

    //* flter entities that are in current user's entities
    if (this.user?.roleId === subEntityLeaderRoleId && this.user?.entityIds) {
      const currentUserEntityIds = this.user.entityIds;
      return entityList.filter(
        entity =>
          currentUserEntityIds.includes(entity.id) && entity?.activeAssessment?.standardFrameworkList?.items?.length
      );
    } else {
      return entityList.filter(entity => entity?.activeAssessment?.standardFrameworkList?.items?.length);
    }
  }

  async listEntitysByParentId(parentId: string, filter?: ModelEntityFilterInput): Promise<GetEntityQuery[]> {
    try {
      let entityList: GetEntityQuery[] = [];
      let nextToken = null;
      // We Get All Entities w.r.t current domain
      const domain = DomainFrameworkService.getDomain();
      if (filter) {
        filter.domain = { eq: domain };
      } else {
        filter = { domain: { eq: domain } };
      }
      do {
        const entityResponse: EntityParentQuery = await this.customApi.EntityParent(
          parentId,
          null,
          filter,
          environment.queryListLimit,
          nextToken
        );
        entityList = entityList.concat(entityResponse.items as any);
        nextToken = entityResponse.nextToken;
      } while (nextToken);
      return entityList;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async listEntitysByFrameworkType(
    entityId?: string,
    frameworkType?: ModelStringKeyConditionInput,
    isRootEntity = true,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    fromCache = true
  ): Promise<GetEntityQuery[]> {
    if (!entityId) {
      this.logger.error('listEntitysByFrameworkType - Error: entityId is required');
      return Promise.reject('listEntitysByFrameworkType - Error: filter is required');
    }

    console.log(fromCache);
    // console.clear();

    let listEntitys: GetEntityQuery[] = null;
    // if (fromCache && EntityService.entityMap[JSON.stringify(filter)]) {
    //   listEntitys = EntityService.entityMap[JSON.stringify(filter)];
    // }

    // if (fromCache && listEntitys && listEntitys.length) {
    //   return Promise.resolve(listEntitys);
    // }

    try {
      const fetchEntitiesFunction = isRootEntity
        ? this.customApi.EntityRootEntityByType
        : this.customApi.EntityChildEntityByType;
      let result = await fetchEntitiesFunction(
        entityId,
        frameworkType,
        sortDirection,
        filter,
        environment.queryListLimit
      );

      listEntitys = [...result.items] as GetEntityQuery[];

      while (result.nextToken) {
        result = await fetchEntitiesFunction(
          entityId,
          frameworkType,
          (sortDirection = ModelSortDirection.ASC),
          filter,
          environment.queryListLimit,
          result.nextToken
        );
        const cacheItems = result.items as GetEntityQuery[];
        listEntitys = [...listEntitys, ...cacheItems];
      }

      this.logger.log('EntityService.listEntitys() returned (not from cache)');

      // update cache map
      // EntityService.entityMap[JSON.stringify(filter)] = listEntitys;
      listEntitys.forEach(
        entity =>
          (EntityService.entityMap[entity.id] = entity) &&
          entity?.activeAssessment?.standardFrameworkList?.items?.length
      );

      return listEntitys;
    } catch (e) {
      this.logger.error('listEntitys - Error: ', e);
      return Promise.reject(e);
    }
  }

  public async assignProjectManager(entityId: string, userId: string): Promise<UpdateEntityMutation> {
    const updateEntityInput: UpdateEntityInput = {
      id: entityId,
      projectManager: userId,
    };
    const response = await this.customApi.UpdateEntity(updateEntityInput);
    return response;
  }

  private async afterCreateEntity(
    createdEntity: CreateEntityMutation,
    frameworkReviewers,
    needToCreateAssignments,
    isFirstParty: boolean = false
  ): Promise<void> {
    // console.log('afterCreateEntity called', createdEntity);
    if (
      createdEntity.entityType === EntityTypeEnum.CHILD_ENTITY ||
      createdEntity.entityType === EntityTypeEnum.VENDOR
    ) {
      try {
        // First time projectManager will be empty, because in user we need entityId, so first create entity
        // and then add projectmanager
        if (
          !!createdEntity.activeAssessment &&
          !!createdEntity.projectManager &&
          !needToCreateAssignments &&
          !isFirstParty
        ) {
          await Promise.all(
            createdEntity.activeAssessment.standardFrameworkList.items.map(async framework => {
              await this.assignFramework(
                framework.fileName ? framework.fileName : framework.key,
                framework.assessmentId,
                createdEntity.projectManager
              );
            })
          );
        } else if (!!createdEntity.activeAssessment && needToCreateAssignments && isFirstParty) {
          const reviewersPromises = createdEntity.activeAssessment.standardFrameworkList.items.flatMap(framework =>
            frameworkReviewers.map(reviewer =>
              this.assignFramework(framework.fileName || framework.key, framework.assessmentId, reviewer.id)
            )
          );

          await Promise.all(reviewersPromises);
        } else if (createdEntity.activeAssessment) {
          createdEntity.activeAssessment.standardFrameworkList.items.flatMap(framework => {
            this.customApi.CalcAssessmentScore(framework.assessmentId, framework.key);
          });
        }

        if (createdEntity.id) {
          // wait for entity to add frameworks
          await new Promise(resolve => setTimeout(resolve, 4000));
          await this.generateIntegrationAnswers(createdEntity.id);
        }
      } catch (e) {
        console.log('failed to assign assessment to project manager', e);
      }
    }
  }

  /**
   * Invokes lambda (handleIntegrationAnswers) which will create
   * integrations answers ONLY if any integration had ever ran
   * before inside its root entity
   * @param  {string}    id     newly created subentity id
   * @return void
   * */
  generateIntegrationAnswers = async id => {
    try {
      const credentials = await Auth.currentCredentials();
      // fix import - build-optimization-1
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      // call to generate all connectable integrations answers.
      // lambda will check if file exists before creating answers.
      await Promise.all([
        lambda
          .invoke({
            FunctionName: `handleIntegrationAnswers-${environment.name}`,
            Payload: JSON.stringify({
              serviceType: Integration.TENABLE.toUpperCase(), // uppercase due to old flow
              subEntityId: id,
            }),
          })
          .promise(),
        lambda
          .invoke({
            FunctionName: `handleIntegrationAnswers-${environment.name}`,
            Payload: JSON.stringify({
              serviceType: Integration.CROWDSTRIKE.toUpperCase(),
              subEntityId: id,
            }),
          })
          .promise(),
        lambda
          .invoke({
            FunctionName: `handleIntegrationAnswers-${environment.name}`,
            Payload: JSON.stringify({
              serviceType: Integration.RAPID7.toUpperCase(),
              subEntityId: id,
            }),
          })
          .promise(),
        lambda
          .invoke({
            FunctionName: `handleIntegrationAnswers-${environment.name}`,
            Payload: JSON.stringify({
              serviceType: Integration.AZURE.toUpperCase(),
              subEntityId: id,
            }),
          })
          .promise(),
        lambda
          .invoke({
            FunctionName: `handleIntegrationAnswers-${environment.name}`,
            Payload: JSON.stringify({
              serviceType: Integration.MICROSOFT.toUpperCase(),
              subEntityId: id,
            }),
          })
          .promise(),
      ]);
    } catch (error) {
      console.log('error invoking handleIntegration lambda - ', error);
    }
  };

  async assignAssessment(assessmentId: string, userId: string): Promise<CreateAssignmentMutation> {
    const response = await this.customApi.CreateAssignment({
      id: uuid(),
      assessmentId,
      userId,
      targetId: assessmentId,
      left: 0,
      right: 1000000,
    });

    return response;
  }

  async assignFrameworkToReviewers(
    frameworkKey: string,
    assessmentId: string,
    reviewers: any
  ): Promise<CreateAssignmentMutation[]> {
    const frameworkRange = this.getFrameworkRange(frameworkKey.split('#')[0]);
    const promises = [];
    if (frameworkRange) {
      for (const reviewer of reviewers) {
        promises.push(
          this.customApi.CreateAssignment({
            id: uuid(),
            assessmentId,
            userId: reviewer.id,
            targetId: assessmentId,
            left: frameworkRange.left,
            right: frameworkRange.right,
          })
        );
      }

      const createdAssignments = await Promise.all(promises);

      this.assignmentCreatedEvent.emit(createdAssignments);

      return createdAssignments;
    }
  }

  async assignFramework(frameworkKey: string, assessmentId: string, userId: string): Promise<CreateAssignmentMutation> {
    const frameworkRange = this.getFrameworkRange(frameworkKey.split('#')[0]);
    if (frameworkRange) {
      const response = await this.customApi.CreateAssignment({
        id: uuid(),
        assessmentId,
        userId,
        targetId: assessmentId,
        left: frameworkRange.left,
        right: frameworkRange.right,
      });

      return response;
    }
    return {} as CreateAssignmentMutation;
  }

  getFrameworkRange(frameworkKey): any {
    return this.frameworkRangeMap.find(mapItem => mapItem.name === frameworkKey);
  }

  async getCurrentUser(): Promise<GetUserQuery> {
    try {
      // check if user authenticated or not, if not return null
      const userInfo = await Auth.currentUserInfo();
      if (!userInfo) {
        return;
      }
      this.authUser = this.authUser ? this.authUser : await Auth.currentAuthenticatedUser();
      if (this.authUser && !this.user && this.authUser.attributes) {
        const isFederatedUser =
          this.authUser.username &&
          (this.authUser.username.toLowerCase().includes(DomainBaseEnum.OKTA) ||
            this.authUser.username.toLowerCase().includes(DomainBaseEnum.AAD) ||
            this.authUser.username.toLowerCase().includes(DomainBaseEnum.SAML));
        this.user = isFederatedUser
          ? await this.queryUserByEmail(this.authUser.attributes.email.toLowerCase())
          : await this.customApi.GetUser(this.authUser.username);
      }
      if (this.user && this.user.roleId) {
        this.userRole = await this.customApi.GetRole(this.user.roleId);
      }
      return this.user;
    } catch (error) {
      console.log('error in auth user', error);
    }
  }

  async queryUserByEmail(email: string): Promise<GetUserQuery> {
    try {
      const user = (await this.customApi.UserByEmail(email.toLowerCase())).items.pop();
      return user;
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  onWizardDomainListener(): any {
    return this.customApi.OnUpdateEntityWizardListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const wizardData = res.value.data.onUpdateEntityWizard;
        if ((wizardData && !!wizardData.domainScanned) || !wizardData) {
          this.updateWizardDomainSubscriber.next(true);
        }
        if (wizardData && UtilsService.isMidMarket) {
          const obj = {
            user: this.currentUser?.onBoardingStatus?.length ? 'client' : 'admin',
            wizardData,
          };
          this.wizardDataUpdated.next(obj);
        }
      },
    });
  }

  onCreateEntityListener(): any {
    return this.customApi.OnCreateEntityListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const createdEntity: GetEntityQuery = res.value.data.onCreateEntity;
        const domain = DomainFrameworkService.getDomain();
        if (domain.trim() !== createdEntity.domain.trim() || !this.listenerMiddleware(createdEntity)) {
          return;
        }

        // this.logger.log('onCreateEntityListener - createdEntity', createdEntity);
        if (createdEntity) {
          // update cache map
          EntityService.entityMap[createdEntity.id] = createdEntity;
          // emit updated entity list
          EntityService.onEntityAdded.next(createdEntity);
        }
      },
    });
  }

  onUpdateEntityIntegrationListener(): any {
    return this.customApi.OnUpdateEntityListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const updatedEntity: GetEntityQuery = res.value.data.onUpdateEntity;
        if (UtilsService.isDefined(updatedEntity)) {
          this.updateEntityIntegrationSubscriber.next(updatedEntity);
          EntityService.onDataChanged.next(true);
        }
      },
    });
  }

  onUpdateEntityListener(): any {
    // return this.api.OnUpdateEntityListener.subscribe({
    //   next: (res: any) => {
    //     console.log('Subscription on ', res?.value?.data?.onUpdateEntity);
    //     const updatedEntity: GetEntityQuery = res.value.data.onUpdateEntity;
    //     // for preventing the call after vendor deletion
    //     if (updatedEntity.scores === null) {
    //       return;
    //     }
    //     // const entityObject = await this.getEntity(updatedEntity.id, false);
    //     const domain = DomainFrameworkService.getDomain();
    //     if (domain.trim() !== updatedEntity.domain.trim()) {
    //       return;
    //     }
    //     if (UtilsService.isDefined(updatedEntity)) {
    //       if (!this.listenerMiddleware(updatedEntity)) {
    //         return;
    //       }
    //     }
    //     // this.logger.log('onUpdateEntityListener - updatedEntity', updatedEntity);
    //     if (updatedEntity) {
    //       this.updateEntityScoreSubscriber.next(updatedEntity);
    //       // Update score in local entityMap cache
    //       if (
    //         updatedEntity &&
    //         updatedEntity.scores &&
    //         EntityService.entityMap &&
    //         EntityService.entityMap[updatedEntity.id] &&
    //         EntityService.entityMap[updatedEntity.id].scores
    //       ) {
    //         EntityService.entityMap[updatedEntity.id].scores.total = updatedEntity.scores.total || 0;
    //         EntityService.entityMap[updatedEntity.id].scores.remediation = updatedEntity.scores.remediation || 0;
    //         EntityService.entityMap[updatedEntity.id].scores.collection = updatedEntity.scores.collection || 0;
    //         EntityService.entityMap[updatedEntity.id].scores.target = updatedEntity.scores.target || 0;
    //       }
    //       this.updateVendorSubscriber.next(updatedEntity);
    //     }
    //     // emit updated entity list
    //     EntityService.onDataChanged.next(true);
    //   },
    // });
    // return this.api.OnUpdateEntityScoreListener.subscribe({
    //   next: async (res: any) => {
    //     const updatedEntity: GetEntityQuery = res.value.data.onUpdateEntity;
    //     // for preventing the call after vendor deletion
    //     if (updatedEntity.scores === null) {
    //       return;
    //     }
    //     const entityObject = await this.getEntity(updatedEntity.id, false);
    //     const domain = DomainFrameworkService.getDomain();
    //     if (domain.trim() !== entityObject.domain.trim()) {
    //       return;
    //     }
    //     if (UtilsService.isDefined(entityObject)) {
    //       if (!this.listenerMiddleware(entityObject)) {
    //         return;
    //       }
    //     }
    //     // this.logger.log('onUpdateEntityListener - updatedEntity', updatedEntity);
    //     if (updatedEntity) {
    //       this.updateEntityScoreSubscriber.next(updatedEntity);
    //       // Update score in local entityMap cache
    //       if (
    //         updatedEntity &&
    //         updatedEntity.scores &&
    //         EntityService.entityMap &&
    //         EntityService.entityMap[updatedEntity.id] &&
    //         EntityService.entityMap[updatedEntity.id].scores
    //       ) {
    //         EntityService.entityMap[updatedEntity.id].scores.total = updatedEntity.scores.total || 0;
    //         EntityService.entityMap[updatedEntity.id].scores.remediation = updatedEntity.scores.remediation || 0;
    //         EntityService.entityMap[updatedEntity.id].scores.collection = updatedEntity.scores.collection || 0;
    //         EntityService.entityMap[updatedEntity.id].scores.target = updatedEntity.scores.target || 0;
    //       }
    //       this.updateVendorSubscriber.next(updatedEntity);
    //     }
    //     // emit updated entity list
    //     EntityService.onDataChanged.next(true);
    //   },
    // });
  }
  onIntelligenceUpdate(): any {
    return this.customApi.OnUpdateIntelligenceListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        console.log('res.value.data I: ', res.value.data);
        const updatedIntelligence = res.value.data.onUpdateIntelligence;
        if (updatedIntelligence) {
          this.updateIntelligenceSubscriber.next(updatedIntelligence);
        }
      },
    });
  }
  onBreachesUpdate(): any {
    return this.customApi.OnUpdateBreachesListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        console.log('res.value.data: ', res.value.data);
        const updatedBreaches = res.value.data.onUpdateBreaches;
        if (updatedBreaches) {
          this.updateBreachesSubscriber.next(updatedBreaches);
        }
      },
    });
  }

  deleteParentObservable(rootId: string): void {
    // eslint-disable-next-line
    if (rootId && this.fetchEntityPromises[rootId]) {
      delete this.fetchEntityPromises[rootId];
    }
  }
  onDeleteEntityListener(): any {
    return this.customApi.OnDeleteEntityListener(this.currentUserToken).subscribe({
      next: (res: any) => {
        const deletedEntity: GetEntityQuery = res.value.data.onDeleteEntity;
        // this.logger.log('onDeleteEntityListener - deletedEntity', deletedEntity);
        if (deletedEntity && EntityService.entityMap[deletedEntity.id]) {
          // update cache map
          delete EntityService.entityMap[deletedEntity.id];
          // need to update the local cache and promise.
          if (this.isChildEntity(deletedEntity)) {
            const foundIndex = this.subEntitiesListByParent[deletedEntity?.rootEntityId]?.findIndex(
              ent => ent.id === deletedEntity.id
            );
            if (foundIndex > -1) {
              this.subEntitiesListByParent[deletedEntity.rootEntityId].splice(foundIndex, 1);
              delete this.fetchEntityPromises[deletedEntity.rootEntityId];
            }
          }
          // emit updated entity list
          EntityService.onDataChanged.next(true);
          EntityService.onModifyEntity.next({ isDelete: true, modifiedEntity: deletedEntity });
        }
      },
    });
  }

  /**
   * for proceeding only verified and related subscriptions
   */
  listenerMiddleware(entity: GetEntityQuery): boolean {
    const routeEntityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (!UtilsService.isDefined(routeEntityId)) {
      // routeEntityId will be defined in case of multi-entity. Means multi-entity will contains the entity id in URL.
      // If it is the "clients" screen (while creating entity), it will return true.
      if (this.router.url.includes('clients')) {
        return true;
      }
      return false;
    }
    if (entity && routeEntityId) {
      /**
       * if there is some other root entity created or updated
       */
      if (entity.parentId === null && entity.id !== routeEntityId) {
        return false;
      }
      /**
       * if some sub entity created or updated, outside the scope of current root entity
       */
      if (UtilsService.isDefined(entity.parentId) && entity.parentId !== routeEntityId) {
        return false;
      }
    }
    return true;
  }

  async afterDeleteEntity(entityId): Promise<void> {
    await this.removeChildEntities(entityId);
  }

  async removeChildEntities(entityId): Promise<void> {
    this.logger.log('parentId = ', entityId);
    const childs = await this.getAllChilds(entityId);
    this.logger.log('child  =  ', childs);
  }

  async getAllChilds(entityId: string): Promise<GetEntityQueryExtended[]> {
    // let result: ListEntitiesQuery;
    let items: GetEntityQuery[];
    let nextToken;
    let result = await this.customApi.ListEntities(null, environment.queryListLimit, nextToken);

    // do {
    //   nextToken = result ? result.nextToken : null;
    //   result = await this.customApi.ListEntities(null, environment.queryListLimit, nextToken);
    //   items = result.items as any;
    // } while (result.nextToken);

    while (result.nextToken) {
      nextToken = result ? result.nextToken : null;
      result = await this.customApi.ListEntities(null, environment.queryListLimit, nextToken);
      items = result.items as any;
    }
    const promises = items.map(entity => this.setLogoUrl(entity));
    return Promise.all(promises);
  }

  async getExtendEntityList(filter: ModelEntityFilterInput, fetchLogo = true): Promise<GetEntityQueryExtended[]> {
    let result = await this.customApi.ListEntities(filter, environment.queryListLimit);

    let entityList: GetEntityQuery[] = result.items as any;

    while (result.nextToken) {
      result = await this.customApi.ListEntities(filter, environment.queryListLimit, result.nextToken);
      entityList = entityList.concat(result.items as any);
    }

    if (fetchLogo) {
      const promises = entityList.map(entity => this.setLogoUrl(entity));
      return Promise.all(promises);
    }

    return entityList;
  }

  isRootEntity(entity: GetEntityQuery): boolean {
    return entity.entityType === EntityTypeEnum.ROOT_ENTITY;
  }

  isChildEntity(entity: GetEntityQuery): boolean {
    return entity.entityType === EntityTypeEnum.CHILD_ENTITY;
  }

  /**
   *  ( Note ) Needs to update/remove : same function is already written is userService
   *           user architecture is also updated and currently we are using entityIds not entityId in User's object
   * @param entityId
   * @returns
   */
  async getUsersByEntityId(entityId: string): Promise<GetUserQuery[]> {
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.customApi.UserByEntityId(entityId, null, null, environment.queryListLimit, nextToken);
      items = items.concat(result.items);
    } while (result.nextToken);

    return items;
  }

  async getUsersByRole(role: RoleEnum): Promise<GetUserQuery[]> {
    let result: ListUsersQuery;
    let items: GetUserQuery[] = [];
    // filtering on basis of domain so users can see other users of same domain only
    const filter: ModelUserConditionInput = {
      domain: {
        eq: DomainFrameworkService.getDomain(),
      },
    };
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.customApi.UserRole(role, null, filter, environment.queryListLimit, nextToken);
      items = items.concat(result.items);
    } while (result.nextToken);

    return items;
  }

  async getUsersByRoleId(roleId: string): Promise<GetUserQuery[]> {
    let result: UserByRoleIdQuery;
    let items: GetUserQuery[] = [];
    try {
      // filtering on basis of domain so users can see other users of same domain only
      const filter: ModelUserConditionInput = {
        domain: {
          eq: DomainFrameworkService.getDomain(),
        },
      };
      let nextToken;
      do {
        nextToken = result ? result.nextToken : null;
        result = await this.customApi.UserByRoleId(roleId, null, filter, environment.queryListLimit, nextToken);
        items = items.concat(result.items as GetUserQuery[]);
      } while (result.nextToken);

      return items;
    } catch (e) {
      this.logger.error('Users cannot be fetched - Error: ', e);
      return [];
    }
  }

  async getAllUsersForEntity(entity: GetEntityQuery, childEntityIds: [string]): Promise<GetUserQuery[]> {
    let promises = [];
    promises.push(this.getUsersByEntityId(entity.id));

    if (this.isRootEntity(entity)) {
      promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
    }

    const usersArrays = await Promise.all(promises);
    let users = [];
    usersArrays.forEach(usersArray => {
      users = users.concat(usersArray);
    });

    return users;
  }

  async getAllUsersForEntityWithRole(
    rootEntityId: string,
    childEntityIds: [string],
    currentUser: GetUserQuery
  ): Promise<GetUserQuery[]> {
    let promises = [];

    switch (currentUser.role) {
      case RoleEnum.ADMIN:
        promises.push(this.getUsersByRole(RoleEnum.ADMIN));
        promises.push(this.getUsersByRole(RoleEnum.MSSP));
        promises.push(this.getUsersByEntityId(rootEntityId));
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
      case RoleEnum.MSSP:
        promises.push(this.getUsersByRole(RoleEnum.MSSP));
        promises.push(this.getUsersByEntityId(rootEntityId));
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
      case RoleEnum.LEADER:
        if (currentUser.entityId === rootEntityId) {
          // Entity Leader
          promises.push(this.getUsersByEntityId(rootEntityId));
        }
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
      default:
        promises = promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
    }

    const usersArrays = await Promise.all(promises);
    let users = [];
    usersArrays.forEach(usersArray => (users = users.concat(usersArray)));
    return users;
  }

  /**
   *
   * @param rootEntityId ID of the root Entity.
   * @param childEntityIds will be the list of all the child entities of the root entity
   * @param currentUserRole role of currently logged in user.
   * @returns list of all the users based on the currently logged in user.
   */
  async getAllUsersForEntityWithRoleId(
    rootEntityId: string,
    childEntityIds: [string],
    currentUserRole: GetRoleQuery
  ): Promise<GetUserQuery[]> {
    const promises: any = [];

    switch (currentUserRole?.name) {
      // If currently Logged in user is admin, then all the users from all the roles will be shown to him.
      case AppLevelRoleEnum.ADMIN:
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.ADMIN)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.MSSP)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.ENTITY_LEADER)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.SUBENTITY_LEADER)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.PARTICIPANT)));
        break;

      case AppLevelRoleEnum.MSSP:
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.MSSP)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.ENTITY_LEADER)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.SUBENTITY_LEADER)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.PARTICIPANT)));
        break;

      case AppLevelRoleEnum.ENTITY_LEADER:
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.SUBENTITY_LEADER)));
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.PARTICIPANT)));
        break;

      case AppLevelRoleEnum.SUBENTITY_LEADER:
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.PARTICIPANT)));
        break;

      case AppLevelRoleEnum.PARTICIPANT:
        promises.push(this.getUsersByRoleId(this.getRoleId(AppLevelRoleEnum.PARTICIPANT)));
        break;
      default:
        promises.concat(childEntityIds.map(this.getUsersByEntityId, this));
        break;
    }

    const usersArrays = await Promise.all(promises);
    let users = [];
    usersArrays.forEach(usersArray => (users = users.concat(usersArray)));
    this.filterEntityOrSubEntityLeadersUsers(users, rootEntityId, childEntityIds);
    // to check if the currently logged in user is not admin and mssp.
    if (
      currentUserRole &&
      (currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase() ||
        currentUserRole?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase())
    ) {
      users.push(await this.getCurrentUser());
    }
    return users;
  }

  /**
   * This method will filters all the users according to the entity.
   * @returns the list of updated users list.
   */
  filterEntityOrSubEntityLeadersUsers(
    allUsers: GetUserQuery[],
    rootEntity: string,
    childEntities: [string]
  ): GetUserQuery[] {
    // Filtering the entityLeader users
    const entityLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
    );
    const subEntityLeaderRole = this.allRoles.find(
      role => role?.name?.toLowerCase() === AppLevelRoleEnum.SUBENTITY_LEADER.toLowerCase()
    );
    allUsers.find((user, index) => {
      if (user && user.entityIds && user.entityIds.length) {
        // first check if the user us subEntity leader or not and has subEntity in childEntities.
        const selectedChildEntities = childEntities.filter(entity =>
          user.entityIds?.includes(entity) ? entity : null
        );
        // If selectedChildEntities array is 0 then it will be a entity leader.
        if (!selectedChildEntities.length) {
          // if no child Entity is assigned then it maybe rootEntity.
          // now checking for the rootEntity leader and entityId
          if (
            entityLeaderRole &&
            user?.roleId?.toLowerCase() === entityLeaderRole?.id?.toLowerCase() &&
            user?.entityIds[0] !== rootEntity
          ) {
            allUsers.splice(index, 1);
          }

          // if no child Entity is assigned then it maybe subEntityLeader but not with current rootEntity's childs.
          if (subEntityLeaderRole && user?.roleId?.toLowerCase() === subEntityLeaderRole?.id?.toLowerCase()) {
            allUsers.splice(index, 1);
          }
        }
      }
    });
    return allUsers;
  }

  /**
   * Helper method to get the Id of the role.
   */
  getRoleId(roleName: string): string {
    return this.allRoles.find(role => role?.name?.toLowerCase() === roleName?.toLowerCase())?.id;
  }

  /* @param customRoles is list of GetRoleQuery
   * @returns list of GetUserQuery users
   */
  async usersWithCustomRoles(customRoles: GetRoleQuery[]): Promise<GetUserQuery[]> {
    try {
      const usersArrays = await Promise.all(customRoles.map(role => this.customApi.UserByRoleId(role.id)));
      let users = [];
      usersArrays.forEach(usersArray => (users = users.concat(usersArray.items)));
      return users;
    } catch (e) {
      console.log(e);

      return [];
    }
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }

  private async createAndAddUserGroup(createdEntity: CreateEntityInput): Promise<void> {
    try {
      // Add Cognito Group after creating Entity
      const groupInput: any = { id: createdEntity.id, desc: createdEntity.name };
      let shouldAddUser = false;
      const currentUser = await this.getCurrentUser();

      if (currentUser && currentUser.role === RoleEnum.MSSP) {
        shouldAddUser = true;
      }

      await this.customApi.AddCognitoGroup({
        ...groupInput,
        shouldAddUser,
      });

      // this.logger.log('AddCognitoGroup: ', addCognitoGroupRes);
    } catch (e) {
      this.logger.error('afterCreateEntity - AddCognitoGroup - Error: ', e);
    }

    try {
      await this.updateUserGroups();
      // this.logger.log('updateUserGroups: ', updateUserGroupsRes);
    } catch (e) {
      this.logger.error('afterCreateEntity - updateUserGroups - Error: ', e);
    }
  }

  async listAssessmentByRootId(entityId): Promise<GetAssessmentQuery[]> {
    let result: ListAssessmentsQuery;
    let items: GetAssessmentQuery[];
    let nextToken;
    const filter: ModelAssessmentFilterInput = {
      rootId: { eq: entityId },
    };
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.customApi.ListAssessments(filter, environment.queryListLimit, nextToken);
      items = result.items as any;
    } while (result.nextToken);

    return result.items as any;
  }

  public async getFrameworksAndManagersByEntityId(
    entityId: string,
    standardType: StandardType,
    isRootEntity: boolean,
    thirdParty = false
  ): Promise<FrameworkScoreAndManagers> {
    if (entityId) {
      const subEntitiesList: GetEntityQuery[] = await this.getSubEntitiesList(entityId, isRootEntity, thirdParty);
      const managers = [];
      subEntitiesList.forEach(se => {
        if (se.activeAssessment && se.activeAssessment.managers) {
          se.activeAssessment.managers.forEach(manager => {
            managers.push(manager);
          });
        }
      });
      const frameworks = await this.fetchEntitiesFrameworks(subEntitiesList, standardType);
      return {
        frameworks,
        managers,
      };
    }
  }

  /**
   * @param entityID
   * @param standardType
   * @param isRootEntity
   * @param thirdParty
   * @param archiveMode - If archive mode then get only archived frameworks ByDefault - False
   * @returns frameworks by entityID
   */
  public async getFrameworksByEntityId(
    entityId: string,
    standardType: StandardType,
    isRootEntity: boolean,
    thirdParty = false,
    archiveMode = false,
    entity?: GetEntityQuery
  ): Promise<FrameworkScore[]> {
    if (entityId) {
      const subEntitiesList: GetEntityQuery[] = await this.getSubEntitiesList(
        entityId,
        isRootEntity,
        thirdParty,
        entity
      );
      let frameworks = await this.fetchEntitiesFrameworks(subEntitiesList, standardType);
      const index = frameworks?.findIndex(fw => fw?.archived && !fw?.not_added);
      if (subEntitiesList?.length) {
        this.entityArchivedFrameworks[subEntitiesList[0].id] = index !== -1;
      }

      if (archiveMode) {
        frameworks = frameworks?.filter(framework => framework?.archived);
      } else {
        frameworks = frameworks?.filter(framework => !framework?.archived);
      }
      return frameworks;
    }
  }

  public async getSubEntitiesList(
    entityId: string,
    isRoot: boolean,
    thirdParty = false,
    entityObj?: any
  ): Promise<GetEntityQuery[]> {
    try {
      let subEntitiesList: GetEntityQuery[];
      if (isRoot) {
        let filter: ModelEntityFilterInput;
        if (thirdParty) {
          filter = {
            entityType: { eq: EntityTypeEnum.VENDOR },
          };
        } else {
          filter = {
            entityType: { eq: EntityTypeEnum.CHILD_ENTITY },
          };
        }
        subEntitiesList = await this.listEntitysByParentId(entityId, filter);
      } else {
        const entity = await this.getEntity(entityId, false, entityObj);
        if (entity) {
          subEntitiesList = [entity];
        }
      }
      return subEntitiesList;
    } catch (err) {
      console.log('Error ==>>', err);
      return [];
    }
  }

  public async fetchEntitiesFrameworks(
    subEntitiesList: GetEntityQuery[],
    frameworkType?: StandardType
  ): Promise<FrameworkScore[]> {
    try {
      let filter = (listItem): boolean => true;
      if (frameworkType) {
        filter = (listItem): boolean => listItem.type === frameworkType;
      }
      if (!UtilsService.isDefined(subEntitiesList)) {
        subEntitiesList = [];
      }
      for (const [index, subEntity] of subEntitiesList.entries()) {
        if (subEntity?.activeAssessment?.standardFrameworkList?.nextToken) {
          await this.getListOfStandardFrameworkListWithNextToken(
            subEntity?.activeAssessmentId,
            subEntity,
            subEntity.activeAssessment?.standardFrameworkList?.nextToken
          );

          // Ensure the updates are reflected in the original array
          subEntitiesList[index] = subEntity;
        }
      }

      return (
        subEntitiesList &&
        subEntitiesList
          .map(subEntity => {
            return subEntity.activeAssessment?.standardFrameworkList.items.filter(filter).map(listItem => {
              return {
                ...{
                  key: listItem.key,
                  id: listItem.id,
                  type: listItem.type,
                  fileName: listItem.fileName,
                  filter: listItem.filter,
                },
                defaultQuestionSettings: listItem.defaultQuestionSettings,
                suggestedArtifact: listItem.suggestedArtifact,
                suggestedComments: listItem.suggestedComments,
                assessmentId: subEntity.activeAssessmentId,
                creationDate: subEntity.createdAt,
                expiryDate: subEntity?.timeline?.collectionDate,
                completionStatus: subEntity.completionStatus,
                name: this.domainFrameworkService.getFrameworkName(listItem.key.split('#')[0]),
                not_added: listItem.not_added,
                archived: listItem.archived,
                reassessmentStatus: listItem.reassessmentStatus,
                updatedAt: listItem.updatedAt,
                scores: {},
                rootId: subEntity.parentId,
                childId: subEntity.id,
                __typename: 'StandardFramework',
              } as FrameworkScore;
            });
          })
          .flat()
      );
    } catch (e) {
      console.log('Error : ', e);
    }
  }

  public getFrameworkScores(assessmentId: string, frameworkId: string): Promise<GetFrameworkScoresQuery> {
    return this.customApi.GetFrameworkScores(`${assessmentId}_${frameworkId}`);
  }

  async updateUserGroups(): Promise<void> {
    const currentUser = await Auth.currentAuthenticatedUser();
    const userSession = currentUser.getSignInUserSession();
    const refreshToken = userSession.getRefreshToken();
    currentUser.refreshSession(refreshToken, (err, session) => {
      currentUser.setSignInUserSession(session);
    });
  }

  emitSubEntityName(subEntityName: string): void {
    this.activeSubEntityObservable.next(subEntityName);
  }

  async getArchivedUserById(userId: string): Promise<GetArchivedUserQuery> {
    const archivedUser = await this.customApi.GetArchivedUser(userId);
    return archivedUser;
  }

  async getAllArchivedUsers(filters: ModelArchivedUserFilterInput = null): Promise<void> {
    if (!filters) {
      filters = { domain: { eq: DomainFrameworkService.getDomain() } };
    }
    let result: ListArchivedUsersQuery;
    let items: GetArchivedUserQuery[] = [];
    let nextToken;
    do {
      nextToken = result ? result.nextToken : null;
      result = await this.customApi.ListArchivedUsers(filters, environment.queryListLimit, nextToken);
      items = items.concat(result.items);
    } while (result.nextToken);
    this.archivedUsers = items?.length ? items : [];
  }

  setArchivedUserToDeletedUserAns(
    assignments: GetAssignmentQuery[] = [],
    answeredQuestions: GetAnswerQuery[] = [],
    answeredQuestionsMap: Map<string, GetAnswerQuery[]> | any = {}
  ): void {
    // if answer doesnt have user but has userId, then it means user has been deleted.
    // use archivedUser table to get required data and paste it in user property of answer

    // set ArchivedUser for AnsweredQuestion
    if (answeredQuestions && !!answeredQuestions.length) {
      answeredQuestions.map(ans => {
        if (!ans.user && ans.userId) {
          const archivedUser = this.archivedUsers.find(au => au.id === ans.userId);
          ans.user = archivedUser as any;
          return archivedUser;
        }
      });
    }

    // set ArchivedUser for AnsweredQuestionMap
    if (answeredQuestionsMap && !!Object.keys(answeredQuestionsMap).length) {
      Object.values(answeredQuestionsMap).map((answers: any[]) => {
        return answers.map(ans => {
          if (!ans.user && ans.userId) {
            const archivedUser = this.archivedUsers.find(au => au.id === ans.userId);
            ans.user = archivedUser;
            return archivedUser;
          }
        });
      });
    }

    // set ArchivedUser for Assignments
    if (assignments && !!assignments.length) {
      assignments.map(assign => {
        if (assign?.user && !Object.keys(assign.user).length && assign.userId) {
          const archivedUser = this.archivedUsers.find(au => au.id === assign.userId);
          assign.user = archivedUser as any;
          return archivedUser;
        }
      });
    }
  }

  // This is the generic loader. We can use it across different component (other than parent and child too).
  updateIsLoading(value: boolean) {
    this.isLoadingSub.next(value);
  }

  /**
   * if a user is Accessing any screen without subEntityId. function will redirect him with a subEntityId
   * if subEnityId is needed
   * @param rootEntityId is type of string
   */
  async redirectWithSubEntityId(rootEntityId: string): Promise<void> {
    const subEntities = await this.listChildEntitysByParentId(rootEntityId);
    let subEntityId;
    if (subEntities && subEntities.length > 0) {
      subEntityId = subEntities[0].id;
    }

    if (subEntityId) {
      this.router.navigate([this.router.url, subEntityId]);
    }
  }

  setLayerId(layerName: string, layerId: string = '', layerEntityName: string = ''): void {
    if (layerId && layerEntityName) {
      EntityService._entityLayerIds[layerName] = { id: layerId, name: layerEntityName };
    }
    Object.keys(EntityService._layerPriority).forEach(layer => {
      if (EntityService._layerPriority[layerName] < EntityService._layerPriority[layer]) {
        EntityService._entityLayerIds[layer] = null;
      }
    });
  }

  getLayerId(layerName: string): string {
    return EntityService._entityLayerIds[layerName]?.id;
  }

  getLayerName(layerName: string): string {
    return EntityService._entityLayerIds[layerName]?.name;
  }

  /**
   *
   * @param ParentId - Parent id for bnb entity layer
   * @returns it will return entities that are siblings; same parent
   */
  async getEntityLayerChildren(ParentId: string, domain?: string): Promise<GetEntityLayersQuery[]> {
    try {
      let entityLayer = [];
      let filters = null;
      if (ParentId === 'rootlayer') {
        filters = {
          domain: {
            eq: domain,
          },
        };
      }
      let result = await this.customApi.EntityLayersByParentId(
        ParentId,
        null,
        filters,
        environment.queryListLimit,
        null
      );
      if (result && result.items && result.items.length) {
        entityLayer = [...result.items];
      }
      while (result.nextToken) {
        result = await this.customApi.EntityLayersByParentId(
          ParentId,
          null,
          null,
          environment.queryListLimit,
          result.nextToken
        );
        entityLayer = entityLayer.concat(result.items as any);
      }
      return entityLayer;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getSingleEntityLayer(id: string): Promise<GetEntityLayersQuery> {
    try {
      return await this.customApi.GetEntityLayers(id);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *
   * @param entity to be created
   * @returns created entity
   */
  async createEntityLayer(entity: CreateEntityLayersInput): Promise<CreateEntityLayersMutation> {
    try {
      const createdEntity: CreateEntityLayersMutation = await this.customApi.CreateEntityLayers(entity);
      return createdEntity;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *
   * @param entity - new entity
   * @param s3Input - logo file input
   * @param parentEntityId - parent entity id
   * @returns - fully initialized entity
   */
  async setNewEntityLayer(
    entity: GetEntityLayersQuery,
    s3Input: S3FileInput,
    parentEntityId: string
  ): Promise<GetEntityLayersQuery> {
    try {
      const generatedId = uuid();
      entity.id = generatedId;
      entity.srName = entity.name.toLowerCase();
      entity.logo = s3Input ? await this.fileService.uploadToS3(s3Input) : null;
      entity.parentId = parentEntityId;
      return entity;
    } catch (e) {
      console.log(e);
    }
  }

  async createNewWizard(wizardData: CreateEntityWizardInput): Promise<CreateEntityWizardInput> {
    try {
      return await this.customApi.CreateEntityWizard(wizardData);
    } catch (e) {
      console.log(e);
    }
  }
  /**
   *
   * @param entity - entitylayer to be updated
   * @returns updated entity layer
   */
  async updateEntityLayer(entity: UpdateEntityLayersInput): Promise<UpdateEntityLayersInput> {
    try {
      const updatedEntityLayer = await this.customApi.UpdateEntityLayers(entity);
      return updatedEntityLayer ? updatedEntityLayer : null;
    } catch (e) {
      console.log(e);
    }
  }

  async updateEntityWizard(wizardData: UpdateEntityWizardInput, parentId: string): Promise<UpdateEntityWizardInput> {
    try {
      return await this.customApi.UpdateEntityWizard(wizardData, { parentId: { eq: parentId } });
    } catch (e) {
      console.log(e);
    }
  }
  /**
   *
   * @param entityId to be deleted
   */
  async deleteEntityLayer(entityId: string): Promise<void> {
    try {
      const deletedEntity = await this.customApi.DeleteEntityLayers({ id: entityId });
    } catch (e) {
      console.log(e);
    }
  }

  async getBreakdownFromParentId(id: string): Promise<GetEntityWizardQuery[]> {
    try {
      let domain = null;
      if (id === 'root') {
        domain = DomainFrameworkService.getDomain();
      }
      let res = domain
        ? await this.customApi.EntityWizardByParentAndDomain(
            domain,
            { eq: id },
            null,
            null,
            environment.queryListLimit,
            null
          )
        : await this.customApi.EntityWizardByParentId(id, null, null, environment.queryListLimit, null);
      if (res && res.items && res.items.length) {
        let cacheList = [...res.items];
        while (res.nextToken) {
          res = domain
            ? await this.customApi.EntityWizardByParentAndDomain(
                domain,
                { eq: id },
                null,
                null,
                environment.queryListLimit,
                res.nextToken
              )
            : await this.customApi.EntityWizardByParentId(id, null, null, environment.queryListLimit, res.nextToken);
          cacheList = cacheList.concat(res.items);
        }
        const sortedEntities = cacheList.sort(function (a, b) {
          const aDate = new Date(a.createdAt).getTime();
          const bDate = new Date(b.createdAt).getTime();
          return aDate - bDate;
        });
        return sortedEntities as GetEntityWizardQuery[];
      } else {
        return [];
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getSingleWizardEntity(id: string): Promise<GetEntityWizardQuery> {
    try {
      return await this.customApi.GetEntityWizard(id);
    } catch (e) {
      console.log(e);
    }
  }

  async deleteEntityWizard(
    id: string,
    parentId: string,
    isDraft: boolean = false
  ): Promise<DeleteEntityWizardMutation> {
    try {
      if (isDraft) {
        return await this.customApi.DeleteEntityWizard({ id }, { isDraft: { eq: isDraft } });
      }
      return await this.customApi.DeleteEntityWizard({ id }, { parentId: { eq: parentId } });
    } catch (e) {}
  }

  async createWizardRootEntity(
    firstStepData: any,
    wizard: EntityWizard,
    userId: string,
    existingWizard: any
  ): Promise<void> {
    const rootExists = !!(existingWizard && existingWizard.rootEntity);
    const name = firstStepData[0][0].value;
    const industry = UtilsService.isBnBCyberSite ? IndustryEnum.FINANCE : firstStepData[2]?.industry.toUpperCase();
    const logoS3 = firstStepData[2].logoS3 ? firstStepData[2].logoS3 : null;
    const projectManagerName = firstStepData[0][1].value;

    const promises = [];

    const initDate = UtilsService.getDateInNgbDateStructFormat(new Date().getTime());
    const initiationDate = UtilsService.getDateFromNgbDateStruct(initDate).getTime();
    const collectionDate = initDate;
    if (collectionDate.month !== 11) {
      collectionDate.month += 1;
    } else {
      collectionDate.month = 0;
      collectionDate.year += 1;
    }
    const deadline = UtilsService.getDateFromNgbDateStruct(collectionDate).getTime();
    if (rootExists) {
      const updated: UpdateEntityInput = {
        id: wizard.id,
        name,
        projectManagerName,
        logo: logoS3,
      };

      // for now not updating industry due to grouping effect;
      // prevRoot.industry = industry;

      // only update root entity
      await this.customApi.UpdateEntity(updated);
    } else {
      const root = EntityService.initEntity(EntityTypeEnum.ROOT_ENTITY, userId);
      root.id = wizard.id;
      root.logo = logoS3;
      root.name = name;
      root.industry = industry;
      root.projectManagerName = projectManagerName;
      root.projectDeadline = deadline;
      root.tierSelected = 4;
      // removing the auto generated ID of root and using the wizard ID from access groups
      root.accessGroups.pop();
      root.accessGroups.push(root.id);
      promises.push(this.createEntity(root, []));
      this.toastr.info('Creating Entity...');
      // creating sub-entity

      const subEntity = EntityService.initEntity(EntityTypeEnum.CHILD_ENTITY, userId, wizard.id);
      subEntity.name = name;
      subEntity.tierSelected = 4;
      subEntity.projectDeadline = deadline;

      subEntity.timeline.collectionDate = deadline;
      subEntity.timeline.initiationDate = initiationDate;
      subEntity.defaultQuestionSettings = {
        isApprovalRequired: false,
        isArtifactsMandatory: false,
        isCollaborative: true,
      };
      let requiredStandard: RequiredStandard[];
      if (UtilsService.isBnB || UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
        const isFrameworkKey = this.domainFrameworkService.isFrameworkKey(wizard.riskFramework);
        const frameworkName = isFrameworkKey
          ? this.domainFrameworkService.getFrameworkName(wizard.riskFramework)
          : wizard.riskFramework;
        requiredStandard = [
          {
            key: wizard.riskFramework,
            name: frameworkName,
            type: StandardType.RISK_FRAMEWORK,
            checked: true,
          },
        ];
        // add compliance frameworks too in Beecher, Midmarket and BNB
        if (wizard.frameworks && wizard.frameworks.length) {
          wizard.frameworks.map(f => {
            const f_name = this.domainFrameworkService.getFrameworkName(f);
            requiredStandard.push({
              key: f?.replace(/ /g, '_'),
              name: f_name,
              type: StandardType.COMPLIANCE_FRAMEWORK,
              checked: true,
            });
          });
        }
      } else {
        requiredStandard = [
          {
            key: 'NIST_CSF',
            name: 'NIST CSF',
            type: StandardType.RISK_FRAMEWORK,
            checked: true,
          },
        ];
      }
      promises.push(this.createEntity(subEntity, requiredStandard));
      await Promise.all(promises);
    }
  }
  async getBnbUpperdeckBreadCrumbs(currId: string): Promise<any> {
    const dropList = [];
    if (this.getLayerId('BREAKDOWN')) {
      const regionId = this.getLayerId('REGION');
      const listWizards = await this.getBreakdownFromParentId(regionId);
      listWizards.forEach(wiz => {
        if (!wiz.isDraft && wiz.rootEntity?.name) {
          dropList.push({ id: wiz.id, name: wiz.rootEntity.name.toUpperCase() });
        }
      });
    } else {
      const wizard = await this.getSingleWizardEntity(currId);
      if (wizard) {
        const regionId = wizard.parentId;
        const listWizards = await this.getBreakdownFromParentId(regionId);
        listWizards.forEach(wiz => {
          if (!wiz.isDraft && wiz.rootEntity?.name) {
            dropList.push({ id: wiz.id, name: wiz.rootEntity.name.toUpperCase() });
          }
        });

        const regionEnt = await this.getSingleEntityLayer(regionId);
        const divisionId = regionEnt.parentId;
        const divisionEnt = await this.getSingleEntityLayer(divisionId);
        const enterpriseId = divisionEnt.parentId;
        const enterpriseEnt = await this.getSingleEntityLayer(enterpriseId);
        const obj = {
          ENTERPRISE: { id: enterpriseId, name: enterpriseEnt.name },
          DIVISION: { id: divisionId, name: divisionEnt.name },
          REGION: { id: regionId, name: regionEnt.name },
          BREAKDOWN: { id: currId, name: wizard.rootEntity.name },
        };
        EntityService._entityLayerIds = obj;
      }
    }

    return {
      parent: this.getLayerName('REGION')?.toUpperCase(),
      grandParent: this.getLayerName('DIVISION')?.toUpperCase(),
      wizardLists: dropList && dropList.length ? dropList : [],
    };
  }

  updateLayerScoresManualInvocation = async (parentId, isRemoved = false, currentLayerId = null) => {
    if (parentId === 'rootlayer') {
      return;
    }
    try {
      const credentials = await Auth.currentCredentials();
      // fix import - build-optimization-1
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      await lambda
        .invoke({
          FunctionName: `entityWizardTrigger-${environment.name}`,
          Payload: JSON.stringify({
            manualInvocation: true,
            isRemoved,
            parentId,
            currentLayerId,
          }),
        })
        .promise();
    } catch (error) {
      console.log('error invoking EntityWizardTrigger lambda - ', error);
    }
  };
  async deleteWizardAndRoot(id: string, parentId: string, isDraft: boolean = false): Promise<void> {
    // check if the wizard exists on Entity Wizard
    try {
      const wizard = await this.getSingleWizardEntity(id);
      await this.deleteEntityWizard(id, parentId, isDraft);
      if (wizard && wizard.rootEntity) {
        await this.deleteEntity(id);
      }
    } catch (e) {}
  }

  async createManualCustomTask(task: CreateCustomTaskInput): Promise<CreateCustomTaskInput> {
    return await this.customApi.CreateCustomTask(task);
  }

  async getEntityLayersByType(layerType: BnbLayerEnum): Promise<GetEntityLayersQuery[] | any> {
    let result: ListEntityLayersQuery;
    let items: GetEntityLayersQuery[] = [];
    try {
      // filtering on basis of domain so users can see other users of same domain only
      const filter: ModelEntityLayersConditionInput = {
        type: {
          eq: layerType,
        },
        domain: { eq: DomainFrameworkService.getDomain() },
      };
      let nextToken;
      do {
        nextToken = result ? result.nextToken : null;
        result = await this.customApi.ListEntityLayers(filter, environment.queryListLimit, nextToken);
        items = result && result.items ? items.concat(result.items as GetEntityLayersQuery[]) : items;
      } while (result.nextToken);

      return items;
    } catch (e) {
      console.log('Users cannot be fetched - Error: ===>>', e);
      return [];
    }
  }

  async getDivisionRegionId(isDiv: boolean = false, rootId: string): Promise<any> {
    try {
      if (rootId) {
        const wizard = await this.getSingleWizardEntity(rootId);
        const region = await this.getSingleEntityLayer(wizard.parentId);
        return isDiv ? region.parentId : wizard.parentId;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getCategoriesFromCRB(): Promise<string[]> {
    const crbKey = this.RiskFrameworkEnum.CROSS_RIVER.split(' ').join('_');
    const crbTree = await this.frameworkTreeService.getFrameworkTreeFromS3(crbKey);
    const crbJson = await new Response(crbTree.Body).json();
    const categoriesLevel = 2;
    const level = 0;
    const categories = [];
    this.extractCategories(crbJson, level, categoriesLevel, categories);
    return categories;
  }

  extractCategories(tree, level, targetLevel, categories) {
    if (tree.children) {
      if (level < targetLevel && (!level || tree.entityType === 'FRAMEWORK_NODE')) {
        level++;
        tree.children.forEach(child => this.extractCategories(child, level, targetLevel, categories));
      } else if (
        level === targetLevel &&
        !categories.some(category => category.toLowerCase() === tree.name.toLowerCase())
      ) {
        categories.push(tree.name);
      }
    }
  }

  async getDivisionRegionName(rootId: string): Promise<any> {
    try {
      if (rootId) {
        const wizard = await this.getSingleWizardEntity(rootId);
        if (wizard) {
          const region = await this.getSingleEntityLayer(wizard.parentId);
          if (region) {
            const division = await this.getSingleEntityLayer(region.parentId);
            return {
              div: division.name,
              reg: region.name,
            };
          } else {
            return null;
          }
        }
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  /**
   * Used to get the total Question counts for Residual Calculation BNB
   * @param frameName the name of the framework to get the questions
   */
  async preLoadQuestionCounts(frameName): Promise<any> {
    if (!this.nistMappedRC[frameName]?.mappedRisks?.length) {
      const mapper = await this.frameworkTreeService.getRiskControlsFromS3('mapped-risks', frameName);
      if (mapper) {
        this.nistMappedRC[frameName] = { mappedRisks: [...mapper?.mappedRisks] };
      }
    }
    this.rskCount = {};
    if (this.nistMappedRC[frameName]?.mappedRisks?.length) {
      this.nistMappedRC[frameName].mappedRisks.forEach(mr => {
        const allConnectedRisks = mr.riskID ? mr.riskID.split(',') : [];
        allConnectedRisks.forEach(gr => {
          const riskName = gr.trim();
          if (this.rskCount[riskName] && this.rskCount[riskName] > 0) {
            this.rskCount[riskName]++;
          } else {
            this.rskCount[riskName] = 1;
          }
        });
      });
    }
  }
  /**
   * The function gathers all the BB Risks , calculates the residual score and saves it.
   */
  async updateResidualScore(
    rootId: string,
    allowUpdate: boolean = false,
    wiz: any = null,
    countHash = {}
  ): Promise<UpdateEntityWizardInput> {
    try {
      const wizard = wiz ? wiz : await this.getSingleWizardEntity(rootId);
      const frameName = wizard.riskFramework;
      let riskFactor = -1;
      let updateObj = null;

      if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
        // risk factor calculation for weighted framework score
        // only enabled for Beecher right now
        riskFactor = await this.getWeightRiskFactor(rootId);
      }

      if (riskFactor !== -1 && wizard) {
        const { inher1, inher2, inher3, riskScen } = this.getWizardInherents(wizard);

        // updating Data Breach, Business Interruption and Hybrid as well
        const riskRes1 = inher1 * riskFactor;
        const riskRes2 = inher2 * riskFactor;
        const riskRes3 = inher3 * riskFactor;
        riskScen[0].residual = riskRes1 + '';
        riskScen[1].residual = riskRes2 + '';
        riskScen[2].residual = riskRes3 + '';
        wizard.residual = riskRes3 + '';

        wizard.riskScenarios = JSON.stringify(riskScen);
        updateObj = {
          id: wizard.id,
          riskScenarios: wizard.riskScenarios,
          residual: wizard.residual,
        };
      } else if (wizard) {
        // for normal non-risk factor flow - we get the risks
        // calculate the average and use it in the residual calculations

        // getting the total question counts for calculations
        if (
          frameName !== MidMarketEnum.MIDMARKETAPPLICATION &&
          frameName !== MidMarketEnum.MIDMARKETBASIC &&
          frameName !== MidMarketEnum.MIDMARKETBASICOT &&
          frameName !== MidMarketEnum.MIDMARKETBASICTECH
        ) {
          await this.preLoadQuestionCounts(frameName);
        }
        // getting the risks by the entity ID
        const bbRisk1 = [];
        const bbRisk2 = [];
        const bbRisk3 = [];
        // adding the framework check here because for MID_MARKET_APPLICATION framework there are no risks file
        // so we need to revoke the api calling here
        if (
          frameName !== MidMarketEnum.MIDMARKETAPPLICATION &&
          frameName !== MidMarketEnum.MIDMARKETBASIC &&
          frameName !== MidMarketEnum.MIDMARKETBASICOT &&
          frameName !== MidMarketEnum.MIDMARKETBASICTECH
        ) {
          const res = await this.customApi.RiskByEntityId(rootId);
          if (res?.items?.length) {
            // separating the 3 BNB risks
            res.items.forEach(rsk => {
              const name = rsk.idTitle?.trim();
              if (name) {
                if (name.toLowerCase() === NistBBRiskTitleEnum.BB_RISK_1.toLowerCase()) {
                  bbRisk1.push(rsk);
                } else if (name.toLowerCase() === NistBBRiskTitleEnum.BB_RISK_2.toLowerCase()) {
                  bbRisk2.push(rsk);
                } else if (name.toLowerCase() === NistBBRiskTitleEnum.BB_RISK_3.toLowerCase()) {
                  bbRisk3.push(rsk);
                }
              }
            });
          }
        }
        // for Data Breach Calculations
        let avg1 = 1;
        if (bbRisk1 && bbRisk1.length) {
          avg1 = this.getScoreHistoryAvg(bbRisk1, 'BB Risk 1');
        }
        // for Business Interruption Calculations
        let avg2 = 1;
        if (bbRisk2 && bbRisk2.length) {
          avg2 = this.getScoreHistoryAvg(bbRisk2, 'BB Risk 2');
        }
        // for Hybrid Calculations
        let avg3 = 1;
        if (bbRisk3 && bbRisk3.length) {
          avg3 = this.getScoreHistoryAvg(bbRisk3, 'BB Risk 3');
        }

        // getting the wizard data for final calculations
        const { inher1, inher2, inher3, riskScen } = this.getWizardInherents(wizard);
        const res1 = inher1 * avg1;
        const res2 = inher2 * avg2;
        riskScen[0].residual = res1 + '';
        riskScen[1].residual = res2 + '';
        if (UtilsService.isBnB) {
          const { Payload } = await this.clientLambdaService.invokeLambda('riskCreationFlow', {
            isBnbResidualCalc: true,
            inherent: wizard.inherent + '',
            rootId: wizard.id,
          });
          if (Payload && JSON.parse(Payload).finalRes) {
            wizard.residual = JSON.parse(Payload).finalRes + '';
          }
        } else {
          wizard.residual = res1 + res2 + '';
        }
        // only for beecher case where Paragon Full or Basic is used
        if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket || (bbRisk3 && bbRisk3.length)) {
          const res3 = inher3 * avg3;
          riskScen[2].residual = res3 + '';
          wizard.residual = res3 + '';
        }

        wizard.riskScenarios = JSON.stringify(riskScen);
        updateObj = {
          id: wizard.id,
          riskScenarios: wizard.riskScenarios,
          residual: wizard.residual,
        };
      }

      // finalizing the updateObj
      if (wizard && updateObj) {
        // update wizard by calling API or return the updated wizard object
        if (allowUpdate) {
          await this.updateEntityWizard(updateObj, rootId);
        } else {
          return wizard;
        }
      } else if (!updateObj) {
        // if there is no update in wizard
        // simply return the original wizard or null
        return allowUpdate ? null : wiz;
      }
    } catch (e) {
      console.log(e);
      console.log('Could not Update Residual Risk Calculations');
      return allowUpdate ? null : wiz;
    }
  }

  /**
   * This calculates the risk Factor based on the
   * Weight Scoring on Frameworks - Only weight Enabled
   * Framework Scores are added in the formula
   * Same Functionality is also written in riskCreationFlow Lambda
   * If updated here , update in lambda as well.
   * @param rootId Root Id of the Wizard - Root Entity Id
   * @returns the risk factor Value
   */
  async getWeightRiskFactor(rootId: string): Promise<number> {
    const base = 7.5;
    const max = 1;
    const min = 0.5;
    try {
      const beazleyScore = [];
      const assessments = await this.customApi.AssessmentsByRootId(rootId);
      if (assessments?.items?.length) {
        assessments.items.forEach(assess => {
          if (assess?.standardFrameworkList?.items) {
            const allFrameworks = assess.standardFrameworkList.items;
            if (allFrameworks?.length) {
              allFrameworks.forEach(frame => {
                if (
                  !frame.not_added &&
                  !frame.archived &&
                  frame.key &&
                  ['BEAZLEY', 'B_APP_RSWOT'].includes(frame.key.split('#')[0])
                ) {
                  if (frame.assessment?.scores) {
                    beazleyScore.push(frame.assessment.scores.total);
                  }
                }
              });
            }
          }
        });
      }
      if (beazleyScore.length) {
        const sumAll = beazleyScore.reduce((acc, curr) => acc + curr, 0);
        const totalAvg = sumAll / beazleyScore.length;
        const riskFactor = Math.min(max + ((totalAvg - base) / (10 - base)) * (min - max), 1);
        return riskFactor;
      } else {
        return -1;
      }
    } catch (e) {
      console.log('Issue in Risk Factor Calculation !!');
      console.log(e);
    }
    return -1;
  }

  /**
   * Getting the Question Counts according to the Risk connected to those questions
   * @param riskName The name of the risk , or the IdTitle names in DB
   * @returns the number of counts
   */
  getRiskTotalQuestionCount(riskName: string): number {
    if (this.rskCount[riskName]) {
      return this.rskCount[riskName];
    } else {
      return 0;
    }
  }

  /**
   *
   * @param riskList A list of all risk under that root
   * @returns an average score of all the questions connected to those risks
   */
  getScoreHistoryAvg(riskList: any[], rskName: string): number {
    const total = this.getRiskTotalQuestionCount(rskName);
    let acc = 0;
    riskList.forEach(rsk => {
      if (rsk && rsk.scoreHistory && rsk.scoreHistory.length) {
        rsk.scoreHistory.forEach(quest => {
          acc = quest.score + acc;
        });
      }
    });
    let avg = 0;
    if (total) {
      avg = acc / total;
      avg = 1 - avg / 10; // first convert it to actual decimal score value , then divide by 10 for formula;
    }
    return avg;
  }

  /**
   *
   * @param wiz
   * @returns An object containing inherent risk of both BB Risks and Risk Scenarios Object
   */
  getWizardInherents(wiz): any {
    const riskScen = JSON.parse(wiz.riskScenarios);
    const inher1 = riskScen[0]?.inherent ? riskScen[0]?.inherent : 0;
    const inher2 = riskScen[1]?.inherent ? riskScen[1]?.inherent : 0;
    const inher3 = riskScen[2]?.inherent ? riskScen[2]?.inherent : 0;
    return { inher1, inher2, inher3, riskScen };
  }
  async getDivRegName(rootId: string): Promise<any> {
    const divOrRegName = await this.getSingleEntityLayer(rootId);
    return divOrRegName?.name;
  }

  async startDownloadBnbCSV(wizardObj: any, isBeecher: boolean = false): Promise<void> {
    try {
      // Getting the template
      // Need to get template everytime because otherwise the file can have previous values
      await this.getWizardCSVTemplate();
      if (!this.wizardCSVTemplate) {
        this.toastr.error('Template not found');
        return;
      }
      const workbook = this.wizardCSVTemplate;

      // Getting the data from the Entity Wizard
      const organ = JSON.parse(wizardObj.organizational);
      const finance = JSON.parse(wizardObj.financial);
      const frameworks = wizardObj.frameworks;
      const dataScope = JSON.parse(wizardObj.dataScope);
      const tech = JSON.parse(wizardObj.technical);
      const riskScene = JSON.parse(wizardObj.riskScenarios);

      const fileName = 'PML Calculation Report.xlsx';
      const worksheet = workbook.getWorksheet(1);

      // Iterating each Sheet in the Template then each Row to store the values

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * * * * * * * ORGANIZATIONAL STEP * * * * * * * * * * * *
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      let colNames = 'BCDEFGHIJKL'.split('');

      // getting the domains and storing it in Excel Hyper-link objects
      const dom = organ[3].domains && !!organ[3].domains.length ? organ[3].domains : [];
      const domains = dom.map(domStr => {
        return {
          hyperlink: domStr,
          text: domStr,
        };
      });

      // sorting the organizational data sequentially according to template format
      const totalOrgan = [];
      organ[0].forEach(sec => {
        const val = sec.value ? sec.value : null;
        totalOrgan.push(val);
      });
      totalOrgan.push(organ[2].industry);
      organ[1].forEach(sec => {
        const val = sec.value ? sec.value : null;
        totalOrgan.push(val);
      });
      totalOrgan.push(organ[3].isActive ? 'Yes' : 'No');
      if (domains && domains.length) {
        totalOrgan.push(domains[0]);
      } else {
        totalOrgan.push(null);
      }

      // getting the titles as well for beecher change
      const row2 = worksheet.getRow(2);
      // storing it in Excel workbook
      const row3 = worksheet.getRow(3);
      colNames.forEach((col, index) => {
        if (totalOrgan[index]) {
          row3.getCell(col).value = totalOrgan[index];
        } else {
          row3.getCell(col).value = '-';
          row3.getCell(col).alignment = { horizontal: 'center' };
        }
        if (isBeecher) {
          row2.getCell(col).value = this.beecherPMLReportTitleChange(row2.getCell(col).value);
        }
        if (!isBeecher && row2.getCell(col).value === 'Number of Employees') {
          row2.getCell(col).value = 'Number of Teammates';
        }
      });

      if (isBeecher) {
        // column G is Line of Business , We don't need it for Beecher
        const row4 = worksheet.getRow(4);
        row2.splice(7, 1);
        row3.splice(7, 1);
        row4.splice(7, 1);
      }

      // storing the domains if the length is greater than 2
      const domColumns = 'KLMNOPQRSTUVWXYZ'.split('');
      if (domains && domains.length > 1) {
        let domCount = 3;
        let domCo = 1;
        domains.forEach((domObj, index) => {
          if (index && domCo < 16) {
            const rowN = worksheet.getRow(domCount);
            rowN.getCell(domColumns[domCo]).value = domObj;
          }
          domCount++;
          if (domCount > 4) {
            domCo++;
            domCount = 3;
          }
        });
      }

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * * * * * * * FINANCIAL STEP * * * * * * * * * * * *
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      // sorting the financial step data sequentially according to template
      const totalFinance = [];
      finance[0].forEach(sec => {
        totalFinance.push(sec.displayValue ? '$' + sec.displayValue : null);
      });
      finance[1].forEach((sec, index) => {
        if (index !== 1) {
          sec.displayValue = sec.displayValue ? '$' + sec.displayValue : null;
        }
        totalFinance.push(sec.displayValue ? sec.displayValue : null);
      });

      // storing it in Excel workbook
      colNames = ['B', 'C', 'D', 'E', 'F', 'G', 'H'];
      const row8 = worksheet.getRow(8);
      const row7 = worksheet.getRow(7);
      colNames.forEach((col, index) => {
        if (totalFinance[index]) {
          row8.getCell(col).value = totalFinance[index];
        } else {
          row8.getCell(col).value = '-';
          row3.getCell(col).alignment = { horizontal: 'center' };
        }
        if (isBeecher) {
          row7.getCell(col).value = this.beecherPMLReportTitleChange(row7.getCell(col).value);
        }
      });

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * * * * * * * FRAMEWORKS STEP * * * * * * * * * * * *
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      // storing the frameworks , 9 Frameworks names for each column
      let count = 12;
      let colCount = 0;
      frameworks.forEach(fr => {
        worksheet.getRow(count).getCell(colNames[colCount]).value = fr;
        count++;
        if (count === 20) {
          count = 12;
          colCount++;
        }
      });

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * * * * * * * DATA SCOPE STEP * * * * * * * * * * * *
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      // sorting the data sequentially according to template
      const totalDataSc = [];
      dataScope[0].forEach(sec => {
        totalDataSc.push(sec.displayValue ? sec.displayValue : null);
      });
      dataScope[1].forEach(sec => {
        totalDataSc.push(sec.displayValue ? sec.displayValue : null);
      });

      // storing the data into workbook
      colNames = 'BCDEFGHI'.split('');
      const row23 = worksheet.getRow(23);
      colNames.forEach((col, index) => {
        if (totalDataSc[index]) {
          row23.getCell(col).value = totalDataSc[index];
        } else {
          row23.getCell(col).value = '-';
          row3.getCell(col).alignment = { horizontal: 'center' };
        }
      });

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * * * * * * * TECHNICAL STEP * * * * * * * * * * * *
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      // sorting the data sequentially according to template
      const totalTech = [];
      tech[0].forEach((sec, index) => {
        if (index > 1) {
          sec.value = sec.value ? '$' + sec.value : '$0';
        }

        totalTech.push(sec.value ? sec.value : null);
      });
      tech[1].forEach((sec, index) => {
        if (index > 3) {
          sec.value = sec.value ? '$' + sec.value : '$0';
        }

        totalTech.push(sec.value ? sec.value : null);
      });

      // storing the data into workbook
      colNames = 'BCDEFGHIJKL'.split('');
      const row26 = worksheet.getRow(26); // titles of Technical section
      const row27 = worksheet.getRow(27);
      colNames.forEach((col, index) => {
        if (totalTech[index]) {
          row27.getCell(col).value = totalTech[index];
        } else {
          row27.getCell(col).value = '-';
          row3.getCell(col).alignment = { horizontal: 'center' };
        }
        if (!isBeecher && row26.getCell(col).value === 'Cost Per Employee') {
          row26.getCell(col).value = 'Cost Per Teammate';
        }
      });

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * * * * * * * RISK SCENARIOS STEP * * * * * * * * * * * *
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

      const dataBre = riskScene[0].moreChildren;
      const busInt = riskScene[1].moreChildren;
      const hybrid = riskScene[2].moreChildren;

      // add inherent first for Data Breach and Business Interruption
      let rowInherent = worksheet.getRow(32);
      rowInherent.getCell('C').value = '$ ' + this.shortNumber.transform(riskScene[0].inherent);

      rowInherent = worksheet.getRow(85);
      rowInherent.getCell('C').value = '$ ' + this.shortNumber.transform(riskScene[1].inherent);

      if (isBeecher) {
        rowInherent = worksheet.getRow(90);
        rowInherent.getCell('C').value = '$ ' + this.shortNumber.transform(riskScene[2].inherent);
        const arr = Array(23).fill({});
        worksheet.insertRows(105, arr, 'i+');
      }

      // storing the total Inherent value
      rowInherent = worksheet.getRow(isBeecher ? 131 : 108);
      rowInherent.getCell('E').value = {
        richText: [
          { text: 'TOTAL INHERENT RISK: ' },
          {
            font: { bold: true, color: { argb: 'FFFFFFF' } },
            text: '$' + this.shortNumber.transform(wizardObj.inherent),
          },
        ],
      };

      // ---------------------------------------------------------------------
      // for Data Breach , first store the title and then it's children if any
      // ---------------------------------------------------------------------

      this.riskScenarioExcelWriter(32, dataBre, worksheet, isBeecher);

      // ---------------------------------------------------------------------
      // storing Business Interruption
      // ---------------------------------------------------------------------
      let rowCount = 85;
      busInt.forEach(bi => {
        const rowD = worksheet.getRow(rowCount);

        rowD.getCell('D').value = bi.name + ': $' + this.shortNumber.transform(bi.value);
        rowCount++;
      });

      // ---------------------------------------------------------------------
      // storing Hybrid Just for Beecher Case
      // ---------------------------------------------------------------------
      if (isBeecher) {
        this.riskScenarioExcelWriter(90, hybrid, worksheet, isBeecher);
      }
      // Saving the File.
      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), fileName);

      this.toastr.success('Download Completed!');
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Write the Risk Scenarios into the PML report Excel sheet
   * @param rowCount The starting row number in excel
   * @param riskScenario The risk scenario object - either Data Breach or Hybrid
   * @param worksheet The Excel Worksheet to write in
   * @param isBeecher is Beecher or BNB Domain
   * @param isD temporary flag used in functionality
   */
  riskScenarioExcelWriter(
    rowCount: number,
    riskScenario: any,
    worksheet,
    isBeecher: boolean,
    isD: boolean = true
  ): void {
    const additionalTitle = ['PII', 'PIFI', 'PHI', 'PCI', 'AR', 'IDT', 'TRN'];
    const beecherTitleMapper = {
      AR: 'Annual Revenue',
      IDT: 'Industry',
      TRN: 'Total Records Without PII',
    };

    riskScenario.forEach(dt => {
      let rowD = worksheet.getRow(rowCount);
      // storing the main title
      if (isD && !(isBeecher && dt.name === 'Security Remediation')) {
        rowD.getCell('D').value = dt.name + ': $' + this.shortNumber.transform(dt.value);
        isD = false;
      }
      // storing the inner children of the current tile
      let innerCount = 0;
      if (!isD && dt.additionalChild && Object.keys(dt.additionalChild).length) {
        const moreChild = dt.additionalChild;
        additionalTitle.forEach((inn, index) => {
          if (moreChild && moreChild[inn] >= 0) {
            if (index > 3) {
              // for beecher titles
              // As we don't need to show Dollar signs with number of records.
              const showDollar = beecherTitleMapper[inn].toLowerCase().includes('records') ? ': ' : ': $';
              rowD = worksheet.getRow(rowCount + innerCount);
              rowD.getCell('E').value =
                beecherTitleMapper[inn] + showDollar + this.shortNumber.transform(moreChild[inn]);
              innerCount++;
            } else {
              const showDollar = dt.name.toLowerCase().includes('records') ? ': ' : ': $';
              rowD = worksheet.getRow(rowCount + innerCount);
              rowD.getCell('E').value = inn + ' ' + dt.name + showDollar + this.shortNumber.transform(moreChild[inn]);
              innerCount++;
            }
          } else if (moreChild && moreChild[inn]) {
            // for beecher titles having String Value
            rowD = worksheet.getRow(rowCount + innerCount);
            rowD.getCell('E').value = beecherTitleMapper[inn] + ': ' + moreChild[inn];
            rowD.getCell('E').alignment = { wrapText: false };
            innerCount++;
          }
          if (innerCount > 1) {
            rowD.getCell('D').value = '';
          }
        });
        const rowEmpty = worksheet.getRow(rowCount + innerCount);
        rowEmpty.getCell('D').value = '';
        isD = true;
      }
      if (!innerCount) {
        isD = true;
      }
      rowCount = rowCount + innerCount + 1;
    });
  }

  // to save a log related to question targetId pattern is 'assessmentId#questionId'
  // type may contain useful keywords like QUESTION#<framework-name>
  async addLog(targetId: string, assessmentId: string, type: string, message: string): Promise<CreateLogsInput> {
    const log = {
      id: uuid(),
      message,
      targetId,
      type,
      assessmentId,
    };
    return await this.customApi.CreateLogs(log);
  }

  async getWizardCSVTemplate(): Promise<void> {
    let urlTemplate = null;
    const res = await this.fileService.checkIfPathExistsS3('BNB_TEMPLATES/bnb-wizard-template.xlsx');
    if (res && res.length) {
      urlTemplate = await this.fileService.downloadFileFromS3('public/BNB_TEMPLATES/bnb-wizard-template.xlsx');
    } else {
      this.toastr.error('No Template Found!');
      return;
    }
    if (urlTemplate) {
      const response = await fetch(urlTemplate);
      const reader = response.body.getReader();
      let completion = false;
      const chunks = [];
      while (!completion) {
        const { done, value } = await reader.read();
        if (done) {
          completion = true;
        } else {
          chunks.push(value);
        }
      }
      const blob = new Blob(chunks);
      const buffer = await blob.arrayBuffer();
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(buffer);
      this.wizardCSVTemplate = workbook;
    }
  }

  beecherPMLReportTitleChange(titleName: string): string {
    let finalName = titleName?.trim();
    switch (finalName) {
      case 'Entity Name':
        finalName = 'Client Name';
        break;
      case 'Profit Center Leader':
        finalName = 'Primary Risk Management Contact';
        break;
      case 'Finance Leader Name':
        finalName = 'Accounting Leader Name';
        break;
      case 'Annual Cost of Services':
        finalName = 'Annual Cost of Goods and/or Services';
        break;
      default:
        finalName = titleName;
        break;
    }
    return finalName;
  }

  /**
   * This function take list of ids as input and return the list of entities
   * @param entityIds list of strings that contains entityIds
   * @returns return the list of Entities
   */
  async getEntitiesByGivenIds(entityIds: string[]): Promise<GetEntityQuery[]> {
    try {
      if (entityIds && entityIds.length) {
        const promises = [];
        entityIds.map(entityId => {
          promises.push(this.getEntity(entityId));
        });
        const result = await Promise.all(promises);
        // Filter Entities by current Domain
        const domain = DomainFrameworkService.getDomain();
        return result.filter(entity => entity?.domain === domain);
      }
    } catch (e) {
      console.log(e);
    }
    return [];
  }

  /**
   * This function get the collection date / due date and return the diff from today
   * @param collectionDate timestamp
   * @returns number
   */
  static calcDays2Target(collectionDate: number): number {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const firstDate: any = new Date(collectionDate);
    const secondDate: any = new Date();
    return Math.round((firstDate - secondDate) / oneDay);
  }

  /**
   *  Get All Default Roles....
   * This function is copied here from src/app/shared/user.service.ts to avoid circular dependency.
   * Make sure to sync it with that function if any change occurs.
   * @returns roles list
   */
  async getDefaultRoles(): Promise<GetRoleQuery[]> {
    // if default roles are not cached in service than get from DB.
    let items: any = [];
    try {
      const result = await this.customApi.RolesByDefaultOrEntityId(UsersSettingConstant.default);
      items = result.items;
    } catch (e) {
      console.log(e);
      return [];
    }
    this.defaultRoles = [...items];
    return items;
  }
  updateEntityMapper(entity: any, isVendor = true) {
    if (EntityService.entityMap[entity.id] && isVendor) {
      EntityService.entityMap[entity.id].vendorDetails = entity.vendorDetails;
    } else {
      EntityService.entityMap[entity.id] = entity;
    }
  }

  setDataLoading(isDataLoading: boolean) {
    this.isDataLoaded.next(!isDataLoading);
  }

  async getListOfStandardFrameworkListWithNextToken(
    assessmentId: string,
    subEntity: any,
    providedNextToken: string
  ): Promise<void> {
    try {
      let nextToken = providedNextToken;
      do {
        const standardFrameworkResponse = await this.customApi.StandardFrameworksByAssessmentId(
          assessmentId,
          null,
          null,
          1000,
          nextToken
        );

        subEntity.activeAssessment.standardFrameworkList.items = [
          ...subEntity?.activeAssessment?.standardFrameworkList?.items,
          ...standardFrameworkResponse.items,
        ];
        nextToken = standardFrameworkResponse.nextToken;
      } while (nextToken);

      const uniqueItemsMap = new Map();
      subEntity.activeAssessment.standardFrameworkList.items.forEach(item => {
        uniqueItemsMap.set(item.id, item);
      });
      const uniqueItems = Array.from(uniqueItemsMap.values());
      subEntity.activeAssessment.standardFrameworkList.items = uniqueItems;
    } catch (err) {
      console.log('Err:: Failed to get assessment standard framework list with next token');
    }
  }

  async getWizardByParentId(rootId: string) {
    try {
      this.wizard = await this.customApi.GetEntityWizard(rootId);
      if (this.wizard) {
        const accountDetail = JSON.parse(this.wizard.accountDetails);
        accountDetail[1].map((detail, idx) => {
          // added these hard-coded checks becuase this is static object.
          if (detail.value && idx <= 1) {
            const emailExists = this.brokersEmail.some(broker => broker.email === detail.value);
            if (!emailExists) {
              this.brokersEmail.push({ email: detail.value, name: 'Dummy Name' });
            }
          }
        });
      }
    } catch (err) {
      console.log('Error while fetching entity Wizard');
    }
  }

  /**
   *
   * this function update the subEntity List on the basis of provided data
   */
  updateSubEntityFrameworkCache(updatedFramework: any, rootId: string, isUpdate = true) {
    const index = this.subEntitiesListByParent[rootId].findIndex(se => se.id === updatedFramework?.assessment.childId);
    if (index >= 0 && isUpdate) {
      this.subEntitiesListByParent[rootId][index].activeAssessment.standardFrameworkList.items =
        this.subEntitiesListByParent[rootId][index].activeAssessment.standardFrameworkList.items.map(f =>
          f.id === updatedFramework.id ? updatedFramework : f
        );
    } else {
      this.subEntitiesListByParent[rootId][index].activeAssessment.standardFrameworkList.items.push(updatedFramework);
    }
    if (EntityService.entityMap[this.subEntitiesListByParent[rootId][index].activeAssessment.childId]) {
      EntityService.entityMap[this.subEntitiesListByParent[rootId][index].activeAssessment.childId] = {
        ...this.subEntitiesListByParent[rootId][index],
      };
    } else {
      EntityService.entityMap = {
        ...EntityService.entityMap,
        [this.subEntitiesListByParent[rootId][index].activeAssessment.childId]: {
          ...this.subEntitiesListByParent[rootId][index],
        },
      };
    }
    if (EntityService.promCache[this.subEntitiesListByParent[rootId][index].activeAssessment.childId]) {
      EntityService.promCache[this.subEntitiesListByParent[rootId][index].activeAssessment.childId] = {
        ...this.subEntitiesListByParent[rootId][index],
      };
    } else {
      EntityService.promCache = {
        ...EntityService.promCache,
        [this.subEntitiesListByParent[rootId][index].activeAssessment.childId]: {
          ...this.subEntitiesListByParent[rootId][index],
        },
      };
    }
  }
  updateSubEntityCache(childEntityList: any, rootId: string) {
    if (this.subEntitiesListByParent[rootId]) {
      if (!this.subEntitiesListByParent[rootId].length) {
        this.subEntitiesListByParent[rootId] = [...this.subEntitiesListByParent[rootId], ...childEntityList];
      } else {
        childEntityList.map(se => {
          const index = this.subEntitiesListByParent[rootId].findIndex(s => se.id === s.id);
          if (index >= 0) {
            this.subEntitiesListByParent[rootId][index] = se;
          } else {
            this.subEntitiesListByParent[rootId].push(se);
          }
        });
      }
    } else {
      this.subEntitiesListByParent[rootId] = [...childEntityList];
    }
  }

  onCreateVendorDetailListner() {
    return this.customApi.OnCreateVendorsDetailListener(this.currentUserToken).subscribe(data => {
      this.onCreateVendorDetailListner$.next();
    });
  }

  loadSubscriptions() {
    this.subscriptionList = [
      this.onCreateEntityListener(),
      // this.onUpdateEntityListener(),
      this.onUpdateEntityIntegrationListener(),
      this.onDeleteEntityListener(),
      this.onWizardDomainListener(),
      this.onBreachesUpdate(),
      this.onCreateVendorDetailListner(),
    ];
  }
  async updateSpiderChartSettings(entity) {
    return await this.customApi.UpdateEntity(entity);
  }
}
