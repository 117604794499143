import { Injectable } from '@angular/core';
import { API, GraphQLSubscription } from '@aws-amplify/api';
import { Auth, graphqlOperation, Hub } from 'aws-amplify';
import {
  AnswersByAssessmentIdAndFrameworkKeyQuery,
  ArchivedUserByEntityIdQuery,
  ArchivedUserRoleQuery,
  AssessmentsByChildIdQuery,
  AssessmentsByRootIdQuery,
  AssignmentsByAssessmentIdQuery,
  AssignmentsByUserIdAndAssessmentQuery,
  AssignmentsByUserIdQuery,
  BreachesByVendorIdAndStatusQuery,
  CommentByAssessmentIdQuery,
  CommentsByAssessmentIdAndQuestionIdQuery,
  CommentsByQuestionIdQuery,
  CompletionByAssessmentQuery,
  CompletionByUserQuery,
  CreateAnswerInput,
  CreateAnswerMutation,
  CreateAssessmentInput,
  CreateAssessmentMutation,
  CreateAssignmentInput,
  CreateAssignmentMutation,
  CreateBreachesInput,
  CreateBreachesMutation,
  CreateDataStateInput,
  CreateDataStateMutation,
  CreateDisableAssignmentInput,
  CreateDisableAssignmentMutation,
  CreateDomainSettingsInput,
  CreateDomainSettingsMutation,
  CreateExecutiveSummaryInput,
  CreateExecutiveSummaryMutation,
  CreateFrameworkScoresInput,
  CreateFrameworkScoresMutation,
  CreateGroupInput,
  CreateGroupMutation,
  CreateIntelligenceInput,
  CreateIntelligenceMutation,
  ModelRiskOwnerAssignmentFilterInput,
  RiskAssignmentByRiskIdQuery,
  CreateNetskopeProjectInput,
  CreateNetskopeProjectMutation,
  CreateRoleInput,
  CreateRoleMutation,
  CreateSmartMappingDetailInput,
  CreateSmartMappingDetailMutation,
  CreateTagsInput,
  CreateTagsMutation,
  CreateUserMutation,
  CreateVersionsInput,
  CreateVersionsMutation,
  CustomTaskAssignmentsByAssessmentIdQuery,
  CustomTasksByAssessmentIdQuery,
  DataStatesByParentIdQuery,
  DeleteAnswerInput,
  DeleteAnswerMutation,
  DeleteAssignmentInput,
  DeleteAssignmentMutation,
  DeleteDataStateInput,
  DeleteDataStateMutation,
  DeleteDisableAssignmentInput,
  DeleteDisableAssignmentMutation,
  DeleteDomainSettingsInput,
  DeleteDomainSettingsMutation,
  DeleteExecutiveSummaryInput,
  DeleteExecutiveSummaryMutation,
  DeleteFrameworkScoresInput,
  DeleteFrameworkScoresMutation,
  DeleteGroupInput,
  DeleteGroupMutation,
  DeleteIntelligenceInput,
  DeleteIntelligenceMutation,
  DeleteNetskopeProjectInput,
  DeleteNetskopeProjectMutation,
  DeleteRiskInput,
  DeleteRiskMutation,
  DeleteRoleInput,
  DeleteRoleMutation,
  DeleteSmartMappingDetailInput,
  DeleteSmartMappingDetailMutation,
  DeleteTagsInput,
  DeleteTagsMutation,
  DeleteUserInput,
  DeleteUserMutation,
  DeleteVendorsDetailInput,
  DeleteVendorsDetailMutation,
  DeleteVersionsInput,
  DeleteVersionsMutation,
  DisableAssignmentsByAssessmentIdQuery,
  DisableAssignmentsByUserIdAndAssessmentQuery,
  DisableAssignmentsByUserIdQuery,
  DomainSettingByDomainQuery,
  EntityLayersByParentIdQuery,
  EntityWizardByParentAndDomainQuery,
  EntityWizardByParentIdQuery,
  ExportsByCategoryIdQuery,
  ExportsByDestinationCategoryIdQuery,
  ExternalThreatsByVendorIdAndStatusQuery,
  FrameworkManagersByAssessmentIdQuery,
  FrameworkManagersByManagerIdQuery,
  FrameworkManagersByStandardFrameworkIdQuery,
  FrameworkScoresByAssessmentQuery,
  GetArchivedUserQuery,
  GetAssessmentQuery,
  GetAssessmentStandardFrameworkQuery,
  GetAssignmentQuery,
  GetBreachesQuery,
  GetCommentQuery,
  GetCustomTaskAssignmentQuery,
  GetCustomTaskQuery,
  GetDataStateQuery,
  GetDisableAssignmentQuery,
  GetDomainSettingsQuery,
  GetEntityLayersQuery,
  GetEntityQuery,
  GetEntityWizardQuery,
  GetExecutiveSummaryQuery,
  GetExportQuery,
  GetExternalThreatsQuery,
  GetFrameworkManagerQuery,
  GetFrameworkScoresQuery,
  GetFrameworkSettingsQuery,
  GetIntegrationMappingsQuery,
  GetIntelligenceQuery,
  GetLogsQuery,
  GetNetskopeProjectQuery,
  GetOthersQuery,
  GetQuestionDataQuery,
  GetQuestionSettingsQuery,
  GetQuestionnaireCompletionQuery,
  GetReportQuery,
  GetRevisionQuery,
  GetRiskQuery,
  GetSmartMappingDetailQuery,
  GetTagsQuery,
  GetTaskActionQuery,
  GetUserQuery,
  GetUsersAssignmentMapperQuery,
  GetVendorsDetailQuery,
  GetVersionsQuery,
  GroupsByRootIdQuery,
  IncrementQuestionnaireAnswerMutation,
  IntegrationMappingsByProjectKeyQuery,
  JiraAuthInput,
  ListAlertsQuery,
  ListArchivedUsersQuery,
  ListAssessmentStandardFrameworksQuery,
  ListAssessmentsQuery,
  ListAssignmentsQuery,
  ListBreachesQuery,
  ListCommentsQuery,
  ListCustomTaskAssignmentsQuery,
  ListCustomTasksQuery,
  ListDataStatesQuery,
  ListDisableAssignmentsQuery,
  ListEntityWizardsQuery,
  ListExportsQuery,
  ListExternalThreatsQuery,
  ListFrameworkManagersQuery,
  ListFrameworkScoresQuery,
  ListFrameworkSettingsQuery,
  ListGroupsQuery,
  ListIntegrationMappingsQuery,
  ListIntelligencesQuery,
  ListLogsQuery,
  ListNetskopeProjectsQuery,
  ListOthersQuery,
  ListQuestionDataQuery,
  ListQuestionSettingsQuery,
  ListQuestionnaireCompletionsQuery,
  ListReportsQuery,
  ListRisksQuery,
  ListSmartMappingDetailsQuery,
  ListTaskActionsQuery,
  ListUsersAssignmentMappersQuery,
  ListUsersQuery,
  ListVendorsDetailsQuery,
  ListVersionsQuery,
  LogsByAssessmentIdAndTypeQuery,
  LogsByAssessmentIdQuery,
  LogsByTargetIdAndTypeQuery,
  LogsByTargetIdQuery,
  MetadataBySmartMapIdQuery,
  ModelAnswerConditionInput,
  ModelAnswerFilterInput,
  ModelArchivedUserFilterInput,
  ModelAssessmentConditionInput,
  ModelAssessmentFilterInput,
  ModelAssessmentStandardFrameworkFilterInput,
  ModelAssignmentConditionInput,
  ModelAssignmentFilterInput,
  ModelBooleanInput,
  ModelBreachesConditionInput,
  ModelBreachesFilterInput,
  ModelCommentFilterInput,
  ModelCustomTaskAssignmentFilterInput,
  ModelCustomTaskFilterInput,
  ModelDataStateConditionInput,
  ModelDataStateFilterInput,
  ModelDisableAssignmentConditionInput,
  ModelDisableAssignmentFilterInput,
  ModelDomainSettingsConditionInput,
  ModelDomainSettingsFilterInput,
  ModelEntityFilterInput,
  ModelEntityLayersFilterInput,
  ModelEntityWizardFilterInput,
  ModelExecutiveSummaryConditionInput,
  ModelExecutiveSummaryFilterInput,
  ModelExportFilterInput,
  ModelExternalThreatsFilterInput,
  ModelFrameworkManagerFilterInput,
  ModelFrameworkScoresConditionInput,
  ModelFrameworkScoresFilterInput,
  ModelFrameworkSettingsFilterInput,
  ModelGroupConditionInput,
  ModelGroupFilterInput,
  ModelIDInput,
  ModelIDKeyConditionInput,
  ModelIntInput,
  ModelIntegrationMappingsFilterInput,
  ModelIntelligenceConditionInput,
  ModelIntelligenceFilterInput,
  ModelLogsFilterInput,
  ModelNetskopeProjectConditionInput,
  ModelNetskopeProjectFilterInput,
  ModelOthersFilterInput,
  ModelQuestionDataFilterInput,
  ModelQuestionSettingsFilterInput,
  ModelQuestionnaireCompletionFilterInput,
  ModelReportFilterInput,
  ModelRiskConditionInput,
  ModelRiskFilterInput,
  ModelRoleConditionInput,
  ModelRoleEnumInput,
  ModelSmartMappingDetailConditionInput,
  ModelSmartMappingDetailFilterInput,
  ModelSortDirection,
  ModelStringInput,
  ModelStringKeyConditionInput,
  ModelTagsConditionInput,
  ModelTaskActionFilterInput,
  ModelUserFilterInput,
  ModelUsersAssignmentMapperFilterInput,
  ModelVendorsDetailConditionInput,
  ModelVendorsDetailFilterInput,
  ModelVersionsConditionInput,
  ModelVersionsFilterInput,
  OthersByVendorIdAndStatusQuery,
  ProjectsByEntityIdQuery,
  QuestionSettingsByAssessmentIdQuery,
  QuestionSettingsByQuestionIdQuery,
  QuestionsByAssessmentIdQuery,
  Rapid7Input,
  RemoveUserAssignmentMutation,
  ReportByEntityIdQuery,
  RiskByAssessmentIdQuery,
  RiskByEntityIdQuery,
  ServiceNowAuthInput,
  StandardFrameworksByAssessmentIdQuery,
  SummaryByEntityIdQuery,
  TaskActionsByAssessmentIdAndTabNameQuery,
  TaskActionsByAssessmentIdQuery,
  TaskActionsByRootEntityIdQuery,
  TaskActionsByUserIdQuery,
  TasksByUserIdAndAssessmentIdQuery,
  TasksByUserIdQuery,
  TenableDetailsInput,
  UpdateAnswerInput,
  UpdateAnswerMutation,
  UpdateAssignmentInput,
  UpdateAssignmentMutation,
  UpdateBreachesInput,
  UpdateBreachesMutation,
  UpdateDataStateInput,
  UpdateDataStateMutation,
  UpdateDisableAssignmentInput,
  UpdateDisableAssignmentMutation,
  UpdateDomainSettingsInput,
  UpdateDomainSettingsMutation,
  UpdateExecutiveSummaryInput,
  UpdateExecutiveSummaryMutation,
  UpdateFrameworkScoresInput,
  UpdateFrameworkScoresMutation,
  UpdateGroupInput,
  UpdateGroupMutation,
  UpdateIntelligenceInput,
  UpdateIntelligenceMutation,
  UpdateNetskopeProjectInput,
  UpdateNetskopeProjectMutation,
  UpdateRoleInput,
  UpdateRoleMutation,
  UpdateSmartMappingDetailInput,
  UpdateSmartMappingDetailMutation,
  UpdateTagsInput,
  UpdateTagsMutation,
  UpdateVendorsDetailInput,
  UpdateVendorsDetailMutation,
  UpdateVersionsInput,
  UpdateVersionsMutation,
  UserByEmailQuery,
  UserByEntityIdQuery,
  UserByRoleIdQuery,
  UserRoleQuery,
  UsersByAssessmentIdQuery,
  UsersByTaskIdQuery,
  VersionsByAssessmentIdAndTypeQuery,
  VersionsByAssessmentIdQuery,
  VersionsByTargetIdAndTypeQuery,
  VersionsByTargetIdQuery,
  AnswersByAssessmentIdAndQuestionIdQuery,
  AnswersByAssessmentIdAndUserIdQuery,
  AnswersByAssessmentIdQuery,
  AnswersByAssessmentQuestionUserQuery,
  AnswersByQuestionIdAndUserQuery,
  AnswersByQuestionIdQuery,
  AnswersByUserIdQuery,
  CreateArchivedUserInput,
  CreateArchivedUserMutation,
  CreateAssessmentStandardFrameworkInput,
  CreateAssessmentStandardFrameworkMutation,
  CreateCommentInput,
  CreateCommentMutation,
  CreateCustomTaskAssignmentInput,
  CreateCustomTaskAssignmentMutation,
  CreateCustomTaskInput,
  CreateCustomTaskMutation,
  CreateEntityInput,
  CreateEntityLayersInput,
  CreateEntityLayersMutation,
  CreateEntityMutation,
  CreateEntityWizardInput,
  CreateEntityWizardMutation,
  CreateExportInput,
  CreateExportMutation,
  CreateExternalThreatsInput,
  CreateExternalThreatsMutation,
  CreateFrameworkManagerInput,
  CreateFrameworkManagerMutation,
  CreateFrameworkSettingsInput,
  CreateFrameworkSettingsMutation,
  CreateLogsInput,
  CreateLogsMutation,
  CreateOthersInput,
  CreateOthersMutation,
  CreateQuestionDataInput,
  CreateQuestionDataMutation,
  CreateQuestionnaireCompletionInput,
  CreateQuestionnaireCompletionMutation,
  CreateReportInput,
  CreateReportMutation,
  CreateRiskInput,
  CreateRiskMutation,
  CreateUsersAssignmentMapperInput,
  CreateUsersAssignmentMapperMutation,
  CreateVendorsDetailInput,
  CreateVendorsDetailMutation,
  DeleteArchivedUserInput,
  DeleteArchivedUserMutation,
  DeleteAssessmentStandardFrameworkInput,
  DeleteAssessmentStandardFrameworkMutation,
  DeleteBreachesInput,
  DeleteBreachesMutation,
  DeleteCommentInput,
  DeleteCommentMutation,
  DeleteCustomTaskAssignmentInput,
  DeleteCustomTaskAssignmentMutation,
  DeleteCustomTaskInput,
  DeleteCustomTaskMutation,
  DeleteEntityInput,
  DeleteEntityLayersInput,
  DeleteEntityLayersMutation,
  DeleteEntityMutation,
  DeleteEntityWizardInput,
  DeleteEntityWizardMutation,
  DeleteExportInput,
  DeleteExportMutation,
  DeleteExternalThreatsInput,
  DeleteExternalThreatsMutation,
  DeleteFrameworkManagerInput,
  DeleteFrameworkManagerMutation,
  DeleteFrameworkSettingsInput,
  DeleteFrameworkSettingsMutation,
  DeleteLogsInput,
  DeleteLogsMutation,
  DeleteOthersInput,
  DeleteOthersMutation,
  DeleteQuestionDataInput,
  DeleteQuestionDataMutation,
  DeleteQuestionnaireCompletionInput,
  DeleteQuestionnaireCompletionMutation,
  DeleteReportInput,
  DeleteReportMutation,
  DeleteTaskActionInput,
  DeleteTaskActionMutation,
  DeleteUsersAssignmentMapperInput,
  DeleteUsersAssignmentMapperMutation,
  EntitiesByDomainAndCreatedAtQuery,
  EntitiesByDomainAndEntityTypeQuery,
  EntitiesByEntityTypeQuery,
  EntityByAssessmentIdQuery,
  EntityChildEntityByTypeQuery,
  EntityFrameworkQuery,
  EntityParentQuery,
  EntityRootEntityByTypeAndFrameworkNameQuery,
  EntityRootEntityByTypeQuery,
  GetAnswerQuery,
  GetGroupQuery,
  GetRoleQuery,
  ListAnswersQuery,
  ListRolesQuery,
  ListTagsQuery,
  ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput,
  ModelArchivedUserConditionInput,
  ModelAssessmentStandardFrameworkConditionInput,
  ModelCommentConditionInput,
  ModelCustomTaskAssignmentConditionInput,
  ModelCustomTaskConditionInput,
  ModelEntityConditionInput,
  ModelEntityLayersConditionInput,
  ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput,
  ModelEntityWizardConditionInput,
  ModelExportConditionInput,
  ModelExternalThreatsConditionInput,
  ModelFrameworkManagerConditionInput,
  ModelFrameworkSettingsConditionInput,
  ModelIntKeyConditionInput,
  ModelLogsConditionInput,
  ModelOthersConditionInput,
  ModelQuestionDataConditionInput,
  ModelQuestionnaireCompletionConditionInput,
  ModelReportConditionInput,
  ModelRoleFilterInput,
  ModelTagsFilterInput,
  ModelTaskActionConditionInput,
  ModelUsersAssignmentMapperConditionInput,
  RolesByDefaultOrEntityIdQuery,
  TagsByAssessmentFrameworkKeyQuery,
  TagsByEntityIdQuery,
  TagsByTargetIdQuery,
  UpdateArchivedUserInput,
  UpdateArchivedUserMutation,
  UpdateAssessmentStandardFrameworkInput,
  UpdateAssessmentStandardFrameworkMutation,
  UpdateCommentInput,
  UpdateCommentMutation,
  UpdateCustomTaskAssignmentInput,
  UpdateCustomTaskAssignmentMutation,
  UpdateCustomTaskInput,
  UpdateCustomTaskMutation,
  UpdateEntityInput,
  UpdateEntityLayersInput,
  UpdateEntityLayersMutation,
  UpdateEntityMutation,
  UpdateEntityWizardInput,
  UpdateEntityWizardMutation,
  UpdateExportInput,
  UpdateExportMutation,
  UpdateExternalThreatsInput,
  UpdateExternalThreatsMutation,
  UpdateFrameworkManagerInput,
  UpdateFrameworkManagerMutation,
  UpdateFrameworkSettingsInput,
  UpdateFrameworkSettingsMutation,
  UpdateLogsInput,
  UpdateLogsMutation,
  UpdateOthersInput,
  UpdateOthersMutation,
  UpdateQuestionDataInput,
  UpdateQuestionDataMutation,
  UpdateQuestionnaireCompletionInput,
  UpdateQuestionnaireCompletionMutation,
  UpdateReportInput,
  UpdateReportMutation,
  UpdateRiskInput,
  UpdateRiskMutation,
  UpdateUsersAssignmentMapperInput,
  UpdateUsersAssignmentMapperMutation,
  FileTypeEnum,
  EntityTypeEnum,
  ListEntitiesQuery,
  ListDomainSettingsQuery,
  ListExecutiveSummariesQuery,
  ListEntityLayersQuery,
  UpdateAssessmentInput,
  UpdateAssessmentMutation,
  UpdateQuestionSettingsMutation,
  UpdateQuestionSettingsInput,
  ModelQuestionSettingsConditionInput,
  UpdateTaskActionInput,
  UpdateTaskActionMutation,
  UpdateGraphMutation,
  ModelGraphConditionInput,
  UpdateGraphInput,
  DeleteGraphInput,
  DeleteGraphMutation,
  ListGraphsQuery,
  ModelGraphFilterInput,
  CreateGraphInput,
  CreateGraphMutation,
  GraphsByRootIdQuery,
  CreateRiskOwnerAssignmentInput,
  CreateRiskOwnerAssignmentMutation,
  CommentsByRiskIdQuery,
  ModelRiskOwnerAssignmentConditionInput,
  CreateSavedFilterInput,
  ModelSavedFilterConditionInput,
  CreateSavedFilterMutation,
  UpdateSavedFilterInput,
  UpdateSavedFilterMutation,
  DeleteSavedFilterInput,
  DeleteSavedFilterMutation,
  ModelSavedFilterFilterInput,
  SavedFiltersByEntityIdAndUserIdQuery,
  RiskAssignmentByUserIdQuery,
  DeleteRiskOwnerAssignmentInput,
  DeleteRiskOwnerAssignmentMutation,
  UpdateRiskOwnerAssignmentInput,
  UpdateRiskOwnerAssignmentMutation,
  CommentsByFrameworkAndAssessmentIdQuery,
  CreateRiskLineChartInput,
  ListRiskLineChartsQuery,
  ModelRiskLineChartFilterInput,
  GetRiskLineChartQuery,
  CreateRiskLineChartMutation,
  ModelRiskLineChartConditionInput,
  SubscriptionResponse,
  __SubscriptionContainer,
  ModelSubscriptionQuestionnaireCompletionFilterInput,
  ModelSubscriptionFrameworkScoresFilterInput,
  ModelSubscriptionEntityFilterInput,
  ModelSubscriptionTagsFilterInput,
  ModelSubscriptionLogsFilterInput,
  ModelSubscriptionVersionsFilterInput,
  ModelSubscriptionAssessmentStandardFrameworkFilterInput,
  ModelSubscriptionUserFilterInput,
  ModelSubscriptionRoleFilterInput,
  ModelSubscriptionGroupFilterInput,
  ModelSubscriptionBreachesFilterInput,
  ModelSubscriptionEntityWizardFilterInput,
  ModelSubscriptionVendorsDetailFilterInput,
  ModelSubscriptionDataStateFilterInput,
  ModelSubscriptionIntelligenceFilterInput,
  ModelSubscriptionAssessmentFilterInput,
  ModelSubscriptionAnswerFilterInput,
  CreateTaskActionMutation,
  CreateTaskActionInput,
} from './API.service';
import * as CryptoJS from 'crypto-js';
import { v4 as uuid } from 'uuid';
import { Observable, Subject } from 'rxjs';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
/* tslint:disable */
/* eslint-disable */

export type User = {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  updatedAt: string;
};

export enum RoleEnum {
  ADMIN = 'ADMIN',
  MSSP = 'MSSP',
  LEADER = 'LEADER',
  VENDOR = 'VENDOR',
  PARTICIPANT = 'PARTICIPANT',
  ENTITY_LEADER = 'ENTITY_LEADER',
  CUSTOM = 'CUSTOM',
}

export enum MidMarketEnum {
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  COMPLETED = 'COMPLETED',
}

export type CreateUserInput = {
  id?: string | null;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  onBoardingStatus?: MidMarketEnum | null;
};

export type OnDeleteSmartMappingDetailSubscription = {
  __typename: 'SmartMappingDetail';
  id: string;
  uniqueId: string;
  metadata: string;
  answer?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateUserInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
};

export type ModelUserConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  role?: ModelRoleEnumInput | null;
  roleId?: ModelIDInput | null;
  isViewOnly?: ModelBooleanInput | null;
  phone?: ModelStringInput | null;
  entityId?: ModelIDInput | null;
  entityIds?: ModelStringInput | null;
  isCognitoUser?: ModelBooleanInput | null;
  questionMap?: ModelStringInput | null;
  createdBy?: ModelStringInput | null;
  createdAt?: ModelIntInput | null;
  lastLogin?: ModelIntInput | null;
  invitationDate?: ModelIntInput | null;
  reminderDate?: ModelIntInput | null;
  modifiedBy?: ModelStringInput | null;
  modifiedAt?: ModelIntInput | null;
  domain?: ModelStringInput | null;
  temporaryHigherRank?: ModelBooleanInput | null;
  entityLayerIds?: ModelStringInput | null;
  clickThrough?: ModelBooleanInput | null;
  clickThroughDate?: ModelStringInput | null;
  projectManagerEmail?: ModelStringInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type UpdateUserMutation = {
  __typename: 'User';
  id: string;
  name: string;
  email: string;
  role?: RoleEnum | null;
  roleId?: string | null;
  isViewOnly?: boolean | null;
  phone?: string | null;
  entityId?: string | null;
  entityIds?: Array<string | null> | null;
  isCognitoUser?: boolean | null;
  questionMap?: string | null;
  createdBy?: string | null;
  createdAt?: number | null;
  lastLogin?: number | null;
  invitationDate?: number | null;
  reminderDate?: number | null;
  modifiedBy?: string | null;
  modifiedAt?: number | null;
  domain?: string | null;
  temporaryHigherRank?: boolean | null;
  entityLayerIds?: Array<string | null> | null;
  clickThrough?: boolean | null;
  clickThroughDate?: string | null;
  projectManagerEmail?: string | null;
  updatedAt: string;
};

export type CreateFrameworksNodesInput = {
  frameworkList?: Array<any> | null;
  accessGroups: Array<string>;
  vendorRawData?: string | null;
};

export type AddGroupInput = {
  id: string;
  desc?: string | null;
};

export type CreateFrameworksNodesMutation = {
  __typename: 'IDList';
  ids?: Array<string | null> | null;
};

export type AssignUserInput = {
  userId: string;
  targetId: string;
  assessmentId: string;
  left: number;
  right: number;
};

export type AssignUserMutation = {
  __typename: 'AssignUser';
  createdAssignments?: Array<{
    __typename: 'Assignment';
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: 'User';
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
  } | null> | null;
  deletedAssignments?: Array<{
    __typename: 'Assignment';
    id: string;
    userId: string;
    targetId: string;
    assessmentId: string;
    user?: {
      __typename: 'User';
      id: string;
      name: string;
      email: string;
      role?: RoleEnum | null;
      roleId?: string | null;
      isViewOnly?: boolean | null;
      phone?: string | null;
      entityId?: string | null;
      entityIds?: Array<string | null> | null;
      isCognitoUser?: boolean | null;
      questionMap?: string | null;
      createdBy?: string | null;
      createdAt?: number | null;
      lastLogin?: number | null;
      invitationDate?: number | null;
      reminderDate?: number | null;
      modifiedBy?: string | null;
      modifiedAt?: number | null;
      domain?: string | null;
      temporaryHigherRank?: boolean | null;
      entityLayerIds?: Array<string | null> | null;
      clickThrough?: boolean | null;
      clickThroughDate?: string | null;
      projectManagerEmail?: string | null;
      updatedAt: string;
    } | null;
    left: number;
    right: number;
    createdAt?: string | null;
    updatedAt?: string | null;
    disable?: boolean | null;
    deletedUser?: boolean | null;
  } | null> | null;
};

@Injectable({
  providedIn: 'root',
})
export class CUSTOMAPIService {
  webSocketConnectionListner$ = new Subject<any>();
  socketConnectionDisruptionCounter: number = 0;
  constructor() {
    Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState;
        console.log(connectionState);

        // Adding a threshold value of 4 to prevent memory leaks
        if (connectionState === 'ConnectionDisrupted') {
          this.socketConnectionDisruptionCounter++;
          if (this.socketConnectionDisruptionCounter <= 4) {
            this.webSocketConnectionListner$.next({ isConnectionClosed: true });
          }
        } else if (connectionState === 'Connecting') {
          this.socketConnectionDisruptionCounter++;
          if (this.socketConnectionDisruptionCounter <= 4) {
            this.webSocketConnectionListner$.next({ isConnectionClosed: false });
          }
        }
      }
    });
  }
  async CreateFakeData(entityId: string): Promise<boolean | null> {
    const statement = `mutation CreateFakeData($entityId: String!) {
        createFakeData(entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <boolean | null>response.data.createFakeData;
  }
  async CreateFrameworksNodes(input?: CreateFrameworksNodesInput): Promise<CreateFrameworksNodesMutation> {
    const statement = `mutation CreateFrameworksNodes($input: CreateFrameworksNodesInput) {
        createFrameworksNodes(input: $input) {
          __typename
          ids
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateFrameworksNodesMutation>response.data.createFrameworksNodes;
  }

  // async AddCognitoUser(user: string, domain: string): Promise<string | null> {
  //   const statement = `mutation AddCognitoUser($user: String!, $domain: String!) {
  //       addCognitoUser(user: $user, domain: $domain)
  //     }`;
  //   const gqlAPIServiceArguments: any = {
  //     user,
  //     domain,
  //   };

  //   const currentSession: any = await Auth.currentSession();

  //   if (!currentSession) {
  //     return;
  //   }
  //   const token = await this.checkIfRefreshTokenRequired(currentSession);
  //   const payload = this.encryptPayload(
  //     JSON.stringify({
  //       username: currentSession?.accessToken?.payload?.username,
  //       jwtToken: token,
  //       callMaker: uuid(),
  //     }),
  //     currentSession?.accessToken?.payload?.username
  //   );
  //   const response = (await API.graphql(
  //     {
  //       query: statement,
  //       variables: { ...gqlAPIServiceArguments },
  //       authMode: 'AWS_IAM',
  //     },
  //     { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
  //   )) as any;
  //   return <string | null>response.data.addCognitoUser;
  // }

  async AddCognitoUser(user: any): Promise<any | null> {
    const currentSession: any = await Auth.currentSession();
    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(user, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/addUser', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async DeleteCognitoUser(user: any): Promise<any | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(user, currentSession?.accessToken?.payload?.username);
    const response = (await API.del('cygovRestApi', '/deleteUser', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async SendInviteEmail(body: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }

    const payload = this.encryptPayload(body, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/sendInviteEmail', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async SendReminderEmail(body: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }

    const payload = this.encryptPayload(body, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/sendReminderEmail', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async AddCognitoGroup(group: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(group, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/addCognitoGroup', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async updateAICredits(updatedData: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(updatedData, currentSession?.accessToken?.payload?.username);
    const response = (await API.patch('cygovRestApi', '/updateAICredits', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async AssignUser(assignedUser: any): Promise<any> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }

    const payload = this.encryptPayload(assignedUser, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/assignUser', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }

  async AssignQuestion(
    questionId: string,
    userId: string,
    assessmentId: string,
    framework: string
  ): Promise<string | null> {
    const statement = `mutation AssignQuestion($questionId: String!, $userId: String!, $assessmentId: String!, $framework: String!) {
        assignQuestion(questionId: $questionId, userId: $userId, assessmentId: $assessmentId, framework: $framework)
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
      userId,
      assessmentId,
      framework,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <string | null>response.data.assignQuestion;
  }

  async CalcAssessmentScore(assessmentId: string, framework: string): Promise<string | null> {
    const response = (await API.post('cygovRestApi', '/calcAssessmentScore', {
      body: { assessmentId, framework },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }

  async UpdateUser(input: UpdateUserInput, condition?: ModelUserConditionInput): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };

    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }

  async ListEntities(filter?: ModelEntityFilterInput, limit?: number, nextToken?: string): Promise<ListEntitiesQuery> {
    const statement = `query ListEntities($filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        listEntities(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListEntitiesQuery>response.data.listEntities;
  }

  async AnswersByAssessmentIdAndFrameworkKey(
    assessmentId: string,
    frameworkName?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndFrameworkKeyQuery> {
    const statement = `query AnswersByAssessmentIdAndFrameworkKey($assessmentId: ID!, $frameworkName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByAssessmentIdAndFrameworkKey(
        assessmentId: $assessmentId
        frameworkName: $frameworkName
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (frameworkName) {
      gqlAPIServiceArguments.frameworkName = frameworkName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <AnswersByAssessmentIdAndFrameworkKeyQuery>response.data.answersByAssessmentIdAndFrameworkKey;
  }

  async GetAssignment(id: string): Promise<GetAssignmentQuery> {
    const statement = `query GetAssignment($id: ID!) {
        getAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetAssignmentQuery>response.data.getAssignment;
  }

  async ListAssignments(
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssignmentsQuery> {
    const statement = `query ListAssignments($filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListAssignmentsQuery>response.data.listAssignments;
  }

  async AssignmentsByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByUserIdQuery> {
    const statement = `query AssignmentsByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <AssignmentsByUserIdQuery>response.data.assignmentsByUserId;
  }

  async AssignmentsByUserIdAndAssessment(
    userId: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByUserIdAndAssessmentQuery> {
    const statement = `query AssignmentsByUserIdAndAssessment($userId: ID!, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByUserIdAndAssessment(userId: $userId, assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
            frameworkKey
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AssignmentsByUserIdAndAssessmentQuery>response.data.assignmentsByUserIdAndAssessment;
  }

  async AssignmentsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssignmentsByAssessmentIdQuery> {
    const statement = `query AssignmentsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssignmentFilterInput, $limit: Int, $nextToken: String) {
        assignmentsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
            deletedUser
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <AssignmentsByAssessmentIdQuery>response.data.assignmentsByAssessmentId;
  }

  async GetDisableAssignment(id: string): Promise<GetDisableAssignmentQuery> {
    const statement = `query GetDisableAssignment($id: ID!) {
        getDisableAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetDisableAssignmentQuery>response.data.getDisableAssignment;
  }

  async ListDisableAssignments(
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDisableAssignmentsQuery> {
    const statement = `query ListDisableAssignments($filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listDisableAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListDisableAssignmentsQuery>response.data.listDisableAssignments;
  }

  async DisableAssignmentsByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DisableAssignmentsByUserIdQuery> {
    const statement = `query DisableAssignmentsByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        disableAssignmentsByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <DisableAssignmentsByUserIdQuery>response.data.disableAssignmentsByUserId;
  }

  async DisableAssignmentsByUserIdAndAssessment(
    userId: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DisableAssignmentsByUserIdAndAssessmentQuery> {
    const statement = `query DisableAssignmentsByUserIdAndAssessment($userId: ID!, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        disableAssignmentsByUserIdAndAssessment(userId: $userId, assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <DisableAssignmentsByUserIdAndAssessmentQuery>response.data.disableAssignmentsByUserIdAndAssessment;
  }

  async DisableAssignmentsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDisableAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DisableAssignmentsByAssessmentIdQuery> {
    const statement = `query DisableAssignmentsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelDisableAssignmentFilterInput, $limit: Int, $nextToken: String) {
        disableAssignmentsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            left
            right
            createdAt
            updatedAt
            disable
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <DisableAssignmentsByAssessmentIdQuery>response.data.disableAssignmentsByAssessmentId;
  }

  async GetAssessment(id: string): Promise<GetAssessmentQuery> {
    const statement = `query GetAssessment($id: ID!) {
      getAssessment(id: $id) {
        __typename
        id
        standardList {
          __typename
          id
          type
          fileName
          filter {
            __typename
            impact
          }
        }
        standardFrameworkList {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  assessmentId
                  managerId
                  selectedChapters
                  not_added
                  archived
                  reassessmentStatus
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              image
              text
              draw
              signDate
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          isAutoReminder
          isAutoReassessment
        }
        rootId
        childId
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        left
        right
        managers
        exports {
          __typename
          jiraEntityIds
          serviceNowEntityIds
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetAssessmentQuery>response.data.getAssessment;
  }

  async ListAssessments(
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentsQuery> {
    const statement = `query ListAssessments($filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListAssessmentsQuery>response.data.listAssessments;
  }

  async AssessmentsByRootId(
    rootId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssessmentsByRootIdQuery> {
    const statement = `query AssessmentsByRootId($rootId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        assessmentsByRootId(rootId: $rootId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AssessmentsByRootIdQuery>response.data.assessmentsByRootId;
  }

  async AssessmentsByChildId(
    childId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AssessmentsByChildIdQuery> {
    const statement = `query AssessmentsByChildId($childId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentFilterInput, $limit: Int, $nextToken: String) {
        assessmentsByChildId(childId: $childId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      childId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AssessmentsByChildIdQuery>response.data.assessmentsByChildId;
  }

  async GetIntegrationMappings(id: string): Promise<GetIntegrationMappingsQuery> {
    const statement = `query GetIntegrationMappings($id: ID!) {
        getIntegrationMappings(id: $id) {
          __typename
          id
          name
          projectKey
          fileName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetIntegrationMappingsQuery>response.data.getIntegrationMappings;
  }

  async ListIntegrationMappings(
    filter?: ModelIntegrationMappingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntegrationMappingsQuery> {
    const statement = `query ListIntegrationMappings($filter: ModelIntegrationMappingsFilterInput, $limit: Int, $nextToken: String) {
        listIntegrationMappings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            projectKey
            fileName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListIntegrationMappingsQuery>response.data.listIntegrationMappings;
  }

  async IntegrationMappingsByProjectKey(
    projectKey: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelIntegrationMappingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IntegrationMappingsByProjectKeyQuery> {
    const statement = `query IntegrationMappingsByProjectKey($projectKey: String!, $sortDirection: ModelSortDirection, $filter: ModelIntegrationMappingsFilterInput, $limit: Int, $nextToken: String) {
        integrationMappingsByProjectKey(projectKey: $projectKey, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            projectKey
            fileName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      projectKey,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <IntegrationMappingsByProjectKeyQuery>response.data.integrationMappingsByProjectKey;
  }

  async GetQuestionSettings(id: string): Promise<GetQuestionSettingsQuery> {
    const statement = `query GetQuestionSettings($id: ID!) {
        getQuestionSettings(id: $id) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetQuestionSettingsQuery>response.data.getQuestionSettings;
  }

  async ListQuestionSettings(
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionSettingsQuery> {
    const statement = `query ListQuestionSettings($filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        listQuestionSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isCommentsMandatory
            isApprovalRequired
            suggestedArtifactAnswers
            requiredCommentAnswers
            versionFrequency
            isIntegrationActive
            isSmartMappingActive
            createdAt
            updatedAt
            isInfoSec
            frameworkName
            left
            right
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListQuestionSettingsQuery>response.data.listQuestionSettings;
  }

  async QuestionSettingsByQuestionId(
    questionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionSettingsByQuestionIdQuery> {
    const statement = `query QuestionSettingsByQuestionId($questionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        QuestionSettingsByQuestionId(questionId: $questionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isCommentsMandatory
            isApprovalRequired
            suggestedArtifactAnswers
            requiredCommentAnswers
            versionFrequency
            isIntegrationActive
            isSmartMappingActive
            createdAt
            updatedAt
            isInfoSec
            frameworkName
            left
            right
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <QuestionSettingsByQuestionIdQuery>response.data.QuestionSettingsByQuestionId;
  }

  async QuestionSettingsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionSettingsByAssessmentIdQuery> {
    const statement = `query QuestionSettingsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionSettingsFilterInput, $limit: Int, $nextToken: String) {
        QuestionSettingsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            questionId
            assessmentId
            isCollaborative
            isArtifactsMandatory
            isCommentsMandatory
            isApprovalRequired
            suggestedArtifactAnswers
            requiredCommentAnswers
            versionFrequency
            isIntegrationActive
            isSmartMappingActive
            createdAt
            updatedAt
            isInfoSec
            frameworkName
            left
            right
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <QuestionSettingsByAssessmentIdQuery>response.data.QuestionSettingsByAssessmentId;
  }

  async GetTaskAction(id: string): Promise<GetTaskActionQuery> {
    const statement = `query GetTaskAction($id: ID!) {
        getTaskAction(id: $id) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetTaskActionQuery>response.data.getTaskAction;
  }

  async ListTaskActions(
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTaskActionsQuery> {
    const statement = `query ListTaskActions($filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        listTaskActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListTaskActionsQuery>response.data.listTaskActions;
  }

  async TaskActionsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByAssessmentIdQuery> {
    const statement = `query TaskActionsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <TaskActionsByAssessmentIdQuery>response.data.taskActionsByAssessmentId;
  }

  async TaskActionsByAssessmentIdAndTabName(
    assessmentId: string,
    tabName?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByAssessmentIdAndTabNameQuery> {
    const statement = `query TaskActionsByAssessmentIdAndTabName($assessmentId: ID!, $tabName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByAssessmentIdAndTabName(assessmentId: $assessmentId, tabName: $tabName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (tabName) {
      gqlAPIServiceArguments.tabName = tabName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <TaskActionsByAssessmentIdAndTabNameQuery>response.data.taskActionsByAssessmentIdAndTabName;
  }

  async TaskActionsByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByUserIdQuery> {
    const statement = `query TaskActionsByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TaskActionsByUserIdQuery>response.data.taskActionsByUserId;
  }

  async TaskActionsByRootEntityId(
    rootEntityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTaskActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TaskActionsByRootEntityIdQuery> {
    const statement = `query TaskActionsByRootEntityId($rootEntityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTaskActionFilterInput, $limit: Int, $nextToken: String) {
        taskActionsByRootEntityId(rootEntityId: $rootEntityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootEntityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TaskActionsByRootEntityIdQuery>response.data.taskActionsByRootEntityId;
  }

  async GetUsersAssignmentMapper(id: string): Promise<GetUsersAssignmentMapperQuery> {
    const statement = `query GetUsersAssignmentMapper($id: ID!) {
        getUsersAssignmentMapper(id: $id) {
          __typename
          id
          createdAt
          updatedAt
          score
          assessmentId
          taskId
          userId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetUsersAssignmentMapperQuery>response.data.getUsersAssignmentMapper;
  }

  async ListUsersAssignmentMappers(
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersAssignmentMappersQuery> {
    const statement = `query ListUsersAssignmentMappers($filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        listUsersAssignmentMappers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListUsersAssignmentMappersQuery>response.data.listUsersAssignmentMappers;
  }

  async UsersByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByAssessmentIdQuery> {
    const statement = `query UsersByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        usersByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UsersByAssessmentIdQuery>response.data.usersByAssessmentId;
  }

  async UsersByTaskId(
    taskId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UsersByTaskIdQuery> {
    const statement = `query UsersByTaskId($taskId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        usersByTaskId(taskId: $taskId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      taskId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UsersByTaskIdQuery>response.data.usersByTaskId;
  }

  async TasksByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TasksByUserIdQuery> {
    const statement = `query TasksByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        tasksByUserId(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TasksByUserIdQuery>response.data.tasksByUserId;
  }

  async TasksByUserIdAndAssessmentId(
    userId: string,
    assessmentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelUsersAssignmentMapperFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TasksByUserIdAndAssessmentIdQuery> {
    const statement = `query TasksByUserIdAndAssessmentId($userId: ID!, $assessmentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUsersAssignmentMapperFilterInput, $limit: Int, $nextToken: String) {
        tasksByUserIdAndAssessmentId(userId: $userId, assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TasksByUserIdAndAssessmentIdQuery>response.data.tasksByUserIdAndAssessmentId;
  }

  async GetFrameworkSettings(id: string): Promise<GetFrameworkSettingsQuery> {
    const statement = `query GetFrameworkSettings($id: ID!) {
        getFrameworkSettings(id: $id) {
          __typename
          id
          settings
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetFrameworkSettingsQuery>response.data.getFrameworkSettings;
  }

  async ListFrameworkSettings(
    filter?: ModelFrameworkSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkSettingsQuery> {
    const statement = `query ListFrameworkSettings($filter: ModelFrameworkSettingsFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            settings
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListFrameworkSettingsQuery>response.data.listFrameworkSettings;
  }

  async GetCustomTask(id: string): Promise<GetCustomTaskQuery> {
    const statement = `query GetCustomTask($id: ID!) {
        getCustomTask(id: $id) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetCustomTaskQuery>response.data.getCustomTask;
  }

  async ListCustomTasks(
    filter?: ModelCustomTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomTasksQuery> {
    const statement = `query ListCustomTasks($filter: ModelCustomTaskFilterInput, $limit: Int, $nextToken: String) {
        listCustomTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            label
            impact
            description
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            assessmentId
            customTodo
            dueDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListCustomTasksQuery>response.data.listCustomTasks;
  }

  async CustomTasksByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomTaskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomTasksByAssessmentIdQuery> {
    const statement = `query CustomTasksByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomTaskFilterInput, $limit: Int, $nextToken: String) {
        CustomTasksByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            label
            impact
            description
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            assessmentId
            customTodo
            dueDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <CustomTasksByAssessmentIdQuery>response.data.CustomTasksByAssessmentId;
  }

  async GetCustomTaskAssignment(id: string): Promise<GetCustomTaskAssignmentQuery> {
    const statement = `query GetCustomTaskAssignment($id: ID!) {
        getCustomTaskAssignment(id: $id) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetCustomTaskAssignmentQuery>response.data.getCustomTaskAssignment;
  }

  async ListCustomTaskAssignments(
    filter?: ModelCustomTaskAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomTaskAssignmentsQuery> {
    const statement = `query ListCustomTaskAssignments($filter: ModelCustomTaskAssignmentFilterInput, $limit: Int, $nextToken: String) {
        listCustomTaskAssignments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListCustomTaskAssignmentsQuery>response.data.listCustomTaskAssignments;
  }

  async CustomTaskAssignmentsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCustomTaskAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CustomTaskAssignmentsByAssessmentIdQuery> {
    const statement = `query CustomTaskAssignmentsByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelCustomTaskAssignmentFilterInput, $limit: Int, $nextToken: String) {
        CustomTaskAssignmentsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userId
            targetId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <CustomTaskAssignmentsByAssessmentIdQuery>response.data.CustomTaskAssignmentsByAssessmentId;
  }

  async GetAssessmentStandardFramework(id: string): Promise<GetAssessmentStandardFrameworkQuery> {
    const statement = `query GetAssessmentStandardFramework($id: ID!) {
      getAssessmentStandardFramework(id: $id) {
        __typename
        id
        key
        type
        fileName
        filter {
          __typename
          impact
        }
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          isAutoReminder
          isAutoReassessment
        }
        assessmentId
        managerId
        assessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        selectedChapters
        managers {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            left
            right
            removedQuestions
            createdAt
            updatedAt
          }
          nextToken
        }
        not_added
        archived
        reassessmentStatus
        defaultQuestionSettings {
          __typename
          isApprovalRequired
          isArtifactsMandatory
          isCommentsMandatory
          isCollaborative
          isIntegrationActive
          isSmartMappingActive
        }
        documentCategory
        documentType
        suggestedArtifact
        suggestedComments
        level
        newStartReassessDate
        signatureUserID
        isLocked
        signedBy {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
        signature {
          __typename
          image
          text
          draw
          signDate
        }
        lockedByUserID
        lockedBy {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetAssessmentStandardFrameworkQuery>response.data.getAssessmentStandardFramework;
  }

  async ListAssessmentStandardFrameworks(
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAssessmentStandardFrameworksQuery> {
    const statement = `query ListAssessmentStandardFrameworks($filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        listAssessmentStandardFrameworks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListAssessmentStandardFrameworksQuery>response.data.listAssessmentStandardFrameworks;
  }

  async StandardFrameworksByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StandardFrameworksByAssessmentIdQuery> {
    const statement = `query StandardFrameworksByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        standardFrameworksByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <StandardFrameworksByAssessmentIdQuery>response.data.standardFrameworksByAssessmentId;
  }

  async GetFrameworkManager(id: string): Promise<GetFrameworkManagerQuery> {
    const statement = `query GetFrameworkManager($id: ID!) {
        getFrameworkManager(id: $id) {
          __typename
          id
          assessmentId
          standardFrameworkId
          managerId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetFrameworkManagerQuery>response.data.getFrameworkManager;
  }

  async ListFrameworkManagers(
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkManagersQuery> {
    const statement = `query ListFrameworkManagers($filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListFrameworkManagersQuery>response.data.listFrameworkManagers;
  }

  async FrameworkManagersByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByAssessmentIdQuery> {
    const statement = `query FrameworkManagersByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            left
            right
            removedQuestions
            user {
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <FrameworkManagersByAssessmentIdQuery>response.data.frameworkManagersByAssessmentId;
  }

  async FrameworkManagersByStandardFrameworkId(
    standardFrameworkId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByStandardFrameworkIdQuery> {
    const statement = `query FrameworkManagersByStandardFrameworkId($standardFrameworkId: String!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByStandardFrameworkId(standardFrameworkId: $standardFrameworkId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      standardFrameworkId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <FrameworkManagersByStandardFrameworkIdQuery>response.data.frameworkManagersByStandardFrameworkId;
  }

  async FrameworkManagersByManagerId(
    managerId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkManagerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkManagersByManagerIdQuery> {
    const statement = `query FrameworkManagersByManagerId($managerId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkManagerFilterInput, $limit: Int, $nextToken: String) {
        frameworkManagersByManagerId(
          managerId: $managerId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            assessmentId
            standardFrameworkId
            managerId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      managerId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <FrameworkManagersByManagerIdQuery>response.data.frameworkManagersByManagerId;
  }

  async GetComment(id: string): Promise<GetCommentQuery> {
    const statement = `query GetComment($id: ID!) {
        getComment(id: $id) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            nextToken
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetCommentQuery>response.data.getComment;
  }

  async ListComments(filter?: ModelCommentFilterInput, limit?: number, nextToken?: string): Promise<ListCommentsQuery> {
    const statement = `query ListComments($filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                updatedAt
              }
              nextToken
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListCommentsQuery>response.data.listComments;
  }

  async CommentsByQuestionId(
    questionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByQuestionIdQuery> {
    const statement = `query CommentsByQuestionId($questionId: String!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByQuestionId(questionId: $questionId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                updatedAt
              }
              nextToken
            }
            archivedUser {
              id
              name
              email
              role
              phone
              entityId
              isCognitoUser
              questionMap
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              domain
              updatedAt
              __typename
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <CommentsByQuestionIdQuery>response.data.commentsByQuestionId;
  }

  async CommentsByRiskId(
    riskId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByRiskIdQuery> {
    const statement = `query CommentsByRiskId($riskId: String!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByRiskId(
          riskId: $riskId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      riskId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CommentsByRiskIdQuery>response.data.commentsByRiskId;
  }
  async CommentByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentByAssessmentIdQuery> {
    const statement = `query CommentByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        CommentByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                domain
                temporaryHigherRank
                entityLayerIds
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
              }
              nextToken
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CommentByAssessmentIdQuery>response.data.CommentByAssessmentId;
  }
  async CommentsByAssessmentIdAndQuestionId(
    assessmentId: string,
    questionId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByAssessmentIdAndQuestionIdQuery> {
    const statement = `query CommentsByAssessmentIdAndQuestionId($assessmentId: String!, $questionId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByAssessmentIdAndQuestionId(
          assessmentId: $assessmentId
          questionId: $questionId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (questionId) {
      gqlAPIServiceArguments.questionId = questionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CommentsByAssessmentIdAndQuestionIdQuery>response.data.commentsByAssessmentIdAndQuestionId;
  }
  async CommentsByFrameworkAndAssessmentId(
    framework: string,
    assessmentId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CommentsByFrameworkAndAssessmentIdQuery> {
    const statement = `query CommentsByFrameworkAndAssessmentId($framework: String!, $assessmentId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        commentsByFrameworkAndAssessmentId(
          framework: $framework
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            latestSource
            comment
            date
            questionId
            riskId
            assessmentId
            userId
            exports {
              __typename
              items {
                __typename
                id
                destination
                destinationCategoryId
                category
                categoryId
                date
                createdAt
                updatedAt
              }
              nextToken
            }
            user {
              __typename
              items {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              nextToken
            }
            type
            sType
            framework
            srcFramework
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      framework,
    };
    if (assessmentId) {
      gqlAPIServiceArguments.assessmentId = assessmentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CommentsByFrameworkAndAssessmentIdQuery>response.data.commentsByFrameworkAndAssessmentId;
  }

  async GetExport(id: string): Promise<GetExportQuery> {
    const statement = `query GetExport($id: ID!) {
        getExport(id: $id) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetExportQuery>response.data.getExport;
  }

  async ListExports(filter?: ModelExportFilterInput, limit?: number, nextToken?: string): Promise<ListExportsQuery> {
    const statement = `query ListExports($filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        listExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListExportsQuery>response.data.listExports;
  }

  async ExportsByDestinationCategoryId(
    destinationCategoryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExportsByDestinationCategoryIdQuery> {
    const statement = `query ExportsByDestinationCategoryId($destinationCategoryId: ID!, $sortDirection: ModelSortDirection, $filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        exportsByDestinationCategoryId(destinationCategoryId: $destinationCategoryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      destinationCategoryId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ExportsByDestinationCategoryIdQuery>response.data.exportsByDestinationCategoryId;
  }

  async ExportsByCategoryId(
    categoryId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelExportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExportsByCategoryIdQuery> {
    const statement = `query ExportsByCategoryId($categoryId: String!, $sortDirection: ModelSortDirection, $filter: ModelExportFilterInput, $limit: Int, $nextToken: String) {
        exportsByCategoryId(categoryId: $categoryId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            destination
            destinationCategoryId
            category
            categoryId
            date
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      categoryId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ExportsByCategoryIdQuery>response.data.exportsByCategoryId;
  }

  async GetReport(id: string): Promise<GetReportQuery> {
    const statement = `query GetReport($id: ID!) {
        getReport(id: $id) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetReportQuery>response.data.getReport;
  }

  async ListReports(filter?: ModelReportFilterInput, limit?: number, nextToken?: string): Promise<ListReportsQuery> {
    const statement = `query ListReports($filter: ModelReportFilterInput, $limit: Int, $nextToken: String) {
        listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            name
            managerName
            startDate
            endDate
            type
            isDraft
            previousReports
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListReportsQuery>response.data.listReports;
  }

  async ReportByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ReportByEntityIdQuery> {
    const statement = `query ReportByEntityId($entityId: String!, $sortDirection: ModelSortDirection, $filter: ModelReportFilterInput, $limit: Int, $nextToken: String) {
        reportByEntityId(entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            name
            managerName
            startDate
            endDate
            type
            isDraft
            previousReports
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ReportByEntityIdQuery>response.data.reportByEntityId;
  }

  async GetRisk(id: string): Promise<GetRiskQuery> {
    const statement = `query GetRisk($id: ID!) {
        getRisk(id: $id) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetRiskQuery>response.data.getRisk;
  }

  async ListRisks(filter?: ModelRiskFilterInput, limit?: number, nextToken?: string): Promise<ListRisksQuery> {
    const statement = `query ListRisks($filter: ModelRiskFilterInput, $limit: Int, $nextToken: String) {
        listRisks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListRisksQuery>response.data.listRisks;
  }

  async RiskByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskByEntityIdQuery> {
    const statement = `query RiskByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskFilterInput, $limit: Int, $nextToken: String) {
        riskByEntityId(
          entityId: $entityId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <RiskByEntityIdQuery>response.data.riskByEntityId;
  }

  async RiskByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskByAssessmentIdQuery> {
    const statement = `query RiskByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskFilterInput, $limit: Int, $nextToken: String) {
        riskByAssessmentId(
          assessmentId: $assessmentId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            idTitle
            entityId
            subEntityId
            subEntity {
              __typename
              id
              name
              entityType
              activeAssessmentId
              scName
            }
            assessmentId
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
            riskOwnerIds
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <RiskByAssessmentIdQuery>response.data.riskByAssessmentId;
  }

  async GetDomainSettings(id: string): Promise<GetDomainSettingsQuery> {
    const statement = `query GetDomainSettings($id: ID!) {
        getDomainSettings(id: $id) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetDomainSettingsQuery>response.data.getDomainSettings;
  }

  async ListDomainSettings(
    filter?: ModelDomainSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDomainSettingsQuery> {
    const statement = `query ListDomainSettings($filter: ModelDomainSettingsFilterInput, $limit: Int, $nextToken: String) {
        listDomainSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            domain
            key
            average
            allowedRoutes
            createdAt
            updatedAt
            allowedFeatures
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListDomainSettingsQuery>response.data.listDomainSettings;
  }

  async DomainSettingByDomain(
    domain: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDomainSettingsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DomainSettingByDomainQuery> {
    const statement = `query DomainSettingByDomain($domain: String!, $sortDirection: ModelSortDirection, $filter: ModelDomainSettingsFilterInput, $limit: Int, $nextToken: String) {
        domainSettingByDomain(domain: $domain, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            domain
            key
            settings
            average
            allowedRoutes
            createdAt
            updatedAt
            allowedFeatures
            kmsStatus
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <DomainSettingByDomainQuery>response.data.domainSettingByDomain;
  }

  async GetExecutiveSummary(id: string): Promise<GetExecutiveSummaryQuery> {
    const statement = `query GetExecutiveSummary($id: ID!) {
        getExecutiveSummary(id: $id) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetExecutiveSummaryQuery>response.data.getExecutiveSummary;
  }

  async ListExecutiveSummaries(
    filter?: ModelExecutiveSummaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExecutiveSummariesQuery> {
    const statement = `query ListExecutiveSummaries($filter: ModelExecutiveSummaryFilterInput, $limit: Int, $nextToken: String) {
        listExecutiveSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            title
            subjectList {
              __typename
              id
              title
              description
              toggle
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListExecutiveSummariesQuery>response.data.listExecutiveSummaries;
  }

  async SummaryByEntityId(
    entityId: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelExecutiveSummaryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SummaryByEntityIdQuery> {
    const statement = `query SummaryByEntityId($entityId: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelExecutiveSummaryFilterInput, $limit: Int, $nextToken: String) {
        summaryByEntityId(entityId: $entityId, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            title
            subjectList {
              __typename
              id
              title
              description
              toggle
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <SummaryByEntityIdQuery>response.data.summaryByEntityId;
  }

  async GetNetskopeProject(id: string): Promise<GetNetskopeProjectQuery> {
    const statement = `query GetNetskopeProject($id: ID!) {
        getNetskopeProject(id: $id) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetNetskopeProjectQuery>response.data.getNetskopeProject;
  }

  async ListNetskopeProjects(
    filter?: ModelNetskopeProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListNetskopeProjectsQuery> {
    const statement = `query ListNetskopeProjects($filter: ModelNetskopeProjectFilterInput, $limit: Int, $nextToken: String) {
        listNetskopeProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            title
            description
            previous
            currentProgress {
              __typename
              startDate
              endDate
              status
            }
            budget
            comments
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListNetskopeProjectsQuery>response.data.listNetskopeProjects;
  }

  async ProjectsByEntityId(
    entityId: string,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelNetskopeProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProjectsByEntityIdQuery> {
    const statement = `query ProjectsByEntityId($entityId: String!, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelNetskopeProjectFilterInput, $limit: Int, $nextToken: String) {
        projectsByEntityId(entityId: $entityId, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            entityId
            title
            description
            previous
            currentProgress {
              __typename
              startDate
              endDate
              status
            }
            budget
            comments
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ProjectsByEntityIdQuery>response.data.projectsByEntityId;
  }

  async GetEntityLayers(id: string): Promise<GetEntityLayersQuery> {
    const statement = `query GetEntityLayers($id: ID!) {
        getEntityLayers(id: $id) {
          __typename
          id
          name
          managerName
          type
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          parentId
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          srName
          createdAt
          updatedAt
          domain
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetEntityLayersQuery>response.data.getEntityLayers;
  }

  async ListEntityLayers(
    filter?: ModelEntityLayersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntityLayersQuery> {
    const statement = `query ListEntityLayers($filter: ModelEntityLayersFilterInput, $limit: Int, $nextToken: String) {
        listEntityLayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            managerName
            type
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            parentId
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            srName
            createdAt
            updatedAt
            domain
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListEntityLayersQuery>response.data.listEntityLayers;
  }

  async EntityLayersByParentId(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityLayersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityLayersByParentIdQuery> {
    const statement = `query EntityLayersByParentId($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityLayersFilterInput, $limit: Int, $nextToken: String) {
        entityLayersByParentId(parentId: $parentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            managerName
            type
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            parentId
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            srName
            createdAt
            updatedAt
            domain
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityLayersByParentIdQuery>response.data.entityLayersByParentId;
  }

  async GetEntityWizard(id: string): Promise<GetEntityWizardQuery> {
    const statement = `query GetEntityWizard($id: ID!) {
      getEntityWizard(id: $id) {
        __typename
        id
        isDraft
        organizational
        financial
        frameworks
        dataScope
        technical
        riskScenarios
        accountDetails
        currentYear
        rootEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  rootId
                  childId
                  left
                  right
                  managers
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          upperdeckRoute
          isLocked
          weightageScore
          updatedAt
        }
        parentId
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        domainScanned
        domainScanTimes
        createdAt
        updatedAt
        riskFramework
        clientDomain
        userId
        applicantInfo
        revenueDetails
        dataInventory
        updatedBy
      }
    }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GetEntityWizardQuery>response.data.getEntityWizard;
  }

  async ListEntityWizards(
    filter?: ModelEntityWizardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntityWizardsQuery> {
    const statement = `query ListEntityWizards($filter: ModelEntityWizardFilterInput, $limit: Int, $nextToken: String) {
        listEntityWizards(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            isDraft
            organizational
            financial
            frameworks
            dataScope
            technical
            riskScenarios
            accountDetails
            currentYear
            rootEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            parentId
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            domainScanned
            domainScanTimes
            createdAt
            updatedAt
            riskFramework
            clientDomain
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListEntityWizardsQuery>response.data.listEntityWizards;
  }

  async EntityWizardByParentId(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityWizardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityWizardByParentIdQuery> {
    const statement = `query EntityWizardByParentId($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityWizardFilterInput, $limit: Int, $nextToken: String) {
        entityWizardByParentId(parentId: $parentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            isDraft
            organizational
            financial
            frameworks
            dataScope
            technical
            riskScenarios
            accountDetails
            currentYear
            rootEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              eSignFinalized
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            parentId
            score {
              __typename
              total
              collection
              remediation
            }
            inherent
            residual
            domainScanned
            domainScanTimes
            createdAt
            updatedAt
            riskFramework
            clientDomain
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <EntityWizardByParentIdQuery>response.data.entityWizardByParentId;
  }

  async EntityWizardByParentAndDomain(
    clientDomain: string,
    parentId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityWizardFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityWizardByParentAndDomainQuery> {
    const statement = `query EntityWizardByParentAndDomain($clientDomain: String!, $parentId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityWizardFilterInput, $limit: Int, $nextToken: String) {
      entityWizardByParentAndDomain(
        clientDomain: $clientDomain
        parentId: $parentId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            eSignFinalized
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  nextToken
                }
                externalThreats {
                  __typename
                  nextToken
                }
                others {
                  __typename
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  assessmentId
                  managerId
                  selectedChapters
                  not_added
                  archived
                  reassessmentStatus
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      clientDomain,
    };
    if (parentId) {
      gqlAPIServiceArguments.parentId = parentId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityWizardByParentAndDomainQuery>response.data.entityWizardByParentAndDomain;
  }

  async GetLogs(id: string): Promise<GetLogsQuery> {
    const statement = `query GetLogs($id: ID!) {
      getLogs(id: $id) {
        __typename
        id
        message
        type
        targetId
        assessmentId
        userID
        user {
          __typename
          id
          name
          email
          role
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetLogsQuery>response.data.getLogs;
  }

  async ListLogs(filter?: ModelLogsFilterInput, limit?: number, nextToken?: string): Promise<ListLogsQuery> {
    const statement = `query ListLogs($filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
      listLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListLogsQuery>response.data.listLogs;
  }

  async LogsByTargetId(
    targetId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByTargetIdQuery> {
    const statement = `query LogsByTargetId($targetId: String!, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
      logsByTargetId(
        targetId: $targetId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      targetId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <LogsByTargetIdQuery>response.data.logsByTargetId;
  }

  async LogsByTargetIdAndType(
    targetId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByTargetIdAndTypeQuery> {
    const statement = `query LogsByTargetIdAndType($targetId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
      logsByTargetIdAndType(
        targetId: $targetId
        type: $type
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      targetId,
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <LogsByTargetIdAndTypeQuery>response.data.logsByTargetIdAndType;
  }

  async LogsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByAssessmentIdQuery> {
    const statement = `query LogsByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <LogsByAssessmentIdQuery>response.data.logsByAssessmentId;
  }

  async LogsByAssessmentIdAndType(
    assessmentId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelLogsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LogsByAssessmentIdAndTypeQuery> {
    const statement = `query LogsByAssessmentIdAndType($assessmentId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelLogsFilterInput, $limit: Int, $nextToken: String) {
        logsByAssessmentIdAndType(assessmentId: $assessmentId, type: $type, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            message
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <LogsByAssessmentIdAndTypeQuery>response.data.logsByAssessmentIdAndType;
  }

  async GetVersions(id: string): Promise<GetVersionsQuery> {
    const statement = `query GetVersions($id: ID!) {
        getVersions(id: $id) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetVersionsQuery>response.data.getVersions;
  }

  async ListVersions(
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVersionsQuery> {
    const statement = `query ListVersions($filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        listVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListVersionsQuery>response.data.listVersions;
  }

  async VersionsByTargetId(
    targetId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByTargetIdQuery> {
    const statement = `query VersionsByTargetId($targetId: String!, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByTargetId(targetId: $targetId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <VersionsByTargetIdQuery>response.data.versionsByTargetId;
  }

  async VersionsByTargetIdAndType(
    targetId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByTargetIdAndTypeQuery> {
    const statement = `query VersionsByTargetIdAndType($targetId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByTargetIdAndType(targetId: $targetId, type: $type, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId,
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <VersionsByTargetIdAndTypeQuery>response.data.versionsByTargetIdAndType;
  }

  async VersionsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByAssessmentIdQuery> {
    const statement = `query VersionsByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <VersionsByAssessmentIdQuery>response.data.versionsByAssessmentId;
  }

  async VersionsByAssessmentIdAndType(
    assessmentId: string,
    type?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelVersionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<VersionsByAssessmentIdAndTypeQuery> {
    const statement = `query VersionsByAssessmentIdAndType($assessmentId: String!, $type: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelVersionsFilterInput, $limit: Int, $nextToken: String) {
        versionsByAssessmentIdAndType(assessmentId: $assessmentId, type: $type, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            data
            type
            targetId
            assessmentId
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <VersionsByAssessmentIdAndTypeQuery>response.data.versionsByAssessmentIdAndType;
  }

  async GetDataState(id: string): Promise<GetDataStateQuery> {
    const statement = `query GetDataState($id: ID!) {
        getDataState(id: $id) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetDataStateQuery>response.data.getDataState;
  }

  async ListDataStates(
    filter?: ModelDataStateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListDataStatesQuery> {
    const statement = `query ListDataStates($filter: ModelDataStateFilterInput, $limit: Int, $nextToken: String) {
        listDataStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            state
            parentId
            message
            detail
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListDataStatesQuery>response.data.listDataStates;
  }

  async DataStatesByParentId(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelDataStateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<DataStatesByParentIdQuery> {
    const statement = `query DataStatesByParentId($parentId: String!, $sortDirection: ModelSortDirection, $filter: ModelDataStateFilterInput, $limit: Int, $nextToken: String) {
        dataStatesByParentId(parentId: $parentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            state
            parentId
            message
            detail
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DataStatesByParentIdQuery>response.data.dataStatesByParentId;
  }

  async GetSmartMappingDetail(id: string): Promise<GetSmartMappingDetailQuery> {
    const statement = `query GetSmartMappingDetail($id: ID!) {
        getSmartMappingDetail(id: $id) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetSmartMappingDetailQuery>response.data.getSmartMappingDetail;
  }

  async ListSmartMappingDetails(
    filter?: ModelSmartMappingDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListSmartMappingDetailsQuery> {
    const statement = `query ListSmartMappingDetails($filter: ModelSmartMappingDetailFilterInput, $limit: Int, $nextToken: String) {
        listSmartMappingDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            uniqueId
            metadata
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListSmartMappingDetailsQuery>response.data.listSmartMappingDetails;
  }

  async MetadataBySmartMapId(
    uniqueId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelSmartMappingDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MetadataBySmartMapIdQuery> {
    const statement = `query MetadataBySmartMapId($uniqueId: ID!, $sortDirection: ModelSortDirection, $filter: ModelSmartMappingDetailFilterInput, $limit: Int, $nextToken: String) {
        metadataBySmartMapId(uniqueId: $uniqueId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            uniqueId
            metadata
            answer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      uniqueId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <MetadataBySmartMapIdQuery>response.data.metadataBySmartMapId;
  }

  async IncrementQuestionnaireAnswer(id: string, value?: number): Promise<IncrementQuestionnaireAnswerMutation> {
    const statement = `mutation IncrementQuestionnaireAnswer($id: String!, $value: Int) {
        incrementQuestionnaireAnswer(id: $id, value: $value) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    if (value) {
      gqlAPIServiceArguments.value = value;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <IncrementQuestionnaireAnswerMutation>response.data.incrementQuestionnaireAnswer;
  }
  async RemoveUserAssignment(userAssignment: any): Promise<any> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(userAssignment, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/removeUserAssignment', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }
  async GenerateEntityData(input: any): Promise<string | null> {
    const statement = `mutation GenerateEntityData($input: any!) {
        generateEntityData(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <string | null>response.data.generateEntityData;
  }
  async SentSupportTicket(body: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();
    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(body, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/sentSupportTicket', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }
  async CreateDeleteTagAndQuestionData(body: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }

    const payload = this.encryptPayload(body, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/createDeleteTagAndQuestionData', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }
  async CalcGroupScores(body: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }

    const payload = this.encryptPayload(body, currentSession?.accessToken?.payload?.username);
    const response = (await API.post('cygovRestApi', '/calcGroupScores', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession?.accessToken?.payload?.username,
      },
    })) as any;

    return response;
  }
  async CreateUser(input: CreateUserInput, condition?: ModelUserConditionInput): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async DeleteUser(input: DeleteUserInput, condition?: ModelUserConditionInput): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateTags(input: CreateTagsInput, condition?: ModelTagsConditionInput): Promise<CreateTagsMutation> {
    const statement = `mutation CreateTags($input: CreateTagsInput!, $condition: ModelTagsConditionInput) {
      createTags(input: $input, condition: $condition) {
        __typename
        id
        name
        entityId
        domain
        targetId
        assessmentFrameworkKey
        referenceId
        questionRefId
        questionData {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateTagsMutation>response.data.createTags;
  }
  async UpdateTags(input: UpdateTagsInput, condition?: ModelTagsConditionInput): Promise<UpdateTagsMutation> {
    const statement = `mutation UpdateTags($input: UpdateTagsInput!, $condition: ModelTagsConditionInput) {
        updateTags(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateTagsMutation>response.data.updateTags;
  }
  async DeleteTags(input: DeleteTagsInput, condition?: ModelTagsConditionInput): Promise<DeleteTagsMutation> {
    const statement = `mutation DeleteTags($input: DeleteTagsInput!, $condition: ModelTagsConditionInput) {
        deleteTags(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteTagsMutation>response.data.deleteTags;
  }
  async CreateRole(input: CreateRoleInput, condition?: ModelRoleConditionInput): Promise<CreateRoleMutation> {
    const statement = `mutation CreateRole($input: CreateRoleInput!, $condition: ModelRoleConditionInput) {
        createRole(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateRoleMutation>response.data.createRole;
  }
  async UpdateRole(input: UpdateRoleInput, condition?: ModelRoleConditionInput): Promise<UpdateRoleMutation> {
    const statement = `mutation UpdateRole($input: UpdateRoleInput!, $condition: ModelRoleConditionInput) {
        updateRole(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateRoleMutation>response.data.updateRole;
  }
  async DeleteRole(input: DeleteRoleInput, condition?: ModelRoleConditionInput): Promise<DeleteRoleMutation> {
    const statement = `mutation DeleteRole($input: DeleteRoleInput!, $condition: ModelRoleConditionInput) {
        deleteRole(input: $input, condition: $condition) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteRoleMutation>response.data.deleteRole;
  }
  async CreateGroup(input: CreateGroupInput, condition?: ModelGroupConditionInput): Promise<CreateGroupMutation> {
    const statement = `mutation CreateGroup($input: CreateGroupInput!, $condition: ModelGroupConditionInput) {
        createGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateGroupMutation>response.data.createGroup;
  }
  async UpdateGroup(input: UpdateGroupInput, condition?: ModelGroupConditionInput): Promise<UpdateGroupMutation> {
    const statement = `mutation UpdateGroup($input: UpdateGroupInput!, $condition: ModelGroupConditionInput) {
        updateGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateGroupMutation>response.data.updateGroup;
  }
  async DeleteGroup(input: DeleteGroupInput, condition?: ModelGroupConditionInput): Promise<DeleteGroupMutation> {
    const statement = `mutation DeleteGroup($input: DeleteGroupInput!, $condition: ModelGroupConditionInput) {
        deleteGroup(input: $input, condition: $condition) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteGroupMutation>response.data.deleteGroup;
  }

  async UpdateVendorsDetail(
    input: UpdateVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<UpdateVendorsDetailMutation> {
    const statement = `mutation UpdateVendorsDetail($input: UpdateVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        updateVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateVendorsDetailMutation>response.data.updateVendorsDetail;
  }
  async DeleteVendorsDetail(
    input: DeleteVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<DeleteVendorsDetailMutation> {
    const statement = `mutation DeleteVendorsDetail($input: DeleteVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        deleteVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteVendorsDetailMutation>response.data.deleteVendorsDetail;
  }
  async CreateFrameworkScores(
    input: CreateFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<CreateFrameworkScoresMutation> {
    const statement = `mutation CreateFrameworkScores($input: CreateFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        createFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateFrameworkScoresMutation>response.data.createFrameworkScores;
  }
  async UpdateFrameworkScores(
    input: UpdateFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<UpdateFrameworkScoresMutation> {
    const statement = `mutation UpdateFrameworkScores($input: UpdateFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        updateFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateFrameworkScoresMutation>response.data.updateFrameworkScores;
  }
  async DeleteFrameworkScores(
    input: DeleteFrameworkScoresInput,
    condition?: ModelFrameworkScoresConditionInput
  ): Promise<DeleteFrameworkScoresMutation> {
    const statement = `mutation DeleteFrameworkScores($input: DeleteFrameworkScoresInput!, $condition: ModelFrameworkScoresConditionInput) {
        deleteFrameworkScores(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteFrameworkScoresMutation>response.data.deleteFrameworkScores;
  }
  async CreateIntelligence(
    input: CreateIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<CreateIntelligenceMutation> {
    const statement = `mutation CreateIntelligence($input: CreateIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        createIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateIntelligenceMutation>response.data.createIntelligence;
  }
  async UpdateIntelligence(
    input: UpdateIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<UpdateIntelligenceMutation> {
    const statement = `mutation UpdateIntelligence($input: UpdateIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        updateIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateIntelligenceMutation>response.data.updateIntelligence;
  }
  async DeleteIntelligence(
    input: DeleteIntelligenceInput,
    condition?: ModelIntelligenceConditionInput
  ): Promise<DeleteIntelligenceMutation> {
    const statement = `mutation DeleteIntelligence($input: DeleteIntelligenceInput!, $condition: ModelIntelligenceConditionInput) {
        deleteIntelligence(input: $input, condition: $condition) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteIntelligenceMutation>response.data.deleteIntelligence;
  }
  async CreateBreaches(
    input: CreateBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<CreateBreachesMutation> {
    const statement = `mutation CreateBreaches($input: CreateBreachesInput!, $condition: ModelBreachesConditionInput) {
        createBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateBreachesMutation>response.data.createBreaches;
  }
  async UpdateBreaches(
    input: UpdateBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<UpdateBreachesMutation> {
    const statement = `mutation UpdateBreaches($input: UpdateBreachesInput!, $condition: ModelBreachesConditionInput) {
        updateBreaches(input: $input, condition: $condition) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateBreachesMutation>response.data.updateBreaches;
  }

  async CreateAnswer(input: CreateAnswerInput, condition?: ModelAnswerConditionInput): Promise<CreateAnswerMutation> {
    const statement = `mutation CreateAnswer($input: CreateAnswerInput!, $condition: ModelAnswerConditionInput) {
        createAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
          reviewerActionStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );

    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateAnswerMutation>response.data.createAnswer;
  }
  async UpdateAnswer(input: UpdateAnswerInput, condition?: ModelAnswerConditionInput): Promise<UpdateAnswerMutation> {
    const statement = `mutation UpdateAnswer($input: UpdateAnswerInput!, $condition: ModelAnswerConditionInput) {
        updateAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
          reviewerActionStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateAnswerMutation>response.data.updateAnswer;
  }
  async DeleteAnswer(input: DeleteAnswerInput, condition?: ModelAnswerConditionInput): Promise<DeleteAnswerMutation> {
    const statement = `mutation DeleteAnswer($input: DeleteAnswerInput!, $condition: ModelAnswerConditionInput) {
        deleteAnswer(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );

    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteAnswerMutation>response.data.deleteAnswer;
  }
  async CreateAssignment(
    input: CreateAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<CreateAssignmentMutation> {
    const statement = `mutation CreateAssignment($input: CreateAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        createAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateAssignmentMutation>response.data.createAssignment;
  }
  async UpdateAssignment(
    input: UpdateAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<UpdateAssignmentMutation> {
    const statement = `mutation UpdateAssignment($input: UpdateAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        updateAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateAssignmentMutation>response.data.updateAssignment;
  }
  async DeleteAssignment(
    input: DeleteAssignmentInput,
    condition?: ModelAssignmentConditionInput
  ): Promise<DeleteAssignmentMutation> {
    const statement = `mutation DeleteAssignment($input: DeleteAssignmentInput!, $condition: ModelAssignmentConditionInput) {
        deleteAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
          deletedUser
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteAssignmentMutation>response.data.deleteAssignment;
  }
  async CreateDisableAssignment(
    input: CreateDisableAssignmentInput,
    condition?: ModelDisableAssignmentConditionInput
  ): Promise<CreateDisableAssignmentMutation> {
    const statement = `mutation CreateDisableAssignment($input: CreateDisableAssignmentInput!, $condition: ModelDisableAssignmentConditionInput) {
        createDisableAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateDisableAssignmentMutation>response.data.createDisableAssignment;
  }
  async UpdateDisableAssignment(
    input: UpdateDisableAssignmentInput,
    condition?: ModelDisableAssignmentConditionInput
  ): Promise<UpdateDisableAssignmentMutation> {
    const statement = `mutation UpdateDisableAssignment($input: UpdateDisableAssignmentInput!, $condition: ModelDisableAssignmentConditionInput) {
        updateDisableAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateDisableAssignmentMutation>response.data.updateDisableAssignment;
  }
  async DeleteDisableAssignment(
    input: DeleteDisableAssignmentInput,
    condition?: ModelDisableAssignmentConditionInput
  ): Promise<DeleteDisableAssignmentMutation> {
    const statement = `mutation DeleteDisableAssignment($input: DeleteDisableAssignmentInput!, $condition: ModelDisableAssignmentConditionInput) {
        deleteDisableAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          targetId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          left
          right
          createdAt
          updatedAt
          disable
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteDisableAssignmentMutation>response.data.deleteDisableAssignment;
  }
  async CreateAssessment(
    input: CreateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<CreateAssessmentMutation> {
    const statement = `mutation CreateAssessment($input: CreateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
      createAssessment(input: $input, condition: $condition) {
        __typename
        id
        standardList {
          __typename
          id
          type
          fileName
          filter {
            __typename
            impact
          }
        }
        standardFrameworkList {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  assessmentId
                  managerId
                  selectedChapters
                  not_added
                  archived
                  reassessmentStatus
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              image
              text
              draw
              signDate
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          isAutoReminder
          isAutoReassessment
        }
        rootId
        childId
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        left
        right
        managers
        exports {
          __typename
          jiraEntityIds
          serviceNowEntityIds
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateAssessmentMutation>response.data.createAssessment;
  }

  async CreateDomainSettings(
    input: CreateDomainSettingsInput,
    condition?: ModelDomainSettingsConditionInput
  ): Promise<CreateDomainSettingsMutation> {
    const statement = `mutation CreateDomainSettings($input: CreateDomainSettingsInput!, $condition: ModelDomainSettingsConditionInput) {
        createDomainSettings(input: $input, condition: $condition) {
          __typename
          id
          domain
          key
          settings
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateDomainSettingsMutation>response.data.createDomainSettings;
  }
  async UpdateDomainSettings(
    input: UpdateDomainSettingsInput,
    condition?: ModelDomainSettingsConditionInput
  ): Promise<UpdateDomainSettingsMutation> {
    const statement = `mutation UpdateDomainSettings($input: UpdateDomainSettingsInput!, $condition: ModelDomainSettingsConditionInput) {
        updateDomainSettings(input: $input, condition: $condition) {
          __typename
          id
          domain
          key
          settings
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
          kmsStatus
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateDomainSettingsMutation>response.data.updateDomainSettings;
  }
  async DeleteDomainSettings(
    input: DeleteDomainSettingsInput,
    condition?: ModelDomainSettingsConditionInput
  ): Promise<DeleteDomainSettingsMutation> {
    const statement = `mutation DeleteDomainSettings($input: DeleteDomainSettingsInput!, $condition: ModelDomainSettingsConditionInput) {
        deleteDomainSettings(input: $input, condition: $condition) {
          __typename
          id
          domain
          key
          average
          allowedRoutes
          createdAt
          updatedAt
          allowedFeatures
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteDomainSettingsMutation>response.data.deleteDomainSettings;
  }
  async CreateExecutiveSummary(
    input: CreateExecutiveSummaryInput,
    condition?: ModelExecutiveSummaryConditionInput
  ): Promise<CreateExecutiveSummaryMutation> {
    const statement = `mutation CreateExecutiveSummary($input: CreateExecutiveSummaryInput!, $condition: ModelExecutiveSummaryConditionInput) {
        createExecutiveSummary(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateExecutiveSummaryMutation>response.data.createExecutiveSummary;
  }
  async UpdateExecutiveSummary(
    input: UpdateExecutiveSummaryInput,
    condition?: ModelExecutiveSummaryConditionInput
  ): Promise<UpdateExecutiveSummaryMutation> {
    const statement = `mutation UpdateExecutiveSummary($input: UpdateExecutiveSummaryInput!, $condition: ModelExecutiveSummaryConditionInput) {
        updateExecutiveSummary(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateExecutiveSummaryMutation>response.data.updateExecutiveSummary;
  }
  async DeleteExecutiveSummary(
    input: DeleteExecutiveSummaryInput,
    condition?: ModelExecutiveSummaryConditionInput
  ): Promise<DeleteExecutiveSummaryMutation> {
    const statement = `mutation DeleteExecutiveSummary($input: DeleteExecutiveSummaryInput!, $condition: ModelExecutiveSummaryConditionInput) {
        deleteExecutiveSummary(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          subjectList {
            __typename
            id
            title
            description
            toggle
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteExecutiveSummaryMutation>response.data.deleteExecutiveSummary;
  }
  async CreateNetskopeProject(
    input: CreateNetskopeProjectInput,
    condition?: ModelNetskopeProjectConditionInput
  ): Promise<CreateNetskopeProjectMutation> {
    const statement = `mutation CreateNetskopeProject($input: CreateNetskopeProjectInput!, $condition: ModelNetskopeProjectConditionInput) {
        createNetskopeProject(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateNetskopeProjectMutation>response.data.createNetskopeProject;
  }
  async UpdateNetskopeProject(
    input: UpdateNetskopeProjectInput,
    condition?: ModelNetskopeProjectConditionInput
  ): Promise<UpdateNetskopeProjectMutation> {
    const statement = `mutation UpdateNetskopeProject($input: UpdateNetskopeProjectInput!, $condition: ModelNetskopeProjectConditionInput) {
        updateNetskopeProject(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateNetskopeProjectMutation>response.data.updateNetskopeProject;
  }
  async DeleteNetskopeProject(
    input: DeleteNetskopeProjectInput,
    condition?: ModelNetskopeProjectConditionInput
  ): Promise<DeleteNetskopeProjectMutation> {
    const statement = `mutation DeleteNetskopeProject($input: DeleteNetskopeProjectInput!, $condition: ModelNetskopeProjectConditionInput) {
        deleteNetskopeProject(input: $input, condition: $condition) {
          __typename
          id
          entityId
          title
          description
          previous
          currentProgress {
            __typename
            startDate
            endDate
            status
          }
          budget
          comments
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteNetskopeProjectMutation>response.data.deleteNetskopeProject;
  }
  async CreateVersions(
    input: CreateVersionsInput,
    condition?: ModelVersionsConditionInput
  ): Promise<CreateVersionsMutation> {
    const statement = `mutation CreateVersions($input: CreateVersionsInput!, $condition: ModelVersionsConditionInput) {
        createVersions(input: $input, condition: $condition) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateVersionsMutation>response.data.createVersions;
  }
  async UpdateVersions(
    input: UpdateVersionsInput,
    condition?: ModelVersionsConditionInput
  ): Promise<UpdateVersionsMutation> {
    const statement = `mutation UpdateVersions($input: UpdateVersionsInput!, $condition: ModelVersionsConditionInput) {
        updateVersions(input: $input, condition: $condition) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateVersionsMutation>response.data.updateVersions;
  }
  async DeleteVersions(
    input: DeleteVersionsInput,
    condition?: ModelVersionsConditionInput
  ): Promise<DeleteVersionsMutation> {
    const statement = `mutation DeleteVersions($input: DeleteVersionsInput!, $condition: ModelVersionsConditionInput) {
        deleteVersions(input: $input, condition: $condition) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteVersionsMutation>response.data.deleteVersions;
  }
  async CreateDataState(
    input: CreateDataStateInput,
    condition?: ModelDataStateConditionInput
  ): Promise<CreateDataStateMutation> {
    const statement = `mutation CreateDataState($input: CreateDataStateInput!, $condition: ModelDataStateConditionInput) {
        createDataState(input: $input, condition: $condition) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateDataStateMutation>response.data.createDataState;
  }
  async UpdateDataState(
    input: UpdateDataStateInput,
    condition?: ModelDataStateConditionInput
  ): Promise<UpdateDataStateMutation> {
    const statement = `mutation UpdateDataState($input: UpdateDataStateInput!, $condition: ModelDataStateConditionInput) {
        updateDataState(input: $input, condition: $condition) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateDataStateMutation>response.data.updateDataState;
  }
  async DeleteDataState(
    input: DeleteDataStateInput,
    condition?: ModelDataStateConditionInput
  ): Promise<DeleteDataStateMutation> {
    const statement = `mutation DeleteDataState($input: DeleteDataStateInput!, $condition: ModelDataStateConditionInput) {
        deleteDataState(input: $input, condition: $condition) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteDataStateMutation>response.data.deleteDataState;
  }
  async CreateSmartMappingDetail(
    input: CreateSmartMappingDetailInput,
    condition?: ModelSmartMappingDetailConditionInput
  ): Promise<CreateSmartMappingDetailMutation> {
    const statement = `mutation CreateSmartMappingDetail($input: CreateSmartMappingDetailInput!, $condition: ModelSmartMappingDetailConditionInput) {
        createSmartMappingDetail(input: $input, condition: $condition) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateSmartMappingDetailMutation>response.data.createSmartMappingDetail;
  }
  async UpdateSmartMappingDetail(
    input: UpdateSmartMappingDetailInput,
    condition?: ModelSmartMappingDetailConditionInput
  ): Promise<UpdateSmartMappingDetailMutation> {
    const statement = `mutation UpdateSmartMappingDetail($input: UpdateSmartMappingDetailInput!, $condition: ModelSmartMappingDetailConditionInput) {
        updateSmartMappingDetail(input: $input, condition: $condition) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateSmartMappingDetailMutation>response.data.updateSmartMappingDetail;
  }
  async DeleteSmartMappingDetail(
    input: DeleteSmartMappingDetailInput,
    condition?: ModelSmartMappingDetailConditionInput
  ): Promise<DeleteSmartMappingDetailMutation> {
    const statement = `mutation DeleteSmartMappingDetail($input: DeleteSmartMappingDetailInput!, $condition: ModelSmartMappingDetailConditionInput) {
        deleteSmartMappingDetail(input: $input, condition: $condition) {
          __typename
          id
          uniqueId
          metadata
          answer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteSmartMappingDetailMutation>response.data.deleteSmartMappingDetail;
  }
  async GetRevision(id: string): Promise<GetRevisionQuery> {
    const statement = `query GetRevision($id: ID!) {
      getRevision(id: $id) {
        __typename
        id
        frameworkKey
        assessmentId
        groupId
        date
        rootId
        tagId
        scores
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetRevisionQuery>response.data.getRevision;
  }
  async ListAlerts(industry: String, fileType: FileTypeEnum): Promise<Array<ListAlertsQuery>> {
    const response = (await API.get(`cygovRestApi`, `/listAlerts?industry=${industry}&fileType=${fileType}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async CreateNewRevision(): Promise<boolean | null> {
    const statement = `query CreateNewRevision {
        createNewRevision
      }`;

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: {},
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <boolean | null>response.data.createNewRevision;
  }
  async IsJiraAuthValid(jira: JiraAuthInput): Promise<boolean | null> {
    const statement = `query IsJiraAuthValid($jira: JiraAuthInput!) {
        isJiraAuthValid(jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      jira,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <boolean | null>response.data.isJiraAuthValid;
  }
  async ValidateJiraAuth(jira: JiraAuthInput): Promise<string | null> {
    const response = (await API.post('cygovRestApi', '/validateJiraAuth', {
      body: { jira },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async ValidateAndSaveJiraCredentials(jira: JiraAuthInput, entityId: string): Promise<string | null> {
    const response = (await API.post('cygovRestApi', '/validateAndSaveJiraCredentials', {
      body: { jira, entityId },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async DecryptThirdPartyIntegration(key: string, data: string): Promise<string | null> {
    const response = (await API.post('cygovRestApi', '/decryptThirdPartyIntegration', {
      body: { key, data },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async ValidateServiceNowAuth(serviceNow: ServiceNowAuthInput): Promise<string | null> {
    const response = (await API.post('cygovRestApi', '/validateServiceNowAuth', {
      body: { serviceNow },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async ExportToJira(
    managerId: string,
    assessment: string,
    jira: JiraAuthInput,
    entityId: string
  ): Promise<string | null> {
    const statement = `query ExportToJira($managerId: String!, $assessment: AWSJSON!, $jira: JiraAuthInput!, $entityId: String!) {
        exportToJira(managerId: $managerId, assessment: $assessment, jira: $jira, entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      managerId,
      assessment,
      jira,
      entityId,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <string | null>response.data.exportToJira;
  }
  async ExportToServiceNow(
    managerId: string,
    assessment: string,
    serviceNow: ServiceNowAuthInput,
    entityId: string
  ): Promise<string | null> {
    const statement = `query ExportToServiceNow($managerId: String!, $assessment: AWSJSON!, $serviceNow: ServiceNowAuthInput!, $entityId: String!) {
        exportToServiceNow(managerId: $managerId, assessment: $assessment, serviceNow: $serviceNow, entityId: $entityId)
      }`;
    const gqlAPIServiceArguments: any = {
      managerId,
      assessment,
      serviceNow,
      entityId,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <string | null>response.data.exportToServiceNow;
  }
  async UpdateJiraTicketStatus(questionId: string, assessmentId: string, jira: JiraAuthInput): Promise<string | null> {
    const statement = `query UpdateJiraTicketStatus($questionId: String!, $assessmentId: String!, $jira: JiraAuthInput!) {
        updateJiraTicketStatus(questionId: $questionId, assessmentId: $assessmentId, jira: $jira)
      }`;
    const gqlAPIServiceArguments: any = {
      questionId,
      assessmentId,
      jira,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <string | null>response.data.updateJiraTicketStatus;
  }
  async UpdateJiraCredentials(assessmentId: string, entityId: string, jira?: JiraAuthInput): Promise<string | null> {
    const response = (await API.put('cygovRestApi', '/updateJiraCredentials', {
      body: { assessmentId, entityId, jira },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async GetRapid7Data(data?: Rapid7Input): Promise<any | null> {
    const response = (await API.post('cygovRestApi', '/getRapid7Data', {
      body: { data },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }
  async TenableIntegration(actions?: string, tenable?: TenableDetailsInput): Promise<string | null> {
    const statement = `query TenableIntegration($actions: String, $tenable: TenableDetailsInput) {
        tenableIntegration(actions: $actions, tenable: $tenable)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (actions) {
      gqlAPIServiceArguments.actions = actions;
    }
    if (tenable) {
      gqlAPIServiceArguments.tenable = tenable;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <string | null>response.data.tenableIntegration;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(filter?: ModelUserFilterInput, limit?: number, nextToken?: string): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            onBoardingStatus
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async UserByEmail(
    email: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByEmailQuery> {
    const statement = `query UserByEmail($email: AWSEmail!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByEmail(email: $email, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
            onBoardingStatus
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UserByEmailQuery>response.data.userByEmail;
  }
  async UserRole(
    role: RoleEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserRoleQuery> {
    const statement = `query UserRole($role: RoleEnum!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userRole(role: $role, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      role,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UserRoleQuery>response.data.userRole;
  }
  async UserByRoleId(
    roleId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByRoleIdQuery> {
    const statement = `query UserByRoleId($roleId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByRoleId(roleId: $roleId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      roleId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UserByRoleIdQuery>response.data.userByRoleId;
  }
  async UserByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserByEntityIdQuery> {
    const statement = `query UserByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userByEntityId(entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UserByEntityIdQuery>response.data.userByEntityId;
  }
  async GetTags(id: string): Promise<GetTagsQuery> {
    const statement = `query GetTags($id: ID!) {
        getTags(id: $id) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetTagsQuery>response.data.getTags;
  }
  async ListGroups(filter?: ModelGroupFilterInput, limit?: number, nextToken?: string): Promise<ListGroupsQuery> {
    const statement = `query ListGroups($filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
        listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            rootId
            tags
            score
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListGroupsQuery>response.data.listGroups;
  }
  async GroupsByRootId(
    rootId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelGroupFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GroupsByRootIdQuery> {
    const statement = `query GroupsByRootId($rootId: String!, $sortDirection: ModelSortDirection, $filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
        groupsByRootId(rootId: $rootId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            rootId
            tags
            score
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);

    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GroupsByRootIdQuery>response.data.groupsByRootId;
  }
  async GetQuestionData(id: string): Promise<GetQuestionDataQuery> {
    const statement = `query GetQuestionData($id: ID!) {
        getQuestionData(id: $id) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);

    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetQuestionDataQuery>response.data.getQuestionData;
  }
  async ListQuestionData(
    filter?: ModelQuestionDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionDataQuery> {
    const statement = `query ListQuestionData($filter: ModelQuestionDataFilterInput, $limit: Int, $nextToken: String) {
        listQuestionData(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }

    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListQuestionDataQuery>response.data.listQuestionData;
  }
  async QuestionsByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionDataFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<QuestionsByAssessmentIdQuery> {
    const statement = `query QuestionsByAssessmentId($assessmentId: String!, $sortDirection: ModelSortDirection, $filter: ModelQuestionDataFilterInput, $limit: Int, $nextToken: String) {
        questionsByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <QuestionsByAssessmentIdQuery>response.data.questionsByAssessmentId;
  }
  async GetArchivedUser(id: string): Promise<GetArchivedUserQuery> {
    const statement = `query GetArchivedUser($id: ID!) {
        getArchivedUser(id: $id) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetArchivedUserQuery>response.data.getArchivedUser;
  }
  async ListArchivedUsers(
    filter?: ModelArchivedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListArchivedUsersQuery> {
    const statement = `query ListArchivedUsers($filter: ModelArchivedUserFilterInput, $limit: Int, $nextToken: String) {
        listArchivedUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListArchivedUsersQuery>response.data.listArchivedUsers;
  }
  async ArchivedUserRole(
    role: RoleEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchivedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivedUserRoleQuery> {
    const statement = `query ArchivedUserRole($role: RoleEnum!, $sortDirection: ModelSortDirection, $filter: ModelArchivedUserFilterInput, $limit: Int, $nextToken: String) {
        archivedUserRole(role: $role, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      role,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ArchivedUserRoleQuery>response.data.archivedUserRole;
  }
  async ArchivedUserByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelArchivedUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ArchivedUserByEntityIdQuery> {
    const statement = `query ArchivedUserByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelArchivedUserFilterInput, $limit: Int, $nextToken: String) {
        archivedUserByEntityId(entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            role
            phone
            entityId
            isCognitoUser
            questionMap
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            domain
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ArchivedUserByEntityIdQuery>response.data.archivedUserByEntityId;
  }

  async GetEntityAIData(id: string): Promise<GetEntityQuery> {
    const statement = `query GetEntity($id: ID!) {
      getEntity(id: $id) {
        __typename
        id
        name
        users
        completionStatus
        accessGroups
        participantGroup
        rootEntityId
        childEntityId
        frameworkId
        frameworkName
        frameworkType
        parentId
        AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
        }
  }}`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetEntityQuery>response.data.getEntity;
  }

  async GetEntity(id: string): Promise<GetEntityQuery> {
    const statement = `query GetEntity($id: ID!) {
      getEntity(id: $id) {
        __typename
        id
        name
        users
        completionStatus
        accessGroups
        participantGroup
        rootEntityId
        childEntityId
        frameworkId
        frameworkName
        frameworkType
        parentId
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          nextStartingDate
          reassessmentNotifier
          vendorOnBoardingDate
          isAutoReminder
          isAutoReassessment
        }
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        entityType
        projectManager
        projectManagerName
        projectDeadline
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        integrations {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        externalFeed {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        defaultSetting {
          __typename
          dueDateInDays
          frequency
          surveyLevel
          artifacts {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          standards {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customRequirements {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customCertifications {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          accessLevel
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          activeScan
          notifications
          selectedRisk
          isThirdParty
          isFourthParty
          domains
          otherData
          fourthPartyWeightage
          enbdDomain
        }
        createdBy
        createdAt
        modifiedBy
        modifiedAt
        vendorDetails {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          createdAt
          updatedAt
        }
        industry
        domain
        activeAssessmentId
        tierSelected
        activeAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        scName
        defaultQuestionSettings {
          __typename
          isApprovalRequired
          isArtifactsMandatory
          isCommentsMandatory
          isCollaborative
          isIntegrationActive
          isSmartMappingActive
        }
        KMS {
          __typename
          status
          version
          key
          alias
          dataKeyEncrypted
          newDataKeyEncrypted
          newKeyId
          rotations {
            __typename
            count
            status
            lastRotation
            firstRotationDate
          }
          userId
        }
        AI {
          __typename
          credits
          date
          isActive
          usedCredits
          userName
        }
        upperdeckRoute
        isLocked
        eSignFinalized
        weightageScore
        upperdeckSetting
        domainScanned
        domainScanTimes
        domainScanEnabledForEntity
        solutionStatus {
          __typename
          firstParty
          thirdParty
          boardView
        }
        vendors {
          __typename
          vendorsTotalCount
          totalVendorsCreated
        }
        frameworkSettings
        usedFrameworkUniqueCount
        spiderChartSetting
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetEntityQuery>response.data.getEntity;
  }
  async GetVendorsDetail(id: string): Promise<GetVendorsDetailQuery> {
    const statement = `query GetVendorsDetail($id: ID!) {
        getVendorsDetail(id: $id) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetVendorsDetailQuery>response.data.getVendorsDetail;
  }
  async ListVendorsDetails(
    filter?: ModelVendorsDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListVendorsDetailsQuery> {
    const statement = `query ListVendorsDetails($filter: ModelVendorsDetailFilterInput, $limit: Int, $nextToken: String) {
        listVendorsDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListVendorsDetailsQuery>response.data.listVendorsDetails;
  }
  async GetFrameworkScores(id: string): Promise<GetFrameworkScoresQuery> {
    const statement = `query GetFrameworkScores($id: ID!) {
        getFrameworkScores(id: $id) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetFrameworkScoresQuery>response.data.getFrameworkScores;
  }
  async ListFrameworkScores(
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFrameworkScoresQuery> {
    const statement = `query ListFrameworkScores($filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        listFrameworkScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            type
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            assignmentCount
            riskStandardsCount
            complianceStandardsCount
            standardsCount
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            groups
            maturity
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListFrameworkScoresQuery>response.data.listFrameworkScores;
  }
  async FrameworkScoresByAssessment(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkScoresByAssessmentQuery> {
    const statement = `query FrameworkScoresByAssessment($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        FrameworkScoresByAssessment(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            type
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            assignmentCount
            riskStandardsCount
            complianceStandardsCount
            standardsCount
            groups
            maturity
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <FrameworkScoresByAssessmentQuery>response.data.FrameworkScoresByAssessment;
  }

  async FrameworkTotalScoreByAssessment(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelFrameworkScoresFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<FrameworkScoresByAssessmentQuery> {
    const statement = `query FrameworkScoresByAssessment($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelFrameworkScoresFilterInput, $limit: Int, $nextToken: String) {
        FrameworkScoresByAssessment(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            id
            total
            target
            collection
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <FrameworkScoresByAssessmentQuery>response.data.FrameworkScoresByAssessment;
  }

  async GetIntelligence(id: string): Promise<GetIntelligenceQuery> {
    const statement = `query GetIntelligence($id: ID!) {
        getIntelligence(id: $id) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetIntelligenceQuery>response.data.getIntelligence;
  }
  async ListIntelligences(
    filter?: ModelIntelligenceFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListIntelligencesQuery> {
    const statement = `query ListIntelligences($filter: ModelIntelligenceFilterInput, $limit: Int, $nextToken: String) {
        listIntelligences(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListIntelligencesQuery>response.data.listIntelligences;
  }
  async GetBreaches(id: string): Promise<GetBreachesQuery> {
    const statement = `query GetBreaches($id: ID!) {
        getBreaches(id: $id) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetBreachesQuery>response.data.getBreaches;
  }
  async ListBreaches(
    filter?: ModelBreachesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBreachesQuery> {
    const statement = `query ListBreaches($filter: ModelBreachesFilterInput, $limit: Int, $nextToken: String) {
        listBreaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            date
            year
            name
            description
            leaks
            data
            status
            email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListBreachesQuery>response.data.listBreaches;
  }
  async BreachesByVendorIdAndStatus(
    vendorId: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelBreachesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BreachesByVendorIdAndStatusQuery> {
    const statement = `query BreachesByVendorIdAndStatus($vendorId: ID!, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelBreachesFilterInput, $limit: Int, $nextToken: String) {
        BreachesByVendorIdAndStatus(vendorId: $vendorId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            date
            year
            name
            description
            leaks
            data
            status
            email
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendorId,
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <BreachesByVendorIdAndStatusQuery>response.data.BreachesByVendorIdAndStatus;
  }
  async GetExternalThreats(id: string): Promise<GetExternalThreatsQuery> {
    const statement = `query GetExternalThreats($id: ID!) {
        getExternalThreats(id: $id) {
          __typename
          id
          vendorId
          source
          findings
          riskScore
          details {
            __typename
            ip
            port
            cve
            cvss
          }
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetExternalThreatsQuery>response.data.getExternalThreats;
  }
  async ListExternalThreats(
    filter?: ModelExternalThreatsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListExternalThreatsQuery> {
    const statement = `query ListExternalThreats($filter: ModelExternalThreatsFilterInput, $limit: Int, $nextToken: String) {
        listExternalThreats(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListExternalThreatsQuery>response.data.listExternalThreats;
  }
  async ExternalThreatsByVendorIdAndStatus(
    vendorId: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelExternalThreatsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ExternalThreatsByVendorIdAndStatusQuery> {
    const statement = `query ExternalThreatsByVendorIdAndStatus($vendorId: ID!, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelExternalThreatsFilterInput, $limit: Int, $nextToken: String) {
        ExternalThreatsByVendorIdAndStatus(vendorId: $vendorId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            source
            findings
            riskScore
            details {
              __typename
              ip
              port
              cve
              cvss
            }
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendorId,
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ExternalThreatsByVendorIdAndStatusQuery>response.data.ExternalThreatsByVendorIdAndStatus;
  }
  async GetOthers(id: string): Promise<GetOthersQuery> {
    const statement = `query GetOthers($id: ID!) {
        getOthers(id: $id) {
          __typename
          id
          vendorId
          name
          label
          severity
          description
          status
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetOthersQuery>response.data.getOthers;
  }
  async ListOthers(filter?: ModelOthersFilterInput, limit?: number, nextToken?: string): Promise<ListOthersQuery> {
    const statement = `query ListOthers($filter: ModelOthersFilterInput, $limit: Int, $nextToken: String) {
        listOthers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListOthersQuery>response.data.listOthers;
  }
  async OthersByVendorIdAndStatus(
    vendorId: string,
    status?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelOthersFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<OthersByVendorIdAndStatusQuery> {
    const statement = `query OthersByVendorIdAndStatus($vendorId: ID!, $status: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelOthersFilterInput, $limit: Int, $nextToken: String) {
        OthersByVendorIdAndStatus(vendorId: $vendorId, status: $status, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            vendorId
            name
            label
            severity
            description
            status
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      vendorId,
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <OthersByVendorIdAndStatusQuery>response.data.OthersByVendorIdAndStatus;
  }
  async GetQuestionnaireCompletion(id: string): Promise<GetQuestionnaireCompletionQuery> {
    const statement = `query GetQuestionnaireCompletion($id: ID!) {
        getQuestionnaireCompletion(id: $id) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetQuestionnaireCompletionQuery>response.data.getQuestionnaireCompletion;
  }
  async ListQuestionnaireCompletions(
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListQuestionnaireCompletionsQuery> {
    const statement = `query ListQuestionnaireCompletions($filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        listQuestionnaireCompletions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            assignmentMap
            firstAnswer
            lastAnswer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListQuestionnaireCompletionsQuery>response.data.listQuestionnaireCompletions;
  }
  async CompletionByUser(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CompletionByUserQuery> {
    const statement = `query CompletionByUser($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        CompletionByUser(userId: $userId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            assignmentMap
            firstAnswer
            lastAnswer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CompletionByUserQuery>response.data.CompletionByUser;
  }
  async CompletionByAssessment(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelQuestionnaireCompletionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CompletionByAssessmentQuery> {
    const statement = `query CompletionByAssessment($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelQuestionnaireCompletionFilterInput, $limit: Int, $nextToken: String) {
        CompletionByAssessment(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            totalQuestions
            completedQuestions
            userId
            assessmentId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            assignmentMap
            firstAnswer
            lastAnswer
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CompletionByAssessmentQuery>response.data.CompletionByAssessment;
  }
  async AssignAssessment(assessmentId: string, userId: string): Promise<string | null> {
    const statement = `mutation AssignAssessment($assessmentId: String!, $userId: String!) {
        assignAssessment(assessmentId: $assessmentId, userId: $userId)
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
      userId,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <string | null>response.data.assignAssessment;
  }
  async SaveIntegrationDetails(entityId: string, details: any): Promise<string | null> {
    const statement = `mutation SaveIntegrationDetails($entityId: String!, $details: any!) {
        saveIntegrationDetails(entityId: $entityId, details: $details)
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
      details,
    };

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <string | null>response.data.saveIntegrationDetails;
  }

  async CreateQuestionData(
    input: CreateQuestionDataInput,
    condition?: ModelQuestionDataConditionInput
  ): Promise<CreateQuestionDataMutation> {
    const statement = `mutation CreateQuestionData($input: CreateQuestionDataInput!, $condition: ModelQuestionDataConditionInput) {
        createQuestionData(input: $input, condition: $condition) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateQuestionDataMutation>response.data.createQuestionData;
  }

  async UpdateQuestionData(
    input: UpdateQuestionDataInput,
    condition?: ModelQuestionDataConditionInput
  ): Promise<UpdateQuestionDataMutation> {
    const statement = `mutation UpdateQuestionData($input: UpdateQuestionDataInput!, $condition: ModelQuestionDataConditionInput) {
        updateQuestionData(input: $input, condition: $condition) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          reviewStatus
          resentBackStatus
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateQuestionDataMutation>response.data.updateQuestionData;
  }

  async DeleteQuestionData(
    input: DeleteQuestionDataInput,
    condition?: ModelQuestionDataConditionInput
  ): Promise<DeleteQuestionDataMutation> {
    const statement = `mutation DeleteQuestionData($input: DeleteQuestionDataInput!, $condition: ModelQuestionDataConditionInput) {
        deleteQuestionData(input: $input, condition: $condition) {
          __typename
          id
          childId
          frameworkKey
          function
          category
          subCategory
          controlNameLabel
          score
          rootId
          assessmentId
          name
          left
          right
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteQuestionDataMutation>response.data.deleteQuestionData;
  }

  async CreateArchivedUser(
    input: CreateArchivedUserInput,
    condition?: ModelArchivedUserConditionInput
  ): Promise<CreateArchivedUserMutation> {
    const statement = `mutation CreateArchivedUser($input: CreateArchivedUserInput!, $condition: ModelArchivedUserConditionInput) {
        createArchivedUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateArchivedUserMutation>response.data.createArchivedUser;
  }
  async UpdateArchivedUser(
    input: UpdateArchivedUserInput,
    condition?: ModelArchivedUserConditionInput
  ): Promise<UpdateArchivedUserMutation> {
    const statement = `mutation UpdateArchivedUser($input: UpdateArchivedUserInput!, $condition: ModelArchivedUserConditionInput) {
        updateArchivedUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateArchivedUserMutation>response.data.updateArchivedUser;
  }

  async DeleteArchivedUser(
    input: DeleteArchivedUserInput,
    condition?: ModelArchivedUserConditionInput
  ): Promise<DeleteArchivedUserMutation> {
    const statement = `mutation DeleteArchivedUser($input: DeleteArchivedUserInput!, $condition: ModelArchivedUserConditionInput) {
        deleteArchivedUser(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          role
          phone
          entityId
          isCognitoUser
          questionMap
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          domain
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteArchivedUserMutation>response.data.deleteArchivedUser;
  }
  async CreateEntity(input: CreateEntityInput, condition?: ModelEntityConditionInput): Promise<CreateEntityMutation> {
    const statement = `mutation CreateEntity($input: CreateEntityInput!, $condition: ModelEntityConditionInput) {
      createEntity(input: $input, condition: $condition) {
        __typename
        id
        name
        users
        completionStatus
        accessGroups
        participantGroup
        rootEntityId
        childEntityId
        frameworkId
        frameworkName
        frameworkType
        parentId
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          nextStartingDate
          reassessmentNotifier
          vendorOnBoardingDate
          isAutoReminder
          isAutoReassessment
        }
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        entityType
        projectManager
        projectManagerName
        projectDeadline
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        integrations {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        externalFeed {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        defaultSetting {
          __typename
          dueDateInDays
          frequency
          surveyLevel
          artifacts {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          standards {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customRequirements {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customCertifications {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          accessLevel
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          activeScan
          notifications
          selectedRisk
          isThirdParty
          isFourthParty
          domains
          otherData
          fourthPartyWeightage
          enbdDomain
        }
        createdBy
        createdAt
        modifiedBy
        modifiedAt
        vendorDetails {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          createdAt
          updatedAt
        }
        industry
        domain
        activeAssessmentId
        tierSelected
        activeAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        scName
        defaultQuestionSettings {
          __typename
          isApprovalRequired
          isArtifactsMandatory
          isCommentsMandatory
          isCollaborative
          isIntegrationActive
          isSmartMappingActive
        }
        KMS {
          __typename
          status
          version
          key
          alias
          dataKeyEncrypted
          newDataKeyEncrypted
          newKeyId
          rotations {
            __typename
            count
            status
            lastRotation
            firstRotationDate
          }
          userId
        }
        AI {
          __typename
          credits
          date
          isActive
          usedCredits
          userName
        }
        upperdeckRoute
        isLocked
        eSignFinalized
        weightageScore
        upperdeckSetting
        domainScanned
        domainScanTimes
        domainScanEnabledForEntity
        solutionStatus {
          __typename
          firstParty
          thirdParty
          boardView
        }
        vendors {
          __typename
          vendorsTotalCount
          totalVendorsCreated
        }
        frameworkSettings
        usedFrameworkUniqueCount
        spiderChartSetting
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateEntityMutation>response.data.createEntity;
  }
  async UpdateEntity(input: UpdateEntityInput, condition?: ModelEntityConditionInput): Promise<UpdateEntityMutation> {
    const statement = `mutation UpdateEntity($input: UpdateEntityInput!, $condition: ModelEntityConditionInput) {
      updateEntity(input: $input, condition: $condition) {
        __typename
        id
        name
        users
        completionStatus
        accessGroups
        participantGroup
        rootEntityId
        childEntityId
        frameworkId
        frameworkName
        frameworkType
        parentId
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          nextStartingDate
          reassessmentNotifier
          vendorOnBoardingDate
          isAutoReminder
          isAutoReassessment
        }
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        entityType
        projectManager
        projectManagerName
        projectDeadline
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        integrations {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        externalFeed {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        defaultSetting {
          __typename
          dueDateInDays
          frequency
          surveyLevel
          artifacts {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          standards {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customRequirements {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customCertifications {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          accessLevel
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          activeScan
          notifications
          selectedRisk
          isThirdParty
          isFourthParty
          domains
          otherData
          fourthPartyWeightage
          enbdDomain
        }
        createdBy
        createdAt
        modifiedBy
        modifiedAt
        vendorDetails {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          createdAt
          updatedAt
        }
        industry
        domain
        activeAssessmentId
        tierSelected
        activeAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        scName
        defaultQuestionSettings {
          __typename
          isApprovalRequired
          isArtifactsMandatory
          isCommentsMandatory
          isCollaborative
          isIntegrationActive
          isSmartMappingActive
        }
        KMS {
          __typename
          status
          version
          key
          alias
          dataKeyEncrypted
          newDataKeyEncrypted
          newKeyId
          rotations {
            __typename
            count
            status
            lastRotation
            firstRotationDate
          }
          userId
        }
        AI {
          __typename
          credits
          date
          isActive
          usedCredits
          userName
        }
        upperdeckRoute
        isLocked
        eSignFinalized
        weightageScore
        upperdeckSetting
        domainScanned
        domainScanTimes
        domainScanEnabledForEntity
        solutionStatus {
          __typename
          firstParty
          thirdParty
          boardView
        }
        vendors {
          __typename
          vendorsTotalCount
          totalVendorsCreated
        }
        frameworkSettings
        usedFrameworkUniqueCount
        spiderChartSetting
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateEntityMutation>response.data.updateEntity;
  }
  async DeleteEntity(input: DeleteEntityInput, condition?: ModelEntityConditionInput): Promise<DeleteEntityMutation> {
    const statement = `mutation DeleteEntity($input: DeleteEntityInput!, $condition: ModelEntityConditionInput) {
      deleteEntity(input: $input, condition: $condition) {
        __typename
        id
        name
        users
        completionStatus
        accessGroups
        participantGroup
        rootEntityId
        childEntityId
        frameworkId
        frameworkName
        frameworkType
        parentId
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          nextStartingDate
          reassessmentNotifier
          vendorOnBoardingDate
          isAutoReminder
          isAutoReassessment
        }
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        entityType
        projectManager
        projectManagerName
        projectDeadline
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        integrations {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        externalFeed {
          __typename
          name
          credentials
          status
          isEnable
          isAuth
          inheritFromParent
          webhookUpdatedAt
        }
        defaultSetting {
          __typename
          dueDateInDays
          frequency
          surveyLevel
          artifacts {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          standards {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customRequirements {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          customCertifications {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          accessLevel
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          activeScan
          notifications
          selectedRisk
          isThirdParty
          isFourthParty
          domains
          otherData
          fourthPartyWeightage
          enbdDomain
        }
        createdBy
        createdAt
        modifiedBy
        modifiedAt
        vendorDetails {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          createdAt
          updatedAt
        }
        industry
        domain
        activeAssessmentId
        tierSelected
        activeAssessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        scName
        defaultQuestionSettings {
          __typename
          isApprovalRequired
          isArtifactsMandatory
          isCommentsMandatory
          isCollaborative
          isIntegrationActive
          isSmartMappingActive
        }
        KMS {
          __typename
          status
          version
          key
          alias
          dataKeyEncrypted
          newDataKeyEncrypted
          newKeyId
          rotations {
            __typename
            count
            status
            lastRotation
            firstRotationDate
          }
          userId
        }
        AI {
          __typename
          credits
          date
          isActive
          usedCredits
          userName
        }
        upperdeckRoute
        isLocked
        eSignFinalized
        weightageScore
        upperdeckSetting
        domainScanned
        domainScanTimes
        domainScanEnabledForEntity
        solutionStatus {
          __typename
          firstParty
          thirdParty
          boardView
        }
        vendors {
          __typename
          vendorsTotalCount
          totalVendorsCreated
        }
        frameworkSettings
        usedFrameworkUniqueCount
        spiderChartSetting
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };

    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteEntityMutation>response.data.deleteEntity;
  }
  async CreateVendorsDetail(
    input: CreateVendorsDetailInput,
    condition?: ModelVendorsDetailConditionInput
  ): Promise<CreateVendorsDetailMutation> {
    const statement = `mutation CreateVendorsDetail($input: CreateVendorsDetailInput!, $condition: ModelVendorsDetailConditionInput) {
        createVendorsDetail(input: $input, condition: $condition) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateVendorsDetailMutation>response.data.createVendorsDetail;
  }
  async DeleteBreaches(
    input: DeleteBreachesInput,
    condition?: ModelBreachesConditionInput
  ): Promise<DeleteBreachesMutation> {
    const statement = `mutation DeleteBreaches($input: DeleteBreachesInput!, $condition: ModelBreachesConditionInput) {
      deleteBreaches(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        date
        year
        name
        description
        leaks
        data
        status
        email
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteBreachesMutation>response.data.deleteBreaches;
  }
  async CreateExternalThreats(
    input: CreateExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<CreateExternalThreatsMutation> {
    const statement = `mutation CreateExternalThreats($input: CreateExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
      createExternalThreats(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        source
        findings
        riskScore
        details {
          __typename
          ip
          port
          cve
          cvss
        }
        status
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateExternalThreatsMutation>response.data.createExternalThreats;
  }
  async UpdateExternalThreats(
    input: UpdateExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<UpdateExternalThreatsMutation> {
    const statement = `mutation UpdateExternalThreats($input: UpdateExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
      updateExternalThreats(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        source
        findings
        riskScore
        details {
          __typename
          ip
          port
          cve
          cvss
        }
        status
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateExternalThreatsMutation>response.data.updateExternalThreats;
  }
  async DeleteExternalThreats(
    input: DeleteExternalThreatsInput,
    condition?: ModelExternalThreatsConditionInput
  ): Promise<DeleteExternalThreatsMutation> {
    const statement = `mutation DeleteExternalThreats($input: DeleteExternalThreatsInput!, $condition: ModelExternalThreatsConditionInput) {
      deleteExternalThreats(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        source
        findings
        riskScore
        details {
          __typename
          ip
          port
          cve
          cvss
        }
        status
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteExternalThreatsMutation>response.data.deleteExternalThreats;
  }
  async CreateOthers(input: CreateOthersInput, condition?: ModelOthersConditionInput): Promise<CreateOthersMutation> {
    const statement = `mutation CreateOthers($input: CreateOthersInput!, $condition: ModelOthersConditionInput) {
      createOthers(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        name
        label
        severity
        description
        status
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateOthersMutation>response.data.createOthers;
  }
  async UpdateOthers(input: UpdateOthersInput, condition?: ModelOthersConditionInput): Promise<UpdateOthersMutation> {
    const statement = `mutation UpdateOthers($input: UpdateOthersInput!, $condition: ModelOthersConditionInput) {
      updateOthers(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        name
        label
        severity
        description
        status
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateOthersMutation>response.data.updateOthers;
  }
  async DeleteOthers(input: DeleteOthersInput, condition?: ModelOthersConditionInput): Promise<DeleteOthersMutation> {
    const statement = `mutation DeleteOthers($input: DeleteOthersInput!, $condition: ModelOthersConditionInput) {
      deleteOthers(input: $input, condition: $condition) {
        __typename
        id
        vendorId
        name
        label
        severity
        description
        status
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteOthersMutation>response.data.deleteOthers;
  }
  async CreateQuestionnaireCompletion(
    input: CreateQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<CreateQuestionnaireCompletionMutation> {
    const statement = `mutation CreateQuestionnaireCompletion($input: CreateQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
      createQuestionnaireCompletion(input: $input, condition: $condition) {
        __typename
        id
        totalQuestions
        completedQuestions
        userId
        assessmentId
        user {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
        assessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        assignmentMap
        firstAnswer
        lastAnswer
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateQuestionnaireCompletionMutation>response.data.createQuestionnaireCompletion;
  }
  async UpdateQuestionnaireCompletion(
    input: UpdateQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<UpdateQuestionnaireCompletionMutation> {
    const statement = `mutation UpdateQuestionnaireCompletion($input: UpdateQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
      updateQuestionnaireCompletion(input: $input, condition: $condition) {
        __typename
        id
        totalQuestions
        completedQuestions
        userId
        assessmentId
        user {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
        assessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        assignmentMap
        firstAnswer
        lastAnswer
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateQuestionnaireCompletionMutation>response.data.updateQuestionnaireCompletion;
  }
  async DeleteQuestionnaireCompletion(
    input: DeleteQuestionnaireCompletionInput,
    condition?: ModelQuestionnaireCompletionConditionInput
  ): Promise<DeleteQuestionnaireCompletionMutation> {
    const statement = `mutation DeleteQuestionnaireCompletion($input: DeleteQuestionnaireCompletionInput!, $condition: ModelQuestionnaireCompletionConditionInput) {
      deleteQuestionnaireCompletion(input: $input, condition: $condition) {
        __typename
        id
        totalQuestions
        completedQuestions
        userId
        assessmentId
        user {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
        assessment {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                }
                standardFrameworkList {
                  __typename
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
        assignmentMap
        firstAnswer
        lastAnswer
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteQuestionnaireCompletionMutation>response.data.deleteQuestionnaireCompletion;
  }
  async DeleteTaskAction(
    input: DeleteTaskActionInput,
    condition?: ModelTaskActionConditionInput
  ): Promise<DeleteTaskActionMutation> {
    const statement = `mutation DeleteTaskAction($input: DeleteTaskActionInput!, $condition: ModelTaskActionConditionInput) {
          deleteTaskAction(input: $input, condition: $condition) {
            __typename
            id
            createdAt
            score
            action
            assessmentId
            taskAssignmentID
            questionId
            frameworkName
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
            userId
            date
            triggerStopper
            searchString
            questionIds
            smartMappingIds
            title
            impact
            maturity
            controlsConnected {
              __typename
              id
              name
              label
            }
            type
            description
            tabName
            managerAction
            rootEntityId
            dueDate
            updatedAt
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteTaskActionMutation>response.data.deleteTaskAction;
  }
  async CreateUsersAssignmentMapper(
    input: CreateUsersAssignmentMapperInput,
    condition?: ModelUsersAssignmentMapperConditionInput
  ): Promise<CreateUsersAssignmentMapperMutation> {
    const statement = `mutation CreateUsersAssignmentMapper($input: CreateUsersAssignmentMapperInput!, $condition: ModelUsersAssignmentMapperConditionInput) {
          createUsersAssignmentMapper(input: $input, condition: $condition) {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateUsersAssignmentMapperMutation>response.data.createUsersAssignmentMapper;
  }
  async UpdateUsersAssignmentMapper(
    input: UpdateUsersAssignmentMapperInput,
    condition?: ModelUsersAssignmentMapperConditionInput
  ): Promise<UpdateUsersAssignmentMapperMutation> {
    const statement = `mutation UpdateUsersAssignmentMapper($input: UpdateUsersAssignmentMapperInput!, $condition: ModelUsersAssignmentMapperConditionInput) {
          updateUsersAssignmentMapper(input: $input, condition: $condition) {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateUsersAssignmentMapperMutation>response.data.updateUsersAssignmentMapper;
  }
  async DeleteUsersAssignmentMapper(
    input: DeleteUsersAssignmentMapperInput,
    condition?: ModelUsersAssignmentMapperConditionInput
  ): Promise<DeleteUsersAssignmentMapperMutation> {
    const statement = `mutation DeleteUsersAssignmentMapper($input: DeleteUsersAssignmentMapperInput!, $condition: ModelUsersAssignmentMapperConditionInput) {
          deleteUsersAssignmentMapper(input: $input, condition: $condition) {
            __typename
            id
            createdAt
            updatedAt
            score
            assessmentId
            taskId
            userId
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              updatedAt
            }
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteUsersAssignmentMapperMutation>response.data.deleteUsersAssignmentMapper;
  }
  async CreateFrameworkSettings(
    input: CreateFrameworkSettingsInput,
    condition?: ModelFrameworkSettingsConditionInput
  ): Promise<CreateFrameworkSettingsMutation> {
    const statement = `mutation CreateFrameworkSettings($input: CreateFrameworkSettingsInput!, $condition: ModelFrameworkSettingsConditionInput) {
          createFrameworkSettings(input: $input, condition: $condition) {
            __typename
            id
            settings
            createdAt
            updatedAt
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateFrameworkSettingsMutation>response.data.createFrameworkSettings;
  }
  async UpdateFrameworkSettings(
    input: UpdateFrameworkSettingsInput,
    condition?: ModelFrameworkSettingsConditionInput
  ): Promise<UpdateFrameworkSettingsMutation> {
    const statement = `mutation UpdateFrameworkSettings($input: UpdateFrameworkSettingsInput!, $condition: ModelFrameworkSettingsConditionInput) {
          updateFrameworkSettings(input: $input, condition: $condition) {
            __typename
            id
            settings
            createdAt
            updatedAt
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateFrameworkSettingsMutation>response.data.updateFrameworkSettings;
  }
  async DeleteFrameworkSettings(
    input: DeleteFrameworkSettingsInput,
    condition?: ModelFrameworkSettingsConditionInput
  ): Promise<DeleteFrameworkSettingsMutation> {
    const statement = `mutation DeleteFrameworkSettings($input: DeleteFrameworkSettingsInput!, $condition: ModelFrameworkSettingsConditionInput) {
          deleteFrameworkSettings(input: $input, condition: $condition) {
            __typename
            id
            settings
            createdAt
            updatedAt
          }
        }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteFrameworkSettingsMutation>response.data.deleteFrameworkSettings;
  }
  async CreateCustomTask(
    input: CreateCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<CreateCustomTaskMutation> {
    const statement = `mutation CreateCustomTask($input: CreateCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        createCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateCustomTaskMutation>response.data.createCustomTask;
  }
  async UpdateCustomTask(
    input: UpdateCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<UpdateCustomTaskMutation> {
    const statement = `mutation UpdateCustomTask($input: UpdateCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        updateCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateCustomTaskMutation>response.data.updateCustomTask;
  }
  async DeleteCustomTask(
    input: DeleteCustomTaskInput,
    condition?: ModelCustomTaskConditionInput
  ): Promise<DeleteCustomTaskMutation> {
    const statement = `mutation DeleteCustomTask($input: DeleteCustomTaskInput!, $condition: ModelCustomTaskConditionInput) {
        deleteCustomTask(input: $input, condition: $condition) {
          __typename
          id
          name
          label
          impact
          description
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          assessmentId
          customTodo
          dueDate
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteCustomTaskMutation>response.data.deleteCustomTask;
  }
  async CreateCustomTaskAssignment(
    input: CreateCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<CreateCustomTaskAssignmentMutation> {
    const statement = `mutation CreateCustomTaskAssignment($input: CreateCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
      createCustomTaskAssignment(input: $input, condition: $condition) {
        __typename
        id
        userId
        targetId
        assessmentId
        user {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateCustomTaskAssignmentMutation>response.data.createCustomTaskAssignment;
  }
  async UpdateCustomTaskAssignment(
    input: UpdateCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<UpdateCustomTaskAssignmentMutation> {
    const statement = `mutation UpdateCustomTaskAssignment($input: UpdateCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
      updateCustomTaskAssignment(input: $input, condition: $condition) {
        __typename
        id
        userId
        targetId
        assessmentId
        user {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateCustomTaskAssignmentMutation>response.data.updateCustomTaskAssignment;
  }
  async DeleteCustomTaskAssignment(
    input: DeleteCustomTaskAssignmentInput,
    condition?: ModelCustomTaskAssignmentConditionInput
  ): Promise<DeleteCustomTaskAssignmentMutation> {
    const statement = `mutation DeleteCustomTaskAssignment($input: DeleteCustomTaskAssignmentInput!, $condition: ModelCustomTaskAssignmentConditionInput) {
      deleteCustomTaskAssignment(input: $input, condition: $condition) {
        __typename
        id
        userId
        targetId
        assessmentId
        user {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteCustomTaskAssignmentMutation>response.data.deleteCustomTaskAssignment;
  }
  async CreateAssessmentStandardFramework(
    input: CreateAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<CreateAssessmentStandardFrameworkMutation> {
    const statement = `mutation CreateAssessmentStandardFramework($input: CreateAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        createAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateAssessmentStandardFrameworkMutation>response.data.createAssessmentStandardFramework;
  }
  async UpdateAssessmentStandardFramework(
    input: UpdateAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<UpdateAssessmentStandardFrameworkMutation> {
    const statement = `mutation UpdateAssessmentStandardFramework($input: UpdateAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        updateAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateAssessmentStandardFrameworkMutation>response.data.updateAssessmentStandardFramework;
  }
  async DeleteAssessmentStandardFramework(
    input: DeleteAssessmentStandardFrameworkInput,
    condition?: ModelAssessmentStandardFrameworkConditionInput
  ): Promise<DeleteAssessmentStandardFrameworkMutation> {
    const statement = `mutation DeleteAssessmentStandardFramework($input: DeleteAssessmentStandardFrameworkInput!, $condition: ModelAssessmentStandardFrameworkConditionInput) {
        deleteAssessmentStandardFramework(input: $input, condition: $condition) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteAssessmentStandardFrameworkMutation>response.data.deleteAssessmentStandardFramework;
  }
  async CreateFrameworkManager(
    input: CreateFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<CreateFrameworkManagerMutation> {
    const statement = `mutation CreateFrameworkManager($input: CreateFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
      createFrameworkManager(input: $input, condition: $condition) {
        __typename
        id
        assessmentId
        standardFrameworkId
        managerId
        left
        right
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateFrameworkManagerMutation>response.data.createFrameworkManager;
  }
  async UpdateFrameworkManager(
    input: UpdateFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<UpdateFrameworkManagerMutation> {
    const statement = `mutation UpdateFrameworkManager($input: UpdateFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
      updateFrameworkManager(input: $input, condition: $condition) {
        __typename
        id
        assessmentId
        standardFrameworkId
        managerId
        left
        right
        removedQuestions
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateFrameworkManagerMutation>response.data.updateFrameworkManager;
  }
  async DeleteFrameworkManager(
    input: DeleteFrameworkManagerInput,
    condition?: ModelFrameworkManagerConditionInput
  ): Promise<DeleteFrameworkManagerMutation> {
    const statement = `mutation DeleteFrameworkManager($input: DeleteFrameworkManagerInput!, $condition: ModelFrameworkManagerConditionInput) {
      deleteFrameworkManager(input: $input, condition: $condition) {
        __typename
        id
        assessmentId
        standardFrameworkId
        managerId
        left
        right
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteFrameworkManagerMutation>response.data.deleteFrameworkManager;
  }
  async CreateComment(
    input: CreateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<CreateCommentMutation> {
    const statement = `mutation CreateComment($input: CreateCommentInput!, $condition: ModelCommentConditionInput) {
        createComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateCommentMutation>response.data.createComment;
  }
  async UpdateComment(
    input: UpdateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<UpdateCommentMutation> {
    const statement = `mutation UpdateComment($input: UpdateCommentInput!, $condition: ModelCommentConditionInput) {
        updateComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateCommentMutation>response.data.updateComment;
  }
  async DeleteComment(
    input: DeleteCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<DeleteCommentMutation> {
    const statement = `mutation DeleteComment($input: DeleteCommentInput!, $condition: ModelCommentConditionInput) {
        deleteComment(input: $input, condition: $condition) {
          __typename
          id
          latestSource
          comment
          date
          questionId
          riskId
          assessmentId
          userId
          exports {
            __typename
            items {
              __typename
              id
              destination
              destinationCategoryId
              category
              categoryId
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            __typename
            items {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            nextToken
          }
          type
          sType
          framework
          srcFramework
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteCommentMutation>response.data.deleteComment;
  }
  async CreateExport(input: CreateExportInput, condition?: ModelExportConditionInput): Promise<CreateExportMutation> {
    const statement = `mutation CreateExport($input: CreateExportInput!, $condition: ModelExportConditionInput) {
        createExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateExportMutation>response.data.createExport;
  }
  async UpdateExport(input: UpdateExportInput, condition?: ModelExportConditionInput): Promise<UpdateExportMutation> {
    const statement = `mutation UpdateExport($input: UpdateExportInput!, $condition: ModelExportConditionInput) {
        updateExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateExportMutation>response.data.updateExport;
  }
  async DeleteExport(input: DeleteExportInput, condition?: ModelExportConditionInput): Promise<DeleteExportMutation> {
    const statement = `mutation DeleteExport($input: DeleteExportInput!, $condition: ModelExportConditionInput) {
        deleteExport(input: $input, condition: $condition) {
          __typename
          id
          destination
          destinationCategoryId
          category
          categoryId
          date
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteExportMutation>response.data.deleteExport;
  }
  async CreateReport(input: CreateReportInput, condition?: ModelReportConditionInput): Promise<CreateReportMutation> {
    const statement = `mutation CreateReport($input: CreateReportInput!, $condition: ModelReportConditionInput) {
        createReport(input: $input, condition: $condition) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateReportMutation>response.data.createReport;
  }
  async UpdateReport(input: UpdateReportInput, condition?: ModelReportConditionInput): Promise<UpdateReportMutation> {
    const statement = `mutation UpdateReport($input: UpdateReportInput!, $condition: ModelReportConditionInput) {
        updateReport(input: $input, condition: $condition) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateReportMutation>response.data.updateReport;
  }
  async DeleteReport(input: DeleteReportInput, condition?: ModelReportConditionInput): Promise<DeleteReportMutation> {
    const statement = `mutation DeleteReport($input: DeleteReportInput!, $condition: ModelReportConditionInput) {
        deleteReport(input: $input, condition: $condition) {
          __typename
          id
          entityId
          name
          managerName
          startDate
          endDate
          type
          isDraft
          previousReports
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteReportMutation>response.data.deleteReport;
  }
  async CreateRisk(input: CreateRiskInput, condition?: ModelRiskConditionInput): Promise<CreateRiskMutation> {
    const statement = `mutation CreateRisk($input: CreateRiskInput!, $condition: ModelRiskConditionInput) {
        createRisk(input: $input, condition: $condition) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
          riskOwnerIds
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateRiskMutation>response.data.createRisk;
  }
  async UpdateRisk(input: UpdateRiskInput, condition?: ModelRiskConditionInput): Promise<UpdateRiskMutation> {
    const statement = `mutation UpdateRisk($input: UpdateRiskInput!, $condition: ModelRiskConditionInput) {
        updateRisk(input: $input, condition: $condition) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateRiskMutation>response.data.updateRisk;
  }
  async DeleteRisk(input: DeleteRiskInput, condition?: ModelRiskConditionInput): Promise<DeleteRiskMutation> {
    const statement = `mutation DeleteRisk($input: DeleteRiskInput!, $condition: ModelRiskConditionInput) {
        deleteRisk(input: $input, condition: $condition) {
          __typename
          id
          idTitle
          entityId
          entity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          subEntityId
          subEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          assessmentId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          type
          disable
          controlIds
          riskTitle
          riskDescription
          riskCategory
          relatedAssets
          residualImpact
          residualProbability
          riskImpact
          riskProbability
          inherentRisk
          residualRisk
          riskStatus
          riskStatusUpdatedBy
          notes
          costToRemediate
          costToRemediateType
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          riskPriority
          hidden
          financialToggle
          scoreHistory {
            __typename
            controlId
            questionOrder
            score
            remediationStatus
            impact
            probability
            weight
            left
            right
          }
          riskTags
          riskTasks {
            __typename
            title
            status
            id
          }
          riskControlNames {
            __typename
            title
            id
            mitigationPlan
            label
          }
          createdAt
          updatedAt
          riskTasksIds
          isArchived
          isEscalated
          sensitivity
          effectiveness
          deadline
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteRiskMutation>response.data.deleteRisk;
  }
  async CreateRiskOwnerAssignment(
    input: CreateRiskOwnerAssignmentInput,
    condition?: ModelRiskOwnerAssignmentConditionInput
  ): Promise<CreateRiskOwnerAssignmentMutation> {
    const statement = `mutation CreateRiskOwnerAssignment($input: CreateRiskOwnerAssignmentInput!, $condition: ModelRiskOwnerAssignmentConditionInput) {
        createRiskOwnerAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            deadline
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateRiskOwnerAssignmentMutation>response.data.createRiskOwnerAssignment;
  }
  async UpdateRiskOwnerAssignment(
    input: UpdateRiskOwnerAssignmentInput,
    condition?: ModelRiskOwnerAssignmentConditionInput
  ): Promise<UpdateRiskOwnerAssignmentMutation> {
    const statement = `mutation UpdateRiskOwnerAssignment($input: UpdateRiskOwnerAssignmentInput!, $condition: ModelRiskOwnerAssignmentConditionInput) {
        updateRiskOwnerAssignment(input: $input, condition: $condition) {
          __typename
          id
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            deadline
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateRiskOwnerAssignmentMutation>response.data.updateRiskOwnerAssignment;
  }
  async DeleteRiskOwnerAssignment(
    input: DeleteRiskOwnerAssignmentInput,
    condition?: ModelRiskOwnerAssignmentConditionInput
  ): Promise<DeleteRiskOwnerAssignmentMutation> {
    const statement = `mutation DeleteRiskOwnerAssignment($input: DeleteRiskOwnerAssignmentInput!, $condition: ModelRiskOwnerAssignmentConditionInput) {
        deleteRiskOwnerAssignment(input: $input, condition: $condition) {
          __typename
          id
          subEntityId
          userId
          riskId
          risk {
            __typename
            id
            idTitle
            entityId
            entity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            subEntityId
            subEntity {
              __typename
              id
              name
              users
              completionStatus
              accessGroups
              participantGroup
              rootEntityId
              childEntityId
              frameworkId
              frameworkName
              frameworkType
              parentId
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              entityType
              projectManager
              projectManagerName
              projectDeadline
              logo {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              integrations {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              externalFeed {
                __typename
                name
                credentials
                status
                isEnable
                isAuth
                inheritFromParent
                webhookUpdatedAt
              }
              defaultSetting {
                __typename
                dueDateInDays
                frequency
                surveyLevel
                artifacts {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                standards {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customRequirements {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                customCertifications {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                accessLevel
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                activeScan
                notifications
                selectedRisk
                isThirdParty
                isFourthParty
                domains
                otherData
                fourthPartyWeightage
                enbdDomain
              }
              createdBy
              createdAt
              modifiedBy
              modifiedAt
              vendorDetails {
                __typename
                id
                impact
                externalScan
                financialRisk
                criticalGaps
                standardsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                status
                probability
                website
                location
                size
                description
                businessPointOfContactId
                accessLevel
                collectionStatus
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                approvalDate
                questionnaires {
                  __typename
                  tag
                  status {
                    __typename
                    totalQuestions
                    completedQuestions
                  }
                  criticalGaps
                  allGaps
                }
                artifacts {
                  __typename
                  documents {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  scans {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  insurances {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  certifications {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                }
                intelligence {
                  __typename
                  id
                  breaches {
                    __typename
                    nextToken
                  }
                  externalThreats {
                    __typename
                    nextToken
                  }
                  others {
                    __typename
                    nextToken
                  }
                  createdAt
                  updatedAt
                }
                primaryPoc {
                  __typename
                  name
                  id
                  email
                }
                stage
                activeScan
                domain
                privacyData
                impactsQualify {
                  __typename
                  name
                  isQualify
                  templateURL {
                    __typename
                    id
                    name
                    names
                    entityId
                    fileType
                    date
                    contentType
                    length
                    key
                  }
                  documentCategory
                  documentType
                  level
                }
                primaryLoss
                financialImpact {
                  __typename
                  type
                  minimum
                  mostLikely
                  maximum
                  confidence
                  stepData
                }
                smartTags
                employees
                domains
                isThirdParty
                isFourthParty
                fourthPartyDomains
                level
                fourthPartyActiveScan
                intelligenceScanScore
                fourthPartyScanScore
                isThirdPartyScanCompleted
                priority
                identification
                materialityRating
                enbdCategory
                enbdDomain
                createdAt
                updatedAt
              }
              industry
              domain
              activeAssessmentId
              tierSelected
              activeAssessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              scName
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              KMS {
                __typename
                status
                version
                key
                alias
                dataKeyEncrypted
                newDataKeyEncrypted
                newKeyId
                rotations {
                  __typename
                  count
                  status
                  lastRotation
                  firstRotationDate
                }
                userId
              }
              upperdeckRoute
              isLocked
              weightageScore
              updatedAt
            }
            assessmentId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            type
            disable
            controlIds
            riskTitle
            riskDescription
            riskCategory
            relatedAssets
            residualImpact
            residualProbability
            riskImpact
            riskProbability
            inherentRisk
            residualRisk
            riskStatus
            riskStatusUpdatedBy
            notes
            costToRemediate
            costToRemediateType
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            riskPriority
            hidden
            financialToggle
            scoreHistory {
              __typename
              controlId
              questionOrder
              score
              remediationStatus
              impact
              probability
              weight
              left
              right
            }
            riskTags
            riskTasks {
              __typename
              title
              status
              id
            }
            riskControlNames {
              __typename
              title
              id
              mitigationPlan
              label
            }
            createdAt
            updatedAt
            riskTasksIds
            isArchived
            isEscalated
            sensitivity
            effectiveness
            deadline
          }
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteRiskOwnerAssignmentMutation>response.data.deleteRiskOwnerAssignment;
  }
  async CreateEntityLayers(
    input: CreateEntityLayersInput,
    condition?: ModelEntityLayersConditionInput
  ): Promise<CreateEntityLayersMutation> {
    const statement = `mutation CreateEntityLayers($input: CreateEntityLayersInput!, $condition: ModelEntityLayersConditionInput) {
      createEntityLayers(input: $input, condition: $condition) {
        __typename
        id
        name
        managerName
        type
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        parentId
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        srName
        createdAt
        updatedAt
        domain
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateEntityLayersMutation>response.data.createEntityLayers;
  }
  async UpdateEntityLayers(
    input: UpdateEntityLayersInput,
    condition?: ModelEntityLayersConditionInput
  ): Promise<UpdateEntityLayersMutation> {
    const statement = `mutation UpdateEntityLayers($input: UpdateEntityLayersInput!, $condition: ModelEntityLayersConditionInput) {
      updateEntityLayers(input: $input, condition: $condition) {
        __typename
        id
        name
        managerName
        type
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        parentId
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        srName
        createdAt
        updatedAt
        domain
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateEntityLayersMutation>response.data.updateEntityLayers;
  }
  async DeleteEntityLayers(
    input: DeleteEntityLayersInput,
    condition?: ModelEntityLayersConditionInput
  ): Promise<DeleteEntityLayersMutation> {
    const statement = `mutation DeleteEntityLayers($input: DeleteEntityLayersInput!, $condition: ModelEntityLayersConditionInput) {
      deleteEntityLayers(input: $input, condition: $condition) {
        __typename
        id
        name
        managerName
        type
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        parentId
        logo {
          __typename
          id
          name
          names
          entityId
          fileType
          date
          contentType
          length
          key
        }
        srName
        createdAt
        updatedAt
        domain
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteEntityLayersMutation>response.data.deleteEntityLayers;
  }
  async CreateEntityWizard(
    input: CreateEntityWizardInput,
    condition?: ModelEntityWizardConditionInput
  ): Promise<CreateEntityWizardMutation> {
    const statement = `mutation CreateEntityWizard($input: CreateEntityWizardInput!, $condition: ModelEntityWizardConditionInput) {
      createEntityWizard(input: $input, condition: $condition) {
        __typename
        id
        isDraft
        organizational
        financial
        frameworks
        dataScope
        technical
        riskScenarios
        accountDetails
        currentYear
        rootEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  rootId
                  childId
                  left
                  right
                  managers
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          updatedAt
        }
        parentId
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        domainScanned
        domainScanTimes
        createdAt
        updatedAt
        riskFramework
        clientDomain
        userId
        applicantInfo
        revenueDetails
        dataInventory
        updatedBy
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateEntityWizardMutation>response.data.createEntityWizard;
  }
  async UpdateEntityWizard(
    input: UpdateEntityWizardInput,
    condition?: ModelEntityWizardConditionInput
  ): Promise<UpdateEntityWizardMutation> {
    const statement = `mutation UpdateEntityWizard($input: UpdateEntityWizardInput!, $condition: ModelEntityWizardConditionInput) {
      updateEntityWizard(input: $input, condition: $condition) {
        __typename
        id
        isDraft
        organizational
        financial
        frameworks
        dataScope
        technical
        riskScenarios
        accountDetails
        currentYear
        rootEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  rootId
                  childId
                  left
                  right
                  managers
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          upperdeckRoute
          isLocked
          weightageScore
          updatedAt
        }
        parentId
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        domainScanned
        domainScanTimes
        createdAt
        updatedAt
        riskFramework
        clientDomain
        userId
        applicantInfo
        revenueDetails
        dataInventory
        updatedBy
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateEntityWizardMutation>response.data.updateEntityWizard;
  }
  async DeleteEntityWizard(
    input: DeleteEntityWizardInput,
    condition?: ModelEntityWizardConditionInput
  ): Promise<DeleteEntityWizardMutation> {
    const statement = `mutation DeleteEntityWizard($input: DeleteEntityWizardInput!, $condition: ModelEntityWizardConditionInput) {
      deleteEntityWizard(input: $input, condition: $condition) {
        __typename
        id
        isDraft
        organizational
        financial
        frameworks
        dataScope
        technical
        riskScenarios
        accountDetails
        currentYear
        rootEntity {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  rootId
                  childId
                  left
                  right
                  managers
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  image
                  text
                  draw
                  signDate
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          upperdeckRoute
          isLocked
          weightageScore
          updatedAt
        }
        parentId
        score {
          __typename
          total
          collection
          remediation
        }
        inherent
        residual
        domainScanned
        domainScanTimes
        createdAt
        updatedAt
        riskFramework
        clientDomain
        userId
        applicantInfo
        revenueDetails
        dataInventory
        updatedBy
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteEntityWizardMutation>response.data.deleteEntityWizard;
  }
  async CreateLogs(input: CreateLogsInput, condition?: ModelLogsConditionInput): Promise<CreateLogsMutation> {
    const statement = `mutation CreateLogs($input: CreateLogsInput!, $condition: ModelLogsConditionInput) {
      createLogs(input: $input, condition: $condition) {
        __typename
        id
        message
        type
        targetId
        assessmentId
        userID
        user {
          __typename
          id
          name
          email
          role
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateLogsMutation>response.data.createLogs;
  }
  async UpdateLogs(input: UpdateLogsInput, condition?: ModelLogsConditionInput): Promise<UpdateLogsMutation> {
    const statement = `mutation UpdateLogs($input: UpdateLogsInput!, $condition: ModelLogsConditionInput) {
      updateLogs(input: $input, condition: $condition) {
        __typename
        id
        message
        type
        targetId
        assessmentId
        userID
        user {
          __typename
          id
          name
          email
          role
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateLogsMutation>response.data.updateLogs;
  }
  async DeleteLogs(input: DeleteLogsInput, condition?: ModelLogsConditionInput): Promise<DeleteLogsMutation> {
    const statement = `mutation DeleteLogs($input: DeleteLogsInput!, $condition: ModelLogsConditionInput) {
      deleteLogs(input: $input, condition: $condition) {
        __typename
        id
        message
        type
        targetId
        assessmentId
        userID
        user {
          __typename
          id
          name
          email
          role
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteLogsMutation>response.data.deleteLogs;
  }

  // start ing
  async ListTags(filter?: ModelTagsFilterInput, limit?: number, nextToken?: string): Promise<ListTagsQuery> {
    const statement = `query ListTags($filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListTagsQuery>response.data.listTags;
  }
  async TagsByEntityId(
    entityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TagsByEntityIdQuery> {
    const statement = `query TagsByEntityId($entityId: ID!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        tagsByEntityId(entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TagsByEntityIdQuery>response.data.tagsByEntityId;
  }
  // async TagsByDomain(
  //   domain: string,
  //   sortDirection?: ModelSortDirection,
  //   filter?: ModelTagsFilterInput,
  //   limit?: number,
  //   nextToken?: string
  // ): Promise<TagsByDomainQuery> {
  //   const statement = `query TagsByDomain($domain: String!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
  //       tagsByDomain(domain: $domain, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
  //         __typename
  //         items {
  //           __typename
  //           id
  //           name
  //           entityId
  //           domain
  //           targetId
  //           assessmentFrameworkKey
  //           referenceId
  //           questionRefId
  //           questionData {
  //             __typename
  //             id
  //             childId
  //             frameworkKey
  //             function
  //             category
  //             subCategory
  //             controlNameLabel
  //             score
  //             rootId
  //             assessmentId
  //             name
  //             left
  //             right
  //             createdAt
  //             updatedAt
  //           }
  //           createdAt
  //           updatedAt
  //         }
  //         nextToken
  //       }
  //     }`;
  //   const gqlAPIServiceArguments: any = {
  //     domain,
  //   };
  //   if (sortDirection) {
  //     gqlAPIServiceArguments.sortDirection = sortDirection;
  //   }
  //   if (filter) {
  //     gqlAPIServiceArguments.filter = filter;
  //   }
  //   if (limit) {
  //     gqlAPIServiceArguments.limit = limit;
  //   }
  //   if (nextToken) {
  //     gqlAPIServiceArguments.nextToken = nextToken;
  //   }
  //   const currentSession: any = await Auth.currentSession();

  //    if (!currentSession) {
  //     return;
  //   }
  //  const payload = this.encryptPayload(JSON.stringify({
  //     username: currentSession?.accessToken?.payload?.username,
  //     jwtToken: token,
  //   }));
  //   const response = (await API.graphql({
  //     query: statement,
  //     variables: { ...gqlAPIServiceArguments },
  //     authMode: 'AWS_LAMBDA',
  //     authToken: payload,
  //   })) as any;
  //   return <TagsByDomainQuery>response.data.tagsByDomain;
  // }
  // async TagsByDomainOrEntityId(
  //   domain: string,
  //   entityId?: ModelIDKeyConditionInput,
  //   sortDirection?: ModelSortDirection,
  //   filter?: ModelTagsFilterInput,
  //   limit?: number,
  //   nextToken?: string
  // ): Promise<TagsByDomainOrEntityIdQuery> {
  //   const statement = `query TagsByDomainOrEntityId($domain: String!, $entityId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
  //       tagsByDomainOrEntityId(domain: $domain, entityId: $entityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
  //         __typename
  //         items {
  //           __typename
  //           id
  //           name
  //           entityId
  //           domain
  //           targetId
  //           assessmentFrameworkKey
  //           referenceId
  //           questionRefId
  //           questionData {
  //             __typename
  //             id
  //             childId
  //             frameworkKey
  //             function
  //             category
  //             subCategory
  //             controlNameLabel
  //             score
  //             rootId
  //             assessmentId
  //             name
  //             left
  //             right
  //             createdAt
  //             updatedAt
  //           }
  //           createdAt
  //           updatedAt
  //         }
  //         nextToken
  //       }
  //     }`;
  //   const gqlAPIServiceArguments: any = {
  //     domain,
  //   };
  //   if (entityId) {
  //     gqlAPIServiceArguments.entityId = entityId;
  //   }
  //   if (sortDirection) {
  //     gqlAPIServiceArguments.sortDirection = sortDirection;
  //   }
  //   if (filter) {
  //     gqlAPIServiceArguments.filter = filter;
  //   }
  //   if (limit) {
  //     gqlAPIServiceArguments.limit = limit;
  //   }
  //   if (nextToken) {
  //     gqlAPIServiceArguments.nextToken = nextToken;
  //   }
  //   const currentSession: any = await Auth.currentSession();

  //    if (!currentSession) {
  //     return;
  //   }
  //  const payload = this.encryptPayload(JSON.stringify({
  //     username: currentSession?.accessToken?.payload?.username,
  //     jwtToken: token,
  //   }));
  //   const response = (await API.graphql({
  //     query: statement,
  //     variables: { ...gqlAPIServiceArguments },
  //     authMode: 'AWS_LAMBDA',
  //     authToken: payload,
  //   })) as any;
  //   return <TagsByDomainOrEntityIdQuery>response.data.tagsByDomainOrEntityId;
  // }

  async UpdateGraph(input: UpdateGraphInput, condition?: ModelGraphConditionInput): Promise<UpdateGraphMutation> {
    const statement = `mutation UpdateGraph($input: UpdateGraphInput!, $condition: ModelGraphConditionInput) {
        updateGraph(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateGraphMutation>response.data.updateGraph;
  }

  async DeleteGraph(input: DeleteGraphInput, condition?: ModelGraphConditionInput): Promise<DeleteGraphMutation> {
    const statement = `mutation DeleteGraph($input: DeleteGraphInput!, $condition: ModelGraphConditionInput) {
        deleteGraph(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteGraphMutation>response.data.deleteGraph;
  }

  async ListGraphs(filter?: ModelGraphFilterInput, limit?: number, nextToken?: string): Promise<ListGraphsQuery> {
    const statement = `query ListGraphs($filter: ModelGraphFilterInput, $limit: Int, $nextToken: String) {
        listGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            type
            rootId
            status
            entities
            domainOrFunctions {
              __typename
              type
              data
            }
            groups {
              __typename
              id
              name
              tags
            }
            tags
            createdBy
            timeSpan
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <ListGraphsQuery>response.data.listGraphs;
  }

  async GraphsByRootId(
    rootId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelGraphFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GraphsByRootIdQuery> {
    const statement = `query GraphsByRootId($rootId: String!, $sortDirection: ModelSortDirection, $filter: ModelGraphFilterInput, $limit: Int, $nextToken: String) {
        graphsByRootId(
          rootId: $rootId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            name
            type
            rootId
            status
            entities
            domainOrFunctions {
              __typename
              type
              data
            }
            groups {
              __typename
              id
              name
              tags
            }
            tags
            createdBy
            timeSpan
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <GraphsByRootIdQuery>response.data.graphsByRootId;
  }

  async CreateGraph(input: CreateGraphInput, condition?: ModelGraphConditionInput): Promise<CreateGraphMutation> {
    const statement = `mutation CreateGraph($input: CreateGraphInput!, $condition: ModelGraphConditionInput) {
        createGraph(input: $input, condition: $condition) {
          __typename
          id
          name
          type
          rootId
          status
          entities
          domainOrFunctions {
            __typename
            type
            data
          }
          groups {
            __typename
            id
            name
            tags
          }
          tags
          createdBy
          timeSpan
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <CreateGraphMutation>response.data.createGraph;
  }

  async TagsByTargetId(
    targetId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TagsByTargetIdQuery> {
    const statement = `query TagsByTargetId($targetId: String!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        tagsByTargetId(targetId: $targetId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      targetId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TagsByTargetIdQuery>response.data.tagsByTargetId;
  }
  async TagsByAssessmentFrameworkKey(
    assessmentFrameworkKey: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelTagsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<TagsByAssessmentFrameworkKeyQuery> {
    const statement = `query TagsByAssessmentFrameworkKey($assessmentFrameworkKey: String!, $sortDirection: ModelSortDirection, $filter: ModelTagsFilterInput, $limit: Int, $nextToken: String) {
        tagsByAssessmentFrameworkKey(assessmentFrameworkKey: $assessmentFrameworkKey, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            domain
            targetId
            assessmentFrameworkKey
            referenceId
            questionRefId
            questionData {
              __typename
              id
              childId
              frameworkKey
              function
              category
              subCategory
              controlNameLabel
              score
              rootId
              assessmentId
              name
              left
              right
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentFrameworkKey,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <TagsByAssessmentFrameworkKeyQuery>response.data.tagsByAssessmentFrameworkKey;
  }
  async GetRole(id: string): Promise<GetRoleQuery> {
    const statement = `query GetRole($id: ID!) {
        getRole(id: $id) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetRoleQuery>response.data.getRole;
  }
  async ListRoles(filter?: ModelRoleFilterInput, limit?: number, nextToken?: string): Promise<ListRolesQuery> {
    const statement = `query ListRoles($filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
        listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            entityIds
            vendorIds
            isRootEntityAccess
            defaultOrEntityId
            screenPermissions
            createdAt
            updatedAt
            parentId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListRolesQuery>response.data.listRoles;
  }
  async RolesByDefaultOrEntityId(
    defaultOrEntityId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRoleFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RolesByDefaultOrEntityIdQuery> {
    const statement = `query RolesByDefaultOrEntityId($defaultOrEntityId: String!, $sortDirection: ModelSortDirection, $filter: ModelRoleFilterInput, $limit: Int, $nextToken: String) {
        rolesByDefaultOrEntityId(defaultOrEntityId: $defaultOrEntityId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            entityId
            entityIds
            vendorIds
            isRootEntityAccess
            defaultOrEntityId
            screenPermissions
            createdAt
            updatedAt
            parentId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      defaultOrEntityId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <RolesByDefaultOrEntityIdQuery>response.data.rolesByDefaultOrEntityId;
  }
  async GetGroup(id: string): Promise<GetGroupQuery> {
    const statement = `query GetGroup($id: ID!) {
        getGroup(id: $id) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetGroupQuery>response.data.getGroup;
  }
  async EntityRootEntityByType(
    rootEntityId: string,
    frameworkType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityRootEntityByTypeQuery> {
    const statement = `query EntityRootEntityByType($rootEntityId: ID!, $frameworkType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityRootEntityByType(rootEntityId: $rootEntityId, frameworkType: $frameworkType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            domainScanEnabledForEntity
            vendors{
              vendorsTotalCount
              totalVendorsCreated
            }
            solutionStatus{
              firstParty
              thirdParty
              boardView
            }
            frameworkSettings
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootEntityId,
    };
    if (frameworkType) {
      gqlAPIServiceArguments.frameworkType = frameworkType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityRootEntityByTypeQuery>response.data.entityRootEntityByType;
  }
  async EntityRootEntityByTypeAndFrameworkName(
    rootEntityId: string,
    frameworkTypeFrameworkName?: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityRootEntityByTypeAndFrameworkNameQuery> {
    const statement = `query EntityRootEntityByTypeAndFrameworkName($rootEntityId: ID!, $frameworkTypeFrameworkName: ModelEntityRootEntityWithTypeAndFrameworkNameCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityRootEntityByTypeAndFrameworkName(rootEntityId: $rootEntityId, frameworkTypeFrameworkName: $frameworkTypeFrameworkName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            domainScanEnabledForEntity
            vendors{
              vendorsTotalCount
              totalVendorsCreated
            }
            solutionStatus{
              firstParty
              thirdParty
              boardView
            }
            frameworkSettings
            usedFrameworkUniqueCount
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      rootEntityId,
    };
    if (frameworkTypeFrameworkName) {
      gqlAPIServiceArguments.frameworkTypeFrameworkName = frameworkTypeFrameworkName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityRootEntityByTypeAndFrameworkNameQuery>response.data.entityRootEntityByTypeAndFrameworkName;
  }
  async EntityChildEntityByType(
    childEntityId: string,
    frameworkType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityChildEntityByTypeQuery> {
    const statement = `query EntityChildEntityByType($childEntityId: ID!, $frameworkType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityChildEntityByType(childEntityId: $childEntityId, frameworkType: $frameworkType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            domainScanEnabledForEntity
            vendors{
              vendorsTotalCount
              totalVendorsCreated
            }
            solutionStatus{
              firstParty
              thirdParty
              boardView
            }
            frameworkSettings
            usedFrameworkUniqueCount
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      childEntityId,
    };
    if (frameworkType) {
      gqlAPIServiceArguments.frameworkType = frameworkType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityChildEntityByTypeQuery>response.data.entityChildEntityByType;
  }
  async EntityFramework(
    frameworkId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityFrameworkQuery> {
    const statement = `query EntityFramework($frameworkId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityFramework(frameworkId: $frameworkId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      frameworkId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityFrameworkQuery>response.data.entityFramework;
  }
  async EntityParent(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityParentQuery> {
    const statement = `query EntityParent($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
      entityParent(
        parentId: $parentId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
             initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            ticketID
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  rootId
                  childId
                  left
                  right
                  managers
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      parentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );

    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityParentQuery>response.data.entityParent;
  }

  async EntitiesByParent(
    parentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityParentQuery> {
    const statement = `query EntityParent($parentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityParent(parentId: $parentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            entityType
            activeAssessmentId
            activeAssessment {
              __typename
              id
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                  }
                  not_added
                  archived
                }
                nextToken
              }
              rootId
              childId
            }
            scName
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      parentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }

    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );

    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityParentQuery>response.data.entityParent;
  }

  async EntitiesByEntityType(
    entityType: EntityTypeEnum,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByEntityTypeQuery> {
    const statement = `query EntitiesByEntityType($entityType: EntityTypeEnum!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByEntityType(entityType: $entityType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityType,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );

    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntitiesByEntityTypeQuery>response.data.entitiesByEntityType;
  }
  async EntitiesByDomainAndEntityType(
    domain: string,
    entityType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByDomainAndEntityTypeQuery> {
    const statement = `query EntitiesByDomainAndEntityType($domain: String!, $entityType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByDomainAndEntityType(domain: $domain, entityType: $entityType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            domainScanEnabledForEntity
            vendors{
              vendorsTotalCount
              totalVendorsCreated
            }
            solutionStatus{
              firstParty
              thirdParty
              boardView
            }
            frameworkSettings
            usedFrameworkUniqueCount
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain,
    };
    if (entityType) {
      gqlAPIServiceArguments.entityType = entityType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntitiesByDomainAndEntityTypeQuery>response.data.entitiesByDomainAndEntityType;
  }
  async EntitiesByDomainAndCreatedAt(
    domain: string,
    createdAt?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntitiesByDomainAndCreatedAtQuery> {
    const statement = `query EntitiesByDomainAndCreatedAt($domain: String!, $createdAt: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entitiesByDomainAndCreatedAt(domain: $domain, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            domainScanEnabledForEntity
            domainScanned
            domainScanTimes
            vendors{
              vendorsTotalCount
              totalVendorsCreated
            }
            solutionStatus{
              firstParty
              thirdParty
              boardView
            }
            frameworkSettings
            usedFrameworkUniqueCount
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      domain,
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntitiesByDomainAndCreatedAtQuery>response.data.entitiesByDomainAndCreatedAt;
  }
  async EntityByAssessmentId(
    activeAssessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityByAssessmentIdQuery> {
    const statement = `query EntityByAssessmentId($activeAssessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityByAssessmentId(activeAssessmentId: $activeAssessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            upperdeckRoute
            isLocked
            weightageScore
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      activeAssessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityByAssessmentIdQuery>response.data.entityByAssessmentId;
  }

  async GetQuestionSettingsFromEntityByAssessmentId(
    activeAssessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelEntityFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<EntityByAssessmentIdQuery> {
    const statement = `query EntityByAssessmentId($activeAssessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelEntityFilterInput, $limit: Int, $nextToken: String) {
        entityByAssessmentId(activeAssessmentId: $activeAssessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name 
            rootEntityId
            childEntityId 
            parentId 
            domain
            activeAssessmentId
         
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
           
     
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      activeAssessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <EntityByAssessmentIdQuery>response.data.entityByAssessmentId;
  }

  async GetAnswer(id: string): Promise<GetAnswerQuery> {
    const statement = `query GetAnswer($id: ID!) {
        getAnswer(id: $id) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetAnswerQuery>response.data.getAnswer;
  }
  async ListAnswers(filter?: ModelAnswerFilterInput, limit?: number, nextToken?: string): Promise<ListAnswersQuery> {
    const statement = `query ListAnswers($filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListAnswersQuery>response.data.listAnswers;
  }
  async AnswersByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByUserIdQuery> {
    const statement = `query AnswersByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByUserId(
        userId: $userId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByUserIdQuery>response.data.answersByUserId;
  }
  async AnswersByQuestionId(
    questionId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByQuestionIdQuery> {
    const statement = `query AnswersByQuestionId($questionId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByQuestionId(
        questionId: $questionId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      questionId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByQuestionIdQuery>response.data.answersByQuestionId;
  }
  async AnswersByQuestionIdAndUser(
    questionId: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByQuestionIdAndUserQuery> {
    const statement = `query AnswersByQuestionIdAndUser($questionId: ID!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByQuestionIdAndUser(
        questionId: $questionId
        userId: $userId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      questionId,
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByQuestionIdAndUserQuery>response.data.answersByQuestionIdAndUser;
  }
  async AnswersByAssessmentId(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdQuery> {
    const statement = `query AnswersByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByAssessmentId(
        assessmentId: $assessmentId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByAssessmentIdQuery>response.data.answersByAssessmentId;
  }
  async AnswersByAssessmentIdAndUserId(
    assessmentId: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndUserIdQuery> {
    const statement = `query AnswersByAssessmentIdAndUserId($assessmentId: ID!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByAssessmentIdAndUserId(
        assessmentId: $assessmentId
        userId: $userId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByAssessmentIdAndUserIdQuery>response.data.answersByAssessmentIdAndUserId;
  }
  async AnswersByAssessmentQuestionUser(
    assessmentId: string,
    questionIdUserId?: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentQuestionUserQuery> {
    const statement = `query AnswersByAssessmentQuestionUser($assessmentId: ID!, $questionIdUserId: ModelAnswerAssessmentIdWithQuestionAndUserIdCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByAssessmentQuestionUser(
        assessmentId: $assessmentId
        questionIdUserId: $questionIdUserId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (questionIdUserId) {
      gqlAPIServiceArguments.questionIdUserId = questionIdUserId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByAssessmentQuestionUserQuery>response.data.answersByAssessmentQuestionUser;
  }
  async AnswersByAssessmentIdAndQuestionId(
    assessmentId: string,
    questionId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelAnswerFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<AnswersByAssessmentIdAndQuestionIdQuery> {
    const statement = `query AnswersByAssessmentIdAndQuestionId($assessmentId: ID!, $questionId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelAnswerFilterInput, $limit: Int, $nextToken: String) {
      answersByAssessmentIdAndQuestionId(
        assessmentId: $assessmentId
        questionId: $questionId
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        __typename
        items {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          updatedAt
        }
        nextToken
      }
    }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (questionId) {
      gqlAPIServiceArguments.questionId = questionId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <AnswersByAssessmentIdAndQuestionIdQuery>response.data.answersByAssessmentIdAndQuestionId;
  }

  encryptPayload(payload, suffix = false) {
    let key = 'cygovSecretKey2023';
    if (suffix) {
      key = `${key}${suffix}`;
    }
    const encryptedPayload = CryptoJS.AES.encrypt(JSON.stringify(payload), key).toString();
    return encryptedPayload;
  }

  OnUpdateQuestionnaireCompletionListener(token: string, filter?: ModelSubscriptionQuestionnaireCompletionFilterInput) {
    const statement = `subscription OnUpdateQuestionnaireCompletion($filter: ModelSubscriptionQuestionnaireCompletionFilterInput) {
        onUpdateQuestionnaireCompletion(filter: $filter) {
          __typename
          id
          totalQuestions
          completedQuestions
          userId
          assessmentId
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
          }
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          assignmentMap
          firstAnswer
          lastAnswer
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }

    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  async UpdateAssessment(
    input: UpdateAssessmentInput,
    condition?: ModelAssessmentConditionInput
  ): Promise<UpdateAssessmentMutation> {
    const statement = `mutation UpdateAssessment($input: UpdateAssessmentInput!, $condition: ModelAssessmentConditionInput) {
      updateAssessment(input: $input, condition: $condition) {
        __typename
        id
        standardList {
          __typename
          id
          type
          fileName
          filter {
            __typename
            impact
          }
        }
        standardFrameworkList {
          __typename
          items {
            __typename
            id
            key
            type
            fileName
            filter {
              __typename
              impact
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              isAutoReminder
              isAutoReassessment
            }
            assessmentId
            managerId
            assessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  assessmentId
                  managerId
                  selectedChapters
                  not_added
                  archived
                  reassessmentStatus
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            selectedChapters
            managers {
              __typename
              items {
                __typename
                id
                assessmentId
                standardFrameworkId
                managerId
                user {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                left
                right
                removedQuestions
                createdAt
                updatedAt
              }
              nextToken
            }
            not_added
            archived
            reassessmentStatus
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            documentCategory
            documentType
            suggestedArtifact
            suggestedComments
            level
            newStartReassessDate
            signatureUserID
            isLocked
            signedBy {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            signature {
              __typename
              image
              text
              draw
              signDate
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        timeline {
          __typename
          initiationDate
          collectionDate
          nextReviewDate
          frequency
          days
          isAutoReminder
          isAutoReassessment
        }
        rootId
        childId
        scores {
          __typename
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          maturity
          tierScores
          totalReadinessTasks
          totalComplianceTasks
          totalInternalTasks
        }
        left
        right
        managers
        exports {
          __typename
          jiraEntityIds
          serviceNowEntityIds
        }
        createdAt
        updatedAt
      }
    }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateAssessmentMutation>response.data.updateAssessment;
  }

  async UpdateQuestionSettings(
    input: UpdateQuestionSettingsInput,
    condition?: ModelQuestionSettingsConditionInput
  ): Promise<UpdateQuestionSettingsMutation> {
    const statement = `mutation UpdateQuestionSettings($input: UpdateQuestionSettingsInput!, $condition: ModelQuestionSettingsConditionInput) {
        updateQuestionSettings(input: $input, condition: $condition) {
          __typename
          id
          questionId
          assessmentId
          isCollaborative
          isArtifactsMandatory
          isCommentsMandatory
          isApprovalRequired
          suggestedArtifactAnswers
          requiredCommentAnswers
          versionFrequency
          isIntegrationActive
          isSmartMappingActive
          createdAt
          updatedAt
          isInfoSec
          frameworkName
          left
          right
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateQuestionSettingsMutation>response.data.updateQuestionSettings;
  }

  async UpdateTaskAction(
    input: UpdateTaskActionInput,
    condition?: ModelTaskActionConditionInput
  ): Promise<UpdateTaskActionMutation> {
    const statement = `mutation UpdateTaskAction($input: UpdateTaskActionInput!, $condition: ModelTaskActionConditionInput) {
        updateTaskAction(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateTaskActionMutation>response.data.updateTaskAction;
  }

  async StandardFrameworksByAssessmentIdSimplified(
    assessmentId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelAssessmentStandardFrameworkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<StandardFrameworksByAssessmentIdQuery> {
    const statement = `query StandardFrameworksByAssessmentId($assessmentId: ID!, $sortDirection: ModelSortDirection, $filter: ModelAssessmentStandardFrameworkFilterInput, $limit: Int, $nextToken: String) {
        standardFrameworksByAssessmentId(assessmentId: $assessmentId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            key
            type
            assessmentId
            archived
            not_added
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      assessmentId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <StandardFrameworksByAssessmentIdQuery>response.data.standardFrameworksByAssessmentId;
  }

  async RiskAssignmentByRiskId(
    riskId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskOwnerAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskAssignmentByRiskIdQuery> {
    const statement = `query RiskAssignmentByRiskId($riskId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskOwnerAssignmentFilterInput, $limit: Int, $nextToken: String) {
        riskAssignmentByRiskId(
          riskId: $riskId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            subEntityId
            userId
            riskId
            risk {
              __typename
              id
              idTitle
              entityId
              entity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                upperdeckRoute
                isLocked
                weightageScore
                updatedAt
              }
              subEntityId
              subEntity {
                __typename
                id
                name
                users
                completionStatus
                accessGroups
                participantGroup
                rootEntityId
                childEntityId
                frameworkId
                frameworkName
                frameworkType
                parentId
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                entityType
                projectManager
                projectManagerName
                projectDeadline
                logo {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                integrations {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                externalFeed {
                  __typename
                  name
                  credentials
                  status
                  isEnable
                  isAuth
                  inheritFromParent
                  webhookUpdatedAt
                }
                defaultSetting {
                  __typename
                  dueDateInDays
                  frequency
                  surveyLevel
                  artifacts {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  standards {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customRequirements {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  customCertifications {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  accessLevel
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  activeScan
                  notifications
                  selectedRisk
                  isThirdParty
                  isFourthParty
                  domains
                  otherData
                  fourthPartyWeightage
                  enbdDomain
                }
                createdBy
                createdAt
                modifiedBy
                modifiedAt
                vendorDetails {
                  __typename
                  id
                  impact
                  externalScan
                  financialRisk
                  criticalGaps
                  standardsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  status
                  probability
                  website
                  location
                  size
                  description
                  businessPointOfContactId
                  accessLevel
                  collectionStatus
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  approvalDate
                  questionnaires {
                    __typename
                    tag
                    criticalGaps
                    allGaps
                  }
                  artifacts {
                    __typename
                  }
                  intelligence {
                    __typename
                    id
                    createdAt
                    updatedAt
                  }
                  primaryPoc {
                    __typename
                    name
                    id
                    email
                  }
                  stage
                  activeScan
                  domain
                  privacyData
                  impactsQualify {
                    __typename
                    name
                    isQualify
                    documentCategory
                    documentType
                    level
                  }
                  primaryLoss
                  financialImpact {
                    __typename
                    type
                    minimum
                    mostLikely
                    maximum
                    confidence
                    stepData
                  }
                  smartTags
                  employees
                  domains
                  isThirdParty
                  isFourthParty
                  fourthPartyDomains
                  level
                  fourthPartyActiveScan
                  intelligenceScanScore
                  fourthPartyScanScore
                  isThirdPartyScanCompleted
                  priority
                  identification
                  materialityRating
                  enbdCategory
                  enbdDomain
                  createdAt
                  updatedAt
                }
                industry
                domain
                activeAssessmentId
                tierSelected
                activeAssessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                scName
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                KMS {
                  __typename
                  status
                  version
                  key
                  alias
                  dataKeyEncrypted
                  newDataKeyEncrypted
                  newKeyId
                  rotations {
                    __typename
                    count
                    status
                    lastRotation
                    firstRotationDate
                  }
                  userId
                }
                upperdeckRoute
                isLocked
                weightageScore
                updatedAt
              }
              assessmentId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              type
              disable
              controlIds
              riskTitle
              riskDescription
              riskCategory
              relatedAssets
              residualImpact
              residualProbability
              riskImpact
              riskProbability
              inherentRisk
              residualRisk
              riskStatus
              riskStatusUpdatedBy
              notes
              costToRemediate
              costToRemediateType
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              riskPriority
              hidden
              financialToggle
              scoreHistory {
                __typename
                controlId
                questionOrder
                score
                remediationStatus
                impact
                probability
                weight
                left
                right
              }
              riskTags
              riskTasks {
                __typename
                title
                status
                id
              }
              riskControlNames {
                __typename
                title
                id
                mitigationPlan
                label
              }
              createdAt
              updatedAt
              riskTasksIds
              isArchived
              isEscalated
              sensitivity
              effectiveness
              deadline
            }
            user {
              __typename
              id
              name
              email
              role
              roleId
              isViewOnly
              phone
              entityId
              entityIds
              isCognitoUser
              questionMap
              createdBy
              createdAt
              lastLogin
              invitationDate
              reminderDate
              modifiedBy
              modifiedAt
              domain
              temporaryHigherRank
              entityLayerIds
              clickThrough
              clickThroughDate
              projectManagerEmail
              isGlobalParticipant
              onBoardingStatus
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      riskId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <RiskAssignmentByRiskIdQuery>response.data.riskAssignmentByRiskId;
  }

  async SavedFiltersByEntityIdAndUserId(
    entityId: string,
    userId?: ModelIDKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelSavedFilterFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<SavedFiltersByEntityIdAndUserIdQuery> {
    const statement = `query SavedFiltersByEntityIdAndUserId($entityId: ID!, $userId: ModelIDKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelSavedFilterFilterInput, $limit: Int, $nextToken: String) {
        SavedFiltersByEntityIdAndUserId(
          entityId: $entityId
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          __typename
          items {
            __typename
            id
            filterObject
            userId
            entityId
            type
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      entityId,
    };
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <SavedFiltersByEntityIdAndUserIdQuery>response.data.SavedFiltersByEntityIdAndUserId;
  }

  async CreateSavedFilter(
    input: CreateSavedFilterInput,
    condition?: ModelSavedFilterConditionInput
  ): Promise<CreateSavedFilterMutation> {
    const statement = `mutation CreateSavedFilter($input: CreateSavedFilterInput!, $condition: ModelSavedFilterConditionInput) {
        createSavedFilter(input: $input, condition: $condition) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateSavedFilterMutation>response.data.createSavedFilter;
  }
  async UpdateSavedFilter(
    input: UpdateSavedFilterInput,
    condition?: ModelSavedFilterConditionInput
  ): Promise<UpdateSavedFilterMutation> {
    const statement = `mutation UpdateSavedFilter($input: UpdateSavedFilterInput!, $condition: ModelSavedFilterConditionInput) {
        updateSavedFilter(input: $input, condition: $condition) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <UpdateSavedFilterMutation>response.data.updateSavedFilter;
  }
  async DeleteSavedFilter(
    input: DeleteSavedFilterInput,
    condition?: ModelSavedFilterConditionInput
  ): Promise<DeleteSavedFilterMutation> {
    const statement = `mutation DeleteSavedFilter($input: DeleteSavedFilterInput!, $condition: ModelSavedFilterConditionInput) {
        deleteSavedFilter(input: $input, condition: $condition) {
          __typename
          id
          filterObject
          userId
          entityId
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <DeleteSavedFilterMutation>response.data.deleteSavedFilter;
  }

  async RiskAssignmentByUserId(
    userId: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelRiskOwnerAssignmentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<RiskAssignmentByUserIdQuery> {
    const statement = `query RiskAssignmentByUserId($userId: ID!, $sortDirection: ModelSortDirection, $filter: ModelRiskOwnerAssignmentFilterInput, $limit: Int, $nextToken: String) {
        riskAssignmentByUserId(
          userId: $userId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            userId
            riskId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <RiskAssignmentByUserIdQuery>response.data.riskAssignmentByUserId;
  }

  async CreateRiskLineChart(
    input: CreateRiskLineChartInput,
    condition?: ModelRiskLineChartConditionInput
  ): Promise<CreateRiskLineChartMutation> {
    const statement = `mutation CreateRiskLineChart($input: CreateRiskLineChartInput!, $condition: ModelRiskLineChartConditionInput) {
        createRiskLineChart(input: $input, condition: $condition) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <CreateRiskLineChartMutation>response.data.createRiskLineChart;
  }
  async GetRiskLineChart(id: string): Promise<GetRiskLineChartQuery> {
    const statement = `query GetRiskLineChart($id: ID!) {
        getRiskLineChart(id: $id) {
          __typename
          id
          assessmentId
          riskId
          residual
          inherent
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id,
    };
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <GetRiskLineChartQuery>response.data.getRiskLineChart;
  }
  async ListRiskLineCharts(
    filter?: ModelRiskLineChartFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRiskLineChartsQuery> {
    const statement = `query ListRiskLineCharts($filter: ModelRiskLineChartFilterInput, $limit: Int, $nextToken: String) {
        listRiskLineCharts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            assessmentId
            riskId
            residual
            inherent
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;
    return <ListRiskLineChartsQuery>response.data.listRiskLineCharts;
  }

  async checkIfRefreshTokenRequired(currentSession) {
    try {
      const accessToken = currentSession.getAccessToken();
      const expirationTime = accessToken.getExpiration();
      const currentTime = Math.floor(Date.now() / 1000);

      // Checking if token expiration time is less than 3 minutes
      // generate a new access token manually
      if (expirationTime - currentTime < 180) {
        // Bypassing cache, so that Cognito will not return token from cache
        await Auth.currentAuthenticatedUser({ bypassCache: true });
      }

      // Returning the new refreshed token
      return currentSession.getAccessToken().getJwtToken();
    } catch (error) {
      console.error('Cannot refresh token:', error);
      throw error;
    }
  }

  async HandleIntegrationDetails(payload: string): Promise<any | null> {
    const response = (await API.post('cygovRestApi', '/handleIntegrationDetails', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
      },
    })) as any;

    return response;
  }

  OnCreateTagsListener(token: string, filter?: ModelSubscriptionTagsFilterInput) {
    const statement = `subscription OnCreateTags($filter: ModelSubscriptionTagsFilterInput) {
        onCreateTags(filter: $filter) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }

    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateLogsListener(token: string, filter?: ModelSubscriptionLogsFilterInput) {
    const statement = `subscription OnCreateLogs($filter: ModelSubscriptionLogsFilterInput) {
        onCreateLogs(filter: $filter) {
          __typename
          id
          message
          type
          targetId
          assessmentId
          userID
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateVersionsListener(token: string, filter?: ModelSubscriptionVersionsFilterInput) {
    const statement = `subscription OnCreateVersions($filter: ModelSubscriptionVersionsFilterInput) {
        onCreateVersions(filter: $filter) {
          __typename
          id
          data
          type
          targetId
          assessmentId
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateAssessmentStandardFrameworkListener(
    token: string,
    filter?: ModelSubscriptionAssessmentStandardFrameworkFilterInput
  ) {
    const statement = `subscription OnUpdateAssessmentStandardFramework($filter: ModelSubscriptionAssessmentStandardFrameworkFilterInput) {
        onUpdateAssessmentStandardFramework(filter: $filter) {
          __typename
          id
          key
          type
          fileName
          filter {
            __typename
            impact
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          assessmentId
          managerId
          assessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          selectedChapters
          managers {
            __typename
            items {
              __typename
              id
              assessmentId
              standardFrameworkId
              managerId
              user {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              left
              right
              removedQuestions
              createdAt
              updatedAt
            }
            nextToken
          }
          not_added
          archived
          reassessmentStatus
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          documentCategory
          documentType
          suggestedArtifact
          suggestedComments
          level
          newStartReassessDate
          signatureUserID
          isLocked
          signedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          signature {
            __typename
            title
            printName
            disclaimerDate
            image
            text
            draw
            signDate
          }
          lockedByUserID
          lockedBy {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnDeleteUserListener(token: string, filter?: ModelSubscriptionUserFilterInput) {
    const statement = `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
        onDeleteUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          updatedAt
        }
      }`;

    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnDeleteTagsListener(token: string, filter?: ModelSubscriptionTagsFilterInput) {
    const statement = `subscription OnDeleteTags($filter: ModelSubscriptionTagsFilterInput) {
        onDeleteTags(filter: $filter) {
          __typename
          id
          name
          entityId
          domain
          targetId
          assessmentFrameworkKey
          referenceId
          questionRefId
          questionData {
            __typename
            id
            childId
            frameworkKey
            function
            category
            subCategory
            controlNameLabel
            score
            rootId
            assessmentId
            name
            left
            right
            reviewStatus
            resentBackStatus
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  OnUpdateUserListener(token: string, filter?: ModelSubscriptionUserFilterInput) {
    const statement = `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
        onUpdateUser(filter: $filter) {
          __typename
          id
          name
          email
          role
          roleId
          isViewOnly
          phone
          entityId
          entityIds
          isCognitoUser
          questionMap
          createdBy
          createdAt
          lastLogin
          invitationDate
          reminderDate
          modifiedBy
          modifiedAt
          domain
          temporaryHigherRank
          entityLayerIds
          clickThrough
          clickThroughDate
          projectManagerEmail
          isGlobalParticipant
          onBoardingStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateRoleListener(token: string, filter?: ModelSubscriptionRoleFilterInput) {
    const statement = `subscription OnUpdateRole($filter: ModelSubscriptionRoleFilterInput) {
        onUpdateRole(filter: $filter) {
          __typename
          id
          name
          entityId
          entityIds
          vendorIds
          isRootEntityAccess
          defaultOrEntityId
          screenPermissions
          createdAt
          updatedAt
          parentId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateGroupListener(token: string, filter?: ModelSubscriptionGroupFilterInput) {
    const statement = `subscription OnUpdateGroup($filter: ModelSubscriptionGroupFilterInput) {
        onUpdateGroup(filter: $filter) {
          __typename
          id
          name
          rootId
          tags
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateEntityListener(token: string, filter?: ModelSubscriptionEntityFilterInput) {
    const statement = `subscription OnCreateEntity($filter: ModelSubscriptionEntityFilterInput) {
        onCreateEntity(filter: $filter) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateEntityListener(token: string, filter?: ModelSubscriptionEntityFilterInput) {
    const statement = `subscription OnUpdateEntity($filter: ModelSubscriptionEntityFilterInput) {
        onUpdateEntity(filter: $filter) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnDeleteEntityListener(token: string, filter?: ModelSubscriptionEntityFilterInput) {
    const statement = `subscription OnDeleteEntity($filter: ModelSubscriptionEntityFilterInput) {
        onDeleteEntity(filter: $filter) {
          __typename
          id
          name
          users
          completionStatus
          accessGroups
          participantGroup
          rootEntityId
          childEntityId
          frameworkId
          frameworkName
          frameworkType
          parentId
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          entityType
          projectManager
          projectManagerName
          projectDeadline
          logo {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          integrations {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          externalFeed {
            __typename
            name
            credentials
            status
            isEnable
            isAuth
            inheritFromParent
            webhookUpdatedAt
          }
          defaultSetting {
            __typename
            dueDateInDays
            frequency
            surveyLevel
            artifacts {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            standards {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customRequirements {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            customCertifications {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            accessLevel
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            activeScan
            notifications
            selectedRisk
            isThirdParty
            isFourthParty
            domains
            otherData
            fourthPartyWeightage
            enbdDomain
          }
          createdBy
          createdAt
          modifiedBy
          modifiedAt
          vendorDetails {
            __typename
            id
            impact
            externalScan
            financialRisk
            criticalGaps
            standardsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            status
            probability
            website
            location
            size
            description
            businessPointOfContactId
            accessLevel
            collectionStatus
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            approvalDate
            questionnaires {
              __typename
              tag
              status {
                __typename
                totalQuestions
                completedQuestions
              }
              criticalGaps
              allGaps
            }
            artifacts {
              __typename
              documents {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              scans {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              insurances {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              certifications {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
            }
            intelligence {
              __typename
              id
              breaches {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  date
                  year
                  name
                  description
                  leaks
                  data
                  status
                  email
                  createdAt
                  updatedAt
                }
                nextToken
              }
              externalThreats {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  source
                  findings
                  riskScore
                  details {
                    __typename
                    ip
                    port
                    cve
                    cvss
                  }
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              others {
                __typename
                items {
                  __typename
                  id
                  vendorId
                  name
                  label
                  severity
                  description
                  status
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            primaryPoc {
              __typename
              name
              id
              email
            }
            stage
            activeScan
            domain
            privacyData
            impactsQualify {
              __typename
              name
              isQualify
              templateURL {
                __typename
                id
                name
                names
                entityId
                fileType
                date
                contentType
                length
                key
              }
              documentCategory
              documentType
              level
            }
            primaryLoss
            financialImpact {
              __typename
              type
              minimum
              mostLikely
              maximum
              confidence
              stepData
            }
            smartTags
            employees
            domains
            isThirdParty
            isFourthParty
            fourthPartyDomains
            level
            fourthPartyActiveScan
            intelligenceScanScore
            fourthPartyScanScore
            isThirdPartyScanCompleted
            priority
            identification
            materialityRating
            enbdCategory
            enbdDomain
            phoneNumber
            projectManagerPhoneNumber
            businessUnits
            category
            dealValue
            products
            createdAt
            updatedAt
          }
          industry
          domain
          activeAssessmentId
          tierSelected
          activeAssessment {
            __typename
            id
            standardList {
              __typename
              id
              type
              fileName
              filter {
                __typename
                impact
              }
            }
            standardFrameworkList {
              __typename
              items {
                __typename
                id
                key
                type
                fileName
                filter {
                  __typename
                  impact
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                assessmentId
                managerId
                assessment {
                  __typename
                  id
                  standardList {
                    __typename
                    id
                    type
                    fileName
                  }
                  standardFrameworkList {
                    __typename
                    nextToken
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  rootId
                  childId
                  scores {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  left
                  right
                  managers
                  exports {
                    __typename
                    jiraEntityIds
                    serviceNowEntityIds
                  }
                  createdAt
                  updatedAt
                }
                selectedChapters
                managers {
                  __typename
                  items {
                    __typename
                    id
                    assessmentId
                    standardFrameworkId
                    managerId
                    left
                    right
                    removedQuestions
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                not_added
                archived
                reassessmentStatus
                defaultQuestionSettings {
                  __typename
                  isApprovalRequired
                  isArtifactsMandatory
                  isCommentsMandatory
                  isCollaborative
                  isIntegrationActive
                  isSmartMappingActive
                }
                documentCategory
                documentType
                suggestedArtifact
                suggestedComments
                level
                newStartReassessDate
                signatureUserID
                isLocked
                signedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                signature {
                  __typename
                  title
                  printName
                  disclaimerDate
                  image
                  text
                  draw
                  signDate
                }
                lockedByUserID
                lockedBy {
                  __typename
                  id
                  name
                  email
                  role
                  roleId
                  isViewOnly
                  phone
                  entityId
                  entityIds
                  isCognitoUser
                  questionMap
                  createdBy
                  createdAt
                  lastLogin
                  invitationDate
                  reminderDate
                  modifiedBy
                  modifiedAt
                  domain
                  temporaryHigherRank
                  entityLayerIds
                  clickThrough
                  clickThroughDate
                  projectManagerEmail
                  isGlobalParticipant
                  onBoardingStatus
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            rootId
            childId
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            left
            right
            managers
            exports {
              __typename
              jiraEntityIds
              serviceNowEntityIds
            }
            createdAt
            updatedAt
          }
          scName
          defaultQuestionSettings {
            __typename
            isApprovalRequired
            isArtifactsMandatory
            isCommentsMandatory
            isCollaborative
            isIntegrationActive
            isSmartMappingActive
          }
          KMS {
            __typename
            status
            version
            key
            alias
            dataKeyEncrypted
            newDataKeyEncrypted
            newKeyId
            rotations {
              __typename
              count
              status
              lastRotation
              firstRotationDate
            }
            userId
          }
          AI {
            __typename
            credits
            date
            isActive
            usedCredits
            userName
          }
          upperdeckRoute
          isLocked
          eSignFinalized
          weightageScore
          upperdeckSetting
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateVendorsDetailListener(token: string, filter?: ModelSubscriptionVendorsDetailFilterInput) {
    const statement = `subscription OnUpdateVendorsDetail($filter: ModelSubscriptionVendorsDetailFilterInput) {
        onUpdateVendorsDetail(filter: $filter) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateFrameworkScoresListener(token: string, filter?: ModelSubscriptionFrameworkScoresFilterInput) {
    const statement = `subscription OnCreateFrameworkScores($filter: ModelSubscriptionFrameworkScoresFilterInput) {
        onCreateFrameworkScores(filter: $filter) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateFrameworkScoresListener(token: string, filter?: ModelSubscriptionFrameworkScoresFilterInput) {
    const statement = `subscription OnUpdateFrameworkScores($filter: ModelSubscriptionFrameworkScoresFilterInput) {
        onUpdateFrameworkScores(filter: $filter) {
          __typename
          id
          assessmentId
          type
          total
          target
          collection
          remediation
          reassessment
          totalQuestions
          completedQuestions
          totalGaps
          criticalGaps
          notApplicable
          assignmentCount
          riskStandardsCount
          complianceStandardsCount
          standardsCount
          top20 {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          groups
          maturity
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateIntelligenceListener(token: string, filter?: ModelSubscriptionIntelligenceFilterInput) {
    const statement = `subscription OnUpdateIntelligence($filter: ModelSubscriptionIntelligenceFilterInput) {
        onUpdateIntelligence(filter: $filter) {
          __typename
          id
          breaches {
            __typename
            items {
              __typename
              id
              vendorId
              date
              year
              name
              description
              leaks
              data
              status
              email
              createdAt
              updatedAt
            }
            nextToken
          }
          externalThreats {
            __typename
            items {
              __typename
              id
              vendorId
              source
              findings
              riskScore
              details {
                __typename
                ip
                port
                cve
                cvss
              }
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          others {
            __typename
            items {
              __typename
              id
              vendorId
              name
              label
              severity
              description
              status
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateBreachesListener(token: string, filter?: ModelSubscriptionBreachesFilterInput) {
    const statement = `subscription OnUpdateBreaches($filter: ModelSubscriptionBreachesFilterInput) {
        onUpdateBreaches(filter: $filter) {
          __typename
          id
          vendorId
          date
          year
          name
          description
          leaks
          data
          status
          email
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateAssessmentListener(token: string, filter?: ModelSubscriptionAssessmentFilterInput) {
    const statement = `subscription OnUpdateAssessment($filter: ModelSubscriptionAssessmentFilterInput) {
        onUpdateAssessment(filter: $filter) {
          __typename
          id
          standardList {
            __typename
            id
            type
            fileName
            filter {
              __typename
              impact
            }
          }
          standardFrameworkList {
            __typename
            items {
              __typename
              id
              key
              type
              fileName
              filter {
                __typename
                impact
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              assessmentId
              managerId
              assessment {
                __typename
                id
                standardList {
                  __typename
                  id
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                }
                standardFrameworkList {
                  __typename
                  items {
                    __typename
                    id
                    key
                    type
                    fileName
                    assessmentId
                    managerId
                    selectedChapters
                    not_added
                    archived
                    reassessmentStatus
                    documentCategory
                    documentType
                    suggestedArtifact
                    suggestedComments
                    level
                    newStartReassessDate
                    signatureUserID
                    isLocked
                    lockedByUserID
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                timeline {
                  __typename
                  initiationDate
                  collectionDate
                  nextReviewDate
                  frequency
                  days
                  nextStartingDate
                  reassessmentNotifier
                  vendorOnBoardingDate
                  isAutoReminder
                  isAutoReassessment
                }
                rootId
                childId
                scores {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                left
                right
                managers
                exports {
                  __typename
                  jiraEntityIds
                  serviceNowEntityIds
                }
                createdAt
                updatedAt
              }
              selectedChapters
              managers {
                __typename
                items {
                  __typename
                  id
                  assessmentId
                  standardFrameworkId
                  managerId
                  user {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  left
                  right
                  removedQuestions
                  createdAt
                  updatedAt
                }
                nextToken
              }
              not_added
              archived
              reassessmentStatus
              defaultQuestionSettings {
                __typename
                isApprovalRequired
                isArtifactsMandatory
                isCommentsMandatory
                isCollaborative
                isIntegrationActive
                isSmartMappingActive
              }
              documentCategory
              documentType
              suggestedArtifact
              suggestedComments
              level
              newStartReassessDate
              signatureUserID
              isLocked
              signedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              signature {
                __typename
                title
                printName
                disclaimerDate
                image
                text
                draw
                signDate
              }
              lockedByUserID
              lockedBy {
                __typename
                id
                name
                email
                role
                roleId
                isViewOnly
                phone
                entityId
                entityIds
                isCognitoUser
                questionMap
                createdBy
                createdAt
                lastLogin
                invitationDate
                reminderDate
                modifiedBy
                modifiedAt
                domain
                temporaryHigherRank
                entityLayerIds
                clickThrough
                clickThroughDate
                projectManagerEmail
                isGlobalParticipant
                onBoardingStatus
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          rootId
          childId
          scores {
            __typename
            total
            target
            collection
            remediation
            reassessment
            totalQuestions
            completedQuestions
            totalGaps
            criticalGaps
            notApplicable
            top20 {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            maturity
            tierScores
            totalReadinessTasks
            totalComplianceTasks
            totalInternalTasks
          }
          left
          right
          managers
          exports {
            __typename
            jiraEntityIds
            serviceNowEntityIds
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateEntityWizardListener(token: string, filter?: ModelSubscriptionEntityWizardFilterInput) {
    const statement = `subscription OnUpdateEntityWizard($filter: ModelSubscriptionEntityWizardFilterInput) {
        onUpdateEntityWizard(filter: $filter) {
          __typename
          id
          isDraft
          organizational
          financial
          frameworks
          dataScope
          technical
          riskScenarios
          accountDetails
          currentYear
          rootEntity {
            __typename
            id
            name
            users
            completionStatus
            accessGroups
            participantGroup
            rootEntityId
            childEntityId
            frameworkId
            frameworkName
            frameworkType
            parentId
            timeline {
              __typename
              initiationDate
              collectionDate
              nextReviewDate
              frequency
              days
              nextStartingDate
              reassessmentNotifier
              vendorOnBoardingDate
              isAutoReminder
              isAutoReassessment
            }
            scores {
              __typename
              total
              target
              collection
              remediation
              reassessment
              totalQuestions
              completedQuestions
              totalGaps
              criticalGaps
              notApplicable
              top20 {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              maturity
              tierScores
              totalReadinessTasks
              totalComplianceTasks
              totalInternalTasks
            }
            entityType
            projectManager
            projectManagerName
            projectDeadline
            logo {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            integrations {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            externalFeed {
              __typename
              name
              credentials
              status
              isEnable
              isAuth
              inheritFromParent
              webhookUpdatedAt
            }
            defaultSetting {
              __typename
              dueDateInDays
              frequency
              surveyLevel
              artifacts {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              standards {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customRequirements {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              customCertifications {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              accessLevel
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              activeScan
              notifications
              selectedRisk
              isThirdParty
              isFourthParty
              domains
              otherData
              fourthPartyWeightage
              enbdDomain
            }
            createdBy
            createdAt
            modifiedBy
            modifiedAt
            vendorDetails {
              __typename
              id
              impact
              externalScan
              financialRisk
              criticalGaps
              standardsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              status
              probability
              website
              location
              size
              description
              businessPointOfContactId
              accessLevel
              collectionStatus
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              approvalDate
              questionnaires {
                __typename
                tag
                status {
                  __typename
                  totalQuestions
                  completedQuestions
                }
                criticalGaps
                allGaps
              }
              artifacts {
                __typename
                documents {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                scans {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                insurances {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                certifications {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
              }
              intelligence {
                __typename
                id
                breaches {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    date
                    year
                    name
                    description
                    leaks
                    data
                    status
                    email
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                externalThreats {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    source
                    findings
                    riskScore
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                others {
                  __typename
                  items {
                    __typename
                    id
                    vendorId
                    name
                    label
                    severity
                    description
                    status
                    createdAt
                    updatedAt
                  }
                  nextToken
                }
                createdAt
                updatedAt
              }
              primaryPoc {
                __typename
                name
                id
                email
              }
              stage
              activeScan
              domain
              privacyData
              impactsQualify {
                __typename
                name
                isQualify
                templateURL {
                  __typename
                  id
                  name
                  names
                  entityId
                  fileType
                  date
                  contentType
                  length
                  key
                }
                documentCategory
                documentType
                level
              }
              primaryLoss
              financialImpact {
                __typename
                type
                minimum
                mostLikely
                maximum
                confidence
                stepData
              }
              smartTags
              employees
              domains
              isThirdParty
              isFourthParty
              fourthPartyDomains
              level
              fourthPartyActiveScan
              intelligenceScanScore
              fourthPartyScanScore
              isThirdPartyScanCompleted
              priority
              identification
              materialityRating
              enbdCategory
              enbdDomain
              phoneNumber
              projectManagerPhoneNumber
              businessUnits
              category
              dealValue
              products
              createdAt
              updatedAt
            }
            industry
            domain
            activeAssessmentId
            tierSelected
            activeAssessment {
              __typename
              id
              standardList {
                __typename
                id
                type
                fileName
                filter {
                  __typename
                  impact
                }
              }
              standardFrameworkList {
                __typename
                items {
                  __typename
                  id
                  key
                  type
                  fileName
                  filter {
                    __typename
                    impact
                  }
                  timeline {
                    __typename
                    initiationDate
                    collectionDate
                    nextReviewDate
                    frequency
                    days
                    nextStartingDate
                    reassessmentNotifier
                    vendorOnBoardingDate
                    isAutoReminder
                    isAutoReassessment
                  }
                  assessmentId
                  managerId
                  assessment {
                    __typename
                    id
                    rootId
                    childId
                    left
                    right
                    managers
                    createdAt
                    updatedAt
                  }
                  selectedChapters
                  managers {
                    __typename
                    nextToken
                  }
                  not_added
                  archived
                  reassessmentStatus
                  defaultQuestionSettings {
                    __typename
                    isApprovalRequired
                    isArtifactsMandatory
                    isCommentsMandatory
                    isCollaborative
                    isIntegrationActive
                    isSmartMappingActive
                  }
                  documentCategory
                  documentType
                  suggestedArtifact
                  suggestedComments
                  level
                  newStartReassessDate
                  signatureUserID
                  isLocked
                  signedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  signature {
                    __typename
                    title
                    printName
                    disclaimerDate
                    image
                    text
                    draw
                    signDate
                  }
                  lockedByUserID
                  lockedBy {
                    __typename
                    id
                    name
                    email
                    role
                    roleId
                    isViewOnly
                    phone
                    entityId
                    entityIds
                    isCognitoUser
                    questionMap
                    createdBy
                    createdAt
                    lastLogin
                    invitationDate
                    reminderDate
                    modifiedBy
                    modifiedAt
                    domain
                    temporaryHigherRank
                    entityLayerIds
                    clickThrough
                    clickThroughDate
                    projectManagerEmail
                    isGlobalParticipant
                    onBoardingStatus
                    updatedAt
                  }
                  createdAt
                  updatedAt
                }
                nextToken
              }
              timeline {
                __typename
                initiationDate
                collectionDate
                nextReviewDate
                frequency
                days
                nextStartingDate
                reassessmentNotifier
                vendorOnBoardingDate
                isAutoReminder
                isAutoReassessment
              }
              rootId
              childId
              scores {
                __typename
                total
                target
                collection
                remediation
                reassessment
                totalQuestions
                completedQuestions
                totalGaps
                criticalGaps
                notApplicable
                top20 {
                  __typename
                  total
                  target
                  collection
                  remediation
                  reassessment
                  totalQuestions
                  completedQuestions
                  totalGaps
                  criticalGaps
                  notApplicable
                  top20 {
                    __typename
                    total
                    target
                    collection
                    remediation
                    reassessment
                    totalQuestions
                    completedQuestions
                    totalGaps
                    criticalGaps
                    notApplicable
                    maturity
                    tierScores
                    totalReadinessTasks
                    totalComplianceTasks
                    totalInternalTasks
                  }
                  maturity
                  tierScores
                  totalReadinessTasks
                  totalComplianceTasks
                  totalInternalTasks
                }
                maturity
                tierScores
                totalReadinessTasks
                totalComplianceTasks
                totalInternalTasks
              }
              left
              right
              managers
              exports {
                __typename
                jiraEntityIds
                serviceNowEntityIds
              }
              createdAt
              updatedAt
            }
            scName
            defaultQuestionSettings {
              __typename
              isApprovalRequired
              isArtifactsMandatory
              isCommentsMandatory
              isCollaborative
              isIntegrationActive
              isSmartMappingActive
            }
            KMS {
              __typename
              status
              version
              key
              alias
              dataKeyEncrypted
              newDataKeyEncrypted
              newKeyId
              rotations {
                __typename
                count
                status
                lastRotation
                firstRotationDate
              }
              userId
            }
            AI {
              __typename
              credits
              date
              isActive
              usedCredits
              userName
            }
            upperdeckRoute
            isLocked
            eSignFinalized
            weightageScore
            upperdeckSetting
            updatedAt
          }
          parentId
          score {
            __typename
            total
            collection
            remediation
          }
          inherent
          residual
          domainScanned
          domainScanTimes
          createdAt
          updatedAt
          riskFramework
          clientDomain
          userId
          applicantInfo
          revenueDetails
          dataInventory
          updatedBy
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateDataStateListener(token: string, filter?: ModelSubscriptionDataStateFilterInput) {
    const statement = `subscription OnCreateDataState($filter: ModelSubscriptionDataStateFilterInput) {
        onCreateDataState(filter: $filter) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateDataStateListener(token: string, filter?: ModelSubscriptionDataStateFilterInput) {
    const statement = `subscription OnUpdateDataState($filter: ModelSubscriptionDataStateFilterInput) {
        onUpdateDataState(filter: $filter) {
          __typename
          id
          state
          parentId
          message
          detail
          type
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateAnswerListener(token: string, filter?: ModelSubscriptionAnswerFilterInput) {
    const statement = `subscription OnCreateAnswer($filter: ModelSubscriptionAnswerFilterInput) {
        onCreateAnswer(filter: $filter) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnUpdateAnswerListener(token: string, filter?: ModelSubscriptionAnswerFilterInput) {
    const statement = `subscription OnUpdateAnswer($filter: ModelSubscriptionAnswerFilterInput) {
        onUpdateAnswer(filter: $filter) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnDeleteAnswerListener(token: string, filter?: ModelSubscriptionAnswerFilterInput) {
    const statement = `subscription OnDeleteAnswer($filter: ModelSubscriptionAnswerFilterInput) {
        onDeleteAnswer(filter: $filter) {
          __typename
          id
          createdAt
          answer
          answerName
          comment
          file {
            __typename
            id
            name
            names
            entityId
            fileType
            date
            contentType
            length
            key
          }
          userId
          riskAction
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          questionId
          date
          assessmentId
          assignmentID
          left
          right
          isActionTaken
          triggerStopper
          origin
          auditStatus
          isUptodate
          reviewerActionStatus
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  OnCreateVendorsDetailListener(token: string, filter?: ModelSubscriptionVendorsDetailFilterInput) {
    const statement = `subscription OnCreateVendorsDetail($filter: ModelSubscriptionVendorsDetailFilterInput) {
        onCreateVendorsDetail(filter: $filter) {
          __typename
          id
          impact
          externalScan
          financialRisk
          criticalGaps
          standardsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          status
          probability
          website
          location
          size
          description
          businessPointOfContactId
          accessLevel
          collectionStatus
          timeline {
            __typename
            initiationDate
            collectionDate
            nextReviewDate
            frequency
            days
            nextStartingDate
            reassessmentNotifier
            vendorOnBoardingDate
            isAutoReminder
            isAutoReassessment
          }
          approvalDate
          questionnaires {
            __typename
            tag
            status {
              __typename
              totalQuestions
              completedQuestions
            }
            criticalGaps
            allGaps
          }
          artifacts {
            __typename
            documents {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            scans {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            insurances {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            certifications {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
          }
          intelligence {
            __typename
            id
            breaches {
              __typename
              items {
                __typename
                id
                vendorId
                date
                year
                name
                description
                leaks
                data
                status
                email
                createdAt
                updatedAt
              }
              nextToken
            }
            externalThreats {
              __typename
              items {
                __typename
                id
                vendorId
                source
                findings
                riskScore
                details {
                  __typename
                  ip
                  port
                  cve
                  cvss
                }
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            others {
              __typename
              items {
                __typename
                id
                vendorId
                name
                label
                severity
                description
                status
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          primaryPoc {
            __typename
            name
            id
            email
          }
          stage
          activeScan
          domain
          privacyData
          impactsQualify {
            __typename
            name
            isQualify
            templateURL {
              __typename
              id
              name
              names
              entityId
              fileType
              date
              contentType
              length
              key
            }
            documentCategory
            documentType
            level
          }
          primaryLoss
          financialImpact {
            __typename
            type
            minimum
            mostLikely
            maximum
            confidence
            stepData
          }
          smartTags
          employees
          domains
          isThirdParty
          isFourthParty
          fourthPartyDomains
          level
          fourthPartyActiveScan
          intelligenceScanScore
          fourthPartyScanScore
          isThirdPartyScanCompleted
          priority
          identification
          materialityRating
          enbdCategory
          enbdDomain
          phoneNumber
          projectManagerPhoneNumber
          businessUnits
          category
          dealValue
          products
          ticketID
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql({
      query: statement,
      variables: { ...gqlAPIServiceArguments },
      authMode: 'AWS_LAMBDA',
      authToken: token,
    }) as unknown as Observable<object>;
  }

  async CreateTaskAction(
    input: CreateTaskActionInput,
    condition?: ModelTaskActionConditionInput
  ): Promise<CreateTaskActionMutation> {
    const statement = `mutation CreateTaskAction($input: CreateTaskActionInput!, $condition: ModelTaskActionConditionInput) {
        createTaskAction(input: $input, condition: $condition) {
          __typename
          id
          createdAt
          score
          action
          assessmentId
          taskAssignmentID
          questionId
          frameworkName
          user {
            __typename
            id
            name
            email
            role
            roleId
            isViewOnly
            phone
            entityId
            entityIds
            isCognitoUser
            questionMap
            createdBy
            createdAt
            lastLogin
            invitationDate
            reminderDate
            modifiedBy
            modifiedAt
            domain
            temporaryHigherRank
            entityLayerIds
            clickThrough
            clickThroughDate
            projectManagerEmail
            isGlobalParticipant
            onBoardingStatus
            updatedAt
          }
          userId
          date
          triggerStopper
          searchString
          questionIds
          smartMappingIds
          title
          impact
          maturity
          controlsConnected {
            __typename
            id
            name
            label
          }
          type
          description
          tabName
          managerAction
          rootEntityId
          dueDate
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input,
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const token = await this.checkIfRefreshTokenRequired(currentSession);
    const payload = this.encryptPayload(
      JSON.stringify({
        username: currentSession?.accessToken?.payload?.username,
        jwtToken: token,
        callMaker: uuid(),
      }),
      currentSession?.accessToken?.payload?.username
    );
    const response = (await API.graphql(
      {
        query: statement,
        variables: { ...gqlAPIServiceArguments },
        authMode: 'AWS_LAMBDA',
        authToken: payload,
      },
      { 'x-api-key-value': currentSession?.accessToken?.payload?.username }
    )) as any;

    return <CreateTaskActionMutation>response.data.createTaskAction;
  }

  async allocateAICredits(updatedData: any): Promise<string | null> {
    const currentSession: any = await Auth.currentSession();

    if (!currentSession) {
      return;
    }
    const payload = this.encryptPayload(updatedData, currentSession?.accessToken?.payload?.username);
    const response = (await API.patch('cygovRestApi', '/allocateAICredits', {
      body: { payload },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': currentSession.getAccessToken().getJwtToken(),
      },
    })) as any;

    return response;
  }
}
