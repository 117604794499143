import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'cygov-domain-frameworks-listings',
  templateUrl: './domain-frameworks-listings.component.html',
  styleUrls: ['./domain-frameworks-listings.component.scss'],
})
export class DomainFrameworksListingsComponent implements OnInit {
  @Input() title: string; // Title to show
  @Input() riskFrameWorkItems; // risk framework list
  @Input() vendorFrameworkItems;
  @Input() complianceItems; // compliance list items
  @Input() showFrameworkCounts = false;
  @Input() showCheckbox: boolean = false; // to show list with checkboxes
  @Input() disableFrameworkChanges: boolean = false; // to show list with checkboxes
  @Input() isFirstPartyOn: boolean = true; // to show list with checkboxes
  @Input() isThirdPartyOn: boolean = true; // to show list with checkboxes
  @Input() isSelectAllVisible: boolean = false; // to show select all checkbox
  @Input() toggleActiveTextColor: string = '#29A9EB'; // color for active toggle text
  @Input() toggleInactiveTextColor: string = '#8c929d'; // color for inactive toggle text
  @Output() showFrameworkGroupDetail = new EventEmitter<boolean>(); // to show framework detail modal
  @Output() clickOnSaveButton = new EventEmitter<any>(); // to save frameworks
  /*
   * 'parentContainerID' you can use this prop to give an id to parent element
   * so you can override the styles as needed.
   */
  @Input() parentContainerID: string = 'domain-framework-listing-container';

  showDetailFrameworkModal: boolean = false;
  childFrameworks = [];
  parentFramework = null;
  constructor() {}
  frameworkTypeTabEnum = {
    firstParty: '1st Party',
    thirdParty: '3rd Party',
  };
  TAB_ENUMS = {
    RISK: 1,
    COMPLIANCE: 2,
    CUSTOM: 3,
  };
  selectAllStatus = {
    '1st Party': {
      1: true,
      2: true,
      3: true,
    },
    '3rd Party': {
      1: true,
      3: true,
    },
  };

  selectedCount = {
    '1st Party': {
      1: 0, // Risk count
      2: 0, // Compliance count
      3: 0, // Custom count
    },
    '3rd Party': {
      1: 0, // Risk count
      3: 0, // Custom count
    },
  };

  tabTitle: string = this.frameworkTypeTabEnum.firstParty;
  active = this.TAB_ENUMS.RISK;
  previousActiveTabId = null;
  riskFrameworks = {
    '1st Party': [],
    '3rd Party': [],
  };
  complianceFrameworks = {
    '1st Party': [],
  };
  customRiskFramework = {
    '1st Party': [],
    '3rd Party': [],
  };
  customComplianceFramework = {
    '1st Party': [],
    '3rd Party': [],
  };

  // backup to store original data before search
  originalRiskFrameworks: any = {};
  originalComplianceFrameworks: any = {};
  originalCustomRiskFramework: any = {};
  originalCustomComplianceFramework: any = {};

  isFirstParty: boolean = true;
  searchText: string = '';
  isFrameworkNameEllipsis: boolean = false;

  ngOnInit() {
    this.complianceFrameworks['1st Party'] = !this.disableFrameworkChanges
      ? this.formatFrameworksByGroup(
          this.complianceItems.filter(framework => !framework.custom && 'frameworkFamily' in framework),
          { tab: '1st Party', value: 2 }
        )
      : this.complianceItems.filter(framework => !framework.custom && 'frameworkFamily' in framework);

    this.customRiskFramework['1st Party'] = this.formatFrameworksByGroup(
      this.riskFrameWorkItems.filter(framework => framework?.custom),
      { tab: '1st Party', value: 3 }
    );
    this.customComplianceFramework['1st Party'] = this.formatFrameworksByGroup(
      this.complianceItems.filter(framework => framework?.custom),
      { tab: '1st Party', value: 3 }
    );

    this.customRiskFramework['3rd Party'] = this.formatFrameworksByGroup(
      this.vendorFrameworkItems.filter(framework => framework?.custom),
      { tab: '3rd Party', value: 3 }
    );

    this.riskFrameworks['1st Party'] = !this.disableFrameworkChanges
      ? this.formatFrameworksByGroup(
          this.riskFrameWorkItems.filter(framework => !framework?.custom && 'frameworkFamily' in framework),
          { tab: '1st Party', value: 1 }
        )
      : this.riskFrameWorkItems.filter(framework => !framework?.custom && 'frameworkFamily' in framework);
    this.riskFrameworks['3rd Party'] = this.formatFrameworksByGroup(
      this.vendorFrameworkItems.filter(framework => !framework?.custom && 'frameworkFamily' in framework),
      { tab: '3rd Party', value: 1 }
    );

    this.originalRiskFrameworks = JSON.parse(JSON.stringify(this.riskFrameworks));
    this.originalCustomRiskFramework = JSON.parse(JSON.stringify(this.customRiskFramework));
    this.originalCustomComplianceFramework = JSON.parse(JSON.stringify(this.customComplianceFramework));
    this.originalComplianceFrameworks = JSON.parse(JSON.stringify(this.complianceFrameworks));

    this.updateSelectedCount();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isFirstPartyOn) {
      this.isFirstParty = this.isThirdPartyOn ? changes.isFirstPartyOn.currentValue : true;
      this.tabTitle = this.isFirstParty ? this.frameworkTypeTabEnum.firstParty : this.frameworkTypeTabEnum.thirdParty;

      if (
        (!this.isFirstParty && this.active === 2) ||
        (!this.isFirstParty &&
          this.active === 3 &&
          !this.customRiskFramework['3rd Party']?.length &&
          !this.customComplianceFramework['3rd Party']?.length)
      ) {
        this.active = 1;
      }
    }

    if (changes?.isThirdPartyOn && changes.isThirdPartyOn.currentValue === false && this.isFirstPartyOn) {
      this.isFirstParty = !changes.isThirdPartyOn.currentValue;
      this.tabTitle = this.isFirstParty ? this.frameworkTypeTabEnum.firstParty : this.frameworkTypeTabEnum.thirdParty;
    }
  }

  formatFrameworksByGroup(frameworks, selectAll) {
    interface Framework {
      key: string;
      name: string;
      status: boolean;
      frameworkFamily?: string;
      custom?: boolean;
    }

    interface GroupedFramework {
      name: string;
      key: string;
      status: boolean;
      children?: Framework[];
      frameworkFamily?: string;
      custom?: boolean;
    }

    const groupedFrameworks: { [key: string]: GroupedFramework } = {};
    const result: GroupedFramework[] = [];

    frameworks.forEach(framework => {
      if (framework.frameworkFamily) {
        if (!groupedFrameworks[framework.frameworkFamily]) {
          groupedFrameworks[framework.frameworkFamily] = {
            name: framework.frameworkFamily,
            key: framework.frameworkFamily,
            status: false, // initially set to false, will update later
            children: [],
          };
        }

        groupedFrameworks[framework.frameworkFamily].children.push({
          name: framework.name,
          key: framework.key,
          status: framework.status,
          frameworkFamily: framework.frameworkFamily,
          custom: framework.custom,
        });

        // Update parent status to true if any child has status true
        if (framework.status) {
          groupedFrameworks[framework.frameworkFamily].status = true;
        }
        if (!framework.status) {
          this.selectAllStatus[selectAll.tab][selectAll.value] = false;
        }
      } else {
        if (!framework.status) {
          this.selectAllStatus[selectAll.tab][selectAll.value] = false;
        }

        result.push({
          name: framework.name,
          key: framework.key,
          status: framework.status,
          frameworkFamily: framework.frameworkFamily,
          custom: framework.custom,
        });
      }
    });

    const groupedArray = Object.values(groupedFrameworks);

    groupedArray.forEach(group => {
      if (group.children && group.children.length) {
        group.children.sort((a, b) => a.name.localeCompare(b.name));
      }
    });

    return result.concat(groupedArray).sort((a, b) => a.name.localeCompare(b.name));
  }

  switchTabs(activeTabTitle: string) {
    this.tabTitle = activeTabTitle;
    if (activeTabTitle === this.frameworkTypeTabEnum.firstParty) {
      this.isFirstParty = true;
    } else {
      this.isFirstParty = false;
    }
  }
  openFrameworkDetailList(frameworkFamily) {
    this.showDetailFrameworkModal = true;
    this.childFrameworks = frameworkFamily?.children;
    this.parentFramework = frameworkFamily;
    this.showFrameworkGroupDetail.emit(true);
  }

  closeFrameworkGroupmodal() {
    this.showDetailFrameworkModal = false;
    this.showFrameworkGroupDetail.emit(false);
    this.childFrameworks = [];
    this.parentFramework = null;
  }

  updateSelectAllStatus(groupedArray) {
    const allSelected = groupedArray.every(group => {
      const parentChecked = group.status;
      const allChildrenChecked = group.children ? group.children.every(child => child.status) : true;
      return parentChecked && allChildrenChecked;
    });

    this.selectAllStatus[this.tabTitle][this.active] = allSelected;
  }

  updateSelectedCount() {
    this.selectedCount['1st Party'] = { 1: 0, 2: 0, 3: 0 };
    this.selectedCount['3rd Party'] = { 1: 0, 3: 0 };

    this.countSelectedItems('1st Party');

    this.countSelectedItems('3rd Party');
  }

  countSelectedItems(tab: string, specificArray = []) {
    interface Framework {
      key: string;
      name: string;
      status: boolean;
      frameworkFamily?: string;
      custom?: boolean;
    }

    interface GroupedFramework {
      name: string;
      key: string;
      status: boolean;
      children?: Framework[];
      frameworkFamily?: string;
      custom?: boolean;
    }

    if (specificArray?.length) {
      this.selectedCount[tab][this.active] = 0;
      for (const frameworkGroup of specificArray) {
        if (frameworkGroup.children && frameworkGroup.children.length > 0) {
          this.selectedCount[tab][this.active] += frameworkGroup.children.length;
        } else {
          this.selectedCount[tab][this.active]++;
        }
      }
      return;
    }

    if (this.complianceFrameworks[tab]) {
      this.selectedCount[tab][2] = 0;
      Object.values(this.complianceFrameworks[tab]).forEach((frameworkGroup: GroupedFramework) => {
        if (frameworkGroup.children && frameworkGroup.children.length > 0) {
          this.selectedCount[tab][2] += frameworkGroup.children.length;
        } else {
          this.selectedCount[tab][2]++;
        }
      });
    }

    if (this.riskFrameworks[tab]) {
      this.selectedCount[tab][1] = 0;
      Object.values(this.riskFrameworks[tab]).forEach((frameworkGroup: GroupedFramework) => {
        if (frameworkGroup.children && frameworkGroup.children.length > 0) {
          this.selectedCount[tab][1] += frameworkGroup.children.length;
        } else {
          this.selectedCount[tab][1]++;
        }
      });
    }

    if (this.customComplianceFramework[tab] && tab === '1st Party') {
      this.selectedCount[tab][3] = 0;
      Object.values(this.customComplianceFramework[tab]).forEach((frameworkGroup: GroupedFramework) => {
        if (frameworkGroup.children && frameworkGroup.children.length > 0) {
          this.selectedCount[tab][3] += frameworkGroup.children.length;
        } else {
          this.selectedCount[tab][3]++;
        }
      });
    }

    if (this.customRiskFramework[tab]) {
      Object.values(this.customRiskFramework[tab]).forEach((frameworkGroup: GroupedFramework) => {
        if (frameworkGroup.children && frameworkGroup.children.length > 0) {
          this.selectedCount[tab][3] += frameworkGroup.children.length;
        } else {
          this.selectedCount[tab][3]++;
        }
      });
    }
  }

  changeToggleSelection(event, type, frameworkArray, index = null) {
    if (type === 'parent') {
      frameworkArray[index].status = event;
      frameworkArray[index]?.children?.forEach(framework => {
        framework.status = event;
      });

      this.updateOriginalFrameworkStatus(frameworkArray[index].key, null, event);

      if (this.active === 3) {
        this.updateSelectAllStatus([
          ...this.customRiskFramework[this.tabTitle],
          ...this.customComplianceFramework[this.tabTitle],
        ]);
      } else {
        this.updateSelectAllStatus(frameworkArray);
      }

      this.updateSelectedCount();
    } else {
      this.childFrameworks[index].status = event;
      const allFalse = this.childFrameworks.every(child => !child.status);
      if (allFalse) {
        this.parentFramework.status = false;
      } else {
        this.parentFramework.status = true;
      }

      // update the status in the original array for both child and parent
      this.updateOriginalFrameworkStatus(this.parentFramework.key, this.childFrameworks[index].key, event);

      // Update select all status for the current tab and category
      this.updateSelectAllStatus([this.parentFramework]);

      // Update the selected count for all tabs and categories
      this.updateSelectedCount();
    }
  }

  getActiveFrameworkArray() {
    if (this.active === 1) {
      return this.originalRiskFrameworks;
    } else if (this.active === 2) {
      return this.originalComplianceFrameworks;
    } else if (this.active === 3) {
      // return both custom risk and custom compliance frameworks
      return {
        customRiskFramework: this.originalCustomRiskFramework,
        customComplianceFramework: this.originalCustomComplianceFramework,
      };
    }
    return {};
  }

  updateOriginalFrameworkStatus(parentKey: string, childKey: string | null, newStatus: boolean) {
    const frameworks = this.getActiveFrameworkArray();

    if (this.active === 3) {
      this.updateStatusForFrameworkArray(frameworks.customRiskFramework, parentKey, childKey, newStatus);
      this.updateStatusForFrameworkArray(frameworks.customComplianceFramework, parentKey, childKey, newStatus);
    } else {
      this.updateStatusForFrameworkArray(frameworks, parentKey, childKey, newStatus);
    }
  }

  updateStatusForFrameworkArray(frameworkArray: any[], parentKey: string, childKey: string | null, newStatus: boolean) {
    const parentFramework = frameworkArray[this.tabTitle]?.find(framework => framework.key === parentKey);

    if (!parentFramework) {
      return;
    }

    if (childKey) {
      // if childKey is provided, update the specific child's status
      const childFramework = parentFramework.children?.find(child => child.key === childKey);
      if (childFramework) {
        childFramework.status = newStatus;

        // update the parent status based on children's statuses
        parentFramework.status = parentFramework.children.some(child => child.status);
      }
    } else {
      // if no childKey, update the parent and all children
      parentFramework.status = newStatus;
      parentFramework.children?.forEach(child => {
        child.status = newStatus;
      });
    }
  }

  changeSelectAllSelection(event) {
    const { frameworks, originalFrameworks } = this.getActiveAndOriginalFrameworks();

    if (!frameworks || !originalFrameworks) {
      console.error('No frameworks found for the active tab.');
      return;
    }

    this.selectAllStatus[this.tabTitle][this.active] = event;

    this.updateFrameworksStatus(frameworks, event);
    this.updateFrameworksStatus(originalFrameworks, event);
  }

  getActiveAndOriginalFrameworks() {
    switch (this.active) {
      case 1:
        return {
          frameworks: this.riskFrameworks[this.tabTitle],
          originalFrameworks: this.originalRiskFrameworks[this.tabTitle],
        };
      case 2:
        return {
          frameworks: this.complianceFrameworks[this.tabTitle],
          originalFrameworks: this.originalComplianceFrameworks[this.tabTitle],
        };
      case 3:
        return {
          frameworks: [...this.customRiskFramework[this.tabTitle], ...this.customComplianceFramework[this.tabTitle]],
          originalFrameworks: [
            ...this.originalCustomRiskFramework[this.tabTitle],
            ...this.originalCustomComplianceFramework[this.tabTitle],
          ],
        };
      default:
        return { frameworks: [], originalFrameworks: [] };
    }
  }

  updateFrameworksStatus(frameworks: any[], status: boolean) {
    frameworks.forEach(framework => {
      framework.status = status;
      if (framework.children) {
        framework.children.forEach(child => {
          child.status = status;
        });
      }
    });
  }

  saveButtonEventHandler() {
    const finalObject = this.formatFrameworkArrays();
    this.clickOnSaveButton.emit(finalObject);
  }

  formatFrameworkArrays() {
    const finalObject = {
      RISK_FRAMEWORKS: this.flattenStructure([
        ...this.originalRiskFrameworks['1st Party'],
        ...this.customRiskFramework['1st Party'],
      ]),
      COMPLIANCE_FRAMEWORKS: this.flattenStructure([
        ...this.complianceFrameworks['1st Party'],
        ...this.customComplianceFramework['1st Party'],
      ]),
      VENDOR_FRAMEWORKS: this.flattenStructure([
        ...this.riskFrameworks['3rd Party'],
        ...this.customRiskFramework['3rd Party'],
      ]),
    };

    return finalObject;
  }

  flattenStructure(arr) {
    const result = [];

    arr.forEach(item => {
      if (item.children && item.children.length > 0) {
        result.push(...item.children);
      } else {
        result.push(item);
      }
    });

    return result;
  }

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  }

  prepareDataForSearch = this.debounce(() => {
    let frameworks = [];

    if (this.active === 1) {
      frameworks = this.originalRiskFrameworks[this.tabTitle];
      this.riskFrameworks[this.tabTitle] = this.searchFrameworksByName(frameworks);
      this.countSelectedItems(this.tabTitle, this.riskFrameworks[this.tabTitle]);
    } else if (this.active === 2) {
      frameworks = this.originalComplianceFrameworks[this.tabTitle];
      this.complianceFrameworks[this.tabTitle] = this.searchFrameworksByName(frameworks);
      this.countSelectedItems(this.tabTitle, this.complianceFrameworks[this.tabTitle]);
    } else if (this.active === 3) {
      frameworks = this.originalCustomRiskFramework[this.tabTitle];
      this.customRiskFramework[this.tabTitle] = this.searchFrameworksByName(frameworks);
      frameworks = this.originalCustomComplianceFramework[this.tabTitle];
      this.customComplianceFramework[this.tabTitle] = this.searchFrameworksByName(frameworks);
      this.countSelectedItems(this.tabTitle, [
        ...this.customComplianceFramework[this.tabTitle],
        ...this.customRiskFramework[this.tabTitle],
      ]);
    }
  }, 500);

  searchFrameworksByName(frameworks): any {
    const lowerCaseSearchTerm = this.searchText.toLowerCase();

    if (!lowerCaseSearchTerm.trim()) {
      return frameworks;
    }

    frameworks = frameworks
      .map(group => {
        const groupNameMatches = group.name.toLowerCase().includes(lowerCaseSearchTerm);
        let matchingChildren = [];

        if (group.children && group.children.length > 0) {
          matchingChildren = group.children.filter(child => child.name.toLowerCase().includes(lowerCaseSearchTerm));
        }

        if (groupNameMatches || matchingChildren.length > 0) {
          return {
            ...group,
            children: matchingChildren.length > 0 ? matchingChildren : group.children,
          };
        }

        return null;
      })
      .filter(group => group !== null);
    return frameworks;
  }

  clearSearch() {
    if (this.searchText === '') {
      this.previousActiveTabId = this.active;
      return;
    }

    this.searchText = '';

    setTimeout(() => {
      if (this.previousActiveTabId === 1 || this.previousActiveTabId === null) {
        this.riskFrameworks = { ...this.originalRiskFrameworks };
        this.countSelectedItems(this.tabTitle, this.riskFrameworks[this.tabTitle]);
      } else if (this.previousActiveTabId === 2) {
        this.complianceFrameworks = { ...this.originalComplianceFrameworks };
        this.countSelectedItems(this.tabTitle, this.complianceFrameworks[this.tabTitle]);
      } else if (this.previousActiveTabId === 3) {
        this.customRiskFramework = { ...this.originalCustomRiskFramework };
        this.countSelectedItems(this.tabTitle, this.customRiskFramework[this.tabTitle]);
        this.customComplianceFramework = { ...this.originalCustomComplianceFramework };
        this.countSelectedItems(this.tabTitle, this.customComplianceFramework[this.tabTitle]);
      }
      this.previousActiveTabId = this.active;
    }, 500);
  }

  onTabClick(selectedTab: any, isFirstParty: boolean): void {
    this.tabTitle = selectedTab;
    this.isFirstParty = isFirstParty;
    if (this.active === 2 && selectedTab === this.frameworkTypeTabEnum.thirdParty) {
      this.active = 1;
    }

    if (
      this.active === 3 &&
      selectedTab === this.frameworkTypeTabEnum.thirdParty &&
      !this.customRiskFramework['3rd Party']?.length &&
      !this.customComplianceFramework['3rd Party']?.length
    ) {
      this.active = 1;
    }

    if (
      this.active === 3 &&
      selectedTab === this.frameworkTypeTabEnum.firstParty &&
      !this.customRiskFramework['1st Party']?.length &&
      !this.customComplianceFramework['1st Party']?.length
    ) {
      this.active = 1;
    }

    this.clearSearch();
  }

  isEllipsisActive(element: any): void {
    const isTooltipVisible = element.scrollWidth > element.clientWidth;
    this.isFrameworkNameEllipsis = isTooltipVisible;
  }
}
