/* eslint-disable camelcase */
import { Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { API, Auth } from 'aws-amplify';
import { AWSEnum } from '../shared/enums/aws.enum';
import Lambda from 'aws-sdk/clients/lambda';
import { APIService, GetUserQuery, RoleEnum, EntityTypeEnum, ModelUserFilterInput } from '../API.service';
import { EntityService } from 'app/shared/entity.service';
import { NGXLogger } from 'ngx-logger';
import { UtilsService } from 'app/shared/utils.service';
import { from, Subject, Subscription } from 'rxjs';
import { DomainBaseEnum } from 'app/shared/enums/cognito.enum';
import * as CryptoJS from 'crypto-js';
import { CUSTOMAPIService, CreateUserInput, UpdateUserInput } from 'app/custom-api.service';
import { ClientLambdaService } from 'app/shared/client-lambda.service';
import { environment } from 'environments/environment';
import { onDeleteUser } from 'graphql/custom-subscriptions/subscription';

export interface Credentials {
  email: string;
  password: string;
  remember?: boolean;
}

Auth.configure({ authenticationFlowType: 'CUSTOM_AUTH' });

const CYGOV_STORAGE = 'cygov_storage';
const COGNITO_USER = 'isCognitoUser';
const AD_USER = 'isADUser';
const ACCESS_TOKEN = 'ad-token';

@Injectable()
export class AuthService implements OnDestroy {
  storage: Storage = localStorage;
  user: GetUserQuery;
  authUser: any = null; // This is for cognito user
  userRole: any;
  tokenHTTP: HttpClient; // We need a separate instance of httpClient to bypass the interceptors.
  credentials;
  isFirstLogin: boolean = false;
  welcomeGuideStatus: Subject<boolean> = new Subject<boolean>();
  headerLoaderSubscriber: Subject<boolean> = new Subject<boolean>();
  private subscriptionList: Subscription[];
  currentToken: any;

  constructor(
    private router: Router,
    private handler: HttpBackend,
    private api: APIService,
    private entityService: EntityService,
    private logger: NGXLogger,
    private customApi: CUSTOMAPIService,
    private toastr: ToastrService,
    private clientLambdaService: ClientLambdaService
  ) {
    from(Auth.currentSession()).subscribe((currentSession: any) => {
      if (!currentSession) {
        return;
      }
      this.currentToken = currentSession.getAccessToken().getJwtToken();
      this.subscriptionList = [this.onDeleteUserListener(this.currentToken)];
    });

    this.customApi.webSocketConnectionListner$.subscribe(hubState => {
      if (hubState?.isConnectionClosed && this.subscriptionList?.length) {
        this.subscriptionList.forEach(listener => listener?.unsubscribe());
        this.subscriptionList = [];
        this.subscriptionList = [this.onDeleteUserListener(this.currentToken)];
      }
    });
    this.tokenHTTP = new HttpClient(this.handler);
    if (sessionStorage.getItem(CYGOV_STORAGE)) {
      this.storage = sessionStorage;
    } else {
      this.storage = localStorage;
    }
  }

  get isCognitoUser(): boolean {
    try {
      const isCognitoUser = JSON.parse(this.storage.getItem(COGNITO_USER));
      return isCognitoUser ? isCognitoUser : null;
    } catch (_) {
      return null;
    }
  }

  setIsCognitoUser(value: boolean): void {
    this.storage.setItem(COGNITO_USER, String(value));
  }

  get isADUser(): boolean {
    try {
      const isADUser = JSON.parse(this.storage.getItem(AD_USER).toString());
      return isADUser ? isADUser : null;
    } catch (_) {
      return null;
    }
  }

  setIsADUser(value: boolean): void {
    this.storage.setItem(AD_USER, String(value));
  }

  get accessToken(): string {
    try {
      const accessToken = this.storage.getItem(ACCESS_TOKEN);
      return accessToken ? accessToken : null;
    } catch (_) {
      return null;
    }
  }

  setAccessToken(value: string): void {
    this.storage.setItem(ACCESS_TOKEN, value);
  }

  get currentAuthUser(): any {
    return this.authUser;
  }

  get isStorageLocal(): boolean {
    return this.storage === localStorage;
  }

  async isAuthenticated(): Promise<boolean> {
    return !!(await this.getCurrentUser());
  }

  updateUserAttributes(attributes: any): Promise<string> {
    this.logger.log('updateUserAttributes');
    const currentUser = this.authUser;
    return Auth.updateUserAttributes(currentUser, attributes);
  }

  async setFirstPassword(email: string, password: string, requiredAttributes: any): Promise<string> {
    this.logger.log('In setFirstPassword');
    const currentUser = this.authUser;
    const loggedUser = await Auth.completeNewPassword(currentUser, password, requiredAttributes);
    this.isFirstLogin = true;
    this.setIsCognitoUser(true);
    this.authUser = loggedUser;
    return loggedUser.challengeName;
  }

  async signIn(credentials: Credentials): Promise<string> {
    const { email: username, password } = credentials;
    const domain = EntityService.getAdminGroup();
    const clientMetadata = { domain };
    this.setIsCognitoUser(true);
    try {
      this.authUser = await Auth.signIn(username, password, clientMetadata);
      return this.authUser.challengeName;
    } catch (e) {
      this.logger.error('signIn - Error: ', e);
      return Promise.reject(e);
    }
  }

  async loginCodeConfirm(codeFromUser?: string): Promise<GetUserQuery> {
    try {
      const authUser = this.authUser;
      if (!authUser) {
        console.log('No Auth User');
      }
      this.authUser = await Auth.sendCustomChallengeAnswer(authUser, codeFromUser);
      return await this.getCurrentUser();
    } catch (e) {
      this.logger.error('twoFactorPhase - Error: ', e);
      throw Error(e);
    }
  }

  async logOut(): Promise<void> {
    try {
      this.user = null;
      this.userRole = null;
      const currentSession: any = await Auth.currentSession();

      if (!currentSession) {
        this.logger.error('logOut - Error: Failed to get current session state');
        return Promise.reject('logOut - Error: Failed to get current session state');
      }

      await this.clientLambdaService.invokeLambda('revokeTokens', { token: currentSession?.accessToken?.jwtToken });
      localStorage.clear();
      sessionStorage.clear();
    } catch (e) {
      this.logger.error('logOut - Error: ', e);
      return Promise.reject(e);
    }
  }

  async sendInviteEmail(
    userId: string,
    userEmail: string,
    userName: string,
    isWelcomeEmail: boolean = false
  ): Promise<string> {
    try {
      const domain = EntityService.getAdminGroup();
      const payload = {
        userId,
        userEmail,
        userName,
        domain,
        isWelcomeEmail,
      };
      await this.customApi.SendInviteEmail(payload);
    } catch (e) {
      this.logger.error('sendInviteEmail - Error: ', e);
      return Promise.reject(e);
    }
  }

  async createUser(userInput: CreateUserInput): Promise<any> {
    await this.customApi.CreateUser(userInput);
  }

  async addUserToGroup(groupName) {
    const groupInput = { id: groupName, desc: groupName };
    const addCognitoGroupRes = await this.customApi.AddCognitoGroup({
      ...groupInput,
      shouldAddUser: true,
    });
    return addCognitoGroupRes;
    // this.logger.log('AddCognitoGroup: ', addCognitoGroupRes);
  }

  async deleteUserFromCognito(user: any) {
    const result = await this.customApi.DeleteCognitoUser(user);
    if (result) {
      this.authUser = result ? result : null;
    }
    this.logger.log('Cognito user deleted');
  }

  async removeUser(userId: string): Promise<void> {
    await this.customApi.DeleteUser({ id: userId });
  }

  async updateUser(user: any): Promise<void> {
    await this.updateUserOrRole(user);
  }

  async sendReminderEmail(userId: string, subEntityId: string): Promise<any> {
    try {
      return this.customApi.SendReminderEmail({
        userId,
        subEntityId,
      });
    } catch (e) {
      this.logger.error('sendReminderEmail - Error: ', e);
      return Promise.reject(e);
    }
  }

  async getUserByEmail(userEmail: string): Promise<GetUserQuery> {
    try {
      const filter: ModelUserFilterInput = {
        email: { eq: userEmail },
      };
      return (await this.customApi.ListUsers(filter)).items.pop();
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  async queryUserByEmail(email: string): Promise<GetUserQuery> {
    try {
      const user = (await this.customApi.UserByEmail(email.toLowerCase())).items.pop();
      return user;
    } catch (e) {
      return Promise.resolve(null);
    }
  }

  async sendRestartEmail(userEmail: string): Promise<any> {
    this.setIsCognitoUser(true);
    return await Auth.forgotPassword(userEmail);
  }

  getCurrentUserSync(): GetUserQuery {
    return this.user ? this.user : null;
  }

  async getCurrentUser(): Promise<GetUserQuery> {
    try {
      // check if user authenticated or not, if not return null
      const userInfo = await Auth.currentUserInfo();
      if (!userInfo) {
        return;
      }
      this.authUser = this.authUser ? this.authUser : await Auth.currentAuthenticatedUser();
      if (this.authUser && !this.user && this.authUser.attributes) {
        const isFederatedUser =
          this.authUser.username &&
          (this.authUser.username.toLowerCase().includes(DomainBaseEnum.OKTA) ||
            this.authUser.username.toLowerCase().includes(DomainBaseEnum.AAD) ||
            this.authUser.username.toLowerCase().includes(DomainBaseEnum.SAML));
        this.user = isFederatedUser
          ? await this.queryUserByEmail(this.authUser.attributes.email.toLowerCase())
          : await this.customApi.GetUser(this.authUser.username);
      }
      if (this.user && this.user.roleId) {
        this.userRole = await this.customApi.GetRole(this.user.roleId);
      }
      return this.user;
    } catch (error) {
      console.log('error in auth user', error);
    }
  }

  async getUserById(id): Promise<GetUserQuery> {
    return await this.customApi.GetUser(id);
  }

  /**            ( Code optimization and reusability )
   * ( Note ) Remove : This Function is not in use right now as according to new architecture existing user can be
   *  assigned to multiple entities.
   * @param email is type string conatin email
   * @returns boolean
   */
  async isUserPresent(email: string): Promise<boolean> {
    const code = '000000';
    try {
      await Auth.confirmSignUp(email, code, {
        forceAliasCreation: false,
      });
      return true;
    } catch (error) {
      switch (error.code) {
        case 'UserNotFoundException':
          return false;
        // Below are the other possible codes which shoes user is present
        case 'NotAuthorizedException':
        case 'AliasExistsException':
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          return true;
        default:
          return true;
      }
    }
  }

  async updateCurrentUser(user: UpdateUserInput): Promise<GetUserQuery> {
    try {
      this.user = (await this.customApi.UpdateUser(user)) as any;
      return this.user;
    } catch (e) {
      this.logger.error('updateCurrentUser - Error: ', e);
      return Promise.reject(e);
    }
  }

  async resendCode(): Promise<string> {
    try {
      return await this.signIn(this.credentials);
    } catch (e) {
      this.logger.error('Resend Code Error: ', e);
      return Promise.reject(e.message);
    }
  }

  /**
   * Fetch the authentication tokens for the user.
   *
   * @param token string The token  returned by SAML Auth.
   */
  getOAuthTokens(token: string): Promise<Record<string, any>> {
    const params = new HttpParams({
      fromObject: {
        grant_type: AWSEnum.GRANT_TYPE,
        client_id: AWSEnum.CLIENT_ID,
        redirect_uri: AWSEnum.REDIRECT_URI,
        code: token,
      },
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': AWSEnum.CONTENT_TYPE_HEADER,
        Authorization: AWSEnum.AUTHORIZATION_TYPE + ' ' + btoa(AWSEnum.CLIENT_ID + ':' + AWSEnum.CLIENT_SECRET),
      }),
    };

    try {
      const result = this.tokenHTTP.post(AWSEnum.TOKEN_VERIFICATION_ENDPOINT, params, httpOptions).toPromise();
      return result;
    } catch (e) {
      this.logger.error('OAuth get tokens - Error: ', e);
      return Promise.reject(e);
    }
  }

  async navigateByRole(): Promise<void> {
    let nextRoute: string[];
    let extras: any;
    try {
      const user: GetUserQuery = await this.getCurrentUser();

      if (!user || !user.role) {
        this.logger.error('User role is required!');
        await this.router.navigate(['']);
        return Promise.reject();
      }

      let entityId = null;

      if (user.role !== RoleEnum.ADMIN && user.role !== RoleEnum.MSSP) {
        if (user.entityId) {
          // if one entity id in permission ok to navigate
          // if multiple present navigate to first one
          entityId = user.entityId;
        } else {
          this.logger.error('User has no Permission!');
          await this.router.navigate(['']);
          return Promise.reject();
        }
      }
      const entity = entityId ? await this.entityService.getEntity(entityId) : null;
      switch (user.role) {
        case RoleEnum.ADMIN:
          nextRoute = ['clients'];
          break;
        case RoleEnum.MSSP:
          nextRoute = ['clients'];
          break;
        case RoleEnum.LEADER:
          // if one entity id in permission ok to navigate
          // if multiple present navigate to first one

          // if user is entity leader and isNetskope is enable then re route to executive summary (hotfix)
          if (entity.entityType === EntityTypeEnum.ROOT_ENTITY && UtilsService.isNetskope) {
            nextRoute = [`board-netskope/${entityId}/executive-summary`];
          } else if (entity.entityType === EntityTypeEnum.ROOT_ENTITY) {
            nextRoute = [`first-party/${entityId}/upperdeck`];
          } else {
            nextRoute = UtilsService.isCRB
              ? [`first-party/${entity.parentId}/collection`, entity.id]
              : [`first-party/${entity.parentId}/multi-entity`, entity.id];
          }
          break;
        case RoleEnum.PARTICIPANT:
          // for now navigate to first entity
          nextRoute = [`first-party/${entity.parentId}/collection`, entity.id];
          break;
        case RoleEnum.VENDOR:
          nextRoute = [`first-party/${entity.parentId}/collection`, entity.id];
          extras = {
            queryParams: { userId: user.id },
          };
          break;

        default:
          nextRoute = ['**'];
      }
      await this.router.navigate(nextRoute, extras);
    } catch (e) {
      this.logger.error('navigateByRole - Error: ', e);
      await this.router.navigate(['']);
      return Promise.reject(e);
    }
  }

  async hasPermission(minRole: RoleEnum, entityId?: string, subEntityId?: string): Promise<boolean> {
    try {
      const user: GetUserQuery = await this.getCurrentUser();
      let hasPermission = false;
      if (user) {
        switch (minRole) {
          case RoleEnum.ADMIN:
            hasPermission = user.role === RoleEnum.ADMIN;
            break;
          case RoleEnum.MSSP:
            hasPermission = user.role === RoleEnum.ADMIN || user.role === RoleEnum.MSSP;
            break;
          case RoleEnum.LEADER:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              (user.role === RoleEnum.LEADER &&
                entityId &&
                (user.entityId === entityId || user.entityId === subEntityId));
            break;
          case RoleEnum.PARTICIPANT:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.LEADER ||
              (user.role === RoleEnum.PARTICIPANT &&
                entityId &&
                subEntityId &&
                (user.entityId === entityId || user.entityId === subEntityId));
            break;
          case RoleEnum.VENDOR:
            hasPermission =
              user.role === RoleEnum.ADMIN ||
              user.role === RoleEnum.MSSP ||
              user.role === RoleEnum.LEADER ||
              (user.role === RoleEnum.VENDOR &&
                entityId &&
                subEntityId &&
                (user.entityId === entityId || user.entityId === subEntityId));
            break;
        }
      } else {
        this.logger.log('No user is found');
      }

      return Promise.resolve(hasPermission);
    } catch (e) {
      this.logger.error('hasPermission - Error: ', e);
      return Promise.reject(e);
    }
  }

  isEntityIdInUrl(url: string, entityId: string): boolean {
    let isIncluded = false;
    if (entityId && url.includes(entityId)) {
      isIncluded = true;
    }
    return isIncluded;
  }

  isAdmin(): boolean {
    return this.user && this.user.role === RoleEnum.ADMIN;
  }

  async addNewParticipant(newUser): Promise<any> {
    const inputUser = JSON.stringify(newUser);
    const domain = EntityService.getAdminGroup();
    return await this.customApi.AddCognitoUser({
      inputUser,
      domain,
    });
  }

  getCurrentEnvironment() {
    return environment.name;
  }

  getRegion() {
    return environment.region;
  }

  clearCache(): void {
    this.user = null;
  }

  setHeaderLoaderStatus(status) {
    this.headerLoaderSubscriber.next(status);
  }

  isFirstTimeUser() {
    return this.isFirstLogin && this.isAdmin() && !UtilsService.isBnB;
  }

  setWelcomeGuideStatus(event) {
    this.welcomeGuideStatus.next(event);
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(listener => listener.unsubscribe());
    this.clearCache();
  }

  // Temporary methods
  /**
   * to check if the current user is entity leader or sub-entity leader
   */
  isNotFromHigherRoles(): boolean {
    // handling the case for default roles.
    if (
      this.userRole &&
      this.userRole.name.toLowerCase() !== 'admin' &&
      this.userRole.name.toLowerCase() !== 'mssp' &&
      this.userRole.name.toLowerCase() !== 'entity leader' &&
      this.userRole.defaultOrEntityId === 'default'
    ) {
      return true;
    } else if (this.userRole && this.userRole.defaultOrEntityId !== 'default' && !this.userRole.isRootEntityAccess) {
      // handling the case for default roles.
      return true;
    } else {
      return false;
    }
  }

  /**
   * to check if user is participant or not.
   */
  isParticipant(): boolean {
    return this.userRole && this.userRole.name && this.userRole.name.toLowerCase() === 'participant' ? true : false;
  }

  /**
   *
   * @params parentRoleId is the role ID of the current role.
   * @returns the parent role of the current Role.
   */
  async getParentRole(parentRoleId: string): Promise<any> {
    try {
      return await this.customApi.GetRole(parentRoleId);
    } catch (e) {
      console.log('error in getting the parent role', e);
    }
  }

  async updateUserOrRole(user, byPassAuth = false) {
    try {
      await this.getCurrentUser();
      user = {
        userRole: this.userRole?.name,
        userDetail: user,
        byPassAuth,
      };
      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      const encyptedData = CryptoJS.AES.encrypt(JSON.stringify(user), 'cygovSecretKey2023').toString();
      const encyptedUserData = JSON.stringify(encyptedData);
      const params = {
        FunctionName: `userAuthorizer-${environment.name}`,
        Payload: encyptedUserData,
      };
      const response = await lambda.invoke(params).promise();
      let resp;
      if (response && response.Payload) {
        resp = JSON.parse(JSON.stringify(response.Payload));
        if (JSON.parse(resp).statusCode === 200) {
          this.toastr.success('User Updated');
        }
      }
      return JSON.parse(resp) || response;
    } catch (e) {
      this.toastr.error('Error while User Updated');
      console.log('Error - userAuthMiddleware');
    }
  }

  onDeleteUserListener(token): any {
    return this.customApi.OnDeleteUserListener(token).subscribe({
      next: (res: any) => {
        if (res) {
          const deleteUser = res.value.data.onDeleteUser;
          if (deleteUser && deleteUser.id === this.user.id) {
            this.toastr.success('This User Has Been Deleted');
            this.logOut()
              .then(() => {
                this.toastr.success('Logged out');
                window.location.reload();
              })
              .catch(err => {
                this.toastr.error('Log out failed');
              });
          }
        }
      },
      error: err => {
        console.log('Subscription error: ', err);
      },
    });
  }

  async checkTokensValidity() {
    try {
      const session = await Auth.currentSession();
      if (!session) {
        return false;
      }
      const accessToken = session.getAccessToken();
      const currentTime = Math.floor(Date.now() / 1000);
      const accessTokenExp = accessToken.getExpiration();
      // Check access token validity
      const isAccessTokenValid = currentTime < accessTokenExp;
      // Calculate refresh token validity
      const refreshTokenIssuedAt = session.getIdToken().decodePayload().iat;
      const refreshTokenValidityPeriod = 1 * 24 * 60 * 60; // 1 day in seconds
      const refreshTokenExp = refreshTokenIssuedAt + refreshTokenValidityPeriod;
      const isRefreshTokenValid = currentTime < refreshTokenExp;
      if (!isAccessTokenValid && !isRefreshTokenValid) {
        console.log('Both tokens are expired.');
        return false;
      }
      return true;
    } catch (error) {
      console.error('Error checking token validity:', error);
    }
  }
}
