import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';

// * Collection CSV Header row formatting
const HEADER_ROW_FORMAT: {
  font: Partial<Excel.Font>;
  fill: Excel.Fill;
  alignment: Partial<Excel.Alignment>;
  height: number;
} = {
  font: { name: 'Calibri', size: 14, bold: true, color: { argb: 'FFFFFF' } },
  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ff1b73f0' } },
  alignment: { wrapText: true, horizontal: 'center', vertical: 'middle' },
  height: 41,
};

// * Collection CSV Columns Alignments
const ALIGNMENTS: Partial<Excel.Alignment>[] = [
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'center', vertical: 'middle' },
  { wrapText: true, horizontal: 'center', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
  { wrapText: true, horizontal: 'left', vertical: 'middle' },
];

//* Collection CSV column headers
const HEADERS = [
  { name: 'Control Id', filterButton: true },
  { name: 'Function', filterButton: true },
  { name: 'Process Sub-Area', filterButton: true },
  { name: 'Control Name', filterButton: true },
  { name: 'Control Label', filterButton: true },
  { name: 'Control Objectives', filterButton: true },
  { name: 'Supplemented Guide', filterButton: true },
  { name: 'Severity', filterButton: true },
  { name: 'Answer', filterButton: true },
  { name: 'Comments', filterButton: true },
  { name: 'Audit Logs', filterButton: true },
  { name: 'Tags', filterButton: true },
  { name: 'Artifacts', filterButton: true },
];

//* Collection Column Widths
const COLS_WIDTH = [25, 25, 25, 25, 25, 34, 80, 19, 19, 34, 52, 35, 34];

//* Function Generates Excel File
export function generateCollectionExcelFile(data, fileName: string) {
  try {
    //* Collection report related constants
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    //* Add data to the worksheet
    worksheet.addTable({
      name: 'MyTable',
      ref: 'A1',
      headerRow: true,
      totalsRow: false,
      columns: HEADERS,
      rows: data.slice(1), // Exclude the header row from data
    });

    //* freeze Table headers
    worksheet.views = [{ state: 'frozen', xSplit: 0, ySplit: 1, topLeftCell: 'B2' }];

    const defaultHeight = 1;
    //* Setting Height for rows on tha base of content length
    worksheet.eachRow({ includeEmpty: true }, row => {
      let maxHeight = 0;

      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        cell.alignment = ALIGNMENTS[colNumber - 1];
        cell.font = { name: 'Calibri', size: 12 };
        let contentHeight = 1;

        //* Calculate the height required for the content in this cell
        contentHeight = calculateCellHeight(cell.value);

        //* Update the maximum height if needed
        maxHeight = Math.max(maxHeight, contentHeight);
      });

      //* Set the row height based on the maximum height
      row.height = Math.max(defaultHeight, maxHeight);
    });

    //* Set Widths of Columns
    for (let index = 1; index <= COLS_WIDTH.length; index++) {
      worksheet.getColumn(index).width = COLS_WIDTH[index - 1];
    }

    //* Table Headers Formatting
    const headerRow = worksheet.getRow(1);
    headerRow.font = HEADER_ROW_FORMAT.font;
    headerRow.fill = HEADER_ROW_FORMAT.fill;
    headerRow.alignment = HEADER_ROW_FORMAT.alignment;
    headerRow.height = HEADER_ROW_FORMAT.height;

    //* Write to node buffer
    workbook.xlsx.writeBuffer().then(function (buffer) {
      //* Create a Blob from the buffer
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      //* Save the Blob as a file using FileSaver.js
      saveAs(blob, fileName);
    });
  } catch (error) {
    console.error('❌❌❌ Error occurred: while creating Excel file', error);
    return null;
  }
}

//* Function to calculate the height needed for a cell based on its content
function calculateCellHeight(content) {
  //* Here, we'll use a simple calculation based on the number of lines
  let lines = content ? content.toString().split('\n').length : 1;
  if (content?.length > 30 && lines === 1) {
    lines = Math.ceil(content?.length / 30);
  }
  const defaultHeight = 20; // Adjust this value based on your font and styling
  const lineHeight = 20; // Adjust this value based on your font size and styling
  return defaultHeight + (lines - 1) * lineHeight;
}

/**
 * * Function generates and downloads CSV File
 * @param data csv data
 * @param fileName name of file
 */
export function generateCollectionCSVFile(data: any, fileName: string) {
  try {
    const csv = Papa.unparse(data);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    saveAs(csvData, fileName);
  } catch (error) {
    console.error('Error  occurred: while creating CSV file', error);
  }
}
