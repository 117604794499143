import { Component, OnInit, OnDestroy } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Router, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { UtilsService } from 'app/shared/utils.service';
import { GetUserQuery } from './API.service';
import { UserService } from './shared/user.service';
import { DomainFrameworkService } from './shared/domain-framework.service';
import { FileService } from 'app/shared/file.service';
@Component({
  selector: 'cygov-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  showSidenav = true;
  showLegend = false;
  isOnboarding = false;
  user: GetUserQuery;
  routeChangedSubscription: Subscription;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private userService: UserService,
    private domainFrameworkService: DomainFrameworkService,
    private fileService: FileService
  ) {
    const companyConfig = UtilsService.companyConfig;
    // Set index page title and meta
    this.titleService.setTitle(companyConfig.title);
    this.metaService.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    this.metaService.addTag({ name: 'og:title', content: companyConfig.ogTitle });
    this.metaService.addTag({ name: 'og:description', content: companyConfig.ogDescription });
    this.metaService.addTag({ name: 'og:image', content: companyConfig.ogImage });
    this.favIcon.href = companyConfig.favicon;

    this.routeChangedSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(val => {
        // check if user is authenticate in every route change
        if (val instanceof NavigationEnd) {
          this.setConfig(val);
          this.initDomainFramework();
        }
      });
  }

  async ngOnInit() {
    // https://github.com/DethAriel/ng-recaptcha/issues/123#issuecomment-426112101
    // eslint-disable-next-line @typescript-eslint/unbound-method
    RecaptchaComponent.prototype.ngOnDestroy = function () {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    };
    // Initiate the file service to load the Enums from s3. These enums are required before we go to any component
    await this.fileService.init();
    this.userService.observeUserInteraction();
  }

  setConfig(val): void {
    this.user = this.userService.getCurrentUserSync();
    this.isOnboarding = val.url.includes('onboarding');
    this.isAuthenticated = !!this.user;
    this.showSidenav = !!this.user;
    this.showLegend =
      (val.url.includes('clients') && !val.url.includes('user-settings')) ||
      val.url.includes('upperdeck') ||
      val.url.includes('multi-entity');
  }

  // Initialize domain domain allowed routes, domain average score and domain frameworks settings
  // this should be run after user logged-in.
  initDomainFramework(): void {
    if (this.isAuthenticated) {
      this.domainFrameworkService.init();
    }
  }

  ngOnDestroy(): void {
    this.routeChangedSubscription.unsubscribe();
  }
}
